import { ScaleIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import AdminSidebar from "../components/AdminSidebar";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  SearchIcon,
} from "@heroicons/react/solid";

import { useParams } from "react-router";
import { getToken, getUserId } from "../Authentication/Auth";
import PreLoader from "../components/PreLoader";

const styles = {
  detailName: "text-xl font-medium col-span-3",
  detailValue: "text-lg col-span-2 text-gray-600",
};

function SellerAccount() {
  const [isOpened, setIsOpened] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };
  const [sellerDetails, setSellerDetails] = useState(false);
  const [status, setStatus] = useState(null);

  const { id } = useParams();

  const loadSellerDetails = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/sellerDetails`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ id: id}),
    })
      .then((res) => res.json())
      .then((response) => {
        setValues(response);
        console.log(response);
      })
      .catch((error) => console.log(error)).finally(()=>setIsLoading(false));
  };

  const  updateUser = async () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/user`, {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: id,
        name: values.name,
        mobile1: values.mobile1,
        mobile2: values.mobile2,
        landLine: values.landLine,
        scaleOfBusiness: values.scaleOfBusiness,
        intervalBetweenHarvest: values.intervalBetweenHarvest,
        yieldPerHarvest: values.yieldPerHarvest,
        noOfTrees: values.noOfTrees,
        recieveCalls: values.recieveCalls,
        recieveEmails: values.recieveEmails,
        aditionalInfo: values.aditionalInfo,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        loadSellerDetails();
        setIsEdit();
      })
      .catch((error) => console.log(error)).finally(()=>setIsLoading(false));
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validate = async () => {
    const errors = {};
    console.log(values.mobile1,'console.log(values);');
    let phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!values.name) {
      errors.name = "Name is mandatory*.";
    }
    if (!values.mobile1) {
      errors.mobile1 = "Mobile is mandatory*.";
    } else if (!values.mobile1.toString().match(phoneNo)) {
      errors.mobile1 = "Mobile Number format is incorrect";
    }

    if (!values.mobile2) {
      errors.mobile2 = "Alt Mobile is mandatory*.";
    } else if (!values.mobile2.toString().match(phoneNo)) {
      errors.mobile2 = "Mobile Number format is incorrect";
    }

    if (!values.landLine) {
      errors.landLine = "Landline is mandatory*.";
    } else if (!values.landLine.toString().match(phoneNo)) {
      errors.landLine = "Landline Number format is incorrect";
    }

    if (!values.intervalBetweenHarvest || values.intervalBetweenHarvest <= 0) {
      errors.intervalBetweenHarvest = "Interval Between Harvest should be grater than 0";
    }

    if (!values.yieldPerHarvest || values.yieldPerHarvest <= 0) {
      errors.yieldPerHarvest = "Yield Per Harvest should be grater than 0";
    }

    if (!values.noOfTrees || values.noOfTrees <= 0) {
      errors.noOfTrees = "Total Number of Tree should be grater than 0";
    }
    setErrors(errors);
    return errors;
  };

  const activateSellerAccount = () => {
    if (status == null || status == "Select") {
      alert("Select Status to Continue!");
    } else {
      console.log(id);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/sellersAccountStatus`, {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ id: id, status: status }),
      })
        .then((res) => res.json())
        .then((response) => {
          setSellerDetails(response);
          console.log(response);
        })
        .catch((error) => console.log(error));
    }
  };
  useEffect(() => {
    loadSellerDetails();
  }, []);


  const EditableTR = ({
    title,
    inputName,
    error,
    value,
    isEditable = false,
    type = "text",
  }) => (
    <tr>
      <td className="px-6 py-4 text-sm font-medium text-gray-900">
        <div className={styles.detailName}>{title}</div>
      </td>
      <td className="px-6 py-4 text-sm font-medium">
        {isEditable ? (
          <input
            type={type}
            name={inputName}
            min={0}
            autoFocus={false}
            onChange={handleChange}
            value={value}
            className={`block px-0 w-full text-base font-medium text-gray-700 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
              errors[inputName]?.length ? "border-red-500" : "border-gray-300"
            }`}
            placeholder=" "
          />
        ) : (
          <div className={styles.detailValue}>{value ? value : "---"}</div>
        )}
         {errors[inputName]?.length && <span className="text-red-600">{errors[inputName]}</span>}
      </td>
    </tr>
  );

  const EditableSelectTR = ({
    title,
    inputName,
    value,
    options = [],
    isEditable = false,
  }) => (
    <tr>
      <td className="px-6 py-4 text-sm font-medium text-gray-900">
        <div className={styles.detailName}>{title}</div>
      </td>
      <td className="px-6 py-4 text-base font-medium">
        {isEditable ? (
          <div className="relative z-0 w-full group">
            <select
              className={`block appearance-none w-full bg-gray-200 border text-gray-700 py-2 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                errors[inputName]?.length ? "border-red-500" : "border-gray-200"
              }`}
              id="grid-state"
              name={inputName}
              value={value}
              onChange={handleChange}
            >
              {options.map((e, index) => {
                return (
                  <option key={index} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div className={styles.detailValue}>{value || "---"}</div>
        )}

        {errors[inputName]?.length && <span className="text-red-600">{errors[inputName]}</span>}
      </td>
    </tr>
  );


  return (
    <div className="h-screen flex">
      <AdminSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="sellers"
      />
      <div className="flex-1 flex overflow-hidden pt-20 ">
        <div class="flex-1 overflow-y-scroll">
          <ArrowRightIcon
            className={`w-10 ml-4 ${isOpened ? "hidden" : " md:hidden"}`}
            onClick={handleSidebar}
          />
          <div className="">
      <PreLoader isLoading={isLoading} />
      <div className="flex justify-center">
        <table className="">
          <thead className="border-b">
            <tr>
              <th
                colSpan={2}
                scope="col"
                className="w-full text-sm font-medium text-gray-900 px-6 py-4 text-center"
              >
                <h3 className="ml-8 text-xl font-semibold mb-4">Activate Seller Account</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <EditableTR
              title={"Name"}
              value={values.name}
              inputName={"name"}
              isEditable={isEdit}
            />
            <tr>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailName}>Email</div>
              </td>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.email ? values.email : "---"}
                </div>
              </td>
            </tr>
            <EditableTR
              title={"Mobile"}
              inputName={"mobile1"}
              value={values.mobile1}
              type="tel"
              isEditable={isEdit}
            />
            <EditableTR
              title={"Alt Mobile"}
              inputName={"mobile2"}
              value={values.mobile2}
              type="tel"
              isEditable={isEdit}
            />
            <EditableTR
              title={"Landline"}
              inputName={"landLine"}
              value={values.landLine}
              type="tel"
              isEditable={isEdit}
            />
            <EditableSelectTR
              title={"Scale Of Plantation"}
              inputName={"scaleOfBusiness"}
              value={values.scaleOfBusiness}
              options={[
                { name: "Small Scale", value: "Small Scale" },
                { name: "Medium Scale", value: "Medium Scale" },
                { name: "Large Scale", value: "Large Scale" },
              ]}
              isEditable={isEdit}
            />
            <EditableTR
              title={"Interval Between Harvest"}
              inputName={"intervalBetweenHarvest"}
              value={values.intervalBetweenHarvest}
              type="number"
              isEditable={isEdit}
            />
            <tr>
              <td class="px-6 py-4 text-sm font-medium text-gray-900">
                <div className={styles.detailName}> Total Land</div>
              </td>
              <td class="px-6 py-4 text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.totalHarvest ? values.totalHarvest : "---"} Acres
                </div>
              </td>
            </tr>
            <EditableTR
              title={"Yield Per Harvest"}
              inputName={"yieldPerHarvest"}
              value={values.yieldPerHarvest}
              type="number"
              isEditable={isEdit}
            />
            <EditableTR
              title={"Total Number of Tree"}
              inputName={"noOfTrees"}
              value={values.noOfTrees}
              type="number"
              isEditable={isEdit}
            />
            <tr>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailName}>Inheritor Name</div>
              </td>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.inheritorName ? values.inheritorName : "---"}
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailName}>Inheritor Mobile</div>
              </td>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.inheritorMobile ? values.inheritorMobile : "---"}
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-6 py-4 text-sm font-medium text-gray-900">
                <div className={styles.detailName}>Nearest City</div>
              </td>
              <td class="px-6 py-4 text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.nearestCity ? values.nearestCity : "---"}
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailName}>Areas to Collecting </div>
              </td>
              <td class="px-6 py-4  text-sm font-medium text-gray-900">
                <div className={styles.detailValue}>
                  {values.areasToCollect ? values.areasToCollect : "---"}
                </div>
              </td>
            </tr>
            <EditableSelectTR
              title={"Receive Calls"}
              inputName={"recieveCalls"}
              options={[
                { name: "true", value: "true" },
                { name: "false", value: "false" },
              ]}
              value={values.recieveCalls}
              isEditable={isEdit}
            />
            <EditableSelectTR
              title={"Receive Emails"}
              inputName={"recieveEmails"}
              options={[
                { name: "true", value: "true" },
                { name: "false", value: "false" },
              ]}
              value={values.recieveEmails}
              isEditable={isEdit}
            />
            <EditableTR
              title={"Aditional Info"}
              inputName={"aditionalInfo"}
              value={values.aditionalInfo}
              isEditable={isEdit}
            />
            <tr className="">
              <td className="">
                {isEdit && (
                  <button
                    type="button"
                    className="w-48 my-5 ml-5 p-2 bg-red-400 text-white font-medium text-base  uppercase rounded-full shadow-md hover:bg-orange-500 hover:shadow-lg focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-600 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Cancel
                  </button>
                )}
              </td>
              <td class="py-4 text-sm font-medium text-gray-900">
                <div>
                  {isEdit ? (
                    <button
                      type="button"
                      className="w-48 ml-5 my-5 p-2 bg-green-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={async () => {
                        const error = await validate();
                        if (Object.keys(error).length === 0) {
                          await updateUser();
                        }
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="w-48 mr-5 my-5 inline-block p-2 bg-orange-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-orange-500 hover:shadow-lg focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-600 active:shadow-lg transition duration-150 ease-in-out"
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-2 p-4 bg-green-100 mt-5 rounded-xl shadow-sm  ml-8 w-2/3 items-start">
            <div className="text-xl mt-1 col-span-2">Status</div>
            <select
              className="block col-span-3 appearance-none w-48 bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4  rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
              id="grid-state"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Select">Select</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Published">Published</option>
              <option value="Rejected">Rejected</option>
            </select>

            <div className="text-xl mt-1 col-span-2">Duration</div>
            <input
              type="number"
              name="fname"
              id="floating_first_name"
              class="col-span-3 block py-2.5 px-2 w-48 text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none bg-slate-200 mb-6"
              placeholder="0-90"
            />
            <div className="col-span-4">
              <button
                type="button"
                className="w-48 mr-5 inline-block p-2 bg-green-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                onClick={() => activateSellerAccount()}
              >
                Confirm
              </button>
              <button
                type="button"
                className="w-48 inline-block p-2 bg-red-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerAccount;
