import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getToken } from "../Authentication/Auth";
import OfficerSidebar from "../components/OfficerSidebar";
import Modal from "../components/Modal";
import { dateString, timeString } from "../common/utils";
import PreLoader from "../components/PreLoader";

const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};

const OfficerNotifications = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msgTitle, setMsgTitle] = useState("");
  const [msgBody, setMsgBody] = useState("");

  useEffect(() => {
    loadMessages();
  }, []);

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const loadMessages = () => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/notifications/myReferrals`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setReferrals(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const submitMessage = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ title: msgTitle, body: msgBody }),
    })
      .then((response) => {
        loadMessages()
        setAlert(false);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };
  const ratingForm = () => (
    <div className="w-full">
      <div className=" w-full">
        {/* <div className="m-1">
          <span>Message Title</span>
        </div> */}
        <div className="col-span-5">
          {" "}
          <input
            type="text"
            name="msgTitle"
            value={msgTitle}
            onChange={(e) => setMsgTitle(e.target.value)}
            class=" w-full block py-2.5 px-2 h-12 text-gray-900 bg-transparent border-2 rounded-md appearance-non mb-6"
            placeholder="Message Title"
          />
        </div>
      </div>
      {/* <div className={styles.detailName}>Message Body</div> */}
      <div className=" w-full col-span-5">
        <textarea
          className="text-base w-full rounded-md border-2 py-3 px-2"
          placeholder="Message Body"
          onChange={(e) => setMsgBody(e.target.value)}
          value={msgBody}
        ></textarea>
      </div>
    </div>
  );

  return (
    <>
      <PreLoader isLoading={isLoading} />
      <Modal
        open={openAddMessage}
        handleCancel={() => setOpenAddMessage(false)}
        handleOk={() => {
          setOpenAddMessage(false);
          setAlert(true);
        }}
        title={"New Message"}
      >
        <>{ratingForm()}</>
      </Modal>

      <Modal
        open={alert}
        handleCancel={() => setAlert(false)}
        handleOk={submitMessage}
        title={"Are you sure?"}
      >
        <>
          <p className="text-center">This step can not be undone!</p>
        </>
      </Modal>
      <div className="flex-1 flex overflow-hidden ">
        <div class="flex-1 overflow-y-scroll">
          <div className="h-screen flex">
            <OfficerSidebar
              handleSidebar={handleSidebar}
              isOpened={isOpened}
              selected="officerNotification"
            />
            <div className="flex-1 flex overflow-hidden pt-24 ">
              <div class="flex-1 overflow-y-scroll">
                <div className="flex justify-between">
                  <h3 className="ml-8 text-xl font-semibold mb-4">
                    Notification Campaigns
                  </h3>
                  <button
                    onClick={() => setOpenAddMessage(true)}
                    type="submit"
                    // disabled={isSubmitting}
                    class="mx-8 mt-0 text-white bg-green-600 hover:bg-green-700 focus:ring-1 focus:ring-yellow-400 font-medium rounded-lg text-sm w-auto px-5 py-2.5 text-center "
                  >
                    Add New
                  </button>
                </div>
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
                  <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                      <table class="min-w-full">
                        <thead class="border-b">
                          <tr>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Body
                            </th>

                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Time
                            </th>

                            {/* <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {referrals?.map((e, i) => {
                            return (
                              <tr key={e?.id} class="border-b">
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {i + 1}
                                </td>
                                <td class="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                                  {e.title}
                                </td>
                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {e.body}
                                </td>
                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {timeString(e.createdAt)}
                                </td>
                                <td class="text-sm  px-6 py-4 whitespace-nowrap  text-gray-600">
                                  {dateString(e.createdAt)}
                                </td>
                                {/* <td class="text-sm  px-6 py-4 whitespace-nowrap font-bold text-gray-600">
                                    {"Not Paid"}
                                  </td> */}

                                {/* <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <a
                                    className="bg-red-500 p-2 text-white rounded-xl"
                                    href={"/sellerAccount" + e._id}
                                  >
                                    Change Status
                                  </a>
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default OfficerNotifications;
