import React, { Fragment } from "react";
import { ArrowLeftIcon, UserAddIcon ,BellIcon} from "@heroicons/react/solid";
import useCheckMobileScreen from "../common/hooks/isMobile";
import { Transition } from "@headlessui/react";

function OfficerSidebar({ handleSidebar, isOpened, selected }) {
  return (
    <Transition
    as={Fragment}
    enter="transition ease duration-500 transform "
    enterFrom="opacity-0 -translate-x-12"
    enterTo="opacity-100 translate-x-0"
    leave="transition ease duration-300 transform"
    leaveFrom="opacity-100 translate-x-0"
    leaveTo="opacity-0 -translate-x-12"
    show={isOpened || !useCheckMobileScreen()}
  >
    <div
      className={` sm:flex sm:opacity-100 w-60 h-screen shadow-md bg-lime-100 pt-20 transition-all absolute sm:relative ${
        isOpened ? "z-40" : ""
      }`}
    >
      <ul class="relative">
        <li
          class={`relative w-60 mt-10 ${
            selected === "referral" ? "bg-lime-200" : null
          }`}
        >
          <a
            class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
            href="/officerReferral"
            data-mdb-ripple="true"
            data-mdb-ripple-color="dark"
          >
            <UserAddIcon className="w-8 mx-2" />
            Referral
          </a>
        </li>
        <li
          class={`relative w-60 mt-0.5 ${
            selected === "officerNotification" ? "bg-lime-200" : null
          }`}
        >
          <a
            class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
            href="/officerNotifications"
            data-mdb-ripple="true"
            data-mdb-ripple-color="dark"
          >
            <BellIcon className="w-7 mx-2 mr-3" />
            Notifications
          </a>
        </li>
      </ul>
    </div>
    </Transition>
  );
}

export default OfficerSidebar;
