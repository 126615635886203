export const allCities = [
  {
    id: 1,
    name: "Akkaraipattu",
    si: "අක්කරපත්තුව",
    en: "Akkaraipattu",
  },
  {
    id: 1,
    name: "Ambagahawatta",
    si: "අඹගහවත්ත",
    en: "Ambagahawatta",
  },
  {
    id: 1,
    name: "Ampara",
    si: "අම්පාර",
    en: "Ampara",
  },
  {
    id: 1,
    name: "Bakmitiyawa",
    si: "බක්මිටියාව",
    en: "Bakmitiyawa",
  },
  {
    id: 1,
    name: "Deegawapiya",
    si: "දීඝවාපිය",
    en: "Deegawapiya",
  },
  {
    id: 1,
    name: "Devalahinda",
    si: "දෙවලහිඳ",
    en: "Devalahinda",
  },
  {
    id: 1,
    name: "Digamadulla Weeragoda",
    si: "දිගාමඩුල්ල වීරගොඩ",
    en: "Digamadulla Weeragoda",
  },
  {
    id: 1,
    name: "Dorakumbura",
    si: "දොරකුඹුර",
    en: "Dorakumbura",
  },
  {
    id: 1,
    name: "Gonagolla",
    si: "ගොනගොල්ල",
    en: "Gonagolla",
  },
  {
    id: 1,
    name: "Hulannuge",
    si: "හුලංනුගේ",
    en: "Hulannuge",
  },
  {
    id: 1,
    name: "Kalmunai",
    si: "කල්මුණේ",
    en: "Kalmunai",
  },
  {
    id: 1,
    name: "Kannakipuram",
    si: "කන්නකිපුරම්",
    en: "Kannakipuram",
  },
  {
    id: 1,
    name: "Karativu",
    si: "කරතිව්",
    en: "Karativu",
  },
  {
    id: 1,
    name: "Kekirihena",
    si: "කැකිරිහේන",
    en: "Kekirihena",
  },
  {
    id: 1,
    name: "Koknahara",
    si: "කොක්නහර",
    en: "Koknahara",
  },
  {
    id: 1,
    name: "Kolamanthalawa",
    si: "කෝලමන්තලාව",
    en: "Kolamanthalawa",
  },
  {
    id: 1,
    name: "Komari",
    si: "කෝමාරි",
    en: "Komari",
  },
  {
    id: 1,
    name: "Lahugala",
    si: "ලාහුගල",
    en: "Lahugala",
  },
  {
    id: 1,
    name: "lmkkamam",
    si: "ල්ම්ක්කමම්",
    en: "lmkkamam",
  },
  {
    id: 1,
    name: "Mahaoya",
    si: "මහඔය",
    en: "Mahaoya",
  },
  {
    id: 1,
    name: "Marathamune",
    si: "මාරත්මුනේ",
    en: "Marathamune",
  },
  {
    id: 1,
    name: "Namaloya",
    si: "නාමල්ඔය",
    en: "Namaloya",
  },
  {
    id: 1,
    name: "Navithanveli",
    si: "නාවිදන්වෙලි",
    en: "Navithanveli",
  },
  {
    id: 1,
    name: "Nintavur",
    si: "නින්දවූර්",
    en: "Nintavur",
  },
  {
    id: 1,
    name: "Oluvil",
    si: "ඔළුවිල",
    en: "Oluvil",
  },
  {
    id: 1,
    name: "Padiyatalawa",
    si: "පදියතලාව",
    en: "Padiyatalawa",
  },
  {
    id: 1,
    name: "Pahalalanda",
    si: "පහලලන්ද",
    en: "Pahalalanda",
  },
  {
    id: 1,
    name: "Panama",
    si: "පානම",
    en: "Panama",
  },
  {
    id: 1,
    name: "Pannalagama",
    si: "පන්නලගම",
    en: "Pannalagama",
  },
  {
    id: 1,
    name: "Paragahakele",
    si: "පරගහකැලේ",
    en: "Paragahakele",
  },
  {
    id: 1,
    name: "Periyaneelavanai",
    si: "පෙරියනීලවන්නි",
    en: "Periyaneelavanai",
  },
  {
    id: 1,
    name: "Polwaga Janapadaya",
    si: "පොල්වග ජනපදය",
    en: "Polwaga Janapadaya",
  },
  {
    id: 1,
    name: "Pottuvil",
    si: "පොතුවිල්",
    en: "Pottuvil",
  },
  {
    id: 1,
    name: "Sainthamaruthu",
    si: "සායින්දමරුදු",
    en: "Sainthamaruthu",
  },
  {
    id: 1,
    name: "Samanthurai",
    si: "සමන්තුරේ",
    en: "Samanthurai",
  },
  {
    id: 1,
    name: "Serankada",
    si: "සේරන්කද",
    en: "Serankada",
  },
  {
    id: 1,
    name: "Tempitiya",
    si: "ටැම්පිටිය",
    en: "Tempitiya",
  },
  {
    id: 1,
    name: "Thambiluvil",
    si: "ල්තැඹිළුවි",
    en: "Thambiluvil",
  },
  {
    id: 1,
    name: "Tirukovil",
    si: "තිරුකෝවිල",
    en: "Tirukovil",
  },
  {
    id: 1,
    name: "Uhana",
    si: "උහන",
    en: "Uhana",
  },
  {
    id: 1,
    name: "Wadinagala",
    si: "වඩිනාගල",
    en: "Wadinagala",
  },
  {
    id: 1,
    name: "Wanagamuwa",
    si: "වනගමුව",
    en: "Wanagamuwa",
  },
  {
    id: 2,
    name: "Angamuwa",
    si: "අංගමුව",
    en: "Angamuwa",
  },
  {
    id: 2,
    name: "Anuradhapura",
    si: "අනුරාධපුරය",
    en: "Anuradhapura",
  },
  {
    id: 2,
    name: "Awukana",
    si: "අව්කන",
    en: "Awukana",
  },
  {
    id: 2,
    name: "Bogahawewa",
    si: "බෝගහවැව",
    en: "Bogahawewa",
  },
  {
    id: 2,
    name: "Dematawewa",
    si: "දෙමටවැව",
    en: "Dematawewa",
  },
  {
    id: 2,
    name: "Dimbulagala",
    si: "දිඹුලාගල",
    en: "Dimbulagala",
  },
  {
    id: 2,
    name: "Dutuwewa",
    si: "දුටුවැව",
    en: "Dutuwewa",
  },
  {
    id: 2,
    name: "Elayapattuwa",
    si: "ඇලයාපත්තුව",
    en: "Elayapattuwa",
  },
  {
    id: 2,
    name: "Ellewewa",
    si: "ඇල්ලේවැව",
    en: "Ellewewa",
  },
  {
    id: 2,
    name: "Eppawala",
    si: "එප්පාවල",
    en: "Eppawala",
  },
  {
    id: 2,
    name: "Etawatunuwewa",
    si: "ඇතාවැටුනවැව",
    en: "Etawatunuwewa",
  },
  {
    id: 2,
    name: "Etaweeragollewa",
    si: "ඇතාවීරගොලෑව",
    en: "Etaweeragollewa",
  },
  {
    id: 2,
    name: "Galapitagala",
    si: "ගලපිටගල",
    en: "Galapitagala",
  },
  {
    id: 2,
    name: "Galenbindunuwewa",
    si: "ගලෙන්බිඳුනුවැව",
    en: "Galenbindunuwewa",
  },
  {
    id: 2,
    name: "Galkadawala",
    si: "ගල්කඩවල",
    en: "Galkadawala",
  },
  {
    id: 2,
    name: "Galkiriyagama",
    si: "ගල්කිරියාගම",
    en: "Galkiriyagama",
  },
  {
    id: 2,
    name: "Galkulama",
    si: "ගල්කුලම",
    en: "Galkulama",
  },
  {
    id: 2,
    name: "Galnewa",
    si: "ගල්නෑව",
    en: "Galnewa",
  },
  {
    id: 2,
    name: "Gambirigaswewa",
    si: "ගම්බිරිගස්වැව",
    en: "Gambirigaswewa",
  },
  {
    id: 2,
    name: "Ganewalpola",
    si: "ගනේවල්පොල",
    en: "Ganewalpola",
  },
  {
    id: 2,
    name: "Gemunupura",
    si: "ගැමුණුපුර",
    en: "Gemunupura",
  },
  {
    id: 2,
    name: "Getalawa",
    si: "ගෙතලාව",
    en: "Getalawa",
  },
  {
    id: 2,
    name: "Gnanikulama",
    si: "ඝාණිකුළම",
    en: "Gnanikulama",
  },
  {
    id: 2,
    name: "Gonahaddenawa",
    si: "ගෝනහද්දෙනෑව",
    en: "Gonahaddenawa",
  },
  {
    id: 2,
    name: "Habarana",
    si: "හබරන",
    en: "Habarana",
  },
  {
    id: 2,
    name: "Halmillawa Dambulla",
    si: "හල්මිලෑව දඹුල්ල",
    en: "Halmillawa Dambulla",
  },
  {
    id: 2,
    name: "Halmillawetiya",
    si: "හල්මිල්ලවැටිය",
    en: "Halmillawetiya",
  },
  {
    id: 2,
    name: "Hidogama",
    si: "හිද්දෝගම",
    en: "Hidogama",
  },
  {
    id: 2,
    name: "Horawpatana",
    si: "හොරොව්පතාන",
    en: "Horawpatana",
  },
  {
    id: 2,
    name: "Horiwila",
    si: "හොරිවිල",
    en: "Horiwila",
  },
  {
    id: 2,
    name: "Hurigaswewa",
    si: "හුරිගස්වැව",
    en: "Hurigaswewa",
  },
  {
    id: 2,
    name: "Hurulunikawewa",
    si: "හුරුලුනිකවැව",
    en: "Hurulunikawewa",
  },
  {
    id: 2,
    name: "Ihala Puliyankulama",
    si: "ඉහල පුලියන්කුලම",
    en: "Ihala Puliyankulama",
  },
  {
    id: 2,
    name: "Kagama",
    si: "කගම",
    en: "Kagama",
  },
  {
    id: 2,
    name: "Kahatagasdigiliya",
    si: "කහටගස්දිගිලිය",
    en: "Kahatagasdigiliya",
  },
  {
    id: 2,
    name: "Kahatagollewa",
    si: "කහටගොල්ලෑව",
    en: "Kahatagollewa",
  },
  {
    id: 2,
    name: "Kalakarambewa",
    si: "කලකරඹෑව",
    en: "Kalakarambewa",
  },
  {
    id: 2,
    name: "Kalaoya",
    si: "කලාඔය",
    en: "Kalaoya",
  },
  {
    id: 2,
    name: "Kalawedi Ulpotha",
    si: "කලාවැදි උල්පොත",
    en: "Kalawedi Ulpotha",
  },
  {
    id: 2,
    name: "Kallanchiya",
    si: "කලංචිය",
    en: "Kallanchiya",
  },
  {
    id: 2,
    name: "Kalpitiya",
    si: "කල්පිටිය",
    en: "Kalpitiya",
  },
  {
    id: 2,
    name: "Kalukele Badanagala",
    si: "කළුකැලේ බදනාගල",
    en: "Kalukele Badanagala",
  },
  {
    id: 2,
    name: "Kapugallawa",
    si: "කපුගල්ලව",
    en: "Kapugallawa",
  },
  {
    id: 2,
    name: "Karagahawewa",
    si: "කරගහවැව",
    en: "Karagahawewa",
  },
  {
    id: 2,
    name: "Kashyapapura",
    si: "කාශ්‍යපපුර",
    en: "Kashyapapura",
  },
  {
    id: 2,
    name: "Kebithigollewa",
    si: "කැබිතිගොල්ලෑව",
    en: "Kebithigollewa",
  },
  {
    id: 2,
    name: "Kekirawa",
    si: "කැකිරාව",
    en: "Kekirawa",
  },
  {
    id: 2,
    name: "Kendewa",
    si: "කේන්දෑව",
    en: "Kendewa",
  },
  {
    id: 2,
    name: "Kiralogama",
    si: "කිරළෝගම",
    en: "Kiralogama",
  },
  {
    id: 2,
    name: "Kirigalwewa",
    si: "කිරිගල්වැව",
    en: "Kirigalwewa",
  },
  {
    id: 2,
    name: "Kirimundalama",
    si: "කිරිමුන්ඩලම",
    en: "Kirimundalama",
  },
  {
    id: 2,
    name: "Kitulhitiyawa",
    si: "කිතුල්හිටියාව",
    en: "Kitulhitiyawa",
  },
  {
    id: 2,
    name: "Kurundankulama",
    si: "කුරුන්දන්කුලම",
    en: "Kurundankulama",
  },
  {
    id: 2,
    name: "Labunoruwa",
    si: "ලබුනෝරුව",
    en: "Labunoruwa",
  },
  {
    id: 2,
    name: "Ihalagama",
    si: "ඉහලගම",
    en: "Ihalagama",
  },
  {
    id: 2,
    name: "Ipologama",
    si: "ඉපොලොගම",
    en: "Ipologama",
  },
  {
    id: 2,
    name: "Madatugama",
    si: "මාදතුගම",
    en: "Madatugama",
  },
  {
    id: 2,
    name: "Maha Elagamuwa",
    si: "මහ ඇලගමුව",
    en: "Maha Elagamuwa",
  },
  {
    id: 2,
    name: "Mahabulankulama",
    si: "මහබුලංකුලම",
    en: "Mahabulankulama",
  },
  {
    id: 2,
    name: "Mahailluppallama",
    si: "මහඉලුප්පල්ලම",
    en: "Mahailluppallama",
  },
  {
    id: 2,
    name: "Mahakanadarawa",
    si: "මහකනදරාව",
    en: "Mahakanadarawa",
  },
  {
    id: 2,
    name: "Mahapothana",
    si: "මහපොතාන",
    en: "Mahapothana",
  },
  {
    id: 2,
    name: "Mahasenpura",
    si: "මහසෙන්පුර",
    en: "Mahasenpura",
  },
  {
    id: 2,
    name: "Mahawilachchiya",
    si: "මහවිලච්චිය",
    en: "Mahawilachchiya",
  },
  {
    id: 2,
    name: "Mailagaswewa",
    si: "මයිලගස්වැව",
    en: "Mailagaswewa",
  },
  {
    id: 2,
    name: "Malwanagama",
    si: "මල්වනගම",
    en: "Malwanagama",
  },
  {
    id: 2,
    name: "Maneruwa",
    si: "මනේරුව",
    en: "Maneruwa",
  },
  {
    id: 2,
    name: "Maradankadawala",
    si: "මරදන්කඩවල",
    en: "Maradankadawala",
  },
  {
    id: 2,
    name: "Maradankalla",
    si: "මරදන්කල්ල",
    en: "Maradankalla",
  },
  {
    id: 2,
    name: "Medawachchiya",
    si: "මැදවච්චිය",
    en: "Medawachchiya",
  },
  {
    id: 2,
    name: "Megodawewa",
    si: "මීගොඩවැව",
    en: "Megodawewa",
  },
  {
    id: 2,
    name: "Mihintale",
    si: "මිහින්තලේ",
    en: "Mihintale",
  },
  {
    id: 2,
    name: "Morakewa",
    si: "මොරකෑව",
    en: "Morakewa",
  },
  {
    id: 2,
    name: "Mulkiriyawa",
    si: "මුල්කිරියාව",
    en: "Mulkiriyawa",
  },
  {
    id: 2,
    name: "Muriyakadawala",
    si: "මුරියකඩවල",
    en: "Muriyakadawala",
  },
  {
    id: 5,
    name: "Colombo 15",
    si: "කොළඹ 15",
    en: "Colombo 15",
  },
  {
    id: 2,
    name: "Nachchaduwa",
    si: "නච්චදූව",
    en: "Nachchaduwa",
  },
  {
    id: 2,
    name: "Namalpura",
    si: "නාමල්පුර",
    en: "Namalpura",
  },
  {
    id: 2,
    name: "Negampaha",
    si: "නෑගම්පහ",
    en: "Negampaha",
  },
  {
    id: 2,
    name: "Nochchiyagama",
    si: "නොච්චියාගම",
    en: "Nochchiyagama",
  },
  {
    id: 2,
    name: "Nuwaragala",
    si: "නුවරගල",
    en: "Nuwaragala",
  },
  {
    id: 2,
    name: "Padavi Maithripura",
    si: "පදවි මෛත්‍රීපුර",
    en: "Padavi Maithripura",
  },
  {
    id: 2,
    name: "Padavi Parakramapura",
    si: "පදවි පරාක්‍රමපුර",
    en: "Padavi Parakramapura",
  },
  {
    id: 2,
    name: "Padavi Sripura",
    si: "පදවි ශ්‍රීපුර",
    en: "Padavi Sripura",
  },
  {
    id: 2,
    name: "Padavi Sritissapura",
    si: "පදවි ශ්‍රීතිස්සපුර",
    en: "Padavi Sritissapura",
  },
  {
    id: 2,
    name: "Padaviya",
    si: "පදවිය",
    en: "Padaviya",
  },
  {
    id: 2,
    name: "Padikaramaduwa",
    si: "පඩිකරමඩුව",
    en: "Padikaramaduwa",
  },
  {
    id: 2,
    name: "Pahala Halmillewa",
    si: "පහල හල්මිල්ලෑව",
    en: "Pahala Halmillewa",
  },
  {
    id: 2,
    name: "Pahala Maragahawe",
    si: "පහල මරගහවෙ",
    en: "Pahala Maragahawe",
  },
  {
    id: 2,
    name: "Pahalagama",
    si: "පහලගම",
    en: "Pahalagama",
  },
  {
    id: 2,
    name: "Palugaswewa",
    si: "පලුගස්වැව",
    en: "Palugaswewa",
  },
  {
    id: 2,
    name: "Pandukabayapura",
    si: "පන්ඩුකාබයපුර",
    en: "Pandukabayapura",
  },
  {
    id: 2,
    name: "Pandulagama",
    si: "පන්ඩුලගම",
    en: "Pandulagama",
  },
  {
    id: 2,
    name: "Parakumpura",
    si: "පරාක්‍රමපුර",
    en: "Parakumpura",
  },
  {
    id: 2,
    name: "Parangiyawadiya",
    si: "පරංගියාවාඩිය",
    en: "Parangiyawadiya",
  },
  {
    id: 2,
    name: "Parasangahawewa",
    si: "පරසන්ගහවැව",
    en: "Parasangahawewa",
  },
  {
    id: 2,
    name: "Pelatiyawa",
    si: "පැලටියාව",
    en: "Pelatiyawa",
  },
  {
    id: 2,
    name: "Pemaduwa",
    si: "පෙමදූව",
    en: "Pemaduwa",
  },
  {
    id: 2,
    name: "Perimiyankulama",
    si: "පෙරිමියන්කුලම",
    en: "Perimiyankulama",
  },
  {
    id: 2,
    name: "Pihimbiyagolewa",
    si: "පිහිඹියගොල්ලෑව",
    en: "Pihimbiyagolewa",
  },
  {
    id: 2,
    name: "Pubbogama",
    si: "පුබ්බෝගම",
    en: "Pubbogama",
  },
  {
    id: 2,
    name: "Punewa",
    si: "පූනෑව",
    en: "Punewa",
  },
  {
    id: 2,
    name: "Rajanganaya",
    si: "රාජාංගනය",
    en: "Rajanganaya",
  },
  {
    id: 2,
    name: "Rambewa",
    si: "රම්බෑව්",
    en: "Rambewa",
  },
  {
    id: 2,
    name: "Rampathwila",
    si: "රම්පත්විල",
    en: "Rampathwila",
  },
  {
    id: 2,
    name: "Rathmalgahawewa",
    si: "රත්මල්ගහවැව",
    en: "Rathmalgahawewa",
  },
  {
    id: 2,
    name: "Saliyapura",
    si: "සාලියපුර",
    en: "Saliyapura",
  },
  {
    id: 2,
    name: "Seeppukulama",
    si: "සීප්පුකුලම",
    en: "Seeppukulama",
  },
  {
    id: 2,
    name: "Senapura",
    si: "සේනාපුර",
    en: "Senapura",
  },
  {
    id: 2,
    name: "Sivalakulama",
    si: "සිවලකුලම",
    en: "Sivalakulama",
  },
  {
    id: 2,
    name: "Siyambalewa",
    si: "සියඹලෑව",
    en: "Siyambalewa",
  },
  {
    id: 2,
    name: "Sravasthipura",
    si: "ස්‍රාවස්තිපුර",
    en: "Sravasthipura",
  },
  {
    id: 2,
    name: "Talawa",
    si: "තලාව",
    en: "Talawa",
  },
  {
    id: 2,
    name: "Tambuttegama",
    si: "තඹුත්තේගම",
    en: "Tambuttegama",
  },
  {
    id: 2,
    name: "Tammennawa",
    si: "තම්මැන්නාව",
    en: "Tammennawa",
  },
  {
    id: 2,
    name: "Tantirimale",
    si: "තන්තිරිමලේ",
    en: "Tantirimale",
  },
  {
    id: 2,
    name: "Telhiriyawa",
    si: "තෙල්හිරියාව",
    en: "Telhiriyawa",
  },
  {
    id: 2,
    name: "Tirappane",
    si: "තිරප්පනේ",
    en: "Tirappane",
  },
  {
    id: 2,
    name: "Tittagonewa",
    si: "තිත්තගෝනෑව",
    en: "Tittagonewa",
  },
  {
    id: 2,
    name: "Udunuwara Colony",
    si: "උඩුනුවර කොළණිය",
    en: "Udunuwara Colony",
  },
  {
    id: 2,
    name: "Upuldeniya",
    si: "උපුල්දෙනිය",
    en: "Upuldeniya",
  },
  {
    id: 2,
    name: "Uttimaduwa",
    si: "උට්ටිමඩුව",
    en: "Uttimaduwa",
  },
  {
    id: 2,
    name: "Vellamanal",
    si: "වෙල්ලමනල්",
    en: "Vellamanal",
  },
  {
    id: 2,
    name: "Viharapalugama",
    si: "විහාරපාළුගම",
    en: "Viharapalugama",
  },
  {
    id: 2,
    name: "Wahalkada",
    si: "වාහල්කඩ",
    en: "Wahalkada",
  },
  {
    id: 2,
    name: "Wahamalgollewa",
    si: "වහමල්ගොල්ලෑව",
    en: "Wahamalgollewa",
  },
  {
    id: 2,
    name: "Walagambahuwa",
    si: "වලගම්බාහුව",
    en: "Walagambahuwa",
  },
  {
    id: 2,
    name: "Walahaviddawewa",
    si: "වලහාවිද්දෑව",
    en: "Walahaviddawewa",
  },
  {
    id: 2,
    name: "Welimuwapotana",
    si: "වැලිමුවපතාන",
    en: "Welimuwapotana",
  },
  {
    id: 2,
    name: "Welioya Project",
    si: "වැලිඔය ව්‍යාපෘතිය",
    en: "Welioya Project",
  },
  {
    id: 3,
    name: "Akkarasiyaya",
    si: "අක්කරසියය",
    en: "Akkarasiyaya",
  },
  {
    id: 3,
    name: "Aluketiyawa",
    si: "අලුකෙටියාව",
    en: "Aluketiyawa",
  },
  {
    id: 3,
    name: "Aluttaramma",
    si: "අළුත්තරම",
    en: "Aluttaramma",
  },
  {
    id: 3,
    name: "Ambadandegama",
    si: "අඹදන්ඩෙගම",
    en: "Ambadandegama",
  },
  {
    id: 3,
    name: "Ambagasdowa",
    si: "අඹගස්දූව",
    en: "Ambagasdowa",
  },
  {
    id: 3,
    name: "Arawa",
    si: "අරාව",
    en: "Arawa",
  },
  {
    id: 3,
    name: "Arawakumbura",
    si: "අරාවකුඹුර",
    en: "Arawakumbura",
  },
  {
    id: 3,
    name: "Arawatta",
    si: "අරාවත්ත",
    en: "Arawatta",
  },
  {
    id: 3,
    name: "Atakiriya",
    si: "අටකිරියාව",
    en: "Atakiriya",
  },
  {
    id: 3,
    name: "Badulla",
    si: "බදුල්ල",
    en: "Badulla",
  },
  {
    id: 3,
    name: "Baduluoya",
    si: "බදුලුඔය",
    en: "Baduluoya",
  },
  {
    id: 3,
    name: "Ballaketuwa",
    si: "බල්ලකැටුව",
    en: "Ballaketuwa",
  },
  {
    id: 3,
    name: "Bambarapana",
    si: "බඹරපාන",
    en: "Bambarapana",
  },
  {
    id: 3,
    name: "Bandarawela",
    si: "බණ්ඩාරවෙල",
    en: "Bandarawela",
  },
  {
    id: 3,
    name: "Beramada",
    si: "බෙරමඩ",
    en: "Beramada",
  },
  {
    id: 3,
    name: "Bibilegama",
    si: "බිබිලේගම",
    en: "Bibilegama",
  },
  {
    id: 3,
    name: "Boragas",
    si: "බොරගස්",
    en: "Boragas",
  },
  {
    id: 3,
    name: "Boralanda",
    si: "බොරලන්ද",
    en: "Boralanda",
  },
  {
    id: 3,
    name: "Bowela",
    si: "බෝවෙල",
    en: "Bowela",
  },
  {
    id: 3,
    name: "Central Camp",
    si: "මධ්‍යම කඳවුර",
    en: "Central Camp",
  },
  {
    id: 3,
    name: "Damanewela",
    si: "දමනෙවෙල",
    en: "Damanewela",
  },
  {
    id: 3,
    name: "Dambana",
    si: "දඹාන",
    en: "Dambana",
  },
  {
    id: 3,
    name: "Dehiattakandiya",
    si: "දෙහිඅත්තකන්ඩිය",
    en: "Dehiattakandiya",
  },
  {
    id: 3,
    name: "Demodara",
    si: "දෙමෝදර",
    en: "Demodara",
  },
  {
    id: 3,
    name: "Diganatenna",
    si: "දිගනතැන්න",
    en: "Diganatenna",
  },
  {
    id: 3,
    name: "Dikkapitiya",
    si: "දික්කපිටිය",
    en: "Dikkapitiya",
  },
  {
    id: 3,
    name: "Dimbulana",
    si: "දිඹුලාන",
    en: "Dimbulana",
  },
  {
    id: 3,
    name: "Divulapelessa",
    si: "දිවුලපැලැස්ස",
    en: "Divulapelessa",
  },
  {
    id: 3,
    name: "Diyatalawa",
    si: "දියතලාව",
    en: "Diyatalawa",
  },
  {
    id: 3,
    name: "Dulgolla",
    si: "දුල්ගොල්ල",
    en: "Dulgolla",
  },
  {
    id: 3,
    name: "Ekiriyankumbura",
    si: "ඇකිරියන්කුඹුර",
    en: "Ekiriyankumbura",
  },
  {
    id: 3,
    name: "Ella",
    si: "ඇල්ල",
    en: "Ella",
  },
  {
    id: 3,
    name: "Ettampitiya",
    si: "ඇට්ටම්පිටිය",
    en: "Ettampitiya",
  },
  {
    id: 3,
    name: "Galauda",
    si: "ගලඋඩ",
    en: "Galauda",
  },
  {
    id: 3,
    name: "Galporuyaya",
    si: "ගල්පොරුයාය",
    en: "Galporuyaya",
  },
  {
    id: 3,
    name: "Gawarawela",
    si: "ගවරවෙල",
    en: "Gawarawela",
  },
  {
    id: 3,
    name: "Girandurukotte",
    si: "ගිරාඳුරුකෝට්ටෙ",
    en: "Girandurukotte",
  },
  {
    id: 3,
    name: "Godunna",
    si: "ගොඩුන්න",
    en: "Godunna",
  },
  {
    id: 3,
    name: "Gurutalawa",
    si: "ගුරුතලාව",
    en: "Gurutalawa",
  },
  {
    id: 3,
    name: "Haldummulla",
    si: "හල්දුම්මුල්ල",
    en: "Haldummulla",
  },
  {
    id: 3,
    name: "Hali Ela",
    si: "හාලි ඇල",
    en: "Hali Ela",
  },
  {
    id: 3,
    name: "Hangunnawa",
    si: "හඟුන්නෑව",
    en: "Hangunnawa",
  },
  {
    id: 3,
    name: "Haputale",
    si: "හපුතලේ",
    en: "Haputale",
  },
  {
    id: 3,
    name: "Hebarawa",
    si: "හබරාව",
    en: "Hebarawa",
  },
  {
    id: 3,
    name: "Heeloya",
    si: "හීලොය",
    en: "Heeloya",
  },
  {
    id: 3,
    name: "Helahalpe",
    si: "හෙලහල්පේ",
    en: "Helahalpe",
  },
  {
    id: 3,
    name: "Helapupula",
    si: "හෙලපුපුළ",
    en: "Helapupula",
  },
  {
    id: 3,
    name: "Hopton",
    si: "හෝප්ටන්",
    en: "Hopton",
  },
  {
    id: 3,
    name: "Idalgashinna",
    si: "ඉදල්ගස්ඉන්න",
    en: "Idalgashinna",
  },
  {
    id: 3,
    name: "Kahataruppa",
    si: "කහටරුප්ප",
    en: "Kahataruppa",
  },
  {
    id: 3,
    name: "Kalugahakandura",
    si: "කළුගහකණ්ඳුර",
    en: "Kalugahakandura",
  },
  {
    id: 3,
    name: "Kalupahana",
    si: "කළුපහණ",
    en: "Kalupahana",
  },
  {
    id: 3,
    name: "Kebillawela",
    si: "කොබිල්ලවෙල",
    en: "Kebillawela",
  },
  {
    id: 3,
    name: "Kendagolla",
    si: "කන්දෙගොල්ල",
    en: "Kendagolla",
  },
  {
    id: 3,
    name: "Keselpotha",
    si: "කෙසෙල්පොත",
    en: "Keselpotha",
  },
  {
    id: 3,
    name: "Ketawatta",
    si: "කේතවත්ත",
    en: "Ketawatta",
  },
  {
    id: 3,
    name: "Kiriwanagama",
    si: "කිරිවනගම",
    en: "Kiriwanagama",
  },
  {
    id: 3,
    name: "Koslanda",
    si: "කොස්ලන්ද",
    en: "Koslanda",
  },
  {
    id: 3,
    name: "Kuruwitenna",
    si: "Kuruwitenna",
    en: "Kuruwitenna",
  },
  {
    id: 3,
    name: "Kuttiyagolla",
    si: "Kuttiyagolla",
    en: "Kuttiyagolla",
  },
  {
    id: 3,
    name: "Landewela",
    si: "Landewela",
    en: "Landewela",
  },
  {
    id: 3,
    name: "Liyangahawela",
    si: "Liyangahawela",
    en: "Liyangahawela",
  },
  {
    id: 3,
    name: "Lunugala",
    si: "Lunugala",
    en: "Lunugala",
  },
  {
    id: 3,
    name: "Lunuwatta",
    si: "Lunuwatta",
    en: "Lunuwatta",
  },
  {
    id: 3,
    name: "Madulsima",
    si: "Madulsima",
    en: "Madulsima",
  },
  {
    id: 3,
    name: "Mahiyanganaya",
    si: "Mahiyanganaya",
    en: "Mahiyanganaya",
  },
  {
    id: 3,
    name: "Makulella",
    si: "Makulella",
    en: "Makulella",
  },
  {
    id: 3,
    name: "Malgoda",
    si: "Malgoda",
    en: "Malgoda",
  },
  {
    id: 3,
    name: "Mapakadawewa",
    si: "Mapakadawewa",
    en: "Mapakadawewa",
  },
  {
    id: 3,
    name: "Maspanna",
    si: "Maspanna",
    en: "Maspanna",
  },
  {
    id: 3,
    name: "Maussagolla",
    si: "Maussagolla",
    en: "Maussagolla",
  },
  {
    id: 3,
    name: "Mawanagama",
    si: "Mawanagama",
    en: "Mawanagama",
  },
  {
    id: 3,
    name: "Medawela Udukinda",
    si: "Medawela Udukinda",
    en: "Medawela Udukinda",
  },
  {
    id: 3,
    name: "Meegahakiula",
    si: "Meegahakiula",
    en: "Meegahakiula",
  },
  {
    id: 3,
    name: "Metigahatenna",
    si: "Metigahatenna",
    en: "Metigahatenna",
  },
  {
    id: 3,
    name: "Mirahawatta",
    si: "Mirahawatta",
    en: "Mirahawatta",
  },
  {
    id: 3,
    name: "Miriyabedda",
    si: "Miriyabedda",
    en: "Miriyabedda",
  },
  {
    id: 3,
    name: "Nawamedagama",
    si: "Nawamedagama",
    en: "Nawamedagama",
  },
  {
    id: 3,
    name: "Nelumgama",
    si: "Nelumgama",
    en: "Nelumgama",
  },
  {
    id: 3,
    name: "Nikapotha",
    si: "Nikapotha",
    en: "Nikapotha",
  },
  {
    id: 3,
    name: "Nugatalawa",
    si: "Nugatalawa",
    en: "Nugatalawa",
  },
  {
    id: 3,
    name: "Ohiya",
    si: "Ohiya",
    en: "Ohiya",
  },
  {
    id: 3,
    name: "Pahalarathkinda",
    si: "Pahalarathkinda",
    en: "Pahalarathkinda",
  },
  {
    id: 3,
    name: "Pallekiruwa",
    si: "Pallekiruwa",
    en: "Pallekiruwa",
  },
  {
    id: 3,
    name: "Passara",
    si: "Passara",
    en: "Passara",
  },
  {
    id: 3,
    name: "Pattiyagedara",
    si: "Pattiyagedara",
    en: "Pattiyagedara",
  },
  {
    id: 3,
    name: "Pelagahatenna",
    si: "Pelagahatenna",
    en: "Pelagahatenna",
  },
  {
    id: 3,
    name: "Perawella",
    si: "Perawella",
    en: "Perawella",
  },
  {
    id: 3,
    name: "Pitamaruwa",
    si: "Pitamaruwa",
    en: "Pitamaruwa",
  },
  {
    id: 3,
    name: "Pitapola",
    si: "Pitapola",
    en: "Pitapola",
  },
  {
    id: 3,
    name: "Puhulpola",
    si: "Puhulpola",
    en: "Puhulpola",
  },
  {
    id: 3,
    name: "Rajagalatenna",
    si: "Rajagalatenna",
    en: "Rajagalatenna",
  },
  {
    id: 3,
    name: "Ratkarawwa",
    si: "Ratkarawwa",
    en: "Ratkarawwa",
  },
  {
    id: 3,
    name: "Ridimaliyadda",
    si: "Ridimaliyadda",
    en: "Ridimaliyadda",
  },
  {
    id: 3,
    name: "Silmiyapura",
    si: "Silmiyapura",
    en: "Silmiyapura",
  },
  {
    id: 3,
    name: "Sirimalgoda",
    si: "Sirimalgoda",
    en: "Sirimalgoda",
  },
  {
    id: 3,
    name: "Siripura",
    si: "Siripura",
    en: "Siripura",
  },
  {
    id: 3,
    name: "Sorabora Colony",
    si: "Sorabora Colony",
    en: "Sorabora Colony",
  },
  {
    id: 3,
    name: "Soragune",
    si: "Soragune",
    en: "Soragune",
  },
  {
    id: 3,
    name: "Soranatota",
    si: "Soranatota",
    en: "Soranatota",
  },
  {
    id: 3,
    name: "Taldena",
    si: "Taldena",
    en: "Taldena",
  },
  {
    id: 3,
    name: "Timbirigaspitiya",
    si: "Timbirigaspitiya",
    en: "Timbirigaspitiya",
  },
  {
    id: 3,
    name: "Uduhawara",
    si: "Uduhawara",
    en: "Uduhawara",
  },
  {
    id: 3,
    name: "Uraniya",
    si: "Uraniya",
    en: "Uraniya",
  },
  {
    id: 3,
    name: "Uva Karandagolla",
    si: "Uva Karandagolla",
    en: "Uva Karandagolla",
  },
  {
    id: 3,
    name: "Uva Mawelagama",
    si: "Uva Mawelagama",
    en: "Uva Mawelagama",
  },
  {
    id: 3,
    name: "Uva Tenna",
    si: "Uva Tenna",
    en: "Uva Tenna",
  },
  {
    id: 3,
    name: "Uva Tissapura",
    si: "Uva Tissapura",
    en: "Uva Tissapura",
  },
  {
    id: 3,
    name: "Welimada",
    si: "Welimada",
    en: "Welimada",
  },
  {
    id: 3,
    name: "Werunketagoda",
    si: "Werunketagoda",
    en: "Werunketagoda",
  },
  {
    id: 3,
    name: "Wewatta",
    si: "Wewatta",
    en: "Wewatta",
  },
  {
    id: 3,
    name: "Wineethagama",
    si: "Wineethagama",
    en: "Wineethagama",
  },
  {
    id: 3,
    name: "Yalagamuwa",
    si: "Yalagamuwa",
    en: "Yalagamuwa",
  },
  {
    id: 3,
    name: "Yalwela",
    si: "Yalwela",
    en: "Yalwela",
  },
  {
    id: 4,
    name: "Addalaichenai",
    si: "Addalaichenai",
    en: "Addalaichenai",
  },
  {
    id: 4,
    name: "Ampilanthurai",
    si: "අම්පිලන්තුරෙයි",
    en: "Ampilanthurai",
  },
  {
    id: 4,
    name: "Araipattai",
    si: "Araipattai",
    en: "Araipattai",
  },
  {
    id: 4,
    name: "Ayithiyamalai",
    si: "Ayithiyamalai",
    en: "Ayithiyamalai",
  },
  {
    id: 4,
    name: "Bakiella",
    si: "Bakiella",
    en: "Bakiella",
  },
  {
    id: 4,
    name: "Batticaloa",
    si: "මඩකලපුව",
    en: "Batticaloa",
  },
  {
    id: 4,
    name: "Cheddipalayam",
    si: "චෙඩ්ඩිපලයම්",
    en: "Cheddipalayam",
  },
  {
    id: 4,
    name: "Chenkaladi",
    si: "චෙන්කලඩි",
    en: "Chenkaladi",
  },
  {
    id: 4,
    name: "Eravur",
    si: "එරාවූර්",
    en: "Eravur",
  },
  {
    id: 4,
    name: "Kaluwanchikudi",
    si: "Kaluwanchikudi",
    en: "Kaluwanchikudi",
  },
  {
    id: 4,
    name: "Kaluwankemy",
    si: "Kaluwankemy",
    en: "Kaluwankemy",
  },
  {
    id: 4,
    name: "Kannankudah",
    si: "Kannankudah",
    en: "Kannankudah",
  },
  {
    id: 4,
    name: "Karadiyanaru",
    si: "Karadiyanaru",
    en: "Karadiyanaru",
  },
  {
    id: 4,
    name: "Kathiraveli",
    si: "Kathiraveli",
    en: "Kathiraveli",
  },
  {
    id: 4,
    name: "Kattankudi",
    si: "Kattankudi",
    en: "Kattankudi",
  },
  {
    id: 4,
    name: "Kiran",
    si: "Kiran",
    en: "Kiran",
  },
  {
    id: 4,
    name: "Kirankulam",
    si: "Kirankulam",
    en: "Kirankulam",
  },
  {
    id: 4,
    name: "Koddaikallar",
    si: "Koddaikallar",
    en: "Koddaikallar",
  },
  {
    id: 4,
    name: "Kokkaddichcholai",
    si: "Kokkaddichcholai",
    en: "Kokkaddichcholai",
  },
  {
    id: 4,
    name: "Kurukkalmadam",
    si: "Kurukkalmadam",
    en: "Kurukkalmadam",
  },
  {
    id: 4,
    name: "Mandur",
    si: "Mandur",
    en: "Mandur",
  },
  {
    id: 4,
    name: "Miravodai",
    si: "Miravodai",
    en: "Miravodai",
  },
  {
    id: 4,
    name: "Murakottanchanai",
    si: "Murakottanchanai",
    en: "Murakottanchanai",
  },
  {
    id: 4,
    name: "Navagirinagar",
    si: "Navagirinagar",
    en: "Navagirinagar",
  },
  {
    id: 4,
    name: "Navatkadu",
    si: "Navatkadu",
    en: "Navatkadu",
  },
  {
    id: 4,
    name: "Oddamavadi",
    si: "Oddamavadi",
    en: "Oddamavadi",
  },
  {
    id: 4,
    name: "Palamunai",
    si: "Palamunai",
    en: "Palamunai",
  },
  {
    id: 4,
    name: "Pankudavely",
    si: "Pankudavely",
    en: "Pankudavely",
  },
  {
    id: 4,
    name: "Periyaporativu",
    si: "Periyaporativu",
    en: "Periyaporativu",
  },
  {
    id: 4,
    name: "Periyapullumalai",
    si: "Periyapullumalai",
    en: "Periyapullumalai",
  },
  {
    id: 4,
    name: "Pillaiyaradi",
    si: "Pillaiyaradi",
    en: "Pillaiyaradi",
  },
  {
    id: 4,
    name: "Punanai",
    si: "Punanai",
    en: "Punanai",
  },
  {
    id: 4,
    name: "Thannamunai",
    si: "Thannamunai",
    en: "Thannamunai",
  },
  {
    id: 4,
    name: "Thettativu",
    si: "Thettativu",
    en: "Thettativu",
  },
  {
    id: 4,
    name: "Thikkodai",
    si: "Thikkodai",
    en: "Thikkodai",
  },
  {
    id: 4,
    name: "Thirupalugamam",
    si: "Thirupalugamam",
    en: "Thirupalugamam",
  },
  {
    id: 4,
    name: "Unnichchai",
    si: "Unnichchai",
    en: "Unnichchai",
  },
  {
    id: 4,
    name: "Vakaneri",
    si: "Vakaneri",
    en: "Vakaneri",
  },
  {
    id: 4,
    name: "Vakarai",
    si: "Vakarai",
    en: "Vakarai",
  },
  {
    id: 4,
    name: "Valaichenai",
    si: "Valaichenai",
    en: "Valaichenai",
  },
  {
    id: 4,
    name: "Vantharumoolai",
    si: "Vantharumoolai",
    en: "Vantharumoolai",
  },
  {
    id: 4,
    name: "Vellavely",
    si: "Vellavely",
    en: "Vellavely",
  },
  {
    id: 5,
    name: "Akarawita",
    si: "අකරවිට",
    en: "Akarawita",
  },
  {
    id: 5,
    name: "Ambalangoda",
    si: "අම්බලන්ගොඩ",
    en: "Ambalangoda",
  },
  {
    id: 5,
    name: "Athurugiriya",
    si: "අතුරුගිරිය",
    en: "Athurugiriya",
  },
  {
    id: 5,
    name: "Avissawella",
    si: "අවිස්සාවේල්ල",
    en: "Avissawella",
  },
  {
    id: 5,
    name: "Batawala",
    si: "බටවැල",
    en: "Batawala",
  },
  {
    id: 5,
    name: "Battaramulla",
    si: "බත්තරමුල්ල",
    en: "Battaramulla",
  },
  {
    id: 5,
    name: "Biyagama",
    si: "බියගම",
    en: "Biyagama",
  },
  {
    id: 5,
    name: "Bope",
    si: "බෝපෙ",
    en: "Bope",
  },
  {
    id: 5,
    name: "Boralesgamuwa",
    si: "බොරලැස්ගමුව",
    en: "Boralesgamuwa",
  },
  {
    id: 5,
    name: "Colombo 8",
    si: "කොළඹ 8",
    en: "Colombo 8",
  },
  {
    id: 5,
    name: "Dedigamuwa",
    si: "දැඩිගමුව",
    en: "Dedigamuwa",
  },
  {
    id: 5,
    name: "Dehiwala",
    si: "දෙහිවල",
    en: "Dehiwala",
  },
  {
    id: 5,
    name: "Deltara",
    si: "දෙල්තර",
    en: "Deltara",
  },
  {
    id: 5,
    name: "Habarakada",
    si: "හබරකඩ",
    en: "Habarakada",
  },
  {
    id: 5,
    name: "Hanwella",
    si: "Hanwella",
    en: "Hanwella",
  },
  {
    id: 5,
    name: "Hiripitya",
    si: "Hiripitya",
    en: "Hiripitya",
  },
  {
    id: 5,
    name: "Hokandara",
    si: "Hokandara",
    en: "Hokandara",
  },
  {
    id: 5,
    name: "Homagama",
    si: "Homagama",
    en: "Homagama",
  },
  {
    id: 5,
    name: "Horagala",
    si: "Horagala",
    en: "Horagala",
  },
  {
    id: 5,
    name: "Kaduwela",
    si: "Kaduwela",
    en: "Kaduwela",
  },
  {
    id: 5,
    name: "Kaluaggala",
    si: "Kaluaggala",
    en: "Kaluaggala",
  },
  {
    id: 5,
    name: "Kapugoda",
    si: "Kapugoda",
    en: "Kapugoda",
  },
  {
    id: 5,
    name: "Kehelwatta",
    si: "Kehelwatta",
    en: "Kehelwatta",
  },
  {
    id: 5,
    name: "Kiriwattuduwa",
    si: "Kiriwattuduwa",
    en: "Kiriwattuduwa",
  },
  {
    id: 5,
    name: "Kolonnawa",
    si: "Kolonnawa",
    en: "Kolonnawa",
  },
  {
    id: 5,
    name: "Kosgama",
    si: "Kosgama",
    en: "Kosgama",
  },
  {
    id: 5,
    name: "Madapatha",
    si: "Madapatha",
    en: "Madapatha",
  },
  {
    id: 5,
    name: "Maharagama",
    si: "Maharagama",
    en: "Maharagama",
  },
  {
    id: 5,
    name: "Malabe",
    si: "Malabe",
    en: "Malabe",
  },
  {
    id: 5,
    name: "Moratuwa",
    si: "Moratuwa",
    en: "Moratuwa",
  },
  {
    id: 5,
    name: "Mount Lavinia",
    si: "Mount Lavinia",
    en: "Mount Lavinia",
  },
  {
    id: 5,
    name: "Mullegama",
    si: "Mullegama",
    en: "Mullegama",
  },
  {
    id: 5,
    name: "Napawela",
    si: "Napawela",
    en: "Napawela",
  },
  {
    id: 5,
    name: "Nugegoda",
    si: "Nugegoda",
    en: "Nugegoda",
  },
  {
    id: 5,
    name: "Padukka",
    si: "Padukka",
    en: "Padukka",
  },
  {
    id: 5,
    name: "Pannipitiya",
    si: "Pannipitiya",
    en: "Pannipitiya",
  },
  {
    id: 5,
    name: "Piliyandala",
    si: "Piliyandala",
    en: "Piliyandala",
  },
  {
    id: 5,
    name: "Pitipana Homagama",
    si: "Pitipana Homagama",
    en: "Pitipana Homagama",
  },
  {
    id: 5,
    name: "Polgasowita",
    si: "Polgasowita",
    en: "Polgasowita",
  },
  {
    id: 5,
    name: "Pugoda",
    si: "Pugoda",
    en: "Pugoda",
  },
  {
    id: 5,
    name: "Ranala",
    si: "Ranala",
    en: "Ranala",
  },
  {
    id: 5,
    name: "Siddamulla",
    si: "Siddamulla",
    en: "Siddamulla",
  },
  {
    id: 5,
    name: "Siyambalagoda",
    si: "Siyambalagoda",
    en: "Siyambalagoda",
  },
  {
    id: 5,
    name: "Sri Jayawardenepu",
    si: "Sri Jayawardenepu",
    en: "Sri Jayawardenepu",
  },
  {
    id: 5,
    name: "Talawatugoda",
    si: "Talawatugoda",
    en: "Talawatugoda",
  },
  {
    id: 5,
    name: "Tummodara",
    si: "Tummodara",
    en: "Tummodara",
  },
  {
    id: 5,
    name: "Waga",
    si: "Waga",
    en: "Waga",
  },
  {
    id: 5,
    name: "Colombo 6",
    si: "කොළඹ 6",
    en: "Colombo 6",
  },
  {
    id: 6,
    name: "Agaliya",
    si: "අගලිය",
    en: "Agaliya",
  },
  {
    id: 6,
    name: "Ahangama",
    si: "අහංගම",
    en: "Ahangama",
  },
  {
    id: 6,
    name: "Ahungalla",
    si: "අහුන්ගල්ල",
    en: "Ahungalla",
  },
  {
    id: 6,
    name: "Akmeemana",
    si: "අක්මීමාන",
    en: "Akmeemana",
  },
  {
    id: 6,
    name: "Alawatugoda",
    si: "අලවතුගොඩ",
    en: "Alawatugoda",
  },
  {
    id: 6,
    name: "Aluthwala",
    si: "අළුත්වල",
    en: "Aluthwala",
  },
  {
    id: 6,
    name: "Ampegama",
    si: "අම්පෙගම",
    en: "Ampegama",
  },
  {
    id: 6,
    name: "Amugoda",
    si: "අමුගොඩ",
    en: "Amugoda",
  },
  {
    id: 6,
    name: "Anangoda",
    si: "අනන්ගොඩ",
    en: "Anangoda",
  },
  {
    id: 6,
    name: "Angulugaha",
    si: "අඟුලුගහ",
    en: "Angulugaha",
  },
  {
    id: 6,
    name: "Ankokkawala",
    si: "අංකොක්කාවල",
    en: "Ankokkawala",
  },
  {
    id: 6,
    name: "Aselapura",
    si: "ඇසලපුර",
    en: "Aselapura",
  },
  {
    id: 6,
    name: "Baddegama",
    si: "බද්දේගම",
    en: "Baddegama",
  },
  {
    id: 6,
    name: "Balapitiya",
    si: "බලපිටිය",
    en: "Balapitiya",
  },
  {
    id: 6,
    name: "Banagala",
    si: "බනගල",
    en: "Banagala",
  },
  {
    id: 6,
    name: "Batapola",
    si: "බටපොල",
    en: "Batapola",
  },
  {
    id: 6,
    name: "Bentota",
    si: "බෙන්තොට",
    en: "Bentota",
  },
  {
    id: 6,
    name: "Boossa",
    si: "බූස්ස",
    en: "Boossa",
  },
  {
    id: 6,
    name: "Dellawa",
    si: "දෙල්ලව",
    en: "Dellawa",
  },
  {
    id: 6,
    name: "Dikkumbura",
    si: "දික්කුඹුර",
    en: "Dikkumbura",
  },
  {
    id: 6,
    name: "Dodanduwa",
    si: "දොඩන්දූව",
    en: "Dodanduwa",
  },
  {
    id: 6,
    name: "Ella Tanabaddegama",
    si: "ඇල්ල තනබද්දේගම",
    en: "Ella Tanabaddegama",
  },
  {
    id: 6,
    name: "Elpitiya",
    si: "ඇල්පිටිය",
    en: "Elpitiya",
  },
  {
    id: 6,
    name: "Galle",
    si: "ගාල්ල",
    en: "Galle",
  },
  {
    id: 6,
    name: "Ginimellagaha",
    si: "ගිනිමෙල්ලගහ",
    en: "Ginimellagaha",
  },
  {
    id: 6,
    name: "Gintota",
    si: "ගින්තොට",
    en: "Gintota",
  },
  {
    id: 6,
    name: "Godahena",
    si: "ගොඩහේන",
    en: "Godahena",
  },
  {
    id: 6,
    name: "Gonamulla Junction",
    si: "ගෝනමුල්ල හංදිය",
    en: "Gonamulla Junction",
  },
  {
    id: 6,
    name: "Gonapinuwala",
    si: "ගොනාපිනූවල",
    en: "Gonapinuwala",
  },
  {
    id: 6,
    name: "Habaraduwa",
    si: "හබරාදූව",
    en: "Habaraduwa",
  },
  {
    id: 6,
    name: "Haburugala",
    si: "හබුරුගල",
    en: "Haburugala",
  },
  {
    id: 6,
    name: "Hikkaduwa",
    si: "Hikkaduwa",
    en: "Hikkaduwa",
  },
  {
    id: 6,
    name: "Hiniduma",
    si: "Hiniduma",
    en: "Hiniduma",
  },
  {
    id: 6,
    name: "Hiyare",
    si: "Hiyare",
    en: "Hiyare",
  },
  {
    id: 6,
    name: "Kahaduwa",
    si: "Kahaduwa",
    en: "Kahaduwa",
  },
  {
    id: 6,
    name: "Kahawa",
    si: "Kahawa",
    en: "Kahawa",
  },
  {
    id: 6,
    name: "Karagoda",
    si: "Karagoda",
    en: "Karagoda",
  },
  {
    id: 6,
    name: "Karandeniya",
    si: "Karandeniya",
    en: "Karandeniya",
  },
  {
    id: 6,
    name: "Kosgoda",
    si: "Kosgoda",
    en: "Kosgoda",
  },
  {
    id: 6,
    name: "Kottawagama",
    si: "Kottawagama",
    en: "Kottawagama",
  },
  {
    id: 6,
    name: "Kottegoda",
    si: "Kottegoda",
    en: "Kottegoda",
  },
  {
    id: 6,
    name: "Kuleegoda",
    si: "Kuleegoda",
    en: "Kuleegoda",
  },
  {
    id: 6,
    name: "Magedara",
    si: "Magedara",
    en: "Magedara",
  },
  {
    id: 6,
    name: "Mahawela Sinhapura",
    si: "Mahawela Sinhapura",
    en: "Mahawela Sinhapura",
  },
  {
    id: 6,
    name: "Mapalagama",
    si: "Mapalagama",
    en: "Mapalagama",
  },
  {
    id: 6,
    name: "Mapalagama Central",
    si: "Mapalagama Central",
    en: "Mapalagama Central",
  },
  {
    id: 6,
    name: "Mattaka",
    si: "Mattaka",
    en: "Mattaka",
  },
  {
    id: 6,
    name: "Meda-Keembiya",
    si: "Meda-Keembiya",
    en: "Meda-Keembiya",
  },
  {
    id: 6,
    name: "Meetiyagoda",
    si: "Meetiyagoda",
    en: "Meetiyagoda",
  },
  {
    id: 6,
    name: "Nagoda",
    si: "Nagoda",
    en: "Nagoda",
  },
  {
    id: 6,
    name: "Nakiyadeniya",
    si: "Nakiyadeniya",
    en: "Nakiyadeniya",
  },
  {
    id: 6,
    name: "Nawadagala",
    si: "Nawadagala",
    en: "Nawadagala",
  },
  {
    id: 6,
    name: "Neluwa",
    si: "Neluwa",
    en: "Neluwa",
  },
  {
    id: 6,
    name: "Nindana",
    si: "Nindana",
    en: "Nindana",
  },
  {
    id: 6,
    name: "Pahala Millawa",
    si: "Pahala Millawa",
    en: "Pahala Millawa",
  },
  {
    id: 6,
    name: "Panangala",
    si: "Panangala",
    en: "Panangala",
  },
  {
    id: 6,
    name: "Pannimulla Panagoda",
    si: "Pannimulla Panagoda",
    en: "Pannimulla Panagoda",
  },
  {
    id: 6,
    name: "Parana ThanaYamgoda",
    si: "Parana ThanaYamgoda",
    en: "Parana ThanaYamgoda",
  },
  {
    id: 6,
    name: "Patana",
    si: "Patana",
    en: "Patana",
  },
  {
    id: 6,
    name: "Pitigala",
    si: "Pitigala",
    en: "Pitigala",
  },
  {
    id: 6,
    name: "Poddala",
    si: "Poddala",
    en: "Poddala",
  },
  {
    id: 6,
    name: "Polgampola",
    si: "Polgampola",
    en: "Polgampola",
  },
  {
    id: 6,
    name: "Porawagama",
    si: "Porawagama",
    en: "Porawagama",
  },
  {
    id: 6,
    name: "Rantotuwila",
    si: "Rantotuwila",
    en: "Rantotuwila",
  },
  {
    id: 6,
    name: "Talagampola",
    si: "Talagampola",
    en: "Talagampola",
  },
  {
    id: 6,
    name: "Talgaspe",
    si: "Talgaspe",
    en: "Talgaspe",
  },
  {
    id: 6,
    name: "Talpe",
    si: "Talpe",
    en: "Talpe",
  },
  {
    id: 6,
    name: "Tawalama",
    si: "Tawalama",
    en: "Tawalama",
  },
  {
    id: 6,
    name: "Tiranagama",
    si: "Tiranagama",
    en: "Tiranagama",
  },
  {
    id: 6,
    name: "Udalamatta",
    si: "Udalamatta",
    en: "Udalamatta",
  },
  {
    id: 6,
    name: "Udugama",
    si: "Udugama",
    en: "Udugama",
  },
  {
    id: 6,
    name: "Uluvitike",
    si: "Uluvitike",
    en: "Uluvitike",
  },
  {
    id: 6,
    name: "Unawatuna",
    si: "Unawatuna",
    en: "Unawatuna",
  },
  {
    id: 6,
    name: "Unenwitiya",
    si: "Unenwitiya",
    en: "Unenwitiya",
  },
  {
    id: 6,
    name: "Uragaha",
    si: "Uragaha",
    en: "Uragaha",
  },
  {
    id: 6,
    name: "Uragasmanhandiya",
    si: "Uragasmanhandiya",
    en: "Uragasmanhandiya",
  },
  {
    id: 6,
    name: "Wakwella",
    si: "Wakwella",
    en: "Wakwella",
  },
  {
    id: 6,
    name: "Walahanduwa",
    si: "Walahanduwa",
    en: "Walahanduwa",
  },
  {
    id: 6,
    name: "Wanchawela",
    si: "Wanchawela",
    en: "Wanchawela",
  },
  {
    id: 6,
    name: "Wanduramba",
    si: "Wanduramba",
    en: "Wanduramba",
  },
  {
    id: 6,
    name: "Warukandeniya",
    si: "Warukandeniya",
    en: "Warukandeniya",
  },
  {
    id: 6,
    name: "Watugedara",
    si: "Watugedara",
    en: "Watugedara",
  },
  {
    id: 6,
    name: "Weihena",
    si: "Weihena",
    en: "Weihena",
  },
  {
    id: 6,
    name: "Welikanda",
    si: "Welikanda",
    en: "Welikanda",
  },
  {
    id: 6,
    name: "Wilanagama",
    si: "Wilanagama",
    en: "Wilanagama",
  },
  {
    id: 6,
    name: "Yakkalamulla",
    si: "Yakkalamulla",
    en: "Yakkalamulla",
  },
  {
    id: 6,
    name: "Yatalamatta",
    si: "Yatalamatta",
    en: "Yatalamatta",
  },
  {
    id: 7,
    name: "Akaragama",
    si: "අකරගම",
    en: "Akaragama",
  },
  {
    id: 7,
    name: "Ambagaspitiya",
    si: "අඹගස්පිටිය",
    en: "Ambagaspitiya",
  },
  {
    id: 7,
    name: "Ambepussa",
    si: "අඹේපුස්ස",
    en: "Ambepussa",
  },
  {
    id: 7,
    name: "Andiambalama",
    si: "ආඬිඅම්බලම",
    en: "Andiambalama",
  },
  {
    id: 7,
    name: "Attanagalla",
    si: "අත්තනගල්ල",
    en: "Attanagalla",
  },
  {
    id: 7,
    name: "Badalgama",
    si: "බඩල්ගම",
    en: "Badalgama",
  },
  {
    id: 7,
    name: "Banduragoda",
    si: "බඳුරගොඩ",
    en: "Banduragoda",
  },
  {
    id: 7,
    name: "Batuwatta",
    si: "බටුවත්ත",
    en: "Batuwatta",
  },
  {
    id: 7,
    name: "Bemmulla",
    si: "බෙම්මුල්ල",
    en: "Bemmulla",
  },
  {
    id: 7,
    name: "Biyagama IPZ",
    si: "බියගම IPZ",
    en: "Biyagama IPZ",
  },
  {
    id: 7,
    name: "Bokalagama",
    si: "බොකලගම",
    en: "Bokalagama",
  },
  {
    id: 7,
    name: "Bollete [WP]",
    si: "බොල්ලතේ",
    en: "Bollete [WP]",
  },
  {
    id: 7,
    name: "Bopagama",
    si: "බෝපගම",
    en: "Bopagama",
  },
  {
    id: 7,
    name: "Buthpitiya",
    si: "බුත්පිටිය",
    en: "Buthpitiya",
  },
  {
    id: 7,
    name: "Dagonna",
    si: "දාගොන්න",
    en: "Dagonna",
  },
  {
    id: 7,
    name: "Danowita",
    si: "දංඕවිට",
    en: "Danowita",
  },
  {
    id: 7,
    name: "Debahera",
    si: "දෙබහැර",
    en: "Debahera",
  },
  {
    id: 7,
    name: "Dekatana",
    si: "දෙකටන",
    en: "Dekatana",
  },
  {
    id: 7,
    name: "Delgoda",
    si: "දෙල්ගොඩ",
    en: "Delgoda",
  },
  {
    id: 7,
    name: "Delwagura",
    si: "දෙල්වගුර",
    en: "Delwagura",
  },
  {
    id: 7,
    name: "Demalagama",
    si: "දෙමළගම",
    en: "Demalagama",
  },
  {
    id: 7,
    name: "Demanhandiya",
    si: "දෙමන්හන්දිය",
    en: "Demanhandiya",
  },
  {
    id: 7,
    name: "Dewalapola",
    si: "දේවාලපොල",
    en: "Dewalapola",
  },
  {
    id: 7,
    name: "Divulapitiya",
    si: "දිවුලපිටිය",
    en: "Divulapitiya",
  },
  {
    id: 7,
    name: "Divuldeniya",
    si: "දිවුල්දෙණිය",
    en: "Divuldeniya",
  },
  {
    id: 7,
    name: "Dompe",
    si: "දොම්පෙ",
    en: "Dompe",
  },
  {
    id: 7,
    name: "Dunagaha",
    si: "දුනගහ",
    en: "Dunagaha",
  },
  {
    id: 7,
    name: "Ekala",
    si: "ඒකල",
    en: "Ekala",
  },
  {
    id: 7,
    name: "Ellakkala",
    si: "ඇල්ලක්කල",
    en: "Ellakkala",
  },
  {
    id: 7,
    name: "Essella",
    si: "Essella",
    en: "Essella",
  },
  {
    id: 7,
    name: "Galedanda",
    si: "ගලේදණ්ඩ",
    en: "Galedanda",
  },
  {
    id: 7,
    name: "Gampaha",
    si: "ගම්පහ",
    en: "Gampaha",
  },
  {
    id: 7,
    name: "Ganemulla",
    si: "ගණේමුල්ල",
    en: "Ganemulla",
  },
  {
    id: 7,
    name: "Giriulla",
    si: "ගිරිවුල්ල",
    en: "Giriulla",
  },
  {
    id: 7,
    name: "Gonawala",
    si: "ගෝනවල",
    en: "Gonawala",
  },
  {
    id: 7,
    name: "Halpe",
    si: "හල්පෙ",
    en: "Halpe",
  },
  {
    id: 7,
    name: "Hapugastenna",
    si: "Hapugastenna",
    en: "Hapugastenna",
  },
  {
    id: 7,
    name: "Heiyanthuduwa",
    si: "Heiyanthuduwa",
    en: "Heiyanthuduwa",
  },
  {
    id: 7,
    name: "Hinatiyana Madawala",
    si: "Hinatiyana Madawala",
    en: "Hinatiyana Madawala",
  },
  {
    id: 7,
    name: "Hiswella",
    si: "Hiswella",
    en: "Hiswella",
  },
  {
    id: 7,
    name: "Horampella",
    si: "Horampella",
    en: "Horampella",
  },
  {
    id: 7,
    name: "Hunumulla",
    si: "Hunumulla",
    en: "Hunumulla",
  },
  {
    id: 7,
    name: "Hunupola",
    si: "Hunupola",
    en: "Hunupola",
  },
  {
    id: 7,
    name: "Ihala Madampella",
    si: "Ihala Madampella",
    en: "Ihala Madampella",
  },
  {
    id: 7,
    name: "Imbulgoda",
    si: "Imbulgoda",
    en: "Imbulgoda",
  },
  {
    id: 7,
    name: "Ja-Ela",
    si: "Ja-Ela",
    en: "Ja-Ela",
  },
  {
    id: 7,
    name: "Kadawatha",
    si: "Kadawatha",
    en: "Kadawatha",
  },
  {
    id: 7,
    name: "Kahatowita",
    si: "Kahatowita",
    en: "Kahatowita",
  },
  {
    id: 7,
    name: "Kalagedihena",
    si: "Kalagedihena",
    en: "Kalagedihena",
  },
  {
    id: 7,
    name: "Kaleliya",
    si: "Kaleliya",
    en: "Kaleliya",
  },
  {
    id: 7,
    name: "Kandana",
    si: "Kandana",
    en: "Kandana",
  },
  {
    id: 7,
    name: "Katana",
    si: "Katana",
    en: "Katana",
  },
  {
    id: 7,
    name: "Katudeniya",
    si: "Katudeniya",
    en: "Katudeniya",
  },
  {
    id: 7,
    name: "Katunayake",
    si: "Katunayake",
    en: "Katunayake",
  },
  {
    id: 7,
    name: "Katunayake Air Force Camp",
    si: "Katunayake Air Force Camp",
    en: "Katunayake Air Force Camp",
  },
  {
    id: 7,
    name: "Katunayake[FTZ]",
    si: "Katunayake[FTZ]",
    en: "Katunayake[FTZ]",
  },
  {
    id: 7,
    name: "Katuwellegama",
    si: "Katuwellegama",
    en: "Katuwellegama",
  },
  {
    id: 7,
    name: "Kelaniya",
    si: "Kelaniya",
    en: "Kelaniya",
  },
  {
    id: 7,
    name: "Kimbulapitiya",
    si: "Kimbulapitiya",
    en: "Kimbulapitiya",
  },
  {
    id: 7,
    name: "Kirindiwela",
    si: "Kirindiwela",
    en: "Kirindiwela",
  },
  {
    id: 7,
    name: "Kitalawalana",
    si: "Kitalawalana",
    en: "Kitalawalana",
  },
  {
    id: 7,
    name: "Kochchikade",
    si: "Kochchikade",
    en: "Kochchikade",
  },
  {
    id: 7,
    name: "Kotadeniyawa",
    si: "Kotadeniyawa",
    en: "Kotadeniyawa",
  },
  {
    id: 7,
    name: "Kotugoda",
    si: "Kotugoda",
    en: "Kotugoda",
  },
  {
    id: 7,
    name: "Kumbaloluwa",
    si: "Kumbaloluwa",
    en: "Kumbaloluwa",
  },
  {
    id: 7,
    name: "Loluwagoda",
    si: "Loluwagoda",
    en: "Loluwagoda",
  },
  {
    id: 7,
    name: "Mabodale",
    si: "Mabodale",
    en: "Mabodale",
  },
  {
    id: 7,
    name: "Madelgamuwa",
    si: "Madelgamuwa",
    en: "Madelgamuwa",
  },
  {
    id: 7,
    name: "Makewita",
    si: "Makewita",
    en: "Makewita",
  },
  {
    id: 7,
    name: "Makola",
    si: "Makola",
    en: "Makola",
  },
  {
    id: 7,
    name: "Malwana",
    si: "Malwana",
    en: "Malwana",
  },
  {
    id: 7,
    name: "Mandawala",
    si: "Mandawala",
    en: "Mandawala",
  },
  {
    id: 7,
    name: "Marandagahamula",
    si: "Marandagahamula",
    en: "Marandagahamula",
  },
  {
    id: 7,
    name: "Mellawagedara",
    si: "Mellawagedara",
    en: "Mellawagedara",
  },
  {
    id: 7,
    name: "Minuwangoda",
    si: "Minuwangoda",
    en: "Minuwangoda",
  },
  {
    id: 7,
    name: "Mirigama",
    si: "Mirigama",
    en: "Mirigama",
  },
  {
    id: 7,
    name: "Miriswatta",
    si: "Miriswatta",
    en: "Miriswatta",
  },
  {
    id: 7,
    name: "Mithirigala",
    si: "Mithirigala",
    en: "Mithirigala",
  },
  {
    id: 7,
    name: "Muddaragama",
    si: "Muddaragama",
    en: "Muddaragama",
  },
  {
    id: 7,
    name: "Mudungoda",
    si: "Mudungoda",
    en: "Mudungoda",
  },
  {
    id: 7,
    name: "Mulleriyawa New Town",
    si: "Mulleriyawa New Town",
    en: "Mulleriyawa New Town",
  },
  {
    id: 7,
    name: "Naranwala",
    si: "Naranwala",
    en: "Naranwala",
  },
  {
    id: 7,
    name: "Nawana",
    si: "Nawana",
    en: "Nawana",
  },
  {
    id: 7,
    name: "Nedungamuwa",
    si: "Nedungamuwa",
    en: "Nedungamuwa",
  },
  {
    id: 7,
    name: "Negombo",
    si: "Negombo",
    en: "Negombo",
  },
  {
    id: 7,
    name: "Nikadalupotha",
    si: "Nikadalupotha",
    en: "Nikadalupotha",
  },
  {
    id: 7,
    name: "Nikahetikanda",
    si: "Nikahetikanda",
    en: "Nikahetikanda",
  },
  {
    id: 7,
    name: "Nittambuwa",
    si: "Nittambuwa",
    en: "Nittambuwa",
  },
  {
    id: 7,
    name: "Niwandama",
    si: "Niwandama",
    en: "Niwandama",
  },
  {
    id: 7,
    name: "Opatha",
    si: "Opatha",
    en: "Opatha",
  },
  {
    id: 7,
    name: "Pamunugama",
    si: "Pamunugama",
    en: "Pamunugama",
  },
  {
    id: 7,
    name: "Pamunuwatta",
    si: "Pamunuwatta",
    en: "Pamunuwatta",
  },
  {
    id: 7,
    name: "Panawala",
    si: "Panawala",
    en: "Panawala",
  },
  {
    id: 7,
    name: "Pasyala",
    si: "Pasyala",
    en: "Pasyala",
  },
  {
    id: 7,
    name: "Peliyagoda",
    si: "Peliyagoda",
    en: "Peliyagoda",
  },
  {
    id: 7,
    name: "Pepiliyawala",
    si: "Pepiliyawala",
    en: "Pepiliyawala",
  },
  {
    id: 7,
    name: "Pethiyagoda",
    si: "Pethiyagoda",
    en: "Pethiyagoda",
  },
  {
    id: 7,
    name: "Polpithimukulana",
    si: "Polpithimukulana",
    en: "Polpithimukulana",
  },
  {
    id: 7,
    name: "Puwakpitiya",
    si: "Puwakpitiya",
    en: "Puwakpitiya",
  },
  {
    id: 7,
    name: "Radawadunna",
    si: "Radawadunna",
    en: "Radawadunna",
  },
  {
    id: 7,
    name: "Radawana",
    si: "Radawana",
    en: "Radawana",
  },
  {
    id: 7,
    name: "Raddolugama",
    si: "Raddolugama",
    en: "Raddolugama",
  },
  {
    id: 7,
    name: "Ragama",
    si: "Ragama",
    en: "Ragama",
  },
  {
    id: 7,
    name: "Ruggahawila",
    si: "Ruggahawila",
    en: "Ruggahawila",
  },
  {
    id: 7,
    name: "Seeduwa",
    si: "Seeduwa",
    en: "Seeduwa",
  },
  {
    id: 7,
    name: "Siyambalape",
    si: "Siyambalape",
    en: "Siyambalape",
  },
  {
    id: 7,
    name: "Talahena",
    si: "Talahena",
    en: "Talahena",
  },
  {
    id: 7,
    name: "Thambagalla",
    si: "Thambagalla",
    en: "Thambagalla",
  },
  {
    id: 7,
    name: "Thimbirigaskatuwa",
    si: "Thimbirigaskatuwa",
    en: "Thimbirigaskatuwa",
  },
  {
    id: 7,
    name: "Tittapattara",
    si: "Tittapattara",
    en: "Tittapattara",
  },
  {
    id: 7,
    name: "Udathuthiripitiya",
    si: "Udathuthiripitiya",
    en: "Udathuthiripitiya",
  },
  {
    id: 7,
    name: "Udugampola",
    si: "Udugampola",
    en: "Udugampola",
  },
  {
    id: 7,
    name: "Uggalboda",
    si: "Uggalboda",
    en: "Uggalboda",
  },
  {
    id: 7,
    name: "Urapola",
    si: "Urapola",
    en: "Urapola",
  },
  {
    id: 7,
    name: "Uswetakeiyawa",
    si: "Uswetakeiyawa",
    en: "Uswetakeiyawa",
  },
  {
    id: 7,
    name: "Veyangoda",
    si: "Veyangoda",
    en: "Veyangoda",
  },
  {
    id: 7,
    name: "Walgammulla",
    si: "Walgammulla",
    en: "Walgammulla",
  },
  {
    id: 7,
    name: "Walpita",
    si: "Walpita",
    en: "Walpita",
  },
  {
    id: 7,
    name: "Walpola [WP]",
    si: "Walpola [WP]",
    en: "Walpola [WP]",
  },
  {
    id: 7,
    name: "Wathurugama",
    si: "Wathurugama",
    en: "Wathurugama",
  },
  {
    id: 7,
    name: "Watinapaha",
    si: "Watinapaha",
    en: "Watinapaha",
  },
  {
    id: 7,
    name: "Wattala",
    si: "Wattala",
    en: "Wattala",
  },
  {
    id: 7,
    name: "Weboda",
    si: "Weboda",
    en: "Weboda",
  },
  {
    id: 7,
    name: "Wegowwa",
    si: "Wegowwa",
    en: "Wegowwa",
  },
  {
    id: 7,
    name: "Weweldeniya",
    si: "Weweldeniya",
    en: "Weweldeniya",
  },
  {
    id: 7,
    name: "Yakkala",
    si: "Yakkala",
    en: "Yakkala",
  },
  {
    id: 7,
    name: "Yatiyana",
    si: "Yatiyana",
    en: "Yatiyana",
  },
  {
    id: 8,
    name: "Ambalantota",
    si: "අම්බලන්තොට",
    en: "Ambalantota",
  },
  {
    id: 8,
    name: "Angunakolapelessa",
    si: "අඟුණකොළපැලැස්ස",
    en: "Angunakolapelessa",
  },
  {
    id: 8,
    name: "Angunakolawewa",
    si: "අඟුණකොලවැව",
    en: "Angunakolawewa",
  },
  {
    id: 8,
    name: "Bandagiriya Colony",
    si: "බන්ඩගිරිය කොලොනි",
    en: "Bandagiriya Colony",
  },
  {
    id: 8,
    name: "Barawakumbuka",
    si: "බරවකුඹුර",
    en: "Barawakumbuka",
  },
  {
    id: 8,
    name: "Beliatta",
    si: "බෙලිඅත්ත",
    en: "Beliatta",
  },
  {
    id: 8,
    name: "Beragama",
    si: "බෙරගම",
    en: "Beragama",
  },
  {
    id: 8,
    name: "Beralihela",
    si: "බෙරලිහෙල",
    en: "Beralihela",
  },
  {
    id: 8,
    name: "Bundala",
    si: "බූන්දල",
    en: "Bundala",
  },
  {
    id: 8,
    name: "Ellagala",
    si: "ඇල්ලගල",
    en: "Ellagala",
  },
  {
    id: 8,
    name: "Gangulandeniya",
    si: "ගඟුලදෙණිය",
    en: "Gangulandeniya",
  },
  {
    id: 8,
    name: "Getamanna",
    si: "ගැටමාන්න",
    en: "Getamanna",
  },
  {
    id: 8,
    name: "Goda Koggalla",
    si: "ගොඩ කොග්ගල්ල",
    en: "Goda Koggalla",
  },
  {
    id: 8,
    name: "Gonagamuwa Uduwila",
    si: "ගොනාගමුව උඩුවිල",
    en: "Gonagamuwa Uduwila",
  },
  {
    id: 8,
    name: "Gonnoruwa",
    si: "ගොන්නොරුව",
    en: "Gonnoruwa",
  },
  {
    id: 8,
    name: "Hakuruwela",
    si: "හකුරුවෙල",
    en: "Hakuruwela",
  },
  {
    id: 8,
    name: "Hambantota",
    si: "හම්බන්තොට",
    en: "Hambantota",
  },
  {
    id: 8,
    name: "Handugala",
    si: "හඳගුල",
    en: "Handugala",
  },
  {
    id: 8,
    name: "Hungama",
    si: "Hungama",
    en: "Hungama",
  },
  {
    id: 8,
    name: "Ihala Beligalla",
    si: "Ihala Beligalla",
    en: "Ihala Beligalla",
  },
  {
    id: 8,
    name: "Ittademaliya",
    si: "Ittademaliya",
    en: "Ittademaliya",
  },
  {
    id: 8,
    name: "Julampitiya",
    si: "Julampitiya",
    en: "Julampitiya",
  },
  {
    id: 8,
    name: "Kahandamodara",
    si: "Kahandamodara",
    en: "Kahandamodara",
  },
  {
    id: 8,
    name: "Kariyamaditta",
    si: "Kariyamaditta",
    en: "Kariyamaditta",
  },
  {
    id: 8,
    name: "Katuwana",
    si: "Katuwana",
    en: "Katuwana",
  },
  {
    id: 8,
    name: "Kawantissapura",
    si: "Kawantissapura",
    en: "Kawantissapura",
  },
  {
    id: 8,
    name: "Kirama",
    si: "Kirama",
    en: "Kirama",
  },
  {
    id: 8,
    name: "Kirinda",
    si: "Kirinda",
    en: "Kirinda",
  },
  {
    id: 8,
    name: "Lunama",
    si: "Lunama",
    en: "Lunama",
  },
  {
    id: 8,
    name: "Lunugamwehera",
    si: "Lunugamwehera",
    en: "Lunugamwehera",
  },
  {
    id: 8,
    name: "Magama",
    si: "Magama",
    en: "Magama",
  },
  {
    id: 8,
    name: "Mahagalwewa",
    si: "Mahagalwewa",
    en: "Mahagalwewa",
  },
  {
    id: 8,
    name: "Mamadala",
    si: "Mamadala",
    en: "Mamadala",
  },
  {
    id: 8,
    name: "Medamulana",
    si: "Medamulana",
    en: "Medamulana",
  },
  {
    id: 8,
    name: "Middeniya",
    si: "Middeniya",
    en: "Middeniya",
  },
  {
    id: 8,
    name: "Migahajandur",
    si: "Migahajandur",
    en: "Migahajandur",
  },
  {
    id: 8,
    name: "Modarawana",
    si: "Modarawana",
    en: "Modarawana",
  },
  {
    id: 8,
    name: "Mulkirigala",
    si: "Mulkirigala",
    en: "Mulkirigala",
  },
  {
    id: 8,
    name: "Nakulugamuwa",
    si: "Nakulugamuwa",
    en: "Nakulugamuwa",
  },
  {
    id: 8,
    name: "Netolpitiya",
    si: "Netolpitiya",
    en: "Netolpitiya",
  },
  {
    id: 8,
    name: "Nihiluwa",
    si: "Nihiluwa",
    en: "Nihiluwa",
  },
  {
    id: 8,
    name: "Padawkema",
    si: "Padawkema",
    en: "Padawkema",
  },
  {
    id: 8,
    name: "Pahala Andarawewa",
    si: "Pahala Andarawewa",
    en: "Pahala Andarawewa",
  },
  {
    id: 8,
    name: "Rammalawarapitiya",
    si: "Rammalawarapitiya",
    en: "Rammalawarapitiya",
  },
  {
    id: 8,
    name: "Ranakeliya",
    si: "Ranakeliya",
    en: "Ranakeliya",
  },
  {
    id: 8,
    name: "Ranmuduwewa",
    si: "Ranmuduwewa",
    en: "Ranmuduwewa",
  },
  {
    id: 8,
    name: "Ranna",
    si: "Ranna",
    en: "Ranna",
  },
  {
    id: 8,
    name: "Ratmalwala",
    si: "Ratmalwala",
    en: "Ratmalwala",
  },
  {
    id: 8,
    name: "RU/Ridiyagama",
    si: "RU/Ridiyagama",
    en: "RU/Ridiyagama",
  },
  {
    id: 8,
    name: "Sooriyawewa Town",
    si: "Sooriyawewa Town",
    en: "Sooriyawewa Town",
  },
  {
    id: 8,
    name: "Tangalla",
    si: "Tangalla",
    en: "Tangalla",
  },
  {
    id: 8,
    name: "Tissamaharama",
    si: "Tissamaharama",
    en: "Tissamaharama",
  },
  {
    id: 8,
    name: "Uda Gomadiya",
    si: "Uda Gomadiya",
    en: "Uda Gomadiya",
  },
  {
    id: 8,
    name: "Udamattala",
    si: "Udamattala",
    en: "Udamattala",
  },
  {
    id: 8,
    name: "Uswewa",
    si: "Uswewa",
    en: "Uswewa",
  },
  {
    id: 8,
    name: "Vitharandeniya",
    si: "Vitharandeniya",
    en: "Vitharandeniya",
  },
  {
    id: 8,
    name: "Walasmulla",
    si: "Walasmulla",
    en: "Walasmulla",
  },
  {
    id: 8,
    name: "Weeraketiya",
    si: "Weeraketiya",
    en: "Weeraketiya",
  },
  {
    id: 8,
    name: "Weerawila",
    si: "Weerawila",
    en: "Weerawila",
  },
  {
    id: 8,
    name: "Weerawila NewTown",
    si: "Weerawila NewTown",
    en: "Weerawila NewTown",
  },
  {
    id: 8,
    name: "Wekandawela",
    si: "Wekandawela",
    en: "Wekandawela",
  },
  {
    id: 8,
    name: "Weligatta",
    si: "Weligatta",
    en: "Weligatta",
  },
  {
    id: 8,
    name: "Yatigala",
    si: "Yatigala",
    en: "Yatigala",
  },
  {
    id: 9,
    name: "Jaffna",
    si: "Jaffna",
    en: "Jaffna",
  },
  {
    id: 10,
    name: "Agalawatta",
    si: "අගලවත්ත",
    en: "Agalawatta",
  },
  {
    id: 10,
    name: "Alubomulla",
    si: "අලුබෝමුල්ල",
    en: "Alubomulla",
  },
  {
    id: 10,
    name: "Anguruwatota",
    si: "අංගුරුවතොට",
    en: "Anguruwatota",
  },
  {
    id: 10,
    name: "Atale",
    si: "අටලේ",
    en: "Atale",
  },
  {
    id: 10,
    name: "Baduraliya",
    si: "බදුරලීය",
    en: "Baduraliya",
  },
  {
    id: 10,
    name: "Bandaragama",
    si: "බණ්ඩාරගම",
    en: "Bandaragama",
  },
  {
    id: 10,
    name: "Batugampola",
    si: "බටුගම්පොල",
    en: "Batugampola",
  },
  {
    id: 10,
    name: "Bellana",
    si: "බෙල්ලන",
    en: "Bellana",
  },
  {
    id: 10,
    name: "Beruwala",
    si: "බේරුවල",
    en: "Beruwala",
  },
  {
    id: 10,
    name: "Bolossagama",
    si: "බොලොස්සගම",
    en: "Bolossagama",
  },
  {
    id: 10,
    name: "Bombuwala",
    si: "බොඹුවල",
    en: "Bombuwala",
  },
  {
    id: 10,
    name: "Boralugoda",
    si: "බොරළුගොඩ",
    en: "Boralugoda",
  },
  {
    id: 10,
    name: "Bulathsinhala",
    si: "බුලත්සිංහල",
    en: "Bulathsinhala",
  },
  {
    id: 10,
    name: "Danawala Thiniyawala",
    si: "දනවල තිනියවල",
    en: "Danawala Thiniyawala",
  },
  {
    id: 10,
    name: "Delmella",
    si: "දෙල්මෙල්ල",
    en: "Delmella",
  },
  {
    id: 10,
    name: "Dharga Town",
    si: "දර්ගා නගරය",
    en: "Dharga Town",
  },
  {
    id: 10,
    name: "Diwalakada",
    si: "දිවාලකද",
    en: "Diwalakada",
  },
  {
    id: 10,
    name: "Dodangoda",
    si: "දොඩන්ගොඩ",
    en: "Dodangoda",
  },
  {
    id: 10,
    name: "Dombagoda",
    si: "දොඹගොඩ",
    en: "Dombagoda",
  },
  {
    id: 10,
    name: "Ethkandura",
    si: "ඇත්කඳුර",
    en: "Ethkandura",
  },
  {
    id: 10,
    name: "Galpatha",
    si: "ගල්පාත",
    en: "Galpatha",
  },
  {
    id: 10,
    name: "Gamagoda",
    si: "ගමගොඩ",
    en: "Gamagoda",
  },
  {
    id: 10,
    name: "Gonagalpura",
    si: "ගොනාගල්පුර",
    en: "Gonagalpura",
  },
  {
    id: 10,
    name: "Gonapola Junction",
    si: "ගෝනපොල හංදිය",
    en: "Gonapola Junction",
  },
  {
    id: 10,
    name: "Govinna",
    si: "ගෝවින්න",
    en: "Govinna",
  },
  {
    id: 10,
    name: "Gurulubadda",
    si: "ගුරුලුබැද්ද",
    en: "Gurulubadda",
  },
  {
    id: 10,
    name: "Halkandawila",
    si: "හල්කන්දවිල",
    en: "Halkandawila",
  },
  {
    id: 10,
    name: "Haltota",
    si: "හල්තොට",
    en: "Haltota",
  },
  {
    id: 10,
    name: "Halvitigala Colony",
    si: "හල්විටගල ජනපදය",
    en: "Halvitigala Colony",
  },
  {
    id: 10,
    name: "Halwala",
    si: "හල්වල",
    en: "Halwala",
  },
  {
    id: 10,
    name: "Halwatura",
    si: "හල්වතුර",
    en: "Halwatura",
  },
  {
    id: 10,
    name: "Handapangoda",
    si: "හඳපාන්ගොඩ",
    en: "Handapangoda",
  },
  {
    id: 10,
    name: "Hedigalla Colony",
    si: "Hedigalla Colony",
    en: "Hedigalla Colony",
  },
  {
    id: 10,
    name: "Henegama",
    si: "Henegama",
    en: "Henegama",
  },
  {
    id: 10,
    name: "Hettimulla",
    si: "Hettimulla",
    en: "Hettimulla",
  },
  {
    id: 10,
    name: "Horana",
    si: "Horana",
    en: "Horana",
  },
  {
    id: 10,
    name: "Ittapana",
    si: "Ittapana",
    en: "Ittapana",
  },
  {
    id: 10,
    name: "Kahawala",
    si: "Kahawala",
    en: "Kahawala",
  },
  {
    id: 10,
    name: "Kalawila Kiranthidiya",
    si: "Kalawila Kiranthidiya",
    en: "Kalawila Kiranthidiya",
  },
  {
    id: 10,
    name: "Kalutara",
    si: "Kalutara",
    en: "Kalutara",
  },
  {
    id: 10,
    name: "Kananwila",
    si: "Kananwila",
    en: "Kananwila",
  },
  {
    id: 10,
    name: "Kandanagama",
    si: "Kandanagama",
    en: "Kandanagama",
  },
  {
    id: 10,
    name: "Kelinkanda",
    si: "Kelinkanda",
    en: "Kelinkanda",
  },
  {
    id: 10,
    name: "Kitulgoda",
    si: "Kitulgoda",
    en: "Kitulgoda",
  },
  {
    id: 10,
    name: "Koholana",
    si: "Koholana",
    en: "Koholana",
  },
  {
    id: 10,
    name: "Kuda Uduwa",
    si: "Kuda Uduwa",
    en: "Kuda Uduwa",
  },
  {
    id: 10,
    name: "Labbala",
    si: "Labbala",
    en: "Labbala",
  },
  {
    id: 10,
    name: "lhalahewessa",
    si: "lhalahewessa",
    en: "lhalahewessa",
  },
  {
    id: 10,
    name: "lnduruwa",
    si: "lnduruwa",
    en: "lnduruwa",
  },
  {
    id: 10,
    name: "lngiriya",
    si: "lngiriya",
    en: "lngiriya",
  },
  {
    id: 10,
    name: "Maggona",
    si: "Maggona",
    en: "Maggona",
  },
  {
    id: 10,
    name: "Mahagama",
    si: "Mahagama",
    en: "Mahagama",
  },
  {
    id: 10,
    name: "Mahakalupahana",
    si: "Mahakalupahana",
    en: "Mahakalupahana",
  },
  {
    id: 10,
    name: "Maharangalla",
    si: "Maharangalla",
    en: "Maharangalla",
  },
  {
    id: 10,
    name: "Malgalla Talangalla",
    si: "Malgalla Talangalla",
    en: "Malgalla Talangalla",
  },
  {
    id: 10,
    name: "Matugama",
    si: "Matugama",
    en: "Matugama",
  },
  {
    id: 10,
    name: "Meegahatenna",
    si: "Meegahatenna",
    en: "Meegahatenna",
  },
  {
    id: 10,
    name: "Meegama",
    si: "Meegama",
    en: "Meegama",
  },
  {
    id: 10,
    name: "Meegoda",
    si: "Meegoda",
    en: "Meegoda",
  },
  {
    id: 10,
    name: "Millaniya",
    si: "Millaniya",
    en: "Millaniya",
  },
  {
    id: 10,
    name: "Millewa",
    si: "Millewa",
    en: "Millewa",
  },
  {
    id: 10,
    name: "Miwanapalana",
    si: "Miwanapalana",
    en: "Miwanapalana",
  },
  {
    id: 10,
    name: "Molkawa",
    si: "Molkawa",
    en: "Molkawa",
  },
  {
    id: 10,
    name: "Morapitiya",
    si: "Morapitiya",
    en: "Morapitiya",
  },
  {
    id: 10,
    name: "Morontuduwa",
    si: "Morontuduwa",
    en: "Morontuduwa",
  },
  {
    id: 10,
    name: "Nawattuduwa",
    si: "Nawattuduwa",
    en: "Nawattuduwa",
  },
  {
    id: 10,
    name: "Neboda",
    si: "Neboda",
    en: "Neboda",
  },
  {
    id: 10,
    name: "Padagoda",
    si: "Padagoda",
    en: "Padagoda",
  },
  {
    id: 10,
    name: "Pahalahewessa",
    si: "Pahalahewessa",
    en: "Pahalahewessa",
  },
  {
    id: 10,
    name: "Paiyagala",
    si: "Paiyagala",
    en: "Paiyagala",
  },
  {
    id: 10,
    name: "Panadura",
    si: "Panadura",
    en: "Panadura",
  },
  {
    id: 10,
    name: "Pannala",
    si: "Pannala",
    en: "Pannala",
  },
  {
    id: 10,
    name: "Paragastota",
    si: "Paragastota",
    en: "Paragastota",
  },
  {
    id: 10,
    name: "Paragoda",
    si: "Paragoda",
    en: "Paragoda",
  },
  {
    id: 10,
    name: "Paraigama",
    si: "Paraigama",
    en: "Paraigama",
  },
  {
    id: 10,
    name: "Pelanda",
    si: "Pelanda",
    en: "Pelanda",
  },
  {
    id: 10,
    name: "Pelawatta",
    si: "Pelawatta",
    en: "Pelawatta",
  },
  {
    id: 10,
    name: "Pimbura",
    si: "Pimbura",
    en: "Pimbura",
  },
  {
    id: 10,
    name: "Pitagaldeniya",
    si: "Pitagaldeniya",
    en: "Pitagaldeniya",
  },
  {
    id: 10,
    name: "Pokunuwita",
    si: "Pokunuwita",
    en: "Pokunuwita",
  },
  {
    id: 10,
    name: "Poruwedanda",
    si: "Poruwedanda",
    en: "Poruwedanda",
  },
  {
    id: 10,
    name: "Ratmale",
    si: "Ratmale",
    en: "Ratmale",
  },
  {
    id: 10,
    name: "Remunagoda",
    si: "Remunagoda",
    en: "Remunagoda",
  },
  {
    id: 10,
    name: "Talgaswela",
    si: "Talgaswela",
    en: "Talgaswela",
  },
  {
    id: 10,
    name: "Tebuwana",
    si: "Tebuwana",
    en: "Tebuwana",
  },
  {
    id: 10,
    name: "Uduwara",
    si: "Uduwara",
    en: "Uduwara",
  },
  {
    id: 10,
    name: "Utumgama",
    si: "Utumgama",
    en: "Utumgama",
  },
  {
    id: 10,
    name: "Veyangalla",
    si: "Veyangalla",
    en: "Veyangalla",
  },
  {
    id: 10,
    name: "Wadduwa",
    si: "Wadduwa",
    en: "Wadduwa",
  },
  {
    id: 10,
    name: "Walagedara",
    si: "Walagedara",
    en: "Walagedara",
  },
  {
    id: 10,
    name: "Walallawita",
    si: "Walallawita",
    en: "Walallawita",
  },
  {
    id: 10,
    name: "Waskaduwa",
    si: "Waskaduwa",
    en: "Waskaduwa",
  },
  {
    id: 10,
    name: "Welipenna",
    si: "Welipenna",
    en: "Welipenna",
  },
  {
    id: 10,
    name: "Weliveriya",
    si: "Weliveriya",
    en: "Weliveriya",
  },
  {
    id: 10,
    name: "Welmilla Junction",
    si: "Welmilla Junction",
    en: "Welmilla Junction",
  },
  {
    id: 10,
    name: "Weragala",
    si: "Weragala",
    en: "Weragala",
  },
  {
    id: 10,
    name: "Yagirala",
    si: "Yagirala",
    en: "Yagirala",
  },
  {
    id: 10,
    name: "Yatadolawatta",
    si: "Yatadolawatta",
    en: "Yatadolawatta",
  },
  {
    id: 10,
    name: "Yatawara Junction",
    si: "Yatawara Junction",
    en: "Yatawara Junction",
  },
  {
    id: 11,
    name: "Aludeniya",
    si: "අලුදෙණිය",
    en: "Aludeniya",
  },
  {
    id: 11,
    name: "Ambagahapelessa",
    si: "අඹගහපැලැස්ස",
    en: "Ambagahapelessa",
  },
  {
    id: 11,
    name: "Ambagamuwa Udabulathgama",
    si: "අඹගමුව උඩබුලත්ගම",
    en: "Ambagamuwa Udabulathgama",
  },
  {
    id: 11,
    name: "Ambatenna",
    si: "අඹතැන්න",
    en: "Ambatenna",
  },
  {
    id: 11,
    name: "Ampitiya",
    si: "අම්පිටිය",
    en: "Ampitiya",
  },
  {
    id: 11,
    name: "Ankumbura",
    si: "අංකුඹුර",
    en: "Ankumbura",
  },
  {
    id: 11,
    name: "Atabage",
    si: "අටබාගෙ",
    en: "Atabage",
  },
  {
    id: 11,
    name: "Balana",
    si: "බලන",
    en: "Balana",
  },
  {
    id: 11,
    name: "Bambaragahaela",
    si: "බඹරගහඇල",
    en: "Bambaragahaela",
  },
  {
    id: 11,
    name: "Batagolladeniya",
    si: "බටගොල්ලදෙණිය",
    en: "Batagolladeniya",
  },
  {
    id: 11,
    name: "Batugoda",
    si: "බටුගොඩ",
    en: "Batugoda",
  },
  {
    id: 11,
    name: "Batumulla",
    si: "බටුමුල්ල",
    en: "Batumulla",
  },
  {
    id: 11,
    name: "Bawlana",
    si: "බව්ලන",
    en: "Bawlana",
  },
  {
    id: 11,
    name: "Bopana",
    si: "බෝපන",
    en: "Bopana",
  },
  {
    id: 11,
    name: "Danture",
    si: "දංතුරේ",
    en: "Danture",
  },
  {
    id: 11,
    name: "Dedunupitiya",
    si: "දේදුනුපිටිය",
    en: "Dedunupitiya",
  },
  {
    id: 11,
    name: "Dekinda",
    si: "දෙකිඳ",
    en: "Dekinda",
  },
  {
    id: 11,
    name: "Deltota",
    si: "දෙල්තොට",
    en: "Deltota",
  },
  {
    id: 11,
    name: "Divulankadawala",
    si: "දිවුලන්කදවල",
    en: "Divulankadawala",
  },
  {
    id: 11,
    name: "Dolapihilla",
    si: "දොලපිහිල්ල",
    en: "Dolapihilla",
  },
  {
    id: 11,
    name: "Dolosbage",
    si: "දොලොස්බාගෙ",
    en: "Dolosbage",
  },
  {
    id: 11,
    name: "Dunuwila",
    si: "දුනුවිල",
    en: "Dunuwila",
  },
  {
    id: 11,
    name: "Etulgama",
    si: "ඇතුල්ගම",
    en: "Etulgama",
  },
  {
    id: 11,
    name: "Galaboda",
    si: "ගලබොඩ",
    en: "Galaboda",
  },
  {
    id: 11,
    name: "Galagedara",
    si: "ගලගෙදර",
    en: "Galagedara",
  },
  {
    id: 11,
    name: "Galaha",
    si: "ගලහ",
    en: "Galaha",
  },
  {
    id: 11,
    name: "Galhinna",
    si: "ගල්හින්න",
    en: "Galhinna",
  },
  {
    id: 11,
    name: "Gampola",
    si: "ගම්පොල",
    en: "Gampola",
  },
  {
    id: 11,
    name: "Gelioya",
    si: "ගෙලිඔය",
    en: "Gelioya",
  },
  {
    id: 11,
    name: "Godamunna",
    si: "ගොඩමුන්න",
    en: "Godamunna",
  },
  {
    id: 11,
    name: "Gomagoda",
    si: "ගොමගොඩ",
    en: "Gomagoda",
  },
  {
    id: 11,
    name: "Gonagantenna",
    si: "ගොනාගන්තැන්න",
    en: "Gonagantenna",
  },
  {
    id: 11,
    name: "Gonawalapatana",
    si: "ගෝනවලපතන",
    en: "Gonawalapatana",
  },
  {
    id: 11,
    name: "Gunnepana",
    si: "ගුන්නෙපන",
    en: "Gunnepana",
  },
  {
    id: 11,
    name: "Gurudeniya",
    si: "ගුරුදෙණිය",
    en: "Gurudeniya",
  },
  {
    id: 11,
    name: "Hakmana",
    si: "හක්මන",
    en: "Hakmana",
  },
  {
    id: 11,
    name: "Handaganawa",
    si: "හඳගනාව",
    en: "Handaganawa",
  },
  {
    id: 11,
    name: "Handawalapitiya",
    si: "හඳවලපිටිය",
    en: "Handawalapitiya",
  },
  {
    id: 11,
    name: "Handessa",
    si: "හඳැස්ස",
    en: "Handessa",
  },
  {
    id: 11,
    name: "Hanguranketha",
    si: "Hanguranketha",
    en: "Hanguranketha",
  },
  {
    id: 11,
    name: "Harangalagama",
    si: "Harangalagama",
    en: "Harangalagama",
  },
  {
    id: 11,
    name: "Hataraliyadda",
    si: "Hataraliyadda",
    en: "Hataraliyadda",
  },
  {
    id: 11,
    name: "Hindagala",
    si: "Hindagala",
    en: "Hindagala",
  },
  {
    id: 11,
    name: "Hondiyadeniya",
    si: "Hondiyadeniya",
    en: "Hondiyadeniya",
  },
  {
    id: 11,
    name: "Hunnasgiriya",
    si: "Hunnasgiriya",
    en: "Hunnasgiriya",
  },
  {
    id: 11,
    name: "Inguruwatta",
    si: "Inguruwatta",
    en: "Inguruwatta",
  },
  {
    id: 11,
    name: "Jambugahapitiya",
    si: "Jambugahapitiya",
    en: "Jambugahapitiya",
  },
  {
    id: 11,
    name: "Kadugannawa",
    si: "Kadugannawa",
    en: "Kadugannawa",
  },
  {
    id: 11,
    name: "Kahataliyadda",
    si: "Kahataliyadda",
    en: "Kahataliyadda",
  },
  {
    id: 11,
    name: "Kalugala",
    si: "Kalugala",
    en: "Kalugala",
  },
  {
    id: 11,
    name: "Kandy",
    si: "Kandy",
    en: "Kandy",
  },
  {
    id: 11,
    name: "Kapuliyadde",
    si: "Kapuliyadde",
    en: "Kapuliyadde",
  },
  {
    id: 11,
    name: "Katugastota",
    si: "Katugastota",
    en: "Katugastota",
  },
  {
    id: 11,
    name: "Katukitula",
    si: "Katukitula",
    en: "Katukitula",
  },
  {
    id: 11,
    name: "Kelanigama",
    si: "Kelanigama",
    en: "Kelanigama",
  },
  {
    id: 11,
    name: "Kengalla",
    si: "Kengalla",
    en: "Kengalla",
  },
  {
    id: 11,
    name: "Ketaboola",
    si: "Ketaboola",
    en: "Ketaboola",
  },
  {
    id: 11,
    name: "Ketakumbura",
    si: "Ketakumbura",
    en: "Ketakumbura",
  },
  {
    id: 11,
    name: "Kobonila",
    si: "Kobonila",
    en: "Kobonila",
  },
  {
    id: 11,
    name: "Kolabissa",
    si: "Kolabissa",
    en: "Kolabissa",
  },
  {
    id: 11,
    name: "Kolongoda",
    si: "Kolongoda",
    en: "Kolongoda",
  },
  {
    id: 11,
    name: "Kulugammana",
    si: "Kulugammana",
    en: "Kulugammana",
  },
  {
    id: 11,
    name: "Kumbukkandura",
    si: "Kumbukkandura",
    en: "Kumbukkandura",
  },
  {
    id: 11,
    name: "Kumburegama",
    si: "Kumburegama",
    en: "Kumburegama",
  },
  {
    id: 11,
    name: "Kundasale",
    si: "Kundasale",
    en: "Kundasale",
  },
  {
    id: 11,
    name: "Leemagahakotuwa",
    si: "Leemagahakotuwa",
    en: "Leemagahakotuwa",
  },
  {
    id: 11,
    name: "lhala Kobbekaduwa",
    si: "lhala Kobbekaduwa",
    en: "lhala Kobbekaduwa",
  },
  {
    id: 11,
    name: "Lunugama",
    si: "Lunugama",
    en: "Lunugama",
  },
  {
    id: 11,
    name: "Lunuketiya Maditta",
    si: "Lunuketiya Maditta",
    en: "Lunuketiya Maditta",
  },
  {
    id: 11,
    name: "Madawala Bazaar",
    si: "Madawala Bazaar",
    en: "Madawala Bazaar",
  },
  {
    id: 11,
    name: "Madawalalanda",
    si: "Madawalalanda",
    en: "Madawalalanda",
  },
  {
    id: 11,
    name: "Madugalla",
    si: "Madugalla",
    en: "Madugalla",
  },
  {
    id: 11,
    name: "Madulkele",
    si: "Madulkele",
    en: "Madulkele",
  },
  {
    id: 11,
    name: "Mahadoraliyadda",
    si: "Mahadoraliyadda",
    en: "Mahadoraliyadda",
  },
  {
    id: 11,
    name: "Mahamedagama",
    si: "Mahamedagama",
    en: "Mahamedagama",
  },
  {
    id: 11,
    name: "Mahanagapura",
    si: "Mahanagapura",
    en: "Mahanagapura",
  },
  {
    id: 11,
    name: "Mailapitiya",
    si: "Mailapitiya",
    en: "Mailapitiya",
  },
  {
    id: 11,
    name: "Makkanigama",
    si: "Makkanigama",
    en: "Makkanigama",
  },
  {
    id: 11,
    name: "Makuldeniya",
    si: "Makuldeniya",
    en: "Makuldeniya",
  },
  {
    id: 11,
    name: "Mangalagama",
    si: "Mangalagama",
    en: "Mangalagama",
  },
  {
    id: 11,
    name: "Mapakanda",
    si: "Mapakanda",
    en: "Mapakanda",
  },
  {
    id: 11,
    name: "Marassana",
    si: "Marassana",
    en: "Marassana",
  },
  {
    id: 11,
    name: "Marymount Colony",
    si: "Marymount Colony",
    en: "Marymount Colony",
  },
  {
    id: 11,
    name: "Mawatura",
    si: "Mawatura",
    en: "Mawatura",
  },
  {
    id: 11,
    name: "Medamahanuwara",
    si: "Medamahanuwara",
    en: "Medamahanuwara",
  },
  {
    id: 11,
    name: "Medawala Harispattuwa",
    si: "Medawala Harispattuwa",
    en: "Medawala Harispattuwa",
  },
  {
    id: 11,
    name: "Meetalawa",
    si: "Meetalawa",
    en: "Meetalawa",
  },
  {
    id: 11,
    name: "Megoda Kalugamuwa",
    si: "Megoda Kalugamuwa",
    en: "Megoda Kalugamuwa",
  },
  {
    id: 11,
    name: "Menikdiwela",
    si: "Menikdiwela",
    en: "Menikdiwela",
  },
  {
    id: 11,
    name: "Menikhinna",
    si: "Menikhinna",
    en: "Menikhinna",
  },
  {
    id: 11,
    name: "Mimure",
    si: "Mimure",
    en: "Mimure",
  },
  {
    id: 11,
    name: "Minigamuwa",
    si: "Minigamuwa",
    en: "Minigamuwa",
  },
  {
    id: 11,
    name: "Minipe",
    si: "Minipe",
    en: "Minipe",
  },
  {
    id: 11,
    name: "Moragahapallama",
    si: "Moragahapallama",
    en: "Moragahapallama",
  },
  {
    id: 11,
    name: "Murutalawa",
    si: "Murutalawa",
    en: "Murutalawa",
  },
  {
    id: 11,
    name: "Muruthagahamulla",
    si: "Muruthagahamulla",
    en: "Muruthagahamulla",
  },
  {
    id: 11,
    name: "Nanuoya",
    si: "Nanuoya",
    en: "Nanuoya",
  },
  {
    id: 11,
    name: "Naranpanawa",
    si: "Naranpanawa",
    en: "Naranpanawa",
  },
  {
    id: 11,
    name: "Narawelpita",
    si: "Narawelpita",
    en: "Narawelpita",
  },
  {
    id: 11,
    name: "Nawalapitiya",
    si: "Nawalapitiya",
    en: "Nawalapitiya",
  },
  {
    id: 11,
    name: "Nawathispane",
    si: "Nawathispane",
    en: "Nawathispane",
  },
  {
    id: 11,
    name: "Nillambe",
    si: "Nillambe",
    en: "Nillambe",
  },
  {
    id: 11,
    name: "Nugaliyadda",
    si: "Nugaliyadda",
    en: "Nugaliyadda",
  },
  {
    id: 11,
    name: "Ovilikanda",
    si: "Ovilikanda",
    en: "Ovilikanda",
  },
  {
    id: 11,
    name: "Pallekotuwa",
    si: "Pallekotuwa",
    en: "Pallekotuwa",
  },
  {
    id: 11,
    name: "Panwilatenna",
    si: "Panwilatenna",
    en: "Panwilatenna",
  },
  {
    id: 11,
    name: "Paradeka",
    si: "Paradeka",
    en: "Paradeka",
  },
  {
    id: 11,
    name: "Pasbage",
    si: "Pasbage",
    en: "Pasbage",
  },
  {
    id: 11,
    name: "Pattitalawa",
    si: "Pattitalawa",
    en: "Pattitalawa",
  },
  {
    id: 11,
    name: "Peradeniya",
    si: "Peradeniya",
    en: "Peradeniya",
  },
  {
    id: 11,
    name: "Pilimatalawa",
    si: "Pilimatalawa",
    en: "Pilimatalawa",
  },
  {
    id: 11,
    name: "Poholiyadda",
    si: "Poholiyadda",
    en: "Poholiyadda",
  },
  {
    id: 11,
    name: "Pubbiliya",
    si: "Pubbiliya",
    en: "Pubbiliya",
  },
  {
    id: 11,
    name: "Pupuressa",
    si: "Pupuressa",
    en: "Pupuressa",
  },
  {
    id: 11,
    name: "Pussellawa",
    si: "Pussellawa",
    en: "Pussellawa",
  },
  {
    id: 11,
    name: "Putuhapuwa",
    si: "Putuhapuwa",
    en: "Putuhapuwa",
  },
  {
    id: 11,
    name: "Rajawella",
    si: "Rajawella",
    en: "Rajawella",
  },
  {
    id: 11,
    name: "Rambukpitiya",
    si: "Rambukpitiya",
    en: "Rambukpitiya",
  },
  {
    id: 11,
    name: "Rambukwella",
    si: "Rambukwella",
    en: "Rambukwella",
  },
  {
    id: 11,
    name: "Rangala",
    si: "Rangala",
    en: "Rangala",
  },
  {
    id: 11,
    name: "Rantembe",
    si: "Rantembe",
    en: "Rantembe",
  },
  {
    id: 11,
    name: "Sangarajapura",
    si: "Sangarajapura",
    en: "Sangarajapura",
  },
  {
    id: 11,
    name: "Senarathwela",
    si: "Senarathwela",
    en: "Senarathwela",
  },
  {
    id: 11,
    name: "Talatuoya",
    si: "Talatuoya",
    en: "Talatuoya",
  },
  {
    id: 11,
    name: "Teldeniya",
    si: "Teldeniya",
    en: "Teldeniya",
  },
  {
    id: 11,
    name: "Tennekumbura",
    si: "Tennekumbura",
    en: "Tennekumbura",
  },
  {
    id: 11,
    name: "Uda Peradeniya",
    si: "Uda Peradeniya",
    en: "Uda Peradeniya",
  },
  {
    id: 11,
    name: "Udahentenna",
    si: "Udahentenna",
    en: "Udahentenna",
  },
  {
    id: 11,
    name: "Udatalawinna",
    si: "Udatalawinna",
    en: "Udatalawinna",
  },
  {
    id: 11,
    name: "Udispattuwa",
    si: "Udispattuwa",
    en: "Udispattuwa",
  },
  {
    id: 11,
    name: "Ududumbara",
    si: "Ududumbara",
    en: "Ududumbara",
  },
  {
    id: 11,
    name: "Uduwahinna",
    si: "Uduwahinna",
    en: "Uduwahinna",
  },
  {
    id: 11,
    name: "Uduwela",
    si: "Uduwela",
    en: "Uduwela",
  },
  {
    id: 11,
    name: "Ulapane",
    si: "Ulapane",
    en: "Ulapane",
  },
  {
    id: 11,
    name: "Unuwinna",
    si: "Unuwinna",
    en: "Unuwinna",
  },
  {
    id: 11,
    name: "Velamboda",
    si: "Velamboda",
    en: "Velamboda",
  },
  {
    id: 11,
    name: "Watagoda",
    si: "Watagoda",
    en: "Watagoda",
  },
  {
    id: 11,
    name: "Watagoda Harispattuwa",
    si: "Watagoda Harispattuwa",
    en: "Watagoda Harispattuwa",
  },
  {
    id: 11,
    name: "Wattappola",
    si: "Wattappola",
    en: "Wattappola",
  },
  {
    id: 11,
    name: "Weligampola",
    si: "Weligampola",
    en: "Weligampola",
  },
  {
    id: 11,
    name: "Wendaruwa",
    si: "Wendaruwa",
    en: "Wendaruwa",
  },
  {
    id: 11,
    name: "Weragantota",
    si: "Weragantota",
    en: "Weragantota",
  },
  {
    id: 11,
    name: "Werapitya",
    si: "Werapitya",
    en: "Werapitya",
  },
  {
    id: 11,
    name: "Werellagama",
    si: "Werellagama",
    en: "Werellagama",
  },
  {
    id: 11,
    name: "Wettawa",
    si: "Wettawa",
    en: "Wettawa",
  },
  {
    id: 11,
    name: "Yahalatenna",
    si: "Yahalatenna",
    en: "Yahalatenna",
  },
  {
    id: 11,
    name: "Yatihalagala",
    si: "Yatihalagala",
    en: "Yatihalagala",
  },
  {
    id: 12,
    name: "Alawala",
    si: "අලවල",
    en: "Alawala",
  },
  {
    id: 12,
    name: "Alawatura",
    si: "අලවතුර",
    en: "Alawatura",
  },
  {
    id: 12,
    name: "Alawwa",
    si: "අලව්ව",
    en: "Alawwa",
  },
  {
    id: 12,
    name: "Algama",
    si: "අල්ගම",
    en: "Algama",
  },
  {
    id: 12,
    name: "Alutnuwara",
    si: "අළුත්නුවර",
    en: "Alutnuwara",
  },
  {
    id: 12,
    name: "Ambalakanda",
    si: "අම්බලකන්ද",
    en: "Ambalakanda",
  },
  {
    id: 12,
    name: "Ambulugala",
    si: "අම්බුළුගල",
    en: "Ambulugala",
  },
  {
    id: 12,
    name: "Amitirigala",
    si: "අමිතිරිගල",
    en: "Amitirigala",
  },
  {
    id: 12,
    name: "Ampagala",
    si: "අම්පාගල",
    en: "Ampagala",
  },
  {
    id: 12,
    name: "Anhandiya",
    si: "අංහන්දිය",
    en: "Anhandiya",
  },
  {
    id: 12,
    name: "Anhettigama",
    si: "අංහෙට්ටිගම",
    en: "Anhettigama",
  },
  {
    id: 12,
    name: "Aranayaka",
    si: "අරනායක",
    en: "Aranayaka",
  },
  {
    id: 12,
    name: "Aruggammana",
    si: "අරුග්ගම්මන",
    en: "Aruggammana",
  },
  {
    id: 12,
    name: "Batuwita",
    si: "බටුවිට",
    en: "Batuwita",
  },
  {
    id: 12,
    name: "Beligala[Sab]",
    si: "බෙලිගල",
    en: "Beligala[Sab]",
  },
  {
    id: 12,
    name: "Belihuloya",
    si: "බෙලිහුල්ඔය",
    en: "Belihuloya",
  },
  {
    id: 12,
    name: "Berannawa",
    si: "බෙරන්නව",
    en: "Berannawa",
  },
  {
    id: 12,
    name: "Bopitiya",
    si: "බෝපිටිය",
    en: "Bopitiya",
  },
  {
    id: 12,
    name: "Bopitiya [SAB]",
    si: "බෝපිටිය [සබර]",
    en: "Bopitiya [SAB]",
  },
  {
    id: 12,
    name: "Boralankada",
    si: "බොරලන්කද",
    en: "Boralankada",
  },
  {
    id: 12,
    name: "Bossella",
    si: "බොස්සැල්ල",
    en: "Bossella",
  },
  {
    id: 12,
    name: "Bulathkohupitiya",
    si: "බුලත්කොහුපිටිය",
    en: "Bulathkohupitiya",
  },
  {
    id: 12,
    name: "Damunupola",
    si: "දමුනුපොල",
    en: "Damunupola",
  },
  {
    id: 12,
    name: "Debathgama",
    si: "දෙබත්ගම",
    en: "Debathgama",
  },
  {
    id: 12,
    name: "Dedugala",
    si: "දේදුගල",
    en: "Dedugala",
  },
  {
    id: 12,
    name: "Deewala Pallegama",
    si: "දීවල පල්ලෙගම",
    en: "Deewala Pallegama",
  },
  {
    id: 12,
    name: "Dehiowita",
    si: "දෙහිඕවිට",
    en: "Dehiowita",
  },
  {
    id: 12,
    name: "Deldeniya",
    si: "දෙල්දෙණිය",
    en: "Deldeniya",
  },
  {
    id: 12,
    name: "Deloluwa",
    si: "දෙලෝලුව",
    en: "Deloluwa",
  },
  {
    id: 12,
    name: "Deraniyagala",
    si: "දැරණියගල",
    en: "Deraniyagala",
  },
  {
    id: 12,
    name: "Dewalegama",
    si: "දේවාලේගම",
    en: "Dewalegama",
  },
  {
    id: 12,
    name: "Dewanagala",
    si: "දෙවනගල",
    en: "Dewanagala",
  },
  {
    id: 12,
    name: "Dombemada",
    si: "දොඹේමද",
    en: "Dombemada",
  },
  {
    id: 12,
    name: "Dorawaka",
    si: "දොරවක",
    en: "Dorawaka",
  },
  {
    id: 12,
    name: "Dunumala",
    si: "දුනුමල",
    en: "Dunumala",
  },
  {
    id: 12,
    name: "Galapitamada",
    si: "ගලපිටමඩ",
    en: "Galapitamada",
  },
  {
    id: 12,
    name: "Galatara",
    si: "ගලතර",
    en: "Galatara",
  },
  {
    id: 12,
    name: "Galigamuwa Town",
    si: "ගලිගමුව නගරය",
    en: "Galigamuwa Town",
  },
  {
    id: 12,
    name: "Gallella",
    si: "ගල්ලෑල්ල",
    en: "Gallella",
  },
  {
    id: 12,
    name: "Galpatha[Sab]",
    si: "ගල්පාත [සබරගමුව]",
    en: "Galpatha[Sab]",
  },
  {
    id: 12,
    name: "Gantuna",
    si: "ගන්තුන",
    en: "Gantuna",
  },
  {
    id: 12,
    name: "Getahetta",
    si: "ගැටහැත්ත",
    en: "Getahetta",
  },
  {
    id: 12,
    name: "Godagampola",
    si: "ගොඩගම්පොල",
    en: "Godagampola",
  },
  {
    id: 12,
    name: "Gonagala",
    si: "ගෝනාගල",
    en: "Gonagala",
  },
  {
    id: 12,
    name: "Hakahinna",
    si: "හකහින්න",
    en: "Hakahinna",
  },
  {
    id: 12,
    name: "Hakbellawaka",
    si: "හක්බෙල්ලවක",
    en: "Hakbellawaka",
  },
  {
    id: 12,
    name: "Halloluwa",
    si: "හල්ලෝලුව",
    en: "Halloluwa",
  },
  {
    id: 12,
    name: "Hedunuwewa",
    si: "Hedunuwewa",
    en: "Hedunuwewa",
  },
  {
    id: 12,
    name: "Hemmatagama",
    si: "Hemmatagama",
    en: "Hemmatagama",
  },
  {
    id: 12,
    name: "Hewadiwela",
    si: "Hewadiwela",
    en: "Hewadiwela",
  },
  {
    id: 12,
    name: "Hingula",
    si: "Hingula",
    en: "Hingula",
  },
  {
    id: 12,
    name: "Hinguralakanda",
    si: "Hinguralakanda",
    en: "Hinguralakanda",
  },
  {
    id: 12,
    name: "Hingurana",
    si: "Hingurana",
    en: "Hingurana",
  },
  {
    id: 12,
    name: "Hiriwadunna",
    si: "Hiriwadunna",
    en: "Hiriwadunna",
  },
  {
    id: 12,
    name: "Ihala Walpola",
    si: "Ihala Walpola",
    en: "Ihala Walpola",
  },
  {
    id: 12,
    name: "Ihalagama",
    si: "Ihalagama",
    en: "Ihalagama",
  },
  {
    id: 12,
    name: "Imbulana",
    si: "Imbulana",
    en: "Imbulana",
  },
  {
    id: 12,
    name: "Imbulgasdeniya",
    si: "Imbulgasdeniya",
    en: "Imbulgasdeniya",
  },
  {
    id: 12,
    name: "Kabagamuwa",
    si: "Kabagamuwa",
    en: "Kabagamuwa",
  },
  {
    id: 12,
    name: "Kahapathwala",
    si: "Kahapathwala",
    en: "Kahapathwala",
  },
  {
    id: 12,
    name: "Kandaketya",
    si: "Kandaketya",
    en: "Kandaketya",
  },
  {
    id: 12,
    name: "Kannattota",
    si: "Kannattota",
    en: "Kannattota",
  },
  {
    id: 12,
    name: "Karagahinna",
    si: "Karagahinna",
    en: "Karagahinna",
  },
  {
    id: 12,
    name: "Kegalle",
    si: "Kegalle",
    en: "Kegalle",
  },
  {
    id: 12,
    name: "Kehelpannala",
    si: "Kehelpannala",
    en: "Kehelpannala",
  },
  {
    id: 12,
    name: "Ketawala Leula",
    si: "Ketawala Leula",
    en: "Ketawala Leula",
  },
  {
    id: 12,
    name: "Kitulgala",
    si: "Kitulgala",
    en: "Kitulgala",
  },
  {
    id: 12,
    name: "Kondeniya",
    si: "Kondeniya",
    en: "Kondeniya",
  },
  {
    id: 12,
    name: "Kotiyakumbura",
    si: "Kotiyakumbura",
    en: "Kotiyakumbura",
  },
  {
    id: 12,
    name: "Lewangama",
    si: "Lewangama",
    en: "Lewangama",
  },
  {
    id: 12,
    name: "Mahabage",
    si: "Mahabage",
    en: "Mahabage",
  },
  {
    id: 12,
    name: "Makehelwala",
    si: "Makehelwala",
    en: "Makehelwala",
  },
  {
    id: 12,
    name: "Malalpola",
    si: "Malalpola",
    en: "Malalpola",
  },
  {
    id: 12,
    name: "Maldeniya",
    si: "Maldeniya",
    en: "Maldeniya",
  },
  {
    id: 12,
    name: "Maliboda",
    si: "Maliboda",
    en: "Maliboda",
  },
  {
    id: 12,
    name: "Maliyadda",
    si: "Maliyadda",
    en: "Maliyadda",
  },
  {
    id: 12,
    name: "Malmaduwa",
    si: "Malmaduwa",
    en: "Malmaduwa",
  },
  {
    id: 12,
    name: "Marapana",
    si: "Marapana",
    en: "Marapana",
  },
  {
    id: 12,
    name: "Mawanella",
    si: "Mawanella",
    en: "Mawanella",
  },
  {
    id: 12,
    name: "Meetanwala",
    si: "Meetanwala",
    en: "Meetanwala",
  },
  {
    id: 12,
    name: "Migastenna Sabara",
    si: "Migastenna Sabara",
    en: "Migastenna Sabara",
  },
  {
    id: 12,
    name: "Miyanawita",
    si: "Miyanawita",
    en: "Miyanawita",
  },
  {
    id: 12,
    name: "Molagoda",
    si: "Molagoda",
    en: "Molagoda",
  },
  {
    id: 12,
    name: "Morontota",
    si: "Morontota",
    en: "Morontota",
  },
  {
    id: 12,
    name: "Narangala",
    si: "Narangala",
    en: "Narangala",
  },
  {
    id: 12,
    name: "Narangoda",
    si: "Narangoda",
    en: "Narangoda",
  },
  {
    id: 12,
    name: "Nattarampotha",
    si: "Nattarampotha",
    en: "Nattarampotha",
  },
  {
    id: 12,
    name: "Nelundeniya",
    si: "Nelundeniya",
    en: "Nelundeniya",
  },
  {
    id: 12,
    name: "Niyadurupola",
    si: "Niyadurupola",
    en: "Niyadurupola",
  },
  {
    id: 12,
    name: "Noori",
    si: "Noori",
    en: "Noori",
  },
  {
    id: 12,
    name: "Pannila",
    si: "Pannila",
    en: "Pannila",
  },
  {
    id: 12,
    name: "Pattampitiya",
    si: "Pattampitiya",
    en: "Pattampitiya",
  },
  {
    id: 12,
    name: "Pilawala",
    si: "Pilawala",
    en: "Pilawala",
  },
  {
    id: 12,
    name: "Pothukoladeniya",
    si: "Pothukoladeniya",
    en: "Pothukoladeniya",
  },
  {
    id: 12,
    name: "Puswelitenna",
    si: "Puswelitenna",
    en: "Puswelitenna",
  },
  {
    id: 12,
    name: "Rambukkana",
    si: "Rambukkana",
    en: "Rambukkana",
  },
  {
    id: 12,
    name: "Rilpola",
    si: "Rilpola",
    en: "Rilpola",
  },
  {
    id: 12,
    name: "Rukmale",
    si: "Rukmale",
    en: "Rukmale",
  },
  {
    id: 12,
    name: "Ruwanwella",
    si: "Ruwanwella",
    en: "Ruwanwella",
  },
  {
    id: 12,
    name: "Samanalawewa",
    si: "Samanalawewa",
    en: "Samanalawewa",
  },
  {
    id: 12,
    name: "Seaforth Colony",
    si: "Seaforth Colony",
    en: "Seaforth Colony",
  },
  {
    id: 5,
    name: "Colombo 2",
    si: "කොළඹ 2",
    en: "Colombo 2",
  },
  {
    id: 12,
    name: "Spring Valley",
    si: "Spring Valley",
    en: "Spring Valley",
  },
  {
    id: 12,
    name: "Talgaspitiya",
    si: "Talgaspitiya",
    en: "Talgaspitiya",
  },
  {
    id: 12,
    name: "Teligama",
    si: "Teligama",
    en: "Teligama",
  },
  {
    id: 12,
    name: "Tholangamuwa",
    si: "Tholangamuwa",
    en: "Tholangamuwa",
  },
  {
    id: 12,
    name: "Thotawella",
    si: "Thotawella",
    en: "Thotawella",
  },
  {
    id: 12,
    name: "Udaha Hawupe",
    si: "Udaha Hawupe",
    en: "Udaha Hawupe",
  },
  {
    id: 12,
    name: "Udapotha",
    si: "Udapotha",
    en: "Udapotha",
  },
  {
    id: 12,
    name: "Uduwa",
    si: "Uduwa",
    en: "Uduwa",
  },
  {
    id: 12,
    name: "Undugoda",
    si: "Undugoda",
    en: "Undugoda",
  },
  {
    id: 12,
    name: "Ussapitiya",
    si: "Ussapitiya",
    en: "Ussapitiya",
  },
  {
    id: 12,
    name: "Wahakula",
    si: "Wahakula",
    en: "Wahakula",
  },
  {
    id: 12,
    name: "Waharaka",
    si: "Waharaka",
    en: "Waharaka",
  },
  {
    id: 12,
    name: "Wanaluwewa",
    si: "Wanaluwewa",
    en: "Wanaluwewa",
  },
  {
    id: 12,
    name: "Warakapola",
    si: "Warakapola",
    en: "Warakapola",
  },
  {
    id: 12,
    name: "Watura",
    si: "Watura",
    en: "Watura",
  },
  {
    id: 12,
    name: "Weeoya",
    si: "Weeoya",
    en: "Weeoya",
  },
  {
    id: 12,
    name: "Wegalla",
    si: "Wegalla",
    en: "Wegalla",
  },
  {
    id: 12,
    name: "Weligalla",
    si: "Weligalla",
    en: "Weligalla",
  },
  {
    id: 12,
    name: "Welihelatenna",
    si: "Welihelatenna",
    en: "Welihelatenna",
  },
  {
    id: 12,
    name: "Wewelwatta",
    si: "Wewelwatta",
    en: "Wewelwatta",
  },
  {
    id: 12,
    name: "Yatagama",
    si: "Yatagama",
    en: "Yatagama",
  },
  {
    id: 12,
    name: "Yatapana",
    si: "Yatapana",
    en: "Yatapana",
  },
  {
    id: 12,
    name: "Yatiyantota",
    si: "Yatiyantota",
    en: "Yatiyantota",
  },
  {
    id: 12,
    name: "Yattogoda",
    si: "Yattogoda",
    en: "Yattogoda",
  },
  {
    id: 13,
    name: "Kandavalai",
    si: "Kandavalai",
    en: "Kandavalai",
  },
  {
    id: 13,
    name: "Karachchi",
    si: "Karachchi",
    en: "Karachchi",
  },
  {
    id: 13,
    name: "Kilinochchi",
    si: "Kilinochchi",
    en: "Kilinochchi",
  },
  {
    id: 13,
    name: "Pachchilaipalli",
    si: "Pachchilaipalli",
    en: "Pachchilaipalli",
  },
  {
    id: 13,
    name: "Poonakary",
    si: "Poonakary",
    en: "Poonakary",
  },
  {
    id: 14,
    name: "Akurana",
    si: "අකුරණ",
    en: "Akurana",
  },
  {
    id: 14,
    name: "Alahengama",
    si: "අලහෙන්ගම",
    en: "Alahengama",
  },
  {
    id: 14,
    name: "Alahitiyawa",
    si: "අලහිටියාව",
    en: "Alahitiyawa",
  },
  {
    id: 14,
    name: "Ambakote",
    si: "අඹකොටේ",
    en: "Ambakote",
  },
  {
    id: 14,
    name: "Ambanpola",
    si: "අඹන්පොල",
    en: "Ambanpola",
  },
  {
    id: 14,
    name: "Andiyagala",
    si: "ආඬියාගල",
    en: "Andiyagala",
  },
  {
    id: 14,
    name: "Anukkane",
    si: "අනුක්කනේ",
    en: "Anukkane",
  },
  {
    id: 14,
    name: "Aragoda",
    si: "අරංගොඩ",
    en: "Aragoda",
  },
  {
    id: 14,
    name: "Ataragalla",
    si: "අටරගල්ල",
    en: "Ataragalla",
  },
  {
    id: 14,
    name: "Awulegama",
    si: "අවුලේගම",
    en: "Awulegama",
  },
  {
    id: 14,
    name: "Balalla",
    si: "බලල්ල",
    en: "Balalla",
  },
  {
    id: 14,
    name: "Bamunukotuwa",
    si: "බමුණකොටුව",
    en: "Bamunukotuwa",
  },
  {
    id: 14,
    name: "Bandara Koswatta",
    si: "බන්ඩාර කොස්වත්ත",
    en: "Bandara Koswatta",
  },
  {
    id: 14,
    name: "Bingiriya",
    si: "බින්ගිරිය",
    en: "Bingiriya",
  },
  {
    id: 14,
    name: "Bogamulla",
    si: "බෝගමුල්ල",
    en: "Bogamulla",
  },
  {
    id: 14,
    name: "Boraluwewa",
    si: "බොරළුවැව",
    en: "Boraluwewa",
  },
  {
    id: 14,
    name: "Boyagane",
    si: "බෝයගානෙ",
    en: "Boyagane",
  },
  {
    id: 14,
    name: "Bujjomuwa",
    si: "බුජ්ජෝමුව",
    en: "Bujjomuwa",
  },
  {
    id: 14,
    name: "Buluwala",
    si: "බුලුවල",
    en: "Buluwala",
  },
  {
    id: 14,
    name: "Dadayamtalawa",
    si: "දඩයම්තලාව",
    en: "Dadayamtalawa",
  },
  {
    id: 14,
    name: "Dambadeniya",
    si: "දඹදෙණිය",
    en: "Dambadeniya",
  },
  {
    id: 14,
    name: "Daraluwa",
    si: "දරලුව",
    en: "Daraluwa",
  },
  {
    id: 14,
    name: "Deegalla",
    si: "දීගල්ල",
    en: "Deegalla",
  },
  {
    id: 14,
    name: "Demataluwa",
    si: "දෙමටලුව",
    en: "Demataluwa",
  },
  {
    id: 14,
    name: "Demuwatha",
    si: "දෙමුවත",
    en: "Demuwatha",
  },
  {
    id: 14,
    name: "Diddeniya",
    si: "දෙණියාය",
    en: "Diddeniya",
  },
  {
    id: 14,
    name: "Digannewa",
    si: "දිගන්නෑව",
    en: "Digannewa",
  },
  {
    id: 14,
    name: "Divullegoda",
    si: "දිවුලේගොඩ",
    en: "Divullegoda",
  },
  {
    id: 14,
    name: "Diyasenpura",
    si: "දියසෙන්පුර",
    en: "Diyasenpura",
  },
  {
    id: 14,
    name: "Dodangaslanda",
    si: "දොඩන්ගස්ලන්ද",
    en: "Dodangaslanda",
  },
  {
    id: 14,
    name: "Doluwa",
    si: "දොළුව",
    en: "Doluwa",
  },
  {
    id: 14,
    name: "Doragamuwa",
    si: "දොරගමුව",
    en: "Doragamuwa",
  },
  {
    id: 14,
    name: "Doratiyawa",
    si: "දොරටියාව",
    en: "Doratiyawa",
  },
  {
    id: 14,
    name: "Dunumadalawa",
    si: "දුනුමඩවල",
    en: "Dunumadalawa",
  },
  {
    id: 14,
    name: "Dunuwilapitiya",
    si: "දුනුවිලපිටිය",
    en: "Dunuwilapitiya",
  },
  {
    id: 14,
    name: "Ehetuwewa",
    si: "ඇහැටුවැව",
    en: "Ehetuwewa",
  },
  {
    id: 14,
    name: "Elibichchiya",
    si: "ඇලිබිච්චිය",
    en: "Elibichchiya",
  },
  {
    id: 14,
    name: "Embogama",
    si: "Embogama",
    en: "Embogama",
  },
  {
    id: 14,
    name: "Etungahakotuwa",
    si: "ඇතුන්ගහකොටුව",
    en: "Etungahakotuwa",
  },
  {
    id: 14,
    name: "Galadivulwewa",
    si: "ගලදිවුල්වැව",
    en: "Galadivulwewa",
  },
  {
    id: 14,
    name: "Galgamuwa",
    si: "ගල්ගමුව",
    en: "Galgamuwa",
  },
  {
    id: 14,
    name: "Gallellagama",
    si: "ගල්ලෑල්ලගම",
    en: "Gallellagama",
  },
  {
    id: 14,
    name: "Gallewa",
    si: "Gallewa",
    en: "Gallewa",
  },
  {
    id: 14,
    name: "Ganegoda",
    si: "ගණේගොඩ",
    en: "Ganegoda",
  },
  {
    id: 14,
    name: "Girathalana",
    si: "ගිරාතලන",
    en: "Girathalana",
  },
  {
    id: 14,
    name: "Gokaralla",
    si: "ගොකරුල්ල",
    en: "Gokaralla",
  },
  {
    id: 14,
    name: "Gonawila",
    si: "ගොනාවිල",
    en: "Gonawila",
  },
  {
    id: 14,
    name: "Halmillawewa",
    si: "හල්මිල්ලවැව",
    en: "Halmillawewa",
  },
  {
    id: 14,
    name: "Handungamuwa",
    si: "Handungamuwa",
    en: "Handungamuwa",
  },
  {
    id: 14,
    name: "Harankahawa",
    si: "Harankahawa",
    en: "Harankahawa",
  },
  {
    id: 14,
    name: "Helamada",
    si: "Helamada",
    en: "Helamada",
  },
  {
    id: 14,
    name: "Hengamuwa",
    si: "Hengamuwa",
    en: "Hengamuwa",
  },
  {
    id: 14,
    name: "Hettipola",
    si: "Hettipola",
    en: "Hettipola",
  },
  {
    id: 14,
    name: "Hewainna",
    si: "Hewainna",
    en: "Hewainna",
  },
  {
    id: 14,
    name: "Hilogama",
    si: "Hilogama",
    en: "Hilogama",
  },
  {
    id: 14,
    name: "Hindagolla",
    si: "Hindagolla",
    en: "Hindagolla",
  },
  {
    id: 14,
    name: "Hiriyala Lenawa",
    si: "Hiriyala Lenawa",
    en: "Hiriyala Lenawa",
  },
  {
    id: 14,
    name: "Hiruwalpola",
    si: "Hiruwalpola",
    en: "Hiruwalpola",
  },
  {
    id: 14,
    name: "Horambawa",
    si: "Horambawa",
    en: "Horambawa",
  },
  {
    id: 14,
    name: "Hulogedara",
    si: "Hulogedara",
    en: "Hulogedara",
  },
  {
    id: 14,
    name: "Hulugalla",
    si: "Hulugalla",
    en: "Hulugalla",
  },
  {
    id: 14,
    name: "Ihala Gomugomuwa",
    si: "Ihala Gomugomuwa",
    en: "Ihala Gomugomuwa",
  },
  {
    id: 14,
    name: "Ihala Katugampala",
    si: "Ihala Katugampala",
    en: "Ihala Katugampala",
  },
  {
    id: 14,
    name: "Indulgodakanda",
    si: "Indulgodakanda",
    en: "Indulgodakanda",
  },
  {
    id: 14,
    name: "Ithanawatta",
    si: "Ithanawatta",
    en: "Ithanawatta",
  },
  {
    id: 14,
    name: "Kadigawa",
    si: "Kadigawa",
    en: "Kadigawa",
  },
  {
    id: 14,
    name: "Kalankuttiya",
    si: "Kalankuttiya",
    en: "Kalankuttiya",
  },
  {
    id: 14,
    name: "Kalatuwawa",
    si: "Kalatuwawa",
    en: "Kalatuwawa",
  },
  {
    id: 14,
    name: "Kalugamuwa",
    si: "Kalugamuwa",
    en: "Kalugamuwa",
  },
  {
    id: 14,
    name: "Kanadeniyawala",
    si: "Kanadeniyawala",
    en: "Kanadeniyawala",
  },
  {
    id: 14,
    name: "Kanattewewa",
    si: "Kanattewewa",
    en: "Kanattewewa",
  },
  {
    id: 14,
    name: "Kandegedara",
    si: "Kandegedara",
    en: "Kandegedara",
  },
  {
    id: 14,
    name: "Karagahagedara",
    si: "Karagahagedara",
    en: "Karagahagedara",
  },
  {
    id: 14,
    name: "Karambe",
    si: "Karambe",
    en: "Karambe",
  },
  {
    id: 14,
    name: "Katiyawa",
    si: "Katiyawa",
    en: "Katiyawa",
  },
  {
    id: 14,
    name: "Katupota",
    si: "Katupota",
    en: "Katupota",
  },
  {
    id: 14,
    name: "Kawudulla",
    si: "Kawudulla",
    en: "Kawudulla",
  },
  {
    id: 14,
    name: "Kawuduluwewa Stagell",
    si: "Kawuduluwewa Stagell",
    en: "Kawuduluwewa Stagell",
  },
  {
    id: 14,
    name: "Kekunagolla",
    si: "Kekunagolla",
    en: "Kekunagolla",
  },
  {
    id: 14,
    name: "Keppitiwalana",
    si: "Keppitiwalana",
    en: "Keppitiwalana",
  },
  {
    id: 14,
    name: "Kimbulwanaoya",
    si: "Kimbulwanaoya",
    en: "Kimbulwanaoya",
  },
  {
    id: 14,
    name: "Kirimetiyawa",
    si: "Kirimetiyawa",
    en: "Kirimetiyawa",
  },
  {
    id: 14,
    name: "Kirindawa",
    si: "Kirindawa",
    en: "Kirindawa",
  },
  {
    id: 14,
    name: "Kirindigalla",
    si: "Kirindigalla",
    en: "Kirindigalla",
  },
  {
    id: 14,
    name: "Kithalawa",
    si: "Kithalawa",
    en: "Kithalawa",
  },
  {
    id: 14,
    name: "Kitulwala",
    si: "Kitulwala",
    en: "Kitulwala",
  },
  {
    id: 14,
    name: "Kobeigane",
    si: "Kobeigane",
    en: "Kobeigane",
  },
  {
    id: 14,
    name: "Kohilagedara",
    si: "Kohilagedara",
    en: "Kohilagedara",
  },
  {
    id: 14,
    name: "Konwewa",
    si: "Konwewa",
    en: "Konwewa",
  },
  {
    id: 14,
    name: "Kosdeniya",
    si: "Kosdeniya",
    en: "Kosdeniya",
  },
  {
    id: 14,
    name: "Kosgolla",
    si: "Kosgolla",
    en: "Kosgolla",
  },
  {
    id: 14,
    name: "Kotagala",
    si: "Kotagala",
    en: "Kotagala",
  },
  {
    id: 5,
    name: "Colombo 13",
    si: "කොළඹ 13",
    en: "Colombo 13",
  },
  {
    id: 14,
    name: "Kotawehera",
    si: "Kotawehera",
    en: "Kotawehera",
  },
  {
    id: 14,
    name: "Kudagalgamuwa",
    si: "Kudagalgamuwa",
    en: "Kudagalgamuwa",
  },
  {
    id: 14,
    name: "Kudakatnoruwa",
    si: "Kudakatnoruwa",
    en: "Kudakatnoruwa",
  },
  {
    id: 14,
    name: "Kuliyapitiya",
    si: "Kuliyapitiya",
    en: "Kuliyapitiya",
  },
  {
    id: 14,
    name: "Kumaragama",
    si: "Kumaragama",
    en: "Kumaragama",
  },
  {
    id: 14,
    name: "Kumbukgeta",
    si: "Kumbukgeta",
    en: "Kumbukgeta",
  },
  {
    id: 14,
    name: "Kumbukwewa",
    si: "Kumbukwewa",
    en: "Kumbukwewa",
  },
  {
    id: 14,
    name: "Kuratihena",
    si: "Kuratihena",
    en: "Kuratihena",
  },
  {
    id: 14,
    name: "Kurunegala",
    si: "Kurunegala",
    en: "Kurunegala",
  },
  {
    id: 14,
    name: "lbbagamuwa",
    si: "lbbagamuwa",
    en: "lbbagamuwa",
  },
  {
    id: 14,
    name: "lhala Kadigamuwa",
    si: "lhala Kadigamuwa",
    en: "lhala Kadigamuwa",
  },
  {
    id: 14,
    name: "Lihiriyagama",
    si: "Lihiriyagama",
    en: "Lihiriyagama",
  },
  {
    id: 14,
    name: "lllagolla",
    si: "lllagolla",
    en: "lllagolla",
  },
  {
    id: 14,
    name: "llukhena",
    si: "llukhena",
    en: "llukhena",
  },
  {
    id: 14,
    name: "Lonahettiya",
    si: "Lonahettiya",
    en: "Lonahettiya",
  },
  {
    id: 14,
    name: "Madahapola",
    si: "Madahapola",
    en: "Madahapola",
  },
  {
    id: 14,
    name: "Madakumburumulla",
    si: "Madakumburumulla",
    en: "Madakumburumulla",
  },
  {
    id: 14,
    name: "Madalagama",
    si: "Madalagama",
    en: "Madalagama",
  },
  {
    id: 14,
    name: "Madawala Ulpotha",
    si: "Madawala Ulpotha",
    en: "Madawala Ulpotha",
  },
  {
    id: 14,
    name: "Maduragoda",
    si: "Maduragoda",
    en: "Maduragoda",
  },
  {
    id: 14,
    name: "Maeliya",
    si: "Maeliya",
    en: "Maeliya",
  },
  {
    id: 14,
    name: "Magulagama",
    si: "Magulagama",
    en: "Magulagama",
  },
  {
    id: 14,
    name: "Maha Ambagaswewa",
    si: "Maha Ambagaswewa",
    en: "Maha Ambagaswewa",
  },
  {
    id: 14,
    name: "Mahagalkadawala",
    si: "Mahagalkadawala",
    en: "Mahagalkadawala",
  },
  {
    id: 14,
    name: "Mahagirilla",
    si: "Mahagirilla",
    en: "Mahagirilla",
  },
  {
    id: 14,
    name: "Mahamukalanyaya",
    si: "Mahamukalanyaya",
    en: "Mahamukalanyaya",
  },
  {
    id: 14,
    name: "Mahananneriya",
    si: "Mahananneriya",
    en: "Mahananneriya",
  },
  {
    id: 14,
    name: "Mahapallegama",
    si: "Mahapallegama",
    en: "Mahapallegama",
  },
  {
    id: 14,
    name: "Maharachchimulla",
    si: "Maharachchimulla",
    en: "Maharachchimulla",
  },
  {
    id: 14,
    name: "Mahatalakolawewa",
    si: "Mahatalakolawewa",
    en: "Mahatalakolawewa",
  },
  {
    id: 14,
    name: "Mahawewa",
    si: "Mahawewa",
    en: "Mahawewa",
  },
  {
    id: 14,
    name: "Maho",
    si: "Maho",
    en: "Maho",
  },
  {
    id: 14,
    name: "Makulewa",
    si: "Makulewa",
    en: "Makulewa",
  },
  {
    id: 14,
    name: "Makulpotha",
    si: "Makulpotha",
    en: "Makulpotha",
  },
  {
    id: 14,
    name: "Makulwewa",
    si: "Makulwewa",
    en: "Makulwewa",
  },
  {
    id: 14,
    name: "Malagane",
    si: "Malagane",
    en: "Malagane",
  },
  {
    id: 14,
    name: "Mandapola",
    si: "Mandapola",
    en: "Mandapola",
  },
  {
    id: 14,
    name: "Maspotha",
    si: "Maspotha",
    en: "Maspotha",
  },
  {
    id: 14,
    name: "Mawathagama",
    si: "Mawathagama",
    en: "Mawathagama",
  },
  {
    id: 14,
    name: "Medirigiriya",
    si: "Medirigiriya",
    en: "Medirigiriya",
  },
  {
    id: 14,
    name: "Medivawa",
    si: "Medivawa",
    en: "Medivawa",
  },
  {
    id: 14,
    name: "Meegalawa",
    si: "Meegalawa",
    en: "Meegalawa",
  },
  {
    id: 14,
    name: "Meegaswewa",
    si: "Meegaswewa",
    en: "Meegaswewa",
  },
  {
    id: 14,
    name: "Meewellawa",
    si: "Meewellawa",
    en: "Meewellawa",
  },
  {
    id: 14,
    name: "Melsiripura",
    si: "Melsiripura",
    en: "Melsiripura",
  },
  {
    id: 14,
    name: "Metikumbura",
    si: "Metikumbura",
    en: "Metikumbura",
  },
  {
    id: 14,
    name: "Metiyagane",
    si: "Metiyagane",
    en: "Metiyagane",
  },
  {
    id: 14,
    name: "Minhettiya",
    si: "Minhettiya",
    en: "Minhettiya",
  },
  {
    id: 14,
    name: "Minuwangete",
    si: "Minuwangete",
    en: "Minuwangete",
  },
  {
    id: 14,
    name: "Mirihanagama",
    si: "Mirihanagama",
    en: "Mirihanagama",
  },
  {
    id: 14,
    name: "Monnekulama",
    si: "Monnekulama",
    en: "Monnekulama",
  },
  {
    id: 14,
    name: "Moragane",
    si: "Moragane",
    en: "Moragane",
  },
  {
    id: 14,
    name: "Moragollagama",
    si: "Moragollagama",
    en: "Moragollagama",
  },
  {
    id: 14,
    name: "Morathiha",
    si: "Morathiha",
    en: "Morathiha",
  },
  {
    id: 14,
    name: "Munamaldeniya",
    si: "Munamaldeniya",
    en: "Munamaldeniya",
  },
  {
    id: 14,
    name: "Muruthenge",
    si: "Muruthenge",
    en: "Muruthenge",
  },
  {
    id: 14,
    name: "Mutugala",
    si: "Mutugala",
    en: "Mutugala",
  },
  {
    id: 14,
    name: "Nabadewa",
    si: "Nabadewa",
    en: "Nabadewa",
  },
  {
    id: 14,
    name: "Nagollagama",
    si: "Nagollagama",
    en: "Nagollagama",
  },
  {
    id: 14,
    name: "Nagollagoda",
    si: "Nagollagoda",
    en: "Nagollagoda",
  },
  {
    id: 14,
    name: "Nakkawatta",
    si: "Nakkawatta",
    en: "Nakkawatta",
  },
  {
    id: 14,
    name: "Narammala",
    si: "Narammala",
    en: "Narammala",
  },
  {
    id: 14,
    name: "Nawasenapura",
    si: "Nawasenapura",
    en: "Nawasenapura",
  },
  {
    id: 14,
    name: "Nawatalwatta",
    si: "Nawatalwatta",
    en: "Nawatalwatta",
  },
  {
    id: 14,
    name: "Nelliya",
    si: "Nelliya",
    en: "Nelliya",
  },
  {
    id: 14,
    name: "Nikaweratiya",
    si: "Nikaweratiya",
    en: "Nikaweratiya",
  },
  {
    id: 14,
    name: "Nugagolla",
    si: "Nugagolla",
    en: "Nugagolla",
  },
  {
    id: 14,
    name: "Nugawela",
    si: "Nugawela",
    en: "Nugawela",
  },
  {
    id: 14,
    name: "Padeniya",
    si: "Padeniya",
    en: "Padeniya",
  },
  {
    id: 14,
    name: "Padiwela",
    si: "Padiwela",
    en: "Padiwela",
  },
  {
    id: 14,
    name: "Pahalagiribawa",
    si: "Pahalagiribawa",
    en: "Pahalagiribawa",
  },
  {
    id: 14,
    name: "Pahamune",
    si: "Pahamune",
    en: "Pahamune",
  },
  {
    id: 14,
    name: "Palagala",
    si: "Palagala",
    en: "Palagala",
  },
  {
    id: 14,
    name: "Palapathwela",
    si: "Palapathwela",
    en: "Palapathwela",
  },
  {
    id: 14,
    name: "Palaviya",
    si: "Palaviya",
    en: "Palaviya",
  },
  {
    id: 14,
    name: "Pallewela",
    si: "Pallewela",
    en: "Pallewela",
  },
  {
    id: 14,
    name: "Palukadawala",
    si: "Palukadawala",
    en: "Palukadawala",
  },
  {
    id: 14,
    name: "Panadaragama",
    si: "Panadaragama",
    en: "Panadaragama",
  },
  {
    id: 14,
    name: "Panagamuwa",
    si: "Panagamuwa",
    en: "Panagamuwa",
  },
  {
    id: 14,
    name: "Panaliya",
    si: "Panaliya",
    en: "Panaliya",
  },
  {
    id: 14,
    name: "Panapitiya",
    si: "Panapitiya",
    en: "Panapitiya",
  },
  {
    id: 14,
    name: "Panliyadda",
    si: "Panliyadda",
    en: "Panliyadda",
  },
  {
    id: 14,
    name: "Pansiyagama",
    si: "Pansiyagama",
    en: "Pansiyagama",
  },
  {
    id: 14,
    name: "Parape",
    si: "Parape",
    en: "Parape",
  },
  {
    id: 14,
    name: "Pathanewatta",
    si: "Pathanewatta",
    en: "Pathanewatta",
  },
  {
    id: 14,
    name: "Pattiya Watta",
    si: "Pattiya Watta",
    en: "Pattiya Watta",
  },
  {
    id: 14,
    name: "Perakanatta",
    si: "Perakanatta",
    en: "Perakanatta",
  },
  {
    id: 14,
    name: "Periyakadneluwa",
    si: "Periyakadneluwa",
    en: "Periyakadneluwa",
  },
  {
    id: 14,
    name: "Pihimbiya Ratmale",
    si: "Pihimbiya Ratmale",
    en: "Pihimbiya Ratmale",
  },
  {
    id: 14,
    name: "Pihimbuwa",
    si: "Pihimbuwa",
    en: "Pihimbuwa",
  },
  {
    id: 14,
    name: "Pilessa",
    si: "Pilessa",
    en: "Pilessa",
  },
  {
    id: 14,
    name: "Polgahawela",
    si: "Polgahawela",
    en: "Polgahawela",
  },
  {
    id: 14,
    name: "Polgolla",
    si: "Polgolla",
    en: "Polgolla",
  },
  {
    id: 14,
    name: "Polpitigama",
    si: "Polpitigama",
    en: "Polpitigama",
  },
  {
    id: 14,
    name: "Pothuhera",
    si: "Pothuhera",
    en: "Pothuhera",
  },
  {
    id: 14,
    name: "Pothupitiya",
    si: "Pothupitiya",
    en: "Pothupitiya",
  },
  {
    id: 14,
    name: "Pujapitiya",
    si: "Pujapitiya",
    en: "Pujapitiya",
  },
  {
    id: 14,
    name: "Rakwana",
    si: "Rakwana",
    en: "Rakwana",
  },
  {
    id: 14,
    name: "Ranorawa",
    si: "Ranorawa",
    en: "Ranorawa",
  },
  {
    id: 14,
    name: "Rathukohodigala",
    si: "Rathukohodigala",
    en: "Rathukohodigala",
  },
  {
    id: 14,
    name: "Ridibendiella",
    si: "Ridibendiella",
    en: "Ridibendiella",
  },
  {
    id: 14,
    name: "Ridigama",
    si: "Ridigama",
    en: "Ridigama",
  },
  {
    id: 14,
    name: "Saliya Asokapura",
    si: "Saliya Asokapura",
    en: "Saliya Asokapura",
  },
  {
    id: 14,
    name: "Sandalankawa",
    si: "Sandalankawa",
    en: "Sandalankawa",
  },
  {
    id: 14,
    name: "Sevanapitiya",
    si: "Sevanapitiya",
    en: "Sevanapitiya",
  },
  {
    id: 14,
    name: "Sirambiadiya",
    si: "Sirambiadiya",
    en: "Sirambiadiya",
  },
  {
    id: 14,
    name: "Sirisetagama",
    si: "Sirisetagama",
    en: "Sirisetagama",
  },
  {
    id: 14,
    name: "Siyambalangamuwa",
    si: "Siyambalangamuwa",
    en: "Siyambalangamuwa",
  },
  {
    id: 14,
    name: "Siyambalawewa",
    si: "Siyambalawewa",
    en: "Siyambalawewa",
  },
  {
    id: 14,
    name: "Solepura",
    si: "Solepura",
    en: "Solepura",
  },
  {
    id: 14,
    name: "Solewewa",
    si: "Solewewa",
    en: "Solewewa",
  },
  {
    id: 14,
    name: "Sunandapura",
    si: "Sunandapura",
    en: "Sunandapura",
  },
  {
    id: 14,
    name: "Talawattegedara",
    si: "Talawattegedara",
    en: "Talawattegedara",
  },
  {
    id: 14,
    name: "Tambutta",
    si: "Tambutta",
    en: "Tambutta",
  },
  {
    id: 14,
    name: "Tennepanguwa",
    si: "Tennepanguwa",
    en: "Tennepanguwa",
  },
  {
    id: 14,
    name: "Thalahitimulla",
    si: "Thalahitimulla",
    en: "Thalahitimulla",
  },
  {
    id: 14,
    name: "Thalakolawewa",
    si: "Thalakolawewa",
    en: "Thalakolawewa",
  },
  {
    id: 14,
    name: "Thalwita",
    si: "Thalwita",
    en: "Thalwita",
  },
  {
    id: 14,
    name: "Tharana Udawela",
    si: "Tharana Udawela",
    en: "Tharana Udawela",
  },
  {
    id: 14,
    name: "Thimbiriyawa",
    si: "Thimbiriyawa",
    en: "Thimbiriyawa",
  },
  {
    id: 14,
    name: "Tisogama",
    si: "Tisogama",
    en: "Tisogama",
  },
  {
    id: 14,
    name: "Torayaya",
    si: "Torayaya",
    en: "Torayaya",
  },
  {
    id: 14,
    name: "Tulhiriya",
    si: "Tulhiriya",
    en: "Tulhiriya",
  },
  {
    id: 14,
    name: "Tuntota",
    si: "Tuntota",
    en: "Tuntota",
  },
  {
    id: 14,
    name: "Tuttiripitigama",
    si: "Tuttiripitigama",
    en: "Tuttiripitigama",
  },
  {
    id: 14,
    name: "Udagaldeniya",
    si: "Udagaldeniya",
    en: "Udagaldeniya",
  },
  {
    id: 14,
    name: "Udahingulwala",
    si: "Udahingulwala",
    en: "Udahingulwala",
  },
  {
    id: 14,
    name: "Udawatta",
    si: "Udawatta",
    en: "Udawatta",
  },
  {
    id: 14,
    name: "Udubaddawa",
    si: "Udubaddawa",
    en: "Udubaddawa",
  },
  {
    id: 14,
    name: "Udumulla",
    si: "Udumulla",
    en: "Udumulla",
  },
  {
    id: 14,
    name: "Uhumiya",
    si: "Uhumiya",
    en: "Uhumiya",
  },
  {
    id: 14,
    name: "Ulpotha Pallekele",
    si: "Ulpotha Pallekele",
    en: "Ulpotha Pallekele",
  },
  {
    id: 14,
    name: "Ulpothagama",
    si: "Ulpothagama",
    en: "Ulpothagama",
  },
  {
    id: 14,
    name: "Usgala Siyabmalangamuwa",
    si: "Usgala Siyabmalangamuwa",
    en: "Usgala Siyabmalangamuwa",
  },
  {
    id: 14,
    name: "Vijithapura",
    si: "Vijithapura",
    en: "Vijithapura",
  },
  {
    id: 14,
    name: "Wadakada",
    si: "Wadakada",
    en: "Wadakada",
  },
  {
    id: 14,
    name: "Wadumunnegedara",
    si: "Wadumunnegedara",
    en: "Wadumunnegedara",
  },
  {
    id: 14,
    name: "Walakumburumulla",
    si: "Walakumburumulla",
    en: "Walakumburumulla",
  },
  {
    id: 14,
    name: "Wannigama",
    si: "Wannigama",
    en: "Wannigama",
  },
  {
    id: 14,
    name: "Wannikudawewa",
    si: "Wannikudawewa",
    en: "Wannikudawewa",
  },
  {
    id: 14,
    name: "Wannilhalagama",
    si: "Wannilhalagama",
    en: "Wannilhalagama",
  },
  {
    id: 14,
    name: "Wannirasnayakapura",
    si: "Wannirasnayakapura",
    en: "Wannirasnayakapura",
  },
  {
    id: 14,
    name: "Warawewa",
    si: "Warawewa",
    en: "Warawewa",
  },
  {
    id: 14,
    name: "Wariyapola",
    si: "Wariyapola",
    en: "Wariyapola",
  },
  {
    id: 14,
    name: "Watareka",
    si: "Watareka",
    en: "Watareka",
  },
  {
    id: 14,
    name: "Wattegama",
    si: "Wattegama",
    en: "Wattegama",
  },
  {
    id: 14,
    name: "Watuwatta",
    si: "Watuwatta",
    en: "Watuwatta",
  },
  {
    id: 14,
    name: "Weerapokuna",
    si: "Weerapokuna",
    en: "Weerapokuna",
  },
  {
    id: 14,
    name: "Welawa Juncton",
    si: "Welawa Juncton",
    en: "Welawa Juncton",
  },
  {
    id: 14,
    name: "Welipennagahamulla",
    si: "Welipennagahamulla",
    en: "Welipennagahamulla",
  },
  {
    id: 14,
    name: "Wellagala",
    si: "Wellagala",
    en: "Wellagala",
  },
  {
    id: 14,
    name: "Wellarawa",
    si: "Wellarawa",
    en: "Wellarawa",
  },
  {
    id: 14,
    name: "Wellawa",
    si: "Wellawa",
    en: "Wellawa",
  },
  {
    id: 14,
    name: "Welpalla",
    si: "Welpalla",
    en: "Welpalla",
  },
  {
    id: 14,
    name: "Wennoruwa",
    si: "Wennoruwa",
    en: "Wennoruwa",
  },
  {
    id: 14,
    name: "Weuda",
    si: "Weuda",
    en: "Weuda",
  },
  {
    id: 14,
    name: "Wewagama",
    si: "Wewagama",
    en: "Wewagama",
  },
  {
    id: 14,
    name: "Wilgamuwa",
    si: "Wilgamuwa",
    en: "Wilgamuwa",
  },
  {
    id: 14,
    name: "Yakwila",
    si: "Yakwila",
    en: "Yakwila",
  },
  {
    id: 14,
    name: "Yatigaloluwa",
    si: "Yatigaloluwa",
    en: "Yatigaloluwa",
  },
  {
    id: 15,
    name: "Mannar",
    si: "Mannar",
    en: "Mannar",
  },
  {
    id: 15,
    name: "Puthukudiyiruppu",
    si: "Puthukudiyiruppu",
    en: "Puthukudiyiruppu",
  },
  {
    id: 16,
    name: "Akuramboda",
    si: "අකුරම්බොඩ",
    en: "Akuramboda",
  },
  {
    id: 16,
    name: "Alawatuwala",
    si: "අලවතුවල",
    en: "Alawatuwala",
  },
  {
    id: 16,
    name: "Alwatta",
    si: "අල්වත්ත",
    en: "Alwatta",
  },
  {
    id: 16,
    name: "Ambana",
    si: "අම්බාන",
    en: "Ambana",
  },
  {
    id: 16,
    name: "Aralaganwila",
    si: "අරලගන්විල",
    en: "Aralaganwila",
  },
  {
    id: 16,
    name: "Ataragallewa",
    si: "අටරගල්ලෑව",
    en: "Ataragallewa",
  },
  {
    id: 16,
    name: "Bambaragaswewa",
    si: "බඹරගස්වැව",
    en: "Bambaragaswewa",
  },
  {
    id: 16,
    name: "Barawardhana Oya",
    si: "බරවර්ධන ඔය",
    en: "Barawardhana Oya",
  },
  {
    id: 16,
    name: "Beligamuwa",
    si: "බෙලිගමුව",
    en: "Beligamuwa",
  },
  {
    id: 16,
    name: "Damana",
    si: "දමන",
    en: "Damana",
  },
  {
    id: 16,
    name: "Dambulla",
    si: "දඹුල්ල",
    en: "Dambulla",
  },
  {
    id: 16,
    name: "Damminna",
    si: "දම්මින්න",
    en: "Damminna",
  },
  {
    id: 16,
    name: "Dankanda",
    si: "දංකන්ද",
    en: "Dankanda",
  },
  {
    id: 16,
    name: "Delwite",
    si: "දෙල්විටේ",
    en: "Delwite",
  },
  {
    id: 16,
    name: "Devagiriya",
    si: "දේවගිරිය",
    en: "Devagiriya",
  },
  {
    id: 16,
    name: "Dewahuwa",
    si: "දේවහුව",
    en: "Dewahuwa",
  },
  {
    id: 16,
    name: "Divuldamana",
    si: "දිවුල්දමන",
    en: "Divuldamana",
  },
  {
    id: 16,
    name: "Dullewa",
    si: "දුල්වල",
    en: "Dullewa",
  },
  {
    id: 16,
    name: "Dunkolawatta",
    si: "දුන්කොලවත්ත",
    en: "Dunkolawatta",
  },
  {
    id: 16,
    name: "Elkaduwa",
    si: "ඇල්කඩුව",
    en: "Elkaduwa",
  },
  {
    id: 16,
    name: "Erawula Junction",
    si: "එරවුල හන්දිය",
    en: "Erawula Junction",
  },
  {
    id: 16,
    name: "Etanawala",
    si: "එතනවල",
    en: "Etanawala",
  },
  {
    id: 16,
    name: "Galewela",
    si: "ගලේවෙල",
    en: "Galewela",
  },
  {
    id: 16,
    name: "Galoya Junction",
    si: "ගල්ඔය හන්දිය",
    en: "Galoya Junction",
  },
  {
    id: 16,
    name: "Gammaduwa",
    si: "ගම්මඩුව",
    en: "Gammaduwa",
  },
  {
    id: 16,
    name: "Gangala Puwakpitiya",
    si: "ගන්ගල පුවක්පිටිය",
    en: "Gangala Puwakpitiya",
  },
  {
    id: 16,
    name: "Hasalaka",
    si: "Hasalaka",
    en: "Hasalaka",
  },
  {
    id: 16,
    name: "Hattota Amuna",
    si: "Hattota Amuna",
    en: "Hattota Amuna",
  },
  {
    id: 16,
    name: "Imbulgolla",
    si: "Imbulgolla",
    en: "Imbulgolla",
  },
  {
    id: 16,
    name: "Inamaluwa",
    si: "Inamaluwa",
    en: "Inamaluwa",
  },
  {
    id: 16,
    name: "Iriyagolla",
    si: "Iriyagolla",
    en: "Iriyagolla",
  },
  {
    id: 16,
    name: "Kaikawala",
    si: "Kaikawala",
    en: "Kaikawala",
  },
  {
    id: 16,
    name: "Kalundawa",
    si: "Kalundawa",
    en: "Kalundawa",
  },
  {
    id: 16,
    name: "Kandalama",
    si: "Kandalama",
    en: "Kandalama",
  },
  {
    id: 16,
    name: "Kavudupelella",
    si: "Kavudupelella",
    en: "Kavudupelella",
  },
  {
    id: 16,
    name: "Kibissa",
    si: "Kibissa",
    en: "Kibissa",
  },
  {
    id: 16,
    name: "Kiwula",
    si: "Kiwula",
    en: "Kiwula",
  },
  {
    id: 16,
    name: "Kongahawela",
    si: "Kongahawela",
    en: "Kongahawela",
  },
  {
    id: 16,
    name: "Laggala Pallegama",
    si: "Laggala Pallegama",
    en: "Laggala Pallegama",
  },
  {
    id: 16,
    name: "Leliambe",
    si: "Leliambe",
    en: "Leliambe",
  },
  {
    id: 16,
    name: "Lenadora",
    si: "Lenadora",
    en: "Lenadora",
  },
  {
    id: 16,
    name: "lhala Halmillewa",
    si: "lhala Halmillewa",
    en: "lhala Halmillewa",
  },
  {
    id: 16,
    name: "lllukkumbura",
    si: "lllukkumbura",
    en: "lllukkumbura",
  },
  {
    id: 16,
    name: "Madipola",
    si: "Madipola",
    en: "Madipola",
  },
  {
    id: 16,
    name: "Maduruoya",
    si: "Maduruoya",
    en: "Maduruoya",
  },
  {
    id: 16,
    name: "Mahawela",
    si: "Mahawela",
    en: "Mahawela",
  },
  {
    id: 16,
    name: "Mananwatta",
    si: "Mananwatta",
    en: "Mananwatta",
  },
  {
    id: 16,
    name: "Maraka",
    si: "Maraka",
    en: "Maraka",
  },
  {
    id: 16,
    name: "Matale",
    si: "Matale",
    en: "Matale",
  },
  {
    id: 16,
    name: "Melipitiya",
    si: "Melipitiya",
    en: "Melipitiya",
  },
  {
    id: 16,
    name: "Metihakka",
    si: "Metihakka",
    en: "Metihakka",
  },
  {
    id: 16,
    name: "Millawana",
    si: "Millawana",
    en: "Millawana",
  },
  {
    id: 16,
    name: "Muwandeniya",
    si: "Muwandeniya",
    en: "Muwandeniya",
  },
  {
    id: 16,
    name: "Nalanda",
    si: "Nalanda",
    en: "Nalanda",
  },
  {
    id: 16,
    name: "Naula",
    si: "Naula",
    en: "Naula",
  },
  {
    id: 16,
    name: "Opalgala",
    si: "Opalgala",
    en: "Opalgala",
  },
  {
    id: 16,
    name: "Pallepola",
    si: "Pallepola",
    en: "Pallepola",
  },
  {
    id: 16,
    name: "Pimburattewa",
    si: "Pimburattewa",
    en: "Pimburattewa",
  },
  {
    id: 16,
    name: "Pulastigama",
    si: "Pulastigama",
    en: "Pulastigama",
  },
  {
    id: 16,
    name: "Ranamuregama",
    si: "Ranamuregama",
    en: "Ranamuregama",
  },
  {
    id: 16,
    name: "Rattota",
    si: "Rattota",
    en: "Rattota",
  },
  {
    id: 16,
    name: "Selagama",
    si: "Selagama",
    en: "Selagama",
  },
  {
    id: 16,
    name: "Sigiriya",
    si: "Sigiriya",
    en: "Sigiriya",
  },
  {
    id: 16,
    name: "Sinhagama",
    si: "Sinhagama",
    en: "Sinhagama",
  },
  {
    id: 16,
    name: "Sungavila",
    si: "Sungavila",
    en: "Sungavila",
  },
  {
    id: 16,
    name: "Talagoda Junction",
    si: "Talagoda Junction",
    en: "Talagoda Junction",
  },
  {
    id: 16,
    name: "Talakiriyagama",
    si: "Talakiriyagama",
    en: "Talakiriyagama",
  },
  {
    id: 16,
    name: "Tamankaduwa",
    si: "Tamankaduwa",
    en: "Tamankaduwa",
  },
  {
    id: 16,
    name: "Udasgiriya",
    si: "Udasgiriya",
    en: "Udasgiriya",
  },
  {
    id: 16,
    name: "Udatenna",
    si: "Udatenna",
    en: "Udatenna",
  },
  {
    id: 16,
    name: "Ukuwela",
    si: "Ukuwela",
    en: "Ukuwela",
  },
  {
    id: 16,
    name: "Wahacotte",
    si: "Wahacotte",
    en: "Wahacotte",
  },
  {
    id: 16,
    name: "Walawela",
    si: "Walawela",
    en: "Walawela",
  },
  {
    id: 16,
    name: "Wehigala",
    si: "Wehigala",
    en: "Wehigala",
  },
  {
    id: 16,
    name: "Welangahawatte",
    si: "Welangahawatte",
    en: "Welangahawatte",
  },
  {
    id: 16,
    name: "Wewalawewa",
    si: "Wewalawewa",
    en: "Wewalawewa",
  },
  {
    id: 16,
    name: "Yatawatta",
    si: "Yatawatta",
    en: "Yatawatta",
  },
  {
    id: 17,
    name: "Akuressa",
    si: "අකුරැස්ස",
    en: "Akuressa",
  },
  {
    id: 17,
    name: "Alapaladeniya",
    si: "අලපලදෙණිය",
    en: "Alapaladeniya",
  },
  {
    id: 17,
    name: "Aparekka",
    si: "අපරැක්ක",
    en: "Aparekka",
  },
  {
    id: 17,
    name: "Athuraliya",
    si: "අතුරලීය",
    en: "Athuraliya",
  },
  {
    id: 17,
    name: "Bengamuwa",
    si: "බෙන්ගමුව",
    en: "Bengamuwa",
  },
  {
    id: 17,
    name: "Bopagoda",
    si: "බෝපගොඩ",
    en: "Bopagoda",
  },
  {
    id: 17,
    name: "Dampahala",
    si: "දම්පහල",
    en: "Dampahala",
  },
  {
    id: 17,
    name: "Deegala Lenama",
    si: "දීගල ලෙනම",
    en: "Deegala Lenama",
  },
  {
    id: 17,
    name: "Deiyandara",
    si: "දෙයියන්දර",
    en: "Deiyandara",
  },
  {
    id: 17,
    name: "Denagama",
    si: "දෙනගම",
    en: "Denagama",
  },
  {
    id: 17,
    name: "Denipitiya",
    si: "දෙණිපිටිය",
    en: "Denipitiya",
  },
  {
    id: 17,
    name: "Deniyaya",
    si: "දෙණියාය",
    en: "Deniyaya",
  },
  {
    id: 17,
    name: "Derangala",
    si: "දෙරණගල",
    en: "Derangala",
  },
  {
    id: 17,
    name: "Devinuwara [Dondra]",
    si: "දෙවිනුවර [දෙවුන්දර]",
    en: "Devinuwara [Dondra]",
  },
  {
    id: 17,
    name: "Dikwella",
    si: "දික්වැල්ල",
    en: "Dikwella",
  },
  {
    id: 17,
    name: "Diyagaha",
    si: "දියගහ",
    en: "Diyagaha",
  },
  {
    id: 17,
    name: "Diyalape",
    si: "දියලපේ",
    en: "Diyalape",
  },
  {
    id: 17,
    name: "Gandara",
    si: "ගන්දර",
    en: "Gandara",
  },
  {
    id: 17,
    name: "Godapitiya",
    si: "ගොඩපිටිය",
    en: "Godapitiya",
  },
  {
    id: 17,
    name: "Gomilamawarala",
    si: "ගොමිලමවරල",
    en: "Gomilamawarala",
  },
  {
    id: 17,
    name: "Hawpe",
    si: "Hawpe",
    en: "Hawpe",
  },
  {
    id: 17,
    name: "Horapawita",
    si: "Horapawita",
    en: "Horapawita",
  },
  {
    id: 17,
    name: "Kalubowitiyana",
    si: "Kalubowitiyana",
    en: "Kalubowitiyana",
  },
  {
    id: 17,
    name: "Kamburugamuwa",
    si: "Kamburugamuwa",
    en: "Kamburugamuwa",
  },
  {
    id: 17,
    name: "Kamburupitiya",
    si: "Kamburupitiya",
    en: "Kamburupitiya",
  },
  {
    id: 17,
    name: "Karagoda Uyangoda",
    si: "Karagoda Uyangoda",
    en: "Karagoda Uyangoda",
  },
  {
    id: 17,
    name: "Karaputugala",
    si: "Karaputugala",
    en: "Karaputugala",
  },
  {
    id: 17,
    name: "Karatota",
    si: "Karatota",
    en: "Karatota",
  },
  {
    id: 17,
    name: "Kekanadurra",
    si: "Kekanadurra",
    en: "Kekanadurra",
  },
  {
    id: 17,
    name: "Kiriweldola",
    si: "Kiriweldola",
    en: "Kiriweldola",
  },
  {
    id: 17,
    name: "Kiriwelkele",
    si: "Kiriwelkele",
    en: "Kiriwelkele",
  },
  {
    id: 17,
    name: "Kolawenigama",
    si: "Kolawenigama",
    en: "Kolawenigama",
  },
  {
    id: 17,
    name: "Kotapola",
    si: "Kotapola",
    en: "Kotapola",
  },
  {
    id: 17,
    name: "Lankagama",
    si: "Lankagama",
    en: "Lankagama",
  },
  {
    id: 17,
    name: "Makandura",
    si: "Makandura",
    en: "Makandura",
  },
  {
    id: 17,
    name: "Maliduwa",
    si: "Maliduwa",
    en: "Maliduwa",
  },
  {
    id: 17,
    name: "Maramba",
    si: "Maramba",
    en: "Maramba",
  },
  {
    id: 17,
    name: "Matara",
    si: "Matara",
    en: "Matara",
  },
  {
    id: 17,
    name: "Mediripitiya",
    si: "Mediripitiya",
    en: "Mediripitiya",
  },
  {
    id: 17,
    name: "Miella",
    si: "Miella",
    en: "Miella",
  },
  {
    id: 17,
    name: "Mirissa",
    si: "Mirissa",
    en: "Mirissa",
  },
  {
    id: 17,
    name: "Morawaka",
    si: "Morawaka",
    en: "Morawaka",
  },
  {
    id: 17,
    name: "Mulatiyana Junction",
    si: "Mulatiyana Junction",
    en: "Mulatiyana Junction",
  },
  {
    id: 17,
    name: "Nadugala",
    si: "Nadugala",
    en: "Nadugala",
  },
  {
    id: 17,
    name: "Naimana",
    si: "Naimana",
    en: "Naimana",
  },
  {
    id: 17,
    name: "Palatuwa",
    si: "Palatuwa",
    en: "Palatuwa",
  },
  {
    id: 17,
    name: "Parapamulla",
    si: "Parapamulla",
    en: "Parapamulla",
  },
  {
    id: 17,
    name: "Pasgoda",
    si: "Pasgoda",
    en: "Pasgoda",
  },
  {
    id: 17,
    name: "Penetiyana",
    si: "Penetiyana",
    en: "Penetiyana",
  },
  {
    id: 17,
    name: "Pitabeddara",
    si: "Pitabeddara",
    en: "Pitabeddara",
  },
  {
    id: 17,
    name: "Puhulwella",
    si: "Puhulwella",
    en: "Puhulwella",
  },
  {
    id: 17,
    name: "Radawela",
    si: "Radawela",
    en: "Radawela",
  },
  {
    id: 17,
    name: "Ransegoda",
    si: "Ransegoda",
    en: "Ransegoda",
  },
  {
    id: 17,
    name: "Rotumba",
    si: "Rotumba",
    en: "Rotumba",
  },
  {
    id: 17,
    name: "Sultanagoda",
    si: "Sultanagoda",
    en: "Sultanagoda",
  },
  {
    id: 17,
    name: "Telijjawila",
    si: "Telijjawila",
    en: "Telijjawila",
  },
  {
    id: 17,
    name: "Thihagoda",
    si: "Thihagoda",
    en: "Thihagoda",
  },
  {
    id: 17,
    name: "Urubokka",
    si: "Urubokka",
    en: "Urubokka",
  },
  {
    id: 17,
    name: "Urugamuwa",
    si: "Urugamuwa",
    en: "Urugamuwa",
  },
  {
    id: 17,
    name: "Urumutta",
    si: "Urumutta",
    en: "Urumutta",
  },
  {
    id: 17,
    name: "Viharahena",
    si: "Viharahena",
    en: "Viharahena",
  },
  {
    id: 17,
    name: "Walakanda",
    si: "Walakanda",
    en: "Walakanda",
  },
  {
    id: 17,
    name: "Walasgala",
    si: "Walasgala",
    en: "Walasgala",
  },
  {
    id: 17,
    name: "Waralla",
    si: "Waralla",
    en: "Waralla",
  },
  {
    id: 17,
    name: "Weligama",
    si: "Weligama",
    en: "Weligama",
  },
  {
    id: 17,
    name: "Wilpita",
    si: "Wilpita",
    en: "Wilpita",
  },
  {
    id: 17,
    name: "Yatiyana",
    si: "Yatiyana",
    en: "Yatiyana",
  },
  {
    id: 18,
    name: "Ayiwela",
    si: "Ayiwela",
    en: "Ayiwela",
  },
  {
    id: 18,
    name: "Badalkumbura",
    si: "බඩල්කුඹුර",
    en: "Badalkumbura",
  },
  {
    id: 18,
    name: "Baduluwela",
    si: "බදුලුවෙල",
    en: "Baduluwela",
  },
  {
    id: 18,
    name: "Bakinigahawela",
    si: "බකිණිගහවෙල",
    en: "Bakinigahawela",
  },
  {
    id: 18,
    name: "Balaharuwa",
    si: "බලහරුව",
    en: "Balaharuwa",
  },
  {
    id: 18,
    name: "Bibile",
    si: "බිබිලේ",
    en: "Bibile",
  },
  {
    id: 18,
    name: "Buddama",
    si: "බුද්ධගම",
    en: "Buddama",
  },
  {
    id: 18,
    name: "Buttala",
    si: "බුත්තල",
    en: "Buttala",
  },
  {
    id: 18,
    name: "Dambagalla",
    si: "දඹගල්ල",
    en: "Dambagalla",
  },
  {
    id: 18,
    name: "Diyakobala",
    si: "දියකොබල",
    en: "Diyakobala",
  },
  {
    id: 18,
    name: "Dombagahawela",
    si: "දොඹගහවෙල",
    en: "Dombagahawela",
  },
  {
    id: 18,
    name: "Ethimalewewa",
    si: "ඇතිමලේවැව",
    en: "Ethimalewewa",
  },
  {
    id: 18,
    name: "Ettiliwewa",
    si: "ඇත්තිලිවැව",
    en: "Ettiliwewa",
  },
  {
    id: 18,
    name: "Galabedda",
    si: "ගලබැද්ද",
    en: "Galabedda",
  },
  {
    id: 18,
    name: "Gamewela",
    si: "ගමේවැල",
    en: "Gamewela",
  },
  {
    id: 18,
    name: "Hambegamuwa",
    si: "හම්බෙගමුව",
    en: "Hambegamuwa",
  },
  {
    id: 18,
    name: "Hingurukaduwa",
    si: "Hingurukaduwa",
    en: "Hingurukaduwa",
  },
  {
    id: 18,
    name: "Hulandawa",
    si: "Hulandawa",
    en: "Hulandawa",
  },
  {
    id: 18,
    name: "Inginiyagala",
    si: "Inginiyagala",
    en: "Inginiyagala",
  },
  {
    id: 18,
    name: "Kandaudapanguwa",
    si: "Kandaudapanguwa",
    en: "Kandaudapanguwa",
  },
  {
    id: 18,
    name: "Kandawinna",
    si: "Kandawinna",
    en: "Kandawinna",
  },
  {
    id: 18,
    name: "Kataragama",
    si: "Kataragama",
    en: "Kataragama",
  },
  {
    id: 18,
    name: "Kotagama",
    si: "Kotagama",
    en: "Kotagama",
  },
  {
    id: 18,
    name: "Kotamuduna",
    si: "Kotamuduna",
    en: "Kotamuduna",
  },
  {
    id: 18,
    name: "Kotawehera Mankada",
    si: "Kotawehera Mankada",
    en: "Kotawehera Mankada",
  },
  {
    id: 18,
    name: "Kudawewa",
    si: "Kudawewa",
    en: "Kudawewa",
  },
  {
    id: 18,
    name: "Kumbukkana",
    si: "Kumbukkana",
    en: "Kumbukkana",
  },
  {
    id: 18,
    name: "Marawa",
    si: "Marawa",
    en: "Marawa",
  },
  {
    id: 18,
    name: "Mariarawa",
    si: "Mariarawa",
    en: "Mariarawa",
  },
  {
    id: 18,
    name: "Medagana",
    si: "Medagana",
    en: "Medagana",
  },
  {
    id: 18,
    name: "Medawelagama",
    si: "Medawelagama",
    en: "Medawelagama",
  },
  {
    id: 18,
    name: "Miyanakandura",
    si: "Miyanakandura",
    en: "Miyanakandura",
  },
  {
    id: 18,
    name: "Monaragala",
    si: "Monaragala",
    en: "Monaragala",
  },
  {
    id: 18,
    name: "Moretuwegama",
    si: "Moretuwegama",
    en: "Moretuwegama",
  },
  {
    id: 18,
    name: "Nakkala",
    si: "Nakkala",
    en: "Nakkala",
  },
  {
    id: 18,
    name: "Namunukula",
    si: "Namunukula",
    en: "Namunukula",
  },
  {
    id: 18,
    name: "Nannapurawa",
    si: "Nannapurawa",
    en: "Nannapurawa",
  },
  {
    id: 18,
    name: "Nelliyadda",
    si: "Nelliyadda",
    en: "Nelliyadda",
  },
  {
    id: 18,
    name: "Nilgala",
    si: "Nilgala",
    en: "Nilgala",
  },
  {
    id: 18,
    name: "Obbegoda",
    si: "Obbegoda",
    en: "Obbegoda",
  },
  {
    id: 18,
    name: "Okkampitiya",
    si: "Okkampitiya",
    en: "Okkampitiya",
  },
  {
    id: 18,
    name: "Pangura",
    si: "Pangura",
    en: "Pangura",
  },
  {
    id: 18,
    name: "Pitakumbura",
    si: "Pitakumbura",
    en: "Pitakumbura",
  },
  {
    id: 18,
    name: "Randeniya",
    si: "Randeniya",
    en: "Randeniya",
  },
  {
    id: 18,
    name: "Ruwalwela",
    si: "Ruwalwela",
    en: "Ruwalwela",
  },
  {
    id: 18,
    name: "Sella Kataragama",
    si: "Sella Kataragama",
    en: "Sella Kataragama",
  },
  {
    id: 18,
    name: "Siyambalagune",
    si: "Siyambalagune",
    en: "Siyambalagune",
  },
  {
    id: 18,
    name: "Siyambalanduwa",
    si: "Siyambalanduwa",
    en: "Siyambalanduwa",
  },
  {
    id: 18,
    name: "Suriara",
    si: "Suriara",
    en: "Suriara",
  },
  {
    id: 18,
    name: "Tanamalwila",
    si: "Tanamalwila",
    en: "Tanamalwila",
  },
  {
    id: 18,
    name: "Uva Gangodagama",
    si: "Uva Gangodagama",
    en: "Uva Gangodagama",
  },
  {
    id: 18,
    name: "Uva Kudaoya",
    si: "Uva Kudaoya",
    en: "Uva Kudaoya",
  },
  {
    id: 18,
    name: "Uva Pelwatta",
    si: "Uva Pelwatta",
    en: "Uva Pelwatta",
  },
  {
    id: 18,
    name: "Warunagama",
    si: "Warunagama",
    en: "Warunagama",
  },
  {
    id: 18,
    name: "Wedikumbura",
    si: "Wedikumbura",
    en: "Wedikumbura",
  },
  {
    id: 18,
    name: "Weherayaya Handapanagala",
    si: "Weherayaya Handapanagala",
    en: "Weherayaya Handapanagala",
  },
  {
    id: 18,
    name: "Wellawaya",
    si: "Wellawaya",
    en: "Wellawaya",
  },
  {
    id: 18,
    name: "Wilaoya",
    si: "Wilaoya",
    en: "Wilaoya",
  },
  {
    id: 18,
    name: "Yudaganawa",
    si: "Yudaganawa",
    en: "Yudaganawa",
  },
  {
    id: 19,
    name: "Mullativu",
    si: "Mullativu",
    en: "Mullativu",
  },
  {
    id: 20,
    name: "Agarapathana",
    si: "ආගරපතන",
    en: "Agarapathana",
  },
  {
    id: 20,
    name: "Ambatalawa",
    si: "අඹතලාව",
    en: "Ambatalawa",
  },
  {
    id: 20,
    name: "Ambewela",
    si: "අඹේවෙල",
    en: "Ambewela",
  },
  {
    id: 20,
    name: "Bogawantalawa",
    si: "බොගවන්තලාව",
    en: "Bogawantalawa",
  },
  {
    id: 20,
    name: "Bopattalawa",
    si: "බෝපත්තලාව",
    en: "Bopattalawa",
  },
  {
    id: 20,
    name: "Dagampitiya",
    si: "දාගම්පිටිය",
    en: "Dagampitiya",
  },
  {
    id: 20,
    name: "Dayagama Bazaar",
    si: "දයගම බසාර්",
    en: "Dayagama Bazaar",
  },
  {
    id: 20,
    name: "Dikoya",
    si: "දික්ඔය",
    en: "Dikoya",
  },
  {
    id: 20,
    name: "Doragala",
    si: "දොරගල",
    en: "Doragala",
  },
  {
    id: 20,
    name: "Dunukedeniya",
    si: "දුනුකෙදෙණිය",
    en: "Dunukedeniya",
  },
  {
    id: 20,
    name: "Egodawela",
    si: "එගොඩවෙල",
    en: "Egodawela",
  },
  {
    id: 20,
    name: "Ekiriya",
    si: "ඇකිරිය",
    en: "Ekiriya",
  },
  {
    id: 20,
    name: "Elamulla",
    si: "ඇලමුල්ල",
    en: "Elamulla",
  },
  {
    id: 20,
    name: "Ginigathena",
    si: "ගිනිගතැන",
    en: "Ginigathena",
  },
  {
    id: 20,
    name: "Gonakele",
    si: "ගොනාකැලේ",
    en: "Gonakele",
  },
  {
    id: 20,
    name: "Haggala",
    si: "හග්ගල",
    en: "Haggala",
  },
  {
    id: 20,
    name: "Halgranoya",
    si: "හාල්ගරනඔය",
    en: "Halgranoya",
  },
  {
    id: 20,
    name: "Hangarapitiya",
    si: "Hangarapitiya",
    en: "Hangarapitiya",
  },
  {
    id: 20,
    name: "Hapugastalawa",
    si: "Hapugastalawa",
    en: "Hapugastalawa",
  },
  {
    id: 20,
    name: "Harasbedda",
    si: "Harasbedda",
    en: "Harasbedda",
  },
  {
    id: 20,
    name: "Hatton",
    si: "Hatton",
    en: "Hatton",
  },
  {
    id: 20,
    name: "Hewaheta",
    si: "Hewaheta",
    en: "Hewaheta",
  },
  {
    id: 20,
    name: "Hitigegama",
    si: "Hitigegama",
    en: "Hitigegama",
  },
  {
    id: 20,
    name: "Jangulla",
    si: "Jangulla",
    en: "Jangulla",
  },
  {
    id: 20,
    name: "Kalaganwatta",
    si: "Kalaganwatta",
    en: "Kalaganwatta",
  },
  {
    id: 20,
    name: "Kandapola",
    si: "Kandapola",
    en: "Kandapola",
  },
  {
    id: 20,
    name: "Karandagolla",
    si: "Karandagolla",
    en: "Karandagolla",
  },
  {
    id: 20,
    name: "Keerthi Bandarapura",
    si: "Keerthi Bandarapura",
    en: "Keerthi Bandarapura",
  },
  {
    id: 20,
    name: "Kiribathkumbura",
    si: "Kiribathkumbura",
    en: "Kiribathkumbura",
  },
  {
    id: 20,
    name: "Kotiyagala",
    si: "Kotiyagala",
    en: "Kotiyagala",
  },
  {
    id: 20,
    name: "Kotmale",
    si: "Kotmale",
    en: "Kotmale",
  },
  {
    id: 20,
    name: "Kottellena",
    si: "Kottellena",
    en: "Kottellena",
  },
  {
    id: 20,
    name: "Kumbalgamuwa",
    si: "Kumbalgamuwa",
    en: "Kumbalgamuwa",
  },
  {
    id: 20,
    name: "Kumbukwela",
    si: "Kumbukwela",
    en: "Kumbukwela",
  },
  {
    id: 20,
    name: "Kurupanawela",
    si: "Kurupanawela",
    en: "Kurupanawela",
  },
  {
    id: 20,
    name: "Labukele",
    si: "Labukele",
    en: "Labukele",
  },
  {
    id: 20,
    name: "Laxapana",
    si: "Laxapana",
    en: "Laxapana",
  },
  {
    id: 20,
    name: "Lindula",
    si: "Lindula",
    en: "Lindula",
  },
  {
    id: 20,
    name: "Madulla",
    si: "Madulla",
    en: "Madulla",
  },
  {
    id: 20,
    name: "Mandaram Nuwara",
    si: "Mandaram Nuwara",
    en: "Mandaram Nuwara",
  },
  {
    id: 20,
    name: "Maskeliya",
    si: "Maskeliya",
    en: "Maskeliya",
  },
  {
    id: 20,
    name: "Maswela",
    si: "Maswela",
    en: "Maswela",
  },
  {
    id: 20,
    name: "Maturata",
    si: "Maturata",
    en: "Maturata",
  },
  {
    id: 20,
    name: "Mipanawa",
    si: "Mipanawa",
    en: "Mipanawa",
  },
  {
    id: 20,
    name: "Mipilimana",
    si: "Mipilimana",
    en: "Mipilimana",
  },
  {
    id: 20,
    name: "Morahenagama",
    si: "Morahenagama",
    en: "Morahenagama",
  },
  {
    id: 20,
    name: "Munwatta",
    si: "Munwatta",
    en: "Munwatta",
  },
  {
    id: 20,
    name: "Nayapana Janapadaya",
    si: "Nayapana Janapadaya",
    en: "Nayapana Janapadaya",
  },
  {
    id: 20,
    name: "Nildandahinna",
    si: "Nildandahinna",
    en: "Nildandahinna",
  },
  {
    id: 20,
    name: "Nissanka Uyana",
    si: "Nissanka Uyana",
    en: "Nissanka Uyana",
  },
  {
    id: 20,
    name: "Norwood",
    si: "Norwood",
    en: "Norwood",
  },
  {
    id: 20,
    name: "Nuwara Eliya",
    si: "Nuwara Eliya",
    en: "Nuwara Eliya",
  },
  {
    id: 20,
    name: "Padiyapelella",
    si: "Padiyapelella",
    en: "Padiyapelella",
  },
  {
    id: 20,
    name: "Pallebowala",
    si: "Pallebowala",
    en: "Pallebowala",
  },
  {
    id: 20,
    name: "Panvila",
    si: "Panvila",
    en: "Panvila",
  },
  {
    id: 20,
    name: "Pitawala",
    si: "Pitawala",
    en: "Pitawala",
  },
  {
    id: 20,
    name: "Pundaluoya",
    si: "Pundaluoya",
    en: "Pundaluoya",
  },
  {
    id: 20,
    name: "Ramboda",
    si: "Ramboda",
    en: "Ramboda",
  },
  {
    id: 20,
    name: "Rikillagaskada",
    si: "Rikillagaskada",
    en: "Rikillagaskada",
  },
  {
    id: 20,
    name: "Rozella",
    si: "Rozella",
    en: "Rozella",
  },
  {
    id: 20,
    name: "Rupaha",
    si: "Rupaha",
    en: "Rupaha",
  },
  {
    id: 20,
    name: "Ruwaneliya",
    si: "Ruwaneliya",
    en: "Ruwaneliya",
  },
  {
    id: 20,
    name: "Santhipura",
    si: "Santhipura",
    en: "Santhipura",
  },
  {
    id: 20,
    name: "Talawakele",
    si: "Talawakele",
    en: "Talawakele",
  },
  {
    id: 20,
    name: "Tawalantenna",
    si: "Tawalantenna",
    en: "Tawalantenna",
  },
  {
    id: 20,
    name: "Teripeha",
    si: "Teripeha",
    en: "Teripeha",
  },
  {
    id: 20,
    name: "Udamadura",
    si: "Udamadura",
    en: "Udamadura",
  },
  {
    id: 20,
    name: "Udapussallawa",
    si: "Udapussallawa",
    en: "Udapussallawa",
  },
  {
    id: 20,
    name: "Uva Deegalla",
    si: "Uva Deegalla",
    en: "Uva Deegalla",
  },
  {
    id: 20,
    name: "Uva Uduwara",
    si: "Uva Uduwara",
    en: "Uva Uduwara",
  },
  {
    id: 20,
    name: "Uvaparanagama",
    si: "Uvaparanagama",
    en: "Uvaparanagama",
  },
  {
    id: 20,
    name: "Walapane",
    si: "Walapane",
    en: "Walapane",
  },
  {
    id: 20,
    name: "Watawala",
    si: "Watawala",
    en: "Watawala",
  },
  {
    id: 20,
    name: "Widulipura",
    si: "Widulipura",
    en: "Widulipura",
  },
  {
    id: 20,
    name: "Wijebahukanda",
    si: "Wijebahukanda",
    en: "Wijebahukanda",
  },
  {
    id: 21,
    name: "Attanakadawala",
    si: "අත්තනගඩවල",
    en: "Attanakadawala",
  },
  {
    id: 21,
    name: "Bakamuna",
    si: "බකමූණ",
    en: "Bakamuna",
  },
  {
    id: 21,
    name: "Diyabeduma",
    si: "දියබෙදුම",
    en: "Diyabeduma",
  },
  {
    id: 21,
    name: "Elahera",
    si: "ඇලහැර",
    en: "Elahera",
  },
  {
    id: 21,
    name: "Giritale",
    si: "ගිරිතලේ",
    en: "Giritale",
  },
  {
    id: 21,
    name: "Hingurakdamana",
    si: "Hingurakdamana",
    en: "Hingurakdamana",
  },
  {
    id: 21,
    name: "Hingurakgoda",
    si: "Hingurakgoda",
    en: "Hingurakgoda",
  },
  {
    id: 21,
    name: "Jayanthipura",
    si: "Jayanthipura",
    en: "Jayanthipura",
  },
  {
    id: 21,
    name: "Kalingaela",
    si: "Kalingaela",
    en: "Kalingaela",
  },
  {
    id: 21,
    name: "Lakshauyana",
    si: "Lakshauyana",
    en: "Lakshauyana",
  },
  {
    id: 21,
    name: "Mankemi",
    si: "Mankemi",
    en: "Mankemi",
  },
  {
    id: 21,
    name: "Minneriya",
    si: "Minneriya",
    en: "Minneriya",
  },
  {
    id: 21,
    name: "Onegama",
    si: "Onegama",
    en: "Onegama",
  },
  {
    id: 21,
    name: "Orubendi Siyambalawa",
    si: "Orubendi Siyambalawa",
    en: "Orubendi Siyambalawa",
  },
  {
    id: 21,
    name: "Palugasdamana",
    si: "Palugasdamana",
    en: "Palugasdamana",
  },
  {
    id: 21,
    name: "Panichankemi",
    si: "Panichankemi",
    en: "Panichankemi",
  },
  {
    id: 21,
    name: "Polonnaruwa",
    si: "Polonnaruwa",
    en: "Polonnaruwa",
  },
  {
    id: 21,
    name: "Talpotha",
    si: "Talpotha",
    en: "Talpotha",
  },
  {
    id: 21,
    name: "Tambala",
    si: "Tambala",
    en: "Tambala",
  },
  {
    id: 21,
    name: "Unagalavehera",
    si: "Unagalavehera",
    en: "Unagalavehera",
  },
  {
    id: 21,
    name: "Wijayabapura",
    si: "Wijayabapura",
    en: "Wijayabapura",
  },
  {
    id: 22,
    name: "Adippala",
    si: "Adippala",
    en: "Adippala",
  },
  {
    id: 22,
    name: "Alutgama",
    si: "අළුත්ගම",
    en: "Alutgama",
  },
  {
    id: 22,
    name: "Alutwewa",
    si: "අළුත්වැව",
    en: "Alutwewa",
  },
  {
    id: 22,
    name: "Ambakandawila",
    si: "අඹකඳවිල",
    en: "Ambakandawila",
  },
  {
    id: 22,
    name: "Anamaduwa",
    si: "ආනමඩුව",
    en: "Anamaduwa",
  },
  {
    id: 22,
    name: "Andigama",
    si: "අඬිගම",
    en: "Andigama",
  },
  {
    id: 22,
    name: "Angunawila",
    si: "අඟුණවිල",
    en: "Angunawila",
  },
  {
    id: 22,
    name: "Attawilluwa",
    si: "අත්තවිල්ලුව",
    en: "Attawilluwa",
  },
  {
    id: 22,
    name: "Bangadeniya",
    si: "බංගදෙණිය",
    en: "Bangadeniya",
  },
  {
    id: 22,
    name: "Baranankattuwa",
    si: "බරණන්කට්ටුව",
    en: "Baranankattuwa",
  },
  {
    id: 22,
    name: "Battuluoya",
    si: "බත්තුලුඔය",
    en: "Battuluoya",
  },
  {
    id: 22,
    name: "Bujjampola",
    si: "බුජ්ජම්පොල",
    en: "Bujjampola",
  },
  {
    id: 22,
    name: "Chilaw",
    si: "හලාවත",
    en: "Chilaw",
  },
  {
    id: 22,
    name: "Dalukana",
    si: "දලුකන",
    en: "Dalukana",
  },
  {
    id: 22,
    name: "Dankotuwa",
    si: "දංකොටුව",
    en: "Dankotuwa",
  },
  {
    id: 22,
    name: "Dewagala",
    si: "දේවගල",
    en: "Dewagala",
  },
  {
    id: 22,
    name: "Dummalasuriya",
    si: "දුම්මලසූරිය",
    en: "Dummalasuriya",
  },
  {
    id: 22,
    name: "Dunkannawa",
    si: "දුන්කන්නාව",
    en: "Dunkannawa",
  },
  {
    id: 22,
    name: "Eluwankulama",
    si: "එළුවන්කුලම",
    en: "Eluwankulama",
  },
  {
    id: 22,
    name: "Ettale",
    si: "ඇත්තලේ",
    en: "Ettale",
  },
  {
    id: 22,
    name: "Galamuna",
    si: "ගලමුන",
    en: "Galamuna",
  },
  {
    id: 22,
    name: "Galmuruwa",
    si: "ගල්මුරුව",
    en: "Galmuruwa",
  },
  {
    id: 22,
    name: "Hansayapalama",
    si: "Hansayapalama",
    en: "Hansayapalama",
  },
  {
    id: 22,
    name: "Ihala Kottaramulla",
    si: "Ihala Kottaramulla",
    en: "Ihala Kottaramulla",
  },
  {
    id: 22,
    name: "Ilippadeniya",
    si: "Ilippadeniya",
    en: "Ilippadeniya",
  },
  {
    id: 22,
    name: "Inginimitiya",
    si: "Inginimitiya",
    en: "Inginimitiya",
  },
  {
    id: 22,
    name: "Ismailpuram",
    si: "Ismailpuram",
    en: "Ismailpuram",
  },
  {
    id: 22,
    name: "Jayasiripura",
    si: "Jayasiripura",
    en: "Jayasiripura",
  },
  {
    id: 22,
    name: "Kakkapalliya",
    si: "Kakkapalliya",
    en: "Kakkapalliya",
  },
  {
    id: 22,
    name: "Kalkudah",
    si: "Kalkudah",
    en: "Kalkudah",
  },
  {
    id: 22,
    name: "Kalladiya",
    si: "Kalladiya",
    en: "Kalladiya",
  },
  {
    id: 22,
    name: "Kandakuliya",
    si: "Kandakuliya",
    en: "Kandakuliya",
  },
  {
    id: 22,
    name: "Karathivu",
    si: "Karathivu",
    en: "Karathivu",
  },
  {
    id: 22,
    name: "Karawitagara",
    si: "Karawitagara",
    en: "Karawitagara",
  },
  {
    id: 22,
    name: "Karuwalagaswewa",
    si: "Karuwalagaswewa",
    en: "Karuwalagaswewa",
  },
  {
    id: 22,
    name: "Katuneriya",
    si: "Katuneriya",
    en: "Katuneriya",
  },
  {
    id: 22,
    name: "Koswatta",
    si: "Koswatta",
    en: "Koswatta",
  },
  {
    id: 22,
    name: "Kottantivu",
    si: "Kottantivu",
    en: "Kottantivu",
  },
  {
    id: 22,
    name: "Kottapitiya",
    si: "Kottapitiya",
    en: "Kottapitiya",
  },
  {
    id: 22,
    name: "Kottukachchiya",
    si: "Kottukachchiya",
    en: "Kottukachchiya",
  },
  {
    id: 22,
    name: "Kumarakattuwa",
    si: "Kumarakattuwa",
    en: "Kumarakattuwa",
  },
  {
    id: 22,
    name: "Kurinjanpitiya",
    si: "Kurinjanpitiya",
    en: "Kurinjanpitiya",
  },
  {
    id: 22,
    name: "Kuruketiyawa",
    si: "Kuruketiyawa",
    en: "Kuruketiyawa",
  },
  {
    id: 22,
    name: "Lunuwila",
    si: "Lunuwila",
    en: "Lunuwila",
  },
  {
    id: 22,
    name: "Madampe",
    si: "Madampe",
    en: "Madampe",
  },
  {
    id: 22,
    name: "Madurankuliya",
    si: "Madurankuliya",
    en: "Madurankuliya",
  },
  {
    id: 22,
    name: "Mahakumbukkadawala",
    si: "Mahakumbukkadawala",
    en: "Mahakumbukkadawala",
  },
  {
    id: 22,
    name: "Mahauswewa",
    si: "Mahauswewa",
    en: "Mahauswewa",
  },
  {
    id: 22,
    name: "Mampitiya",
    si: "Mampitiya",
    en: "Mampitiya",
  },
  {
    id: 22,
    name: "Mampuri",
    si: "Mampuri",
    en: "Mampuri",
  },
  {
    id: 22,
    name: "Mangalaeliya",
    si: "Mangalaeliya",
    en: "Mangalaeliya",
  },
  {
    id: 22,
    name: "Marawila",
    si: "Marawila",
    en: "Marawila",
  },
  {
    id: 22,
    name: "Mudalakkuliya",
    si: "Mudalakkuliya",
    en: "Mudalakkuliya",
  },
  {
    id: 22,
    name: "Mugunuwatawana",
    si: "Mugunuwatawana",
    en: "Mugunuwatawana",
  },
  {
    id: 22,
    name: "Mukkutoduwawa",
    si: "Mukkutoduwawa",
    en: "Mukkutoduwawa",
  },
  {
    id: 22,
    name: "Mundel",
    si: "Mundel",
    en: "Mundel",
  },
  {
    id: 22,
    name: "Muttibendiwila",
    si: "Muttibendiwila",
    en: "Muttibendiwila",
  },
  {
    id: 22,
    name: "Nainamadama",
    si: "Nainamadama",
    en: "Nainamadama",
  },
  {
    id: 22,
    name: "Nalladarankattuwa",
    si: "Nalladarankattuwa",
    en: "Nalladarankattuwa",
  },
  {
    id: 22,
    name: "Nattandiya",
    si: "Nattandiya",
    en: "Nattandiya",
  },
  {
    id: 22,
    name: "Nawagattegama",
    si: "Nawagattegama",
    en: "Nawagattegama",
  },
  {
    id: 22,
    name: "Nelumwewa",
    si: "Nelumwewa",
    en: "Nelumwewa",
  },
  {
    id: 22,
    name: "Norachcholai",
    si: "Norachcholai",
    en: "Norachcholai",
  },
  {
    id: 22,
    name: "Pallama",
    si: "Pallama",
    en: "Pallama",
  },
  {
    id: 22,
    name: "Palliwasalturai",
    si: "Palliwasalturai",
    en: "Palliwasalturai",
  },
  {
    id: 22,
    name: "Panirendawa",
    si: "Panirendawa",
    en: "Panirendawa",
  },
  {
    id: 22,
    name: "Parakramasamudraya",
    si: "Parakramasamudraya",
    en: "Parakramasamudraya",
  },
  {
    id: 22,
    name: "Pothuwatawana",
    si: "Pothuwatawana",
    en: "Pothuwatawana",
  },
  {
    id: 22,
    name: "Puttalam",
    si: "Puttalam",
    en: "Puttalam",
  },
  {
    id: 22,
    name: "Puttalam Cement Factory",
    si: "Puttalam Cement Factory",
    en: "Puttalam Cement Factory",
  },
  {
    id: 22,
    name: "Rajakadaluwa",
    si: "Rajakadaluwa",
    en: "Rajakadaluwa",
  },
  {
    id: 22,
    name: "Saliyawewa Junction",
    si: "Saliyawewa Junction",
    en: "Saliyawewa Junction",
  },
  {
    id: 22,
    name: "Serukele",
    si: "Serukele",
    en: "Serukele",
  },
  {
    id: 22,
    name: "Siyambalagashene",
    si: "Siyambalagashene",
    en: "Siyambalagashene",
  },
  {
    id: 22,
    name: "Tabbowa",
    si: "Tabbowa",
    en: "Tabbowa",
  },
  {
    id: 22,
    name: "Talawila Church",
    si: "Talawila Church",
    en: "Talawila Church",
  },
  {
    id: 22,
    name: "Toduwawa",
    si: "Toduwawa",
    en: "Toduwawa",
  },
  {
    id: 22,
    name: "Udappuwa",
    si: "Udappuwa",
    en: "Udappuwa",
  },
  {
    id: 22,
    name: "Uridyawa",
    si: "Uridyawa",
    en: "Uridyawa",
  },
  {
    id: 22,
    name: "Vanathawilluwa",
    si: "Vanathawilluwa",
    en: "Vanathawilluwa",
  },
  {
    id: 22,
    name: "Waikkal",
    si: "Waikkal",
    en: "Waikkal",
  },
  {
    id: 22,
    name: "Watugahamulla",
    si: "Watugahamulla",
    en: "Watugahamulla",
  },
  {
    id: 22,
    name: "Wennappuwa",
    si: "Wennappuwa",
    en: "Wennappuwa",
  },
  {
    id: 22,
    name: "Wijeyakatupotha",
    si: "Wijeyakatupotha",
    en: "Wijeyakatupotha",
  },
  {
    id: 22,
    name: "Wilpotha",
    si: "Wilpotha",
    en: "Wilpotha",
  },
  {
    id: 22,
    name: "Yodaela",
    si: "Yodaela",
    en: "Yodaela",
  },
  {
    id: 22,
    name: "Yogiyana",
    si: "Yogiyana",
    en: "Yogiyana",
  },
  {
    id: 23,
    name: "Akarella",
    si: "අකරැල්ල",
    en: "Akarella",
  },
  {
    id: 23,
    name: "Amunumulla",
    si: "අමුනුමුල්ල",
    en: "Amunumulla",
  },
  {
    id: 23,
    name: "Atakalanpanna",
    si: "අටකලන්පන්න",
    en: "Atakalanpanna",
  },
  {
    id: 23,
    name: "Ayagama",
    si: "අයගම",
    en: "Ayagama",
  },
  {
    id: 23,
    name: "Balangoda",
    si: "බලන්ගොඩ",
    en: "Balangoda",
  },
  {
    id: 23,
    name: "Batatota",
    si: "බටතොට",
    en: "Batatota",
  },
  {
    id: 23,
    name: "Beralapanathara",
    si: "බෙරලපනතර",
    en: "Beralapanathara",
  },
  {
    id: 23,
    name: "Bogahakumbura",
    si: "බෝගහකුඹුර",
    en: "Bogahakumbura",
  },
  {
    id: 23,
    name: "Bolthumbe",
    si: "බොල්තුඹෙ",
    en: "Bolthumbe",
  },
  {
    id: 23,
    name: "Bomluwageaina",
    si: "Bomluwageaina",
    en: "Bomluwageaina",
  },
  {
    id: 23,
    name: "Bowalagama",
    si: "බෝවලගම",
    en: "Bowalagama",
  },
  {
    id: 23,
    name: "Bulutota",
    si: "බුලුතොට",
    en: "Bulutota",
  },
  {
    id: 23,
    name: "Dambuluwana",
    si: "දඹුලුවාන",
    en: "Dambuluwana",
  },
  {
    id: 23,
    name: "Daugala",
    si: "දවුගල",
    en: "Daugala",
  },
  {
    id: 23,
    name: "Dela",
    si: "දෙල",
    en: "Dela",
  },
  {
    id: 23,
    name: "Delwala",
    si: "දෙල්වල",
    en: "Delwala",
  },
  {
    id: 23,
    name: "Dodampe",
    si: "දොඩම්පෙ",
    en: "Dodampe",
  },
  {
    id: 23,
    name: "Doloswalakanda",
    si: "දොලොස්වලකන්ද",
    en: "Doloswalakanda",
  },
  {
    id: 23,
    name: "Dumbara Manana",
    si: "දුම්බර මනන",
    en: "Dumbara Manana",
  },
  {
    id: 23,
    name: "Eheliyagoda",
    si: "ඇහැළියගොඩ",
    en: "Eheliyagoda",
  },
  {
    id: 23,
    name: "Ekamutugama",
    si: "එකමුතුගම",
    en: "Ekamutugama",
  },
  {
    id: 23,
    name: "Elapatha",
    si: "ඇලපාත",
    en: "Elapatha",
  },
  {
    id: 23,
    name: "Ellagawa",
    si: "ඇල්ලගාව",
    en: "Ellagawa",
  },
  {
    id: 23,
    name: "Ellaulla",
    si: "Ellaulla",
    en: "Ellaulla",
  },
  {
    id: 23,
    name: "Ellawala",
    si: "ඇල්ලවල",
    en: "Ellawala",
  },
  {
    id: 23,
    name: "Embilipitiya",
    si: "ඇඹිලිපිටිය",
    en: "Embilipitiya",
  },
  {
    id: 23,
    name: "Eratna",
    si: "එරත්න",
    en: "Eratna",
  },
  {
    id: 23,
    name: "Erepola",
    si: "එරෙපොල",
    en: "Erepola",
  },
  {
    id: 23,
    name: "Gabbela",
    si: "ගබ්බෙල",
    en: "Gabbela",
  },
  {
    id: 23,
    name: "Gangeyaya",
    si: "ගන්ගෙයාය",
    en: "Gangeyaya",
  },
  {
    id: 23,
    name: "Gawaragiriya",
    si: "ගවරගිරිය",
    en: "Gawaragiriya",
  },
  {
    id: 23,
    name: "Gillimale",
    si: "ගිලීමලේ",
    en: "Gillimale",
  },
  {
    id: 23,
    name: "Godakawela",
    si: "ගොඩකවැල",
    en: "Godakawela",
  },
  {
    id: 23,
    name: "Gurubewilagama",
    si: "ගුරුබෙවිලගම",
    en: "Gurubewilagama",
  },
  {
    id: 23,
    name: "Halwinna",
    si: "හල්වින්න",
    en: "Halwinna",
  },
  {
    id: 23,
    name: "Handagiriya",
    si: "හඳගිරිය",
    en: "Handagiriya",
  },
  {
    id: 23,
    name: "Hatangala",
    si: "Hatangala",
    en: "Hatangala",
  },
  {
    id: 23,
    name: "Hatarabage",
    si: "Hatarabage",
    en: "Hatarabage",
  },
  {
    id: 23,
    name: "Hewanakumbura",
    si: "Hewanakumbura",
    en: "Hewanakumbura",
  },
  {
    id: 23,
    name: "Hidellana",
    si: "Hidellana",
    en: "Hidellana",
  },
  {
    id: 23,
    name: "Hiramadagama",
    si: "Hiramadagama",
    en: "Hiramadagama",
  },
  {
    id: 23,
    name: "Horewelagoda",
    si: "Horewelagoda",
    en: "Horewelagoda",
  },
  {
    id: 23,
    name: "Ittakanda",
    si: "Ittakanda",
    en: "Ittakanda",
  },
  {
    id: 23,
    name: "Kahangama",
    si: "Kahangama",
    en: "Kahangama",
  },
  {
    id: 23,
    name: "Kahawatta",
    si: "Kahawatta",
    en: "Kahawatta",
  },
  {
    id: 23,
    name: "Kalawana",
    si: "Kalawana",
    en: "Kalawana",
  },
  {
    id: 23,
    name: "Kaltota",
    si: "Kaltota",
    en: "Kaltota",
  },
  {
    id: 23,
    name: "Kalubululanda",
    si: "Kalubululanda",
    en: "Kalubululanda",
  },
  {
    id: 23,
    name: "Kananke Bazaar",
    si: "Kananke Bazaar",
    en: "Kananke Bazaar",
  },
  {
    id: 23,
    name: "Kandepuhulpola",
    si: "Kandepuhulpola",
    en: "Kandepuhulpola",
  },
  {
    id: 23,
    name: "Karandana",
    si: "Karandana",
    en: "Karandana",
  },
  {
    id: 23,
    name: "Karangoda",
    si: "Karangoda",
    en: "Karangoda",
  },
  {
    id: 23,
    name: "Kella Junction",
    si: "Kella Junction",
    en: "Kella Junction",
  },
  {
    id: 23,
    name: "Keppetipola",
    si: "Keppetipola",
    en: "Keppetipola",
  },
  {
    id: 23,
    name: "Kiriella",
    si: "Kiriella",
    en: "Kiriella",
  },
  {
    id: 23,
    name: "Kiriibbanwewa",
    si: "Kiriibbanwewa",
    en: "Kiriibbanwewa",
  },
  {
    id: 23,
    name: "Kolambageara",
    si: "Kolambageara",
    en: "Kolambageara",
  },
  {
    id: 23,
    name: "Kolombugama",
    si: "Kolombugama",
    en: "Kolombugama",
  },
  {
    id: 23,
    name: "Kolonna",
    si: "Kolonna",
    en: "Kolonna",
  },
  {
    id: 23,
    name: "Kudawa",
    si: "Kudawa",
    en: "Kudawa",
  },
  {
    id: 23,
    name: "Kuruwita",
    si: "Kuruwita",
    en: "Kuruwita",
  },
  {
    id: 23,
    name: "Lellopitiya",
    si: "Lellopitiya",
    en: "Lellopitiya",
  },
  {
    id: 23,
    name: "lmaduwa",
    si: "lmaduwa",
    en: "lmaduwa",
  },
  {
    id: 23,
    name: "lmbulpe",
    si: "lmbulpe",
    en: "lmbulpe",
  },
  {
    id: 23,
    name: "Mahagama Colony",
    si: "Mahagama Colony",
    en: "Mahagama Colony",
  },
  {
    id: 23,
    name: "Mahawalatenna",
    si: "Mahawalatenna",
    en: "Mahawalatenna",
  },
  {
    id: 23,
    name: "Makandura Sabara",
    si: "Makandura Sabara",
    en: "Makandura Sabara",
  },
  {
    id: 23,
    name: "Malwala Junction",
    si: "Malwala Junction",
    en: "Malwala Junction",
  },
  {
    id: 23,
    name: "Malwatta",
    si: "Malwatta",
    en: "Malwatta",
  },
  {
    id: 23,
    name: "Matuwagalagama",
    si: "Matuwagalagama",
    en: "Matuwagalagama",
  },
  {
    id: 23,
    name: "Medagalatur",
    si: "Medagalatur",
    en: "Medagalatur",
  },
  {
    id: 23,
    name: "Meddekanda",
    si: "Meddekanda",
    en: "Meddekanda",
  },
  {
    id: 23,
    name: "Minipura Dumbara",
    si: "Minipura Dumbara",
    en: "Minipura Dumbara",
  },
  {
    id: 23,
    name: "Mitipola",
    si: "Mitipola",
    en: "Mitipola",
  },
  {
    id: 23,
    name: "Moragala Kirillapone",
    si: "Moragala Kirillapone",
    en: "Moragala Kirillapone",
  },
  {
    id: 23,
    name: "Morahela",
    si: "Morahela",
    en: "Morahela",
  },
  {
    id: 23,
    name: "Mulendiyawala",
    si: "Mulendiyawala",
    en: "Mulendiyawala",
  },
  {
    id: 23,
    name: "Mulgama",
    si: "Mulgama",
    en: "Mulgama",
  },
  {
    id: 23,
    name: "Nawalakanda",
    si: "Nawalakanda",
    en: "Nawalakanda",
  },
  {
    id: 23,
    name: "NawinnaPinnakanda",
    si: "NawinnaPinnakanda",
    en: "NawinnaPinnakanda",
  },
  {
    id: 23,
    name: "Niralagama",
    si: "Niralagama",
    en: "Niralagama",
  },
  {
    id: 23,
    name: "Nivitigala",
    si: "Nivitigala",
    en: "Nivitigala",
  },
  {
    id: 23,
    name: "Omalpe",
    si: "Omalpe",
    en: "Omalpe",
  },
  {
    id: 23,
    name: "Opanayaka",
    si: "Opanayaka",
    en: "Opanayaka",
  },
  {
    id: 23,
    name: "Padalangala",
    si: "Padalangala",
    en: "Padalangala",
  },
  {
    id: 23,
    name: "Pallebedda",
    si: "Pallebedda",
    en: "Pallebedda",
  },
  {
    id: 23,
    name: "Pallekanda",
    si: "Pallekanda",
    en: "Pallekanda",
  },
  {
    id: 23,
    name: "Pambagolla",
    si: "Pambagolla",
    en: "Pambagolla",
  },
  {
    id: 23,
    name: "Panamura",
    si: "Panamura",
    en: "Panamura",
  },
  {
    id: 23,
    name: "Panapola",
    si: "Panapola",
    en: "Panapola",
  },
  {
    id: 23,
    name: "Paragala",
    si: "Paragala",
    en: "Paragala",
  },
  {
    id: 23,
    name: "Parakaduwa",
    si: "Parakaduwa",
    en: "Parakaduwa",
  },
  {
    id: 23,
    name: "Pebotuwa",
    si: "Pebotuwa",
    en: "Pebotuwa",
  },
  {
    id: 23,
    name: "Pelmadulla",
    si: "Pelmadulla",
    en: "Pelmadulla",
  },
  {
    id: 23,
    name: "Pinnawala",
    si: "Pinnawala",
    en: "Pinnawala",
  },
  {
    id: 23,
    name: "Pothdeniya",
    si: "Pothdeniya",
    en: "Pothdeniya",
  },
  {
    id: 23,
    name: "Rajawaka",
    si: "Rajawaka",
    en: "Rajawaka",
  },
  {
    id: 23,
    name: "Ranwala",
    si: "Ranwala",
    en: "Ranwala",
  },
  {
    id: 23,
    name: "Rassagala",
    si: "Rassagala",
    en: "Rassagala",
  },
  {
    id: 23,
    name: "Ratgama",
    si: "Ratgama",
    en: "Ratgama",
  },
  {
    id: 23,
    name: "Ratna Hangamuwa",
    si: "Ratna Hangamuwa",
    en: "Ratna Hangamuwa",
  },
  {
    id: 23,
    name: "Ratnapura",
    si: "Ratnapura",
    en: "Ratnapura",
  },
  {
    id: 23,
    name: "Sewanagala",
    si: "Sewanagala",
    en: "Sewanagala",
  },
  {
    id: 23,
    name: "Sri Palabaddala",
    si: "Sri Palabaddala",
    en: "Sri Palabaddala",
  },
  {
    id: 23,
    name: "Sudagala",
    si: "Sudagala",
    en: "Sudagala",
  },
  {
    id: 23,
    name: "Talakolahinna",
    si: "Talakolahinna",
    en: "Talakolahinna",
  },
  {
    id: 23,
    name: "Tanjantenna",
    si: "Tanjantenna",
    en: "Tanjantenna",
  },
  {
    id: 23,
    name: "Teppanawa",
    si: "Teppanawa",
    en: "Teppanawa",
  },
  {
    id: 23,
    name: "Tunkama",
    si: "Tunkama",
    en: "Tunkama",
  },
  {
    id: 23,
    name: "Udakarawita",
    si: "Udakarawita",
    en: "Udakarawita",
  },
  {
    id: 23,
    name: "Udaniriella",
    si: "Udaniriella",
    en: "Udaniriella",
  },
  {
    id: 23,
    name: "Udawalawe",
    si: "Udawalawe",
    en: "Udawalawe",
  },
  {
    id: 23,
    name: "Ullinduwawa",
    si: "Ullinduwawa",
    en: "Ullinduwawa",
  },
  {
    id: 23,
    name: "Veddagala",
    si: "Veddagala",
    en: "Veddagala",
  },
  {
    id: 23,
    name: "Vijeriya",
    si: "Vijeriya",
    en: "Vijeriya",
  },
  {
    id: 23,
    name: "Waleboda",
    si: "Waleboda",
    en: "Waleboda",
  },
  {
    id: 23,
    name: "Watapotha",
    si: "Watapotha",
    en: "Watapotha",
  },
  {
    id: 23,
    name: "Waturawa",
    si: "Waturawa",
    en: "Waturawa",
  },
  {
    id: 23,
    name: "Weligepola",
    si: "Weligepola",
    en: "Weligepola",
  },
  {
    id: 23,
    name: "Welipathayaya",
    si: "Welipathayaya",
    en: "Welipathayaya",
  },
  {
    id: 23,
    name: "Wikiliya",
    si: "Wikiliya",
    en: "Wikiliya",
  },
  {
    id: 24,
    name: "Agbopura",
    si: "අග්බෝපුර",
    en: "Agbopura",
  },
  {
    id: 24,
    name: "Buckmigama",
    si: "බක්මීගම",
    en: "Buckmigama",
  },
  {
    id: 24,
    name: "China Bay",
    si: "චීන වරාය",
    en: "China Bay",
  },
  {
    id: 24,
    name: "Dehiwatte",
    si: "දෙහිවත්ත",
    en: "Dehiwatte",
  },
  {
    id: 24,
    name: "Echchilampattai",
    si: "එච්චිලම්පට්ටෙයි",
    en: "Echchilampattai",
  },
  {
    id: 24,
    name: "Galmetiyawa",
    si: "ගල්මැටියාව",
    en: "Galmetiyawa",
  },
  {
    id: 24,
    name: "Gomarankadawala",
    si: "ගෝමරන්කඩවල",
    en: "Gomarankadawala",
  },
  {
    id: 24,
    name: "Kaddaiparichchan",
    si: "Kaddaiparichchan",
    en: "Kaddaiparichchan",
  },
  {
    id: 24,
    name: "Kallar",
    si: "Kallar",
    en: "Kallar",
  },
  {
    id: 24,
    name: "Kanniya",
    si: "Kanniya",
    en: "Kanniya",
  },
  {
    id: 24,
    name: "Kantalai",
    si: "Kantalai",
    en: "Kantalai",
  },
  {
    id: 24,
    name: "Kantalai Sugar Factory",
    si: "Kantalai Sugar Factory",
    en: "Kantalai Sugar Factory",
  },
  {
    id: 24,
    name: "Kiliveddy",
    si: "Kiliveddy",
    en: "Kiliveddy",
  },
  {
    id: 24,
    name: "Kinniya",
    si: "Kinniya",
    en: "Kinniya",
  },
  {
    id: 24,
    name: "Kuchchaveli",
    si: "Kuchchaveli",
    en: "Kuchchaveli",
  },
  {
    id: 24,
    name: "Kumburupiddy",
    si: "Kumburupiddy",
    en: "Kumburupiddy",
  },
  {
    id: 24,
    name: "Kurinchakemy",
    si: "Kurinchakemy",
    en: "Kurinchakemy",
  },
  {
    id: 24,
    name: "Lankapatuna",
    si: "Lankapatuna",
    en: "Lankapatuna",
  },
  {
    id: 24,
    name: "Mahadivulwewa",
    si: "Mahadivulwewa",
    en: "Mahadivulwewa",
  },
  {
    id: 24,
    name: "Maharugiramam",
    si: "Maharugiramam",
    en: "Maharugiramam",
  },
  {
    id: 24,
    name: "Mallikativu",
    si: "Mallikativu",
    en: "Mallikativu",
  },
  {
    id: 24,
    name: "Mawadichenai",
    si: "Mawadichenai",
    en: "Mawadichenai",
  },
  {
    id: 24,
    name: "Mullipothana",
    si: "Mullipothana",
    en: "Mullipothana",
  },
  {
    id: 24,
    name: "Mutur",
    si: "Mutur",
    en: "Mutur",
  },
  {
    id: 24,
    name: "Neelapola",
    si: "Neelapola",
    en: "Neelapola",
  },
  {
    id: 24,
    name: "Nilaveli",
    si: "නිලාවැලි",
    en: "Nilaveli",
  },
  {
    id: 24,
    name: "Pankulam",
    si: "Pankulam",
    en: "Pankulam",
  },
  {
    id: 24,
    name: "Pulmoddai",
    si: "පුල්මුඩේ",
    en: "Pulmoddai",
  },
  {
    id: 24,
    name: "Rottawewa",
    si: "Rottawewa",
    en: "Rottawewa",
  },
  {
    id: 24,
    name: "Sampaltivu",
    si: "Sampaltivu",
    en: "Sampaltivu",
  },
  {
    id: 24,
    name: "Sampoor",
    si: "සාම්පූර්",
    en: "Sampoor",
  },
  {
    id: 24,
    name: "Serunuwara",
    si: "සේනුවර",
    en: "Serunuwara",
  },
  {
    id: 24,
    name: "Seruwila",
    si: "සේරුවිල",
    en: "Seruwila",
  },
  {
    id: 24,
    name: "Sirajnagar",
    si: "Sirajnagar",
    en: "Sirajnagar",
  },
  {
    id: 24,
    name: "Somapura",
    si: "සෝමපුර",
    en: "Somapura",
  },
  {
    id: 24,
    name: "Tampalakamam",
    si: "Tampalakamam",
    en: "Tampalakamam",
  },
  {
    id: 24,
    name: "Thuraineelavanai",
    si: "Thuraineelavanai",
    en: "Thuraineelavanai",
  },
  {
    id: 24,
    name: "Tiriyayi",
    si: "Tiriyayi",
    en: "Tiriyayi",
  },
  {
    id: 24,
    name: "Toppur",
    si: "Toppur",
    en: "Toppur",
  },
  {
    id: 24,
    name: "Trincomalee",
    si: "තිරිකුණාමලය",
    en: "Trincomalee",
  },
  {
    id: 24,
    name: "Wanela",
    si: "Wanela",
    en: "Wanela",
  },
  {
    id: 25,
    name: "Vavuniya",
    si: "වව්නියාව",
    en: "Vavuniya",
  },
  {
    id: 5,
    name: "Colombo 1",
    si: "කොළඹ 1",
    en: "Colombo 1",
  },
  {
    id: 5,
    name: "Colombo 3",
    si: "කොළඹ 3",
    en: "Colombo 3",
  },
  {
    id: 5,
    name: "Colombo 4",
    si: "කොළඹ 4",
    en: "Colombo 4",
  },
  {
    id: 5,
    name: "Colombo 5",
    si: "කොළඹ 5",
    en: "Colombo 5",
  },
  {
    id: 5,
    name: "Colombo 7",
    si: "කොළඹ 7",
    en: "Colombo 7",
  },
  {
    id: 5,
    name: "Colombo 9",
    si: "කොළඹ 9",
    en: "Colombo 9",
  },
  {
    id: 5,
    name: "Colombo 10",
    si: "කොළඹ 10",
    en: "Colombo 10",
  },
  {
    id: 5,
    name: "Colombo 11",
    si: "කොළඹ 11",
    en: "Colombo 11",
  },
  {
    id: 5,
    name: "Colombo 12",
    si: "කොළඹ 12",
    en: "Colombo 12",
  },
  {
    id: 5,
    name: "Colombo 14",
    si: "කොළඹ 14",
    en: "Colombo 14",
  },
];
