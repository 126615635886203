import React, { useEffect, useState } from "react";
import { getToken } from "../Authentication/Auth";
import { HARVEST_DATE_TYPES } from "../config/constants/general";
import strings from "../config/constants/strings";

const styles = {
  detailName: "text-xl font-medium col-span-3",
  detailValue: "text-lg col-span-2 text-gray-600",
};

function Harvest() {
  const [harvestData, setHarvestData] = useState();
  const [harvestDate, setHarvestDate] = useState();
  const [addDate, setAddDate] = useState();
  const [addDateEdit, setAddDateEdit] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    loadHarvestData();
  }, []);

  useEffect(() => {
    if (harvestDate) {
      const hDate = new Date(harvestDate);
      setAddDateEdit(
        new Date(hDate.setDate(hDate.getDate() - 7)).toISOString().split("T")[0]
      );
    }
  }, [harvestDate]);

  const loadHarvestData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/harvest/getHarvestDate`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setHarvestData(response);
        let ddate = new Date(response?.user?.nextHarvest);
        setHarvestDate(ddate.toISOString().split("T")[0]);
        const newDate = new Date(ddate.setDate(ddate.getDate() - 7))
          .toISOString()
          .split("T")[0];
        setAddDate(newDate);
        setAddDateEdit(newDate);
      })
      .catch((error) => console.log(error));
  };

  const harvestChange = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/harvest/harvestDateChange`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ harvestDate: harvestDate }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setIsEdit(false);
        loadHarvestData();
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="h-screen flex">
      <div className="flex-1 flex overflow-hidden pt-24 ">
        <div class="sm:w-4/5 mx-auto w-full">
          <h3 className="text-2xl font-semibold mb-4 text-center">
            {strings.NEXT_HARVEST_DATE}
          </h3>
          <div className=" gap-2 p-4 mt-5 rounded-xl shadow-sm md:w-3/5  sm:w-5/5 items-start mx-auto">
            <div className="flex flex-col sm:flex-row col-span-2  justify-between">
              <div className="text-xl mt-1 mb-4 sm:mb-0">
                {strings.NEXT_DATE}
              </div>
              <input
                type="date"
                name="nextDate"
                id="floating_first_name"
                disabled={!isEdit}
                defaultValue={harvestDate}
                value={harvestDate}
                onChange={(e) => setHarvestDate(e.target.value)}
                class="col-span-1 block py-2.5 px-2 sm:w-48 h-12 text-lg text-center text-gray-900 bg-transparent border-0 rounded-lg border-b-2  appearance-none bg-slate-200 mb-6"
                placeholder="0-90"
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="flex flex-col sm:flex-row col-span-2  justify-between">
              <div className="text-xl mt-1 mb-4 sm:mb-0 ">
                {strings.APPEARS_ON}
              </div>
              <input
                type="date"
                name="addDate"
                id="floating_first_name"
                disabled={true}
                value={isEdit ? addDateEdit : addDate}
                class="col-span-1 block py-2.5 px-2 sm:w-48 h-12 text-lg text-center text-gray-900 bg-transparent border-0 rounded-lg border-b-2  appearance-none bg-slate-200 mb-6"
                placeholder="0-90"
              />
            </div>
            {harvestData?.user?.status === HARVEST_DATE_TYPES.APPROVED ? (
              isEdit ? (
                <div className="col-span-4">
                  <button
                    disabled={!harvestDate?.length}
                    onClick={() => harvestChange()}
                    type="button"
                    className=" mt-2 sm:w-48  w-full mr-5 inline-block p-2 disabled:bg-slate-300 bg-green-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsEdit(false);
                      loadHarvestData();
                    }}
                    className="mt-2 sm:w-48 w-full inline-block p-2 bg-red-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="col-span-4 ">
                  <button
                    onClick={() => setIsEdit(true)}
                    type="button"
                    className="w-48 right-0 inline-block p-2 bg-green-400 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {strings.EDIT}
                  </button>
                </div>
              )
            ) : (
              harvestData && (
                <div className="text-base mt-1 text-green-700 col-span-3">
                  Your request to change the harvest date (
                  {harvestData?.harvest?.harvestDate}) has been reviewing by our
                  operation team, Your patience is highly appriciated !
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Harvest;
