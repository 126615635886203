import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import AdvertistmentCarousel from "../components/AdvertistmentCarousel";
import ListItemSeller from "../components/ListItemSeller";

import SelectBox from "../components/SelectBox";

import { districts } from "../common/districts";
import {
  getToken,
  getUserLevel,
  getUserLocation,
} from "../Authentication/Auth";
import { allCities } from "../common/cities";
import SelectBoxSearch from "../components/SelectBoxSearch";
import strings from "../config/constants/strings";
import Modal from "../components/Modal";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { addDays, subDays } from "../common/utils";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import PreLoader from "../components/PreLoader";
import { SearchBar } from "../components/SearchBar";
import { useDebounce } from "../hooks/debounce";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Skeleton } from "../components/ui/skeleton";

function Search() {
  const { isReqEnabled } = useParams();
  const navigate = useNavigate();
  const scale = [
    { id: "Any", name: strings.ANY, unavailable: false },
    { id: "Small", name: strings.SMALL, unavailable: false },
    { id: "Medium", name: strings.MEDIUM, unavailable: false },
    { id: "Large", name: strings.LARGE, unavailable: false },
  ];
  const [range, setRange] = useState({ min: 0, max: 100000 });

  const [currentLocation, setCurrentLocation] = useState(
    getUserLocation()
      ? getUserLocation()
      : {
          lat: 6.9271,
          lng: 79.8612,
        }
  );

  const [enableLocation, setEnableLocation] = useState(false);

  const sortings = [
    {
      id: "Select Criteria",
      name: strings.SELECT_CRITERIA,
      unavailable: false,
    },
    {
      id: "Highest Price by killo",
      name: strings.HIGHEST_PRICE,
      unavailable: false,
    },
    {
      id: "Highest Price by unit",
      name: strings.HIGHEST_PRICE_BY_UNIT,
      unavailable: false,
    },
    {
      id: "Overall Highest Rating",
      name: strings.OVERALL_HIGHEST_RATING,
      unavailable: false,
    },
    {
      id: "Highest Price and Rating",
      name: strings.HIGHEST_PRICE_AND_RATING,
      unavailable: false,
    },
    {
      id: "Punctually Rating Highest",
      name: strings.PUNCTUALITY_RATING_HIGHEST,
      unavailable: false,
    },
  ];
  const [swEnable, setSwEnable] = useState(false);
  const [isReqLoading, setIsReqLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sellers, setSellers] = useState(null);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [requirements, setRequirements] = useState(null);
  const [addRequirements, setAddRequirements] = useState([]);
  const [addSellers, setAddSellers] = useState([]);
  const [page, setPage] = useState(1);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState({
    label: "Any",
    value: "Any",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [radius, setRadius] = useState(20);
  const [circleOptions, setCircleOptions] = useState({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: radius * 1000,
    zIndex: 1,
  });

  const [searchText, setSearchText] = useState("");

  const { debouncedValue } = useDebounce(searchText, 300);
  const { debouncedValue: rangeDebounced } = useDebounce(range, 300);

  useEffect(() => {
    setCircleOptions({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: radius * 1000,
      zIndex: 1,
    });
  }, [radius]);

  const loadSellers = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/sellers`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        page,
        scale: `${selectedScale.id} Scale`,
        district: selectedDistrict.name,
        city: selectedCities.value,
        sort:
          selectedSorting.id === "Select Criteria" ? "Any" : selectedSorting.id,
        lat: enableLocation ? currentLocation.lat : undefined,
        lng: enableLocation ? currentLocation.lng : undefined,
        radius,
        searchText,
        range,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setSellers(response);
        console.log(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const saveRequirement = () => {
    const filteredArray = addRequirements?.filter(
      (value) => !addSellers?.includes(value)
    );
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/add-multiple`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        requirementId: selectedRequirement?._id,
        sellers: filteredArray,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.length) {
          alert(
            `Bids were created successfully except for the sellers \n ${response.join(
              ", "
            )}.\nBecause you have provided the bid with the same amount!`
          );
        } else {
          alert(`Bids were created successfully!`);
        }
        navigate("/sellers");
        setSwEnable(false);
        setAddRequirements([]);
        setAddSellers([]);
      })
      .catch((error) => console.log(error));
  };

  const loadRequirements = () => {
    setIsReqLoading(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/requirement-dashboard`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const res = response?.filter(
          (item) =>
            subDays(item.startDate, 7) < new Date() &&
            addDays(item.endDate, 1) > new Date()
        );
        console.log(res);
        if (isReqEnabled) {
          setSwEnable(true);
          const req = res?.filter((item) => item._id === isReqEnabled);
          setSelectedRequirementId(isReqEnabled);
          setSelectedRequirement(req[0]);
          setRequirements(req);
          setAddRequirements(req[0]?.sellers || []);
          setAddSellers(req[0]?.sellers);
        } else {
          setSelectedRequirement(res[0]);
          setRequirements(res);
          setAddRequirements(res[0]?.sellers || []);
          setAddSellers(res[0]?.sellers);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsReqLoading(false));
  };

  useEffect(() => {
    loadSellers();
  }, [page, enableLocation, rangeDebounced]);

  useEffect(() => {
    if (isReqEnabled) {
      loadRequirements();
    }
  }, []);

  useEffect(() => {
    if (swEnable) {
      loadRequirements();
    }
  }, [swEnable]);

  const [selectedDistrict, setSelectedDistrict] = useState(districts[0]);
  const [selectedScale, setSelectedScale] = useState(scale[0]);
  const [selectedSorting, setSelectedSorting] = useState(sortings[0]);

  useEffect(() => {
    getCities("Colombo");
  }, []);

  useEffect(() => {
    if (selectedDistrict !== "Any") {
      getCities(selectedDistrict.name);
    }
  }, [selectedDistrict]);

  // useEffect(() => {
  //   setPage(1);
  //   loadSellers(1);
  // }, [selectedScale, selectedDistrict, selectedCities, selectedSorting]);

  React.useEffect(() => {
    // const getData = setTimeout(() => {
    setPage(1);
    loadSellers(1);
    // }, 2000);
  }, [selectedScale, selectedDistrict, selectedSorting, debouncedValue]);

  const getCities = (data) => {
    const selectedDistrict = districts?.filter((dist) => dist.name === data);

    const selectedCities = allCities?.filter((city) => {
      if (city?.id === selectedDistrict[0]?.id) {
        return city;
      }
    });

    const mappedCities = selectedCities?.map((city) => ({
      label: city[strings.getLanguage()],
      value: city.name,
    }));
    setCities([
      {
        label: strings.ANY,
        value: "Any",
      },
      ...mappedCities,
    ]);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  const addToRequirement = (id) => {
    let local = addRequirements;
    if (!local?.includes(id)) {
      local = [...addRequirements, id];
    } else {
      local = local?.filter((item) => item !== id);
    }
    setAddRequirements(local);
  };

  return (
    <div>
      <div className="sm:container mx-auto pt-1">
        {/* <h1 class="text-3xl font-normal leading-normal mx-auto text-center lg:mx-72 mt-28 lg:text-left">
          Search Sellers
        </h1> */}
        {/* <h1 className="align self-center text-center w-auto text-3xl mt-24">
          Search Coconut Sellers
        </h1> */}
        <div className="mt-24">
          <SearchBar
            placeholder={"Search Coconut Sellers"}
            value={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <PreLoader isLoading={isLoading} />
        <div className="flex flex-wrap mt-5 mx-10 justify-center ">
          <div className="flex mt-2">
            <SelectBox
              list={scale}
              selected={selectedScale}
              setSelected={setSelectedScale}
              title={strings.SCALE}
              full={false}
            />
          </div>
          <div className="flex  mt-2">
            <SelectBox
              list={sortings}
              selected={selectedSorting}
              setSelected={setSelectedSorting}
              title={strings.SORT_BY}
              full={false}
            />
          </div>
          <div className="flex  mt-2 flex-col sm:ml-2 ml-0 sm:w-56 w-72 ">
            <h3 className="sm:ml-2 ml-0 font-semibold">
              {strings.ESTIMATED_HARVEST}
            </h3>
            <div
              id="range-slider"
              className="pt-3 py-1 mt-1 pl-2 pr-2 -ml-3 sm:ml-0 text-left bg-white rounded-lg shadow-md"
            >
              <RangeSlider
                onInput={(e) => {
                  console.log(e);
                  setRange({ min: e[0], max: e[1] });
                }}
                min={0}
                max={100000}
              />
              <p className="text-sm	mt-2 ml-1">
                {range.min} - {range.max}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-wrap mt-8 content-start items-center	mx-auto">
          <center>
            <label for="four">
              <input
                id="four"
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setIsOpen(true);
                  } else {
                    setEnableLocation(false);
                  }
                }}
                checked={enableLocation}
              />
              <span className="font-semibold ">
                {strings.ENABLE_LOCATION_BASED_SEARCHING}
              </span>
            </label>
          </center>
        </div>

        <div className="mb-8 ">
          {getUserLevel() === "Buyer" && (
            <div
              className={`mx-6 md:mx-24 lg:mx-52 xl:mx-80 py-4 px-8 mt-4  border-2 ${
                swEnable ? "border-emerald-600" : "border-gray-300"
              } rounded-lg`}
            >
              <div className="flex justify-between">
                <h1 className="font-normal font-sans text-lg text-gray-600">
                  {strings.BID_MULTIPLE_SET_REQUIRED_AMOUNT}
                </h1>
                <Switch
                  onChange={(e) => {
                    !e && setAddRequirements([]);
                    !e && setAddSellers([]);
                    setSwEnable(e);
                  }}
                  checked={swEnable}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              {swEnable && !isReqLoading && (
                <>
                  <h1 className="w-full text-center mt-2">
                    Added
                    <span className="bg-blue-700 mx-1.5 text-white p-1.5 px-2.5 rounded-full">
                      {addRequirements?.length}
                    </span>
                    Sellers
                  </h1>
                  <div className=" mt-4 flex justify-center">
                    <select
                      className="block rounded-md shadow-lg appearance-none w-full bg-white text-gray-700 py-2 px-4 focus:outline-none"
                      id="grid-state"
                      name="district"
                      onChange={(e) => {
                        setSelectedRequirementId(e.target.value);
                        const addedReq = requirements?.filter(
                          (item) => item._id === e.target.value
                        );
                        setSelectedRequirement(addedReq[0]);
                        setAddRequirements(addedReq[0]?.sellers);
                        setAddSellers(addedReq[0]?.sellers);
                      }}
                      value={selectedRequirementId}
                    >
                      {requirements?.length ? (
                        requirements?.map((e) => {
                          return (
                            <option key={e._id} value={e._id}>
                              {new Date(e.startDate).toLocaleDateString()} -{" "}
                              {new Date(e.endDate).toLocaleDateString()}
                            </option>
                          );
                        })
                      ) : (
                        <option value={null}>No Requirements</option>
                      )}
                    </select>
                    <a href="/requirementsBuyertrue">
                      <PlusCircleIcon className="w-8 mx-2" />
                    </a>
                    <button
                      disabled={
                        !selectedRequirement ||
                        !addRequirements?.length > 0 ||
                        addRequirements?.length === addSellers?.length
                      }
                      onClick={saveRequirement}
                      className="rounded-full text-lime-800 px-4 border-2 border-green-800 bg-white disabled:text-gray-100 disabled:bg-slate-300"
                    >
                      Save
                    </button>
                  </div>
                </>
              )}
              {swEnable && isReqLoading && (
                <center className=" my-7">
                  <Spinner />
                </center>
              )}
            </div>
          )}
        </div>

        {/* <div className="container py-4 mt-4">
          <ListItemSeller name={"John Fernando"} price={"120.00 Rs"} district={"Colombo"} scale={"Large"} rating={3}/>
          <ListItemSeller name={"Nimal Fernando"} price={"120.00 Rs"} district={"Colombo"} scale={"Large"} rating={4}/>
          <ListItemSeller name={"Kasun Fernando"} price={"120.00 Rs"} district={"Colombo"} scale={"Large"} rating={5}/>
          <ListItemSeller name={"Dinith Fernando"} price={"120.00 Rs"} district={"Colombo"} scale={"Large"} rating={2}/>
        </div> */}

        <div className="sm:container py-4 mt-4 ">
          {sellers?.docs ? (
            sellers?.docs?.map((e, i) => {
              return (
                <ListItemSeller
                  clickBtn={() => addToRequirement(e._id)}
                  isRequirementEnabled={swEnable}
                  isDisabled={selectedRequirement?.sellers?.length}
                  isSelected={addRequirements?.includes(e._id)}
                  isNew={
                    !addSellers?.includes(e._id) &&
                    addRequirements?.includes(e._id)
                  }
                  isOld={
                    addSellers?.includes(e._id) &&
                    addRequirements?.includes(e._id)
                  }
                  key={e._id}
                  name={e.name}
                  scale={e.scaleOfBusiness}
                  rating={e?.rating?.average ?? null}
                  id={e._id}
                  harvest={e?.harvest}
                  city={e?.areasToCollect?.[0]}
                  yieldPerHarvest={e.yieldPerHarvest}
                  highestBid={e.highestBid}
                />
              );
            })
          ) : (
            <center>
              <div class="flex justify-center items-center">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </center>
          )}

          {sellers?.docs?.length === 0 && (
            <center className="mb-8 mt-8 h-72">
              <h5 className="text-2xl font-normal leading-normal mx-auto text-center mt-24 text-gray-400">
                No Matches found
              </h5>
            </center>
          )}

          {sellers?.totalPages && (
            <center className="mt-8">
              <nav aria-label="Page navigation example">
                <ul class="inline-flex -space-x-px text-base h-10">
                  <li className="cursor-pointer">
                    <a
                      class="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                      onClick={() => page !== 1 && setPage(page - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {Array.from(
                    {
                      length: sellers?.totalPages < 6 ? sellers?.totalPages : 5,
                    },
                    (_, i) =>
                      sellers?.page === i + 1 ? (
                        <li key={i} className="cursor-pointer">
                          <a
                            href="#"
                            aria-current="page"
                            class="flex items-center justify-center px-4 h-10 text-green-600 border border-green-300 bg-green-50 hover:bg-green-100 hover:text-green-700"
                          >
                            {i + 1}
                          </a>
                        </li>
                      ) : (
                        <li key={i} className="cursor-pointer">
                          <a
                            className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            onClick={() => setPage(i + 1)}
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                  )}
                  {page > 5 && (
                    <li className="cursor-pointer">
                      <a class="flex items-center justify-center px-4 h-10 text-green-600 border border-green-300 bg-green-50 hover:bg-green-100 hover:text-green-700">
                        {page}
                      </a>
                    </li>
                  )}

                  <li className="cursor-pointer">
                    <a
                      class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                      onClick={() =>
                        page < sellers?.totalPages && setPage(page + 1)
                      }
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </center>
          )}
        </div>

        <div className="mb-8 ">
          {getUserLevel() === "Buyer" && (
            <div
              className={`mx-6 md:mx-24 lg:mx-52 xl:mx-80 py-4 px-8 mt-4  border-2 ${
                swEnable ? "border-emerald-600" : "border-gray-300"
              } rounded-lg`}
            >
              <div className="flex justify-between">
                <h1 className="font-normal font-sans text-lg text-gray-600">
                  {strings.BID_MULTIPLE_SET_REQUIRED_AMOUNT}
                </h1>
                <Switch
                  onChange={(e) => {
                    !e && setAddRequirements([]);
                    !e && setAddSellers([]);
                    setSwEnable(e);
                  }}
                  checked={swEnable}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              {swEnable && !isReqLoading && (
                <>
                  <h1 className="w-full text-center mt-2">
                    Added
                    <span className="bg-blue-700 mx-1.5 text-white p-1.5 px-2.5 rounded-full">
                      {addRequirements?.length}
                    </span>
                    Sellers
                  </h1>
                  <div className=" mt-4 flex justify-center">
                    <select
                      className="block rounded-md shadow-lg appearance-none w-full bg-white text-gray-700 py-2 px-4 focus:outline-none"
                      id="grid-state"
                      name="district"
                      onChange={(e) => {
                        setSelectedRequirementId(e.target.value);
                        const addedReq = requirements?.filter(
                          (item) => item._id === e.target.value
                        );
                        setSelectedRequirement(addedReq[0]);
                        setAddRequirements(addedReq[0]?.sellers);
                        setAddSellers(addedReq[0]?.sellers);
                      }}
                      value={selectedRequirementId}
                    >
                      {requirements?.length ? (
                        requirements?.map((e) => {
                          return (
                            <option key={e._id} value={e._id}>
                              {new Date(e.startDate).toLocaleDateString()} -{" "}
                              {new Date(e.endDate).toLocaleDateString()}
                            </option>
                          );
                        })
                      ) : (
                        <option value={null}>No Requirements</option>
                      )}
                    </select>
                    <a href="/requirementsBuyertrue">
                      <PlusCircleIcon className="w-8 mx-2" />
                    </a>
                    <button
                      disabled={
                        !selectedRequirement ||
                        !addRequirements?.length > 0 ||
                        addRequirements?.length === addSellers?.length
                      }
                      onClick={saveRequirement}
                      className="rounded-full text-lime-800 px-4 border-2 border-green-800 bg-white disabled:text-gray-100 disabled:bg-slate-300"
                    >
                      Save
                    </button>
                  </div>
                </>
              )}
              {swEnable && isReqLoading && (
                <center className=" my-7">
                  <Spinner />
                </center>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isOpen}
        handleCancel={() => {
          setIsOpen(false);
          setEnableLocation(false);
        }}
        handleOk={() => {
          setIsOpen(false);
          setEnableLocation(true);
          if (enableLocation) {
            loadSellers();
          }
        }}
        title={"Select Your Location"}
        full={true}
      >
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={{
            width: "100%",
            height: "300px",
          }}
          center={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          zoom={10}
        >
          {/* Child components, such as markers, info windows, etc. */}

          <>
            <Marker
              position={currentLocation}
              draggable={true}
              onDragEnd={(e) => {
                setCurrentLocation({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                });
              }}
            />
            <Circle
              center={{
                lat: currentLocation.lat,
                lng: currentLocation.lng,
              }}
              options={circleOptions}
            />
          </>
        </GoogleMap>

        <div>
          <label for="customRange1" class=" inline-block text-neutral-700 mt-4">
            Search Radius : {radius} KM
          </label>
          <input
            type="range"
            class="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600"
            id="customRange1"
            max={20}
            min={1}
            onChange={(e) => {
              setRadius(e.target.value);
            }}
            value={radius}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Search;
