import React from "react";
import { MyAccountExtrasCard } from "./MyAccountExtrasCard";
import Skeleton from "react-skeleton-loader";
import strings from "../../config/constants/strings";

export const MyAccountExtrasContainer = ({ isLoading, data }) => {
  return isLoading ? (
    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
      <div class="pr-6">
        <Skeleton width={"100%"} widthRandomness={0} height={"150px"} />
      </div>
      <div class="pr-6">
        <Skeleton width={"100%"} widthRandomness={0} height={"150px"} />
      </div>
      <div class="pr-6">
        <Skeleton width={"100%"} widthRandomness={0} height={"150px"} />
      </div>
      <div class="">
        <Skeleton width={"100%"} widthRandomness={0} height={"150px"} />
      </div>
    </div>
  ) : (
    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
      {data?.map(
        (element) =>
          element?.value && (
            <MyAccountExtrasCard
              title={element?.title[strings.getLanguage()]}
              value={element?.value}
            />
          )
      )}
    </div>
  );
};
