export default function Footer() {
  return (
    <div className="bg-gradient-to-r from-teal-400 to-yellow-200 text-dark py-8 sm:mb-0">
      <div className="container mx-auto px-4 md:px-6 flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center space-x-4">
          <a
            href="#"
            className="text-white hover:text-gray-300"
            prefetch={false}
          >
            <span className="sr-only">About</span>
          </a>
          <a
            href="#"
            className="text-white hover:text-gray-300"
            prefetch={false}
          >
            <span className="sr-only">Contact Us</span>
          </a>
          <a
            href="#"
            className="text-white hover:text-gray-300"
            prefetch={false}
          >
            <span className="sr-only">Terms and Agreement</span>
          </a>
        </div>
        <p className="mt-4 md:mt-0 text-sm text-gray-900">
          &copy; 2024 Polmure.lk. All rights reserved.
        </p>
      </div>
    </div>
  );
}
