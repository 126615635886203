import { React, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

function OtpScreen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = state; 
  const [otpValue, setOtpValue] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(otpValue.length===4){
      navigate("/new-password", { state: { otp: otpValue, email: email } });
    }else{
      setErrors({email:'Invalid OTP value'})
    }
    
  };

  return (
    <div className="lg:flex ">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <div className="text-2xl text-green-800 tracking-wide ml-2 font-semibold">
              පොල් මුරේ
            </div>
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-0 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-green-900 font-display font-semibold lg:text-left xl:text-5xl
          xl:text-bold"
          >
            Enter OTP
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="text-sm font-bold text-gray-700 tracking-wide text-center">
                  Enter the 4 digits code that you received to your phone
                </div>
                {/* <input
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-green-500"
                  type="email"
                  placeholder="mike@gmail.com"
                /> */}
                <div className="w-full flex items-center justify-center my-10">
                  {" "}
                  <OtpInput
                    value={otpValue}
                    onChange={setOtpValue}
                    numInputs={4}
                    separator={<span> </span>}
                    className=""
                    shouldAutoFocus={true}
                    inputStyle={{
                      // padding: 4,
                      width: "2em",
                      border: "1px solid #DDDDDD",
                      borderRadius: "8px",
                      padding: "5px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      color: "#265ED7",
                      fontSize: 22,
                      fontWeight: "bold",
                    }}
                    isInputNum={true}
                  />
                </div>
                {errors.email ? (
                  <p className="text-red-500 text-center">{errors.email} !</p>
                ) : null}
              </div>

              <div className="mt-10">
                <button
                  className="bg-green-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                      font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-600
                      shadow-lg"
                >
                  Next
                </button>
              </div>
            </form>
            <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
              Don't have an account ?{" "}
              <a href="/register" className="cursor-pointer text-green-600 hover:text-green-800">
                Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-green-100 flex-1 h-screen">
        <img
          className="h-screen object-cover "
          src="https://images.unsplash.com/photo-1621442745928-8f8d98021f35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2669&q=80"
          alt="nike shoes"
        />
      </div>
    </div>
  );
}

export default OtpScreen;
