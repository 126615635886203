import React from 'react'

export const InputOne = ({
  label,
  name,
  isDisable,
  value,
  onChange,
  error,
  type="text",
}) => {
  return (
    <div className="relative w-full mb-3">
      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        {label}
      </label>
      <input
        type={type}
        disabled={isDisable}
        min={0}
        name={name}
        onChange={onChange}
        className={`${
          !isDisable ? "border border-blue-500" : ""
        } border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 `}
        value={value}
      />
      {!isDisable && error && <span className="text-red-600 text-sm font-semibold">{error}</span>}
    </div>
  );
};
