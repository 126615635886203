import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ListItemBuyer from "../components/ListItemBuyer";
import Logo from "../assets/logo.jpg";
import Cover from "../assets/home.webp";
import calender from "../assets/calender.json";
import { getToken, isAuthenticated } from "../Authentication/Auth";
import Spinner from "../components/Spinner";
import strings from "../config/constants/strings";
import InstallPWA from "../components/PWA/InstallPWA";
import Modal from "../components/Modal";
import LottieComponent from "../components/Lottie/LottieComponent";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNoHarvest, setIsNoHarvest] = useState(false);

  const loadDashboardData = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/home/dashboard`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        console.log(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadDashboardData();
    const time = setTimeout(() => {
      loadHarvestData();
    }, 10000);

    return () => clearTimeout(time);
  }, []);

  const loadHarvestData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getHarvestDate`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        response?.status != undefined && setIsNoHarvest(!response?.status);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="w-screen h-screen px-4 mb-32">
      <InstallPWA />
      <div className="h-20"></div>
      <div class="relative z-0 w-full h-4/5 self-center object-cover justify-items-start border rounded-3xl shadow-lg overflow-hidden ">
        <div class="absolute inset-0 flex flex-col justify-items-center justify-center  z-0 bg-slate-700/40">
          {/* <p class="text-6xl font-bold text-white text-center">පොල් මුරේ</p> */}

          <img
            className="h-44 w-44 md:h-64 md:w-64 object-cover mx-auto rounded-2xl shadow-lg"
            src={Logo}
          ></img>
          <h3 class="text-3xl font-bold text-gray-200 text-center mt-5 p-2">
            The first and only online platform to buy and sell coconuts in{" "}
            <br />
            Sri Lanka
          </h3>

          {isAuthenticated() ? null : ( // <div className="flex flex-col sm:flex-row mx-auto">
            <div className="flex flex-col sm:flex-row mx-auto">
              <a
                type="button"
                className=" mt-5 text-center inline-block px-2 py-4 w-60 bg-green-500 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                href="/register"
              >
                Register Now
              </a>
              <a
                type="button"
                className="sm:ml-4 mt-5 text-center inline-block px-2 py-4 w-60 bg-blue-500 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
                href="/login"
              >
                Login
              </a>
            </div>
          )}
        </div>
        <img
          className="w-full h-full object-cover "
          src={Cover}
          alt="nike shoes"
        />
      </div>
      {/* <div class="absolute inset-y-2 flex justify-center items-center z-10 h-2/3 mt-5 bg-orange-400 mx-20">
        <p class="text-6xl font-bold text-white">පොල් මුරේ</p>
      </div>
      <div class="absolute inset-0 flex justify-center items-center h-2/3 z-10 mt-32">
        <h3 class="text-3xl font-bold text-gray-200">Online Platform for buy and sell coconuts in Sri Lanka</h3>
      </div> */}
      <div className="flex flex-col sm:flex-row p-4 justify-center items-center mt-10">
        <div className="flex flex-col items-center mx-auto">
          <div className="text-4xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700">
            {loading ? (
              <Spinner />
            ) : data?.buyerCount ? (
              <CountUp end={data.buyerCount} duration={3} />
            ) : (
              "N/A"
            )}
          </div>
          <h3 className="text-2xl sm:mr-4 sm:mb-0 mb-6">
            {strings.NUMBER_OF_BUYERS}
          </h3>
        </div>
        <div className="flex flex-col items-center xs:ml-20 mx-auto">
          <div className="font-semibold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
            {loading ? (
              <Spinner />
            ) : data?.sellerCount ? (
              <CountUp end={data.sellerCount} duration={3} />
            ) : (
              "N/A"
            )}
          </div>
          <h3 className="text-2xl sm:mr-4 sm:mb-0 mb-6">
            {" "}
            {strings.NUMBER_OF_SELLERS}
          </h3>
        </div>
        <div className="flex flex-col items-center xs:ml-20 mx-auto">
          <div className="font-semibold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
            {loading ? (
              <Spinner />
            ) : data?.maximumPrice ? (
              <div>
                <CountUp end={data.maximumPrice} duration={3} /> Rs
              </div>
            ) : (
              "N/A"
            )}
          </div>
          <h3 className="text-2xl sm:mr-4">{strings.MAXIMUM_ASKING_PRICE}</h3>
        </div>
      </div>
      {/* <h3 className="text-2xl w-screen text-center mt-20 font-semibold text-lime-700">
        {" "}
        Premium Buyers{" "}
      </h3>
      <div
        id="carouselExampleControls"
        class="carousel slide relative sm:mx-40 mt-6 mb-20"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner relative w-full overflow-hidden pt-4 bg-lime-50">
          <div class="carousel-item active relative float-left w-full">
            <ListItemBuyer
              name={"Nimal Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
          <div class="carousel-item relative float-left w-full">
            <ListItemBuyer
              name={"Sunil Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
          <div class="carousel-item relative float-left w-full ">
            <ListItemBuyer
              name={"Kamal Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
        </div>
        <button
          class="sm:bg-lime-500 carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            class="carousel-control-prev-icon inline-block bg-no-repeat "
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="sm:bg-lime-500 carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            class="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}
      {/* <h3 className="text-2xl w-screen text-center mt-20 font-semibold text-lime-700">
        {" "}
        Premium Sellers{" "}
      </h3>
      <div
        id="carouselExampleControls2"
        class="carousel slide relative sm:mx-40 mt-6 mb-20"
        data-bs-ride="carousel"
      > */}
      {/* <div class="carousel-inner relative w-full overflow-hidden pt-4 bg-lime-50">
          <div class="carousel-item active relative float-left w-full">
            <ListItemBuyer
              name={"Nimal Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
          <div class="carousel-item relative float-left w-full">
            <ListItemBuyer
              name={"Sunil Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
          <div class="carousel-item relative float-left w-full ">
            <ListItemBuyer
              name={"Kamal Fernando"}
              district={"Colombo"}
              scale={"Large"}
              rating={5}
            />
          </div>
        </div> */}
      {/* <button
          class="sm:bg-lime-500 carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleControls2"
          data-bs-slide="prev"
        >
          <span
            class="carousel-control-prev-icon inline-block bg-no-repeat "
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="sm:bg-lime-500 carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleControls2"
          data-bs-slide="next"
        >
          <span
            class="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}
      <div className="h-20"></div>
      <Modal
        open={isNoHarvest}
        handleCancel={() => setIsNoHarvest(!isNoHarvest)}
        handleOk={() => navigate("/harvest")}
        title={""}
        submitButtonTitle="Sure"
      >
        <>
          <div className="mb-8">
            {" "}
            <LottieComponent animationData={calender} />
          </div>
          <p className="text-center">{strings.NO_HARVEST_DATE_MSG}</p>
        </>
      </Modal>
    </div>
  );
}

export default Home;
