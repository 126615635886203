const getToken = () => {
  const logins = JSON.parse(localStorage.getItem("logins"));
  const tName = localStorage.getItem("tokenName");
  return (
    ((tName && logins?.filter(({ tokenName }) => tokenName == tName)[0]?.token)) ||
    (logins?.length && logins[0]?.token)
  );
};

const getUserLogins = () => {
  return JSON.parse(localStorage.getItem("logins"));
};

const getTokenName = () => {
  return localStorage.getItem("tokenName");
};

const getUserLevel = () => {
  if (isAuthenticated()) {
    const user = parseJwt(getToken());
    return user.type;
  }
};

const isSeller = () => {
  if (isAuthenticated()) {
    const user = parseJwt(getToken());
    return user.type === "Seller";
  }
};

const isBuyer = () => {
  if (isAuthenticated()) {
    const user = parseJwt(getToken());
    return user.type === "Buyer";
  }
};

const isOfficer = () => {
  if (isAuthenticated()) {
    const user = parseJwt(getToken());
    return user.type === "OFFICER";
  }
};

const isAuthenticated = () => {
  if (getToken() == null) {
    return false;
  } else {
    return true;
  }
};

const getUserId = () => {
  let uId = null;
  const user = parseJwt(getToken());

  uId = user?.id ? user?.id : "";

  return uId;
};

const getUserName = () => {
  let uId = null;
  const user = parseJwt(getToken());

  if (user) uId = user.name;

  return uId;
};
const getEstate = () => {
  const logins = JSON.parse(localStorage.getItem("logins"));
  const tName = localStorage.getItem("tokenName");
  return (
    tName && logins?.filter(({ tokenName }) => tokenName == tName)[0]?.estate
  );
};

const getUserLocation = () => {
  let uId = null;
  const user = parseJwt(getToken());

  if (user) uId = user.location;

  return uId;
};

const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const logout = (callback) => {
  if (isAuthenticated()) {
    const logins = JSON.parse(localStorage.getItem("logins"));
    logins.forEach((element) => {
      localStorage.removeItem(element?.token);
    });
    localStorage.removeItem("logins");
    localStorage.removeItem("tokenName");
    localStorage.removeItem("token");
    callback(true);
  } else {
    callback(false);
  }
};
module.exports = {
  getToken,
  getUserLevel,
  getUserName,
  isAuthenticated,
  getUserId,
  logout,
  getUserLocation,
  isSeller,
  isBuyer,
  isOfficer,
  parseJwt,
  getUserLogins,
  getTokenName,
  getEstate,
};
