import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getToken } from "../Authentication/Auth";

function PostAdSeller() {
  const [errorMessage, setErrors] = useState({});

  return (
    <div class="lg:flex ">
      <div class="w-screen ">
        <div className="h-10"></div>
        <div class="mt-12 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-12 xl:max-w-2xl mx-auto">
          <h2
            class="text-center text-4xl text-green-900  font-semibold lg:text-left xl:text-3xl
          xl:text-bold mb-8"
          >
            Post New Advertisement
          </h2>
          <Formik
            initialValues={{ daysCount: "", price: "",pricPerKillo:"" }}
            validate={(values) => {
              const errors = {};
              if (!values?.daysCount) {
                errors.daysCount = "Number of days is Required*";
              }
              if (!values.price && !values.pricPerKillo) {
                errors.price = "Please enter Price Per Unit or Price Per Kilo";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                  body: JSON.stringify({
                    numberOfDays: values.daysCount,
                    price: Number(values.price),
                    pricPerKillo: Number(values.pricPerKillo),
                  }),
                };
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/api/adRequest/buyer`,
                  requestOptions
                )
                  .then(function (response) {
                    return response.json();
                  })
                  .then((res) => {
                    console.log(res.error);
                    if (res?.error != null || res?.error != undefined) {
                      setErrors({ error: res?.error });
                    } else {
                      const msg = {
                        success: "Ad Successfully Published!",
                      };
                      setErrors(msg);
                    }
                  })
                  .catch((err) => {
                    setErrors({ error: "server error try again later!" });
                    console.log(err);
                  });
              } catch (err) {
                setErrors({ error: "server error try again later!" });
                console.log(err);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="number"
                    name="daysCount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.daysCount}
                    id="floating_password"
                    class={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      errors.daysCount ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder=" "
                  />
                  <label
                    for="floating_password"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    {errors?.daysCount ? (
                      <span className="text-red-600">{errors?.daysCount}</span>
                    ) : (
                      "Duration (Days) *"
                    )}
                  </label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="number"
                    name="price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.price}
                    id="floating_repeat_password"
                    class={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      errors.price ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder=" "
                  />
                  <label
                    for="floating_repeat_password"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Price Per Unit (Rs)
                  </label>
                </div>
                <div class="relative z-0 mb-3 w-full group">
                  <input
                    type="number"
                    name="pricPerKillo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.pricPerKillo}
                    id="floating_repeat_password"
                    class={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      errors.price ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder=" "
                  />
                  <label
                    for="floating_repeat_password"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Price Per Killo (Rs)
                  </label>
                </div>

                <div className="mb-8">
                  {errors.price && (
                    <span className="text-red-600">{errors.price}</span>
                  )}
                  {errorMessage?.error != null ? (
                    <strong className="text-red-500 text-sm ">
                      {errorMessage?.error}
                    </strong>
                  ) : null}
                  {errorMessage?.success != null ? (
                    <strong className="text-green-500 text-sm ">
                      {errorMessage?.success}
                    </strong>
                  ) : null}
                </div>

                <button
                  // onClick={toreg2}
                  type="submit"
                  // disabled={isSubmitting}
                  class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default PostAdSeller;
