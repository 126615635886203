import React from "react";
import Lottie from 'react-lottie';

export default function LottieComponent({ animationData, height = 100, width = 100 }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={height} width={width} />;
}
