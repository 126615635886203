import React from "react";
import ReactStars from "react-rating-stars-component";
import image from "../assets/tb1.jpg";
import strings from "../config/constants/strings";

function ListItemBuyer({ name, city, scale, price, rating, id }) {
  // alert(name+" "+rating)
  return (
    <a href={"/viewBuyer" + id}>
      <div className="flex justify-center mb-4 hover:cursor-pointer">
        <div class="flex flex-col p-2 sm:p-0 sm:flex-row w-screen sm:w-7/12 rounded-lg  shadow-lg lg:h-32 md:h-40">
          <img
            class="h-56 lg:h-auto md:h-auto object-cover  sm:w-32 rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={image}
            alt=""
          />
          <div class="p-2 pl-4 pr-4 flex flex-col justify-start w-3/5 ">
            <h5 class="text-gray-900 text-lg font-semibold text-transform: capitalize">
              {name.toLowerCase()}
            </h5>
            <h5 class="text-gray-900 text-base font-medium ">{price}</h5>

            {city ? (
              <h5 class="text-gray-900 text-base  font-medium flex">
                <span className="font-semibold">{strings.CITY}:</span> &nbsp;
                {city}
              </h5>
            ) : null}

            {/* <h5 class="text-gray-900 text-base  font-medium flex ">
              <span className="font-semibold">Scale : </span> &nbsp; {scale}
            </h5> */}

            <div className="mt-0">
              {rating ? (
                <ReactStars
                  count={5}
                  // onChange={ratingChanged}
                  value={parseInt(rating)}
                  edit={false}
                  size={24}
                  activeColor="#ffd700"
                />
              ) : (
                <div>
                  <p className="text-gray-500 text-base  font-medium flex ">
                    No Ratings Available
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ListItemBuyer;
