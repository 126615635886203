import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";

import DataTable from "react-data-table-component";
import { ArrowSmRightIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid";
import UserSidebar from "../../components/UserSidebar";
import AdvertistmentCarousel from "../../components/AdvertistmentCarousel";
import Auth, { getToken } from "../../Authentication/Auth";
import ReactStars from "react-rating-stars-component";
import Modal from "../../components/Modal";
import strings from "../../config/constants/strings";
import { addDays, daysBetween, getFirstDateOfMonth, slashDate, subDays } from "../../common/utils";
import ProgressBar from "@ramonak/react-progress-bar";
import DatePicker from "react-datepicker";
import PreLoader from "../../components/PreLoader";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { DateFilter } from "../../components/DateFilter/DateFilter";
const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#effce0",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      backgroundColor: "#effce0",
    },
  },
};

const defaultValues ={
  startDate: "",
  endDate: "",
}

const MyBids = forwardRef((props, ref) => {
  const [harvestDate, setHarvestDate] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [userType, setUserType] = useState(Auth.getUserLevel());
  const [formType, setFormType] = useState(Auth.getUserLevel());
  const [bidsKilo, setBidsKilo] = useState([]);
  const [bidsUnit, setBidsUnits] = useState([]);
  const [error, setError] = useState([]);
  const [errorBox, setErrorBox] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [ratingEdit, setRatingEdit] = useState(true);
  const [collection, setCollection] = useState(5);
  const [arrivalTime, setArrivalTime] = useState(5);
  const [payment, setPayment] = useState(5);
  const [negotiation, setNegotiation] = useState(5);
  const [staff, setStaff] = useState(5);
  const [availability, setAvailability] = useState(5);
  const [description, setDescription] = useState("");
  const [row, setRow] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [status, setStatus] = useState("");
  const [values, setValues] = useState(defaultValues);
  const [selectedChips, setSelectedChips] = useState(null);
  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const columns = useMemo(
    () => [
      {
        name: <p className="text-sm text-center">{strings.MY_BIDS}</p>,
        selector: (row) => row.amount.toFixed(0) + " Rs",
        sortable: true,
      },
      {
        omit: Auth.isSeller(),
        name: (
          <p className="whitespace-pre-wrap text-sm text-center">
            {strings.CURRENT_HIGHEST_BID}
          </p>
        ),
        selector: (row) => row.maxBid.toFixed(0) + " Rs",
        conditionalCellStyles: [
          {
            when: (row) => row.maxBid <= row.amount,
            classNames: [
              "whitespace-pre-wrap text-base text-center font-semibold text-black",
            ],
          },
          {
            when: (row) => row.maxBid > row.amount,
            classNames: [
              "whitespace-pre-wrap text-base text-center font-semibold text-red-600",
            ],
          },
        ],
        sortable: true,
      },
      {
        name: (
          <p className="whitespace-pre-wrap text-sm text-center">
            {userType == "Buyer" ?`${strings.SELLER}` : `${strings.BUYER}`}
          </p>
        ),
        selector: (row) => {
          if (userType == "Buyer") {
            return (
              <p className="whitespace-pre-wrap text-sm text-left">
                {row.sellerName}
              </p>
            );
          } else {
            return (
              <p className="whitespace-pre-wrap text-sm text-center">
                {row.buyerName}
              </p>
            );
          }
        },
      },
      {
        name: (
          <p className="whitespace-pre-wrap text-sm text-center">{strings.ACQUIRED_TILL_NOW}</p>
        ),
        cell: (row) =>
          row?.requirement ? (
            <div className="w-full">
              <ProgressBar
                height="13px"
                animateOnRender
                maxCompleted={100}
                isLabelVisible={false}
                completed={
                  (row.requirement.acceptedQuantity /
                    row.requirement.quantity) *
                  100
                }
              />
              <p className="text-center text-sm font-semibold">
                {row.requirement.acceptedQuantity +
                  "/" +
                  row.requirement.quantity}
              </p>
            </div>
          ) : (
            <p>-</p>
          ),
      },
      {
        name: (
          <p className="whitespace-pre-wrap text-sm text-center">{strings.REQUIREMENT_ENDS_IN}</p>
        ),
        cell: (row) =>
          row?.requirement ? (
            <p className="whitespace-pre text-center text-sm font-semibold">{`${daysBetween(
              new Date().toDateString(),
              new Date(row?.requirement?.endDate),
              1
            )} Days \n ${new Date(
              row?.requirement?.endDate
            ).toLocaleDateString()}`}</p>
          ) : (
            <p>-</p>
          ),
        minWidth: 40,
      },
      {
        name: (
          <p className="text-base whitespace-pre-wrap min-w-full">
            {strings.STATUS}
          </p>
        ),
        selector: (row) => (
          <h4
            className={
              row.status == "pending"
                ? "text-yellow-600 capitalize font-semibold"
                : row.status == "canceled" || row.status == "inflated"
                ? "text-red-600 capitalize font-semibold"
                : row.status == "confirmed" || row.status == "closed"
                ? "text-green-600 capitalize font-semibold"
                : row.status == "expired"
                ? "text-gray-600 capitalize font-semibold"
                : row.status == "accepted"
                ? "text-blue-600 capitalize font-semibold"
                : "capitalize"
            }
          >
            {row.status}
          </h4>
        ),
        sortable: true,
        minWidth: 10,
      },
      {
        name: (
          <p className="text-base whitespace-pre-wrap min-w-full">
            {strings.HARVEST_DATE}
          </p>
        ),
        selector: (row) =>
          row.harvestDate !== "" && row.harvestDate != null ? (
            <p className="text-sm text-center">{slashDate(row.harvestDate)}</p>
          ) : (
            "-"
          ),
        sortable: true,
        minWidth: 40,
      },
      {
        omit: Auth.isSeller(),
        name: (
          <p className="whitespace-pre-wrap text-sm text-center">
            {strings.APPROXIMATE_HARVEST}
          </p>
        ),
        selector: (row, index) => <p className="text-base">{row?.quantity}</p>,
        minWidth: 40,
      },
      {
        name: `${strings.SEE_MORE}`,
        selector: (row) => (
          <a
            href={
              userType === "Seller"
                ? `viewBuyer${row.buyerId}`
                : `viewSeller${row.sellerId}`
            }
          >
            <b className="flex ">
              View
              <ChevronDoubleRightIcon
                className={`w-5 ml-1`}
                onClick={handleSidebar}
              />
            </b>
          </a>
        ),
        minWidth: 40,
      },
      {
        name: `${strings.ACTIONS}`,
        cell: (row) => {
          if (userType === "Seller") {
            return row.status === "pending" ? (
              <button
                className="bg-green-400 p-2 rounded-2xl text-white "
                onClick={(e) => handleAccept(row, "confirmed", e)}
                id={row._id}
              >
                {strings.CONFIRM}
              </button>
            ) : row.status === "confirmed" ? (
              <div className="flex flex-col">
                <button
                  className="bg-orange-300 p-2 rounded-2xl text-white my-2"
                  onClick={(e) => handleAccept(row, "pending", e)}
                  id={row._id}
                >
                  {strings.EDIT_HARVEST_DATE}
                </button>
                <button
                  className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
                  onClick={(e) => handleRatings(row._id, "closed", e)}
                  id={row}
                >
                  {strings.CLOSE}
                </button>
              </div>
            ) : row.status === "closed" && row?.buyerRating ? (
              // <h1> N/A</h1>
              <button
                className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
                onClick={(e) => viewRatings(row?.buyerRating, e)}
                id={row._id}
              >
                View Buyer Rating
              </button>
            ) : (
              <h1> N/A</h1>
            );
          } else {
            return row.status === "pending" ? (
              <button
                className="bg-red-500 p-2 rounded-2xl text-white "
                onClick={declineHandler}
                id={row._id}
              >
                Cancel
              </button>
            ) : row.status === "confirmed" ? (
              <h1> N/A</h1>
            ) : row.status === "closed" &&
              row?.sellerRating &&
              row?.buyerRating ? (
              <button
                className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
                onClick={(e) => viewRatings(row.sellerRating, e)}
                id={row._id}
              >
                View Seller Rating
              </button>
            ) : row.status === "closed" ? (
              <button
                className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
                onClick={(e) => handleRatings(row._id, "closed", e)}
                id={row._id}
              >
                Review
              </button>
            ) : (
              <h1> N/A</h1>
            );
          }
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (values?.startDate && selectedChips) {
      loadBids(values.startDate, values.endDate, selectedChips);
    }
  }, [values.startDate, values.endDate, selectedChips]);

  useEffect(() => {
    setValues({
      ...values,
      startDate: getFirstDateOfMonth(),
      endDate: new Date(),
    });
    setSelectedChips([
      { key: "pending", value: "Pending" },
      { key: "confirmed", value: "Confirmed" },
      { key: "closed", value: "Closed" },
      { key: "canceled", value: "Canceled" },
      { key: "expired", value: "Expired" },
    ])
  }, []);

  const handleAccept = (item, status, e) => {
    e.preventDefault();
    setSelectedRow(item)
    setIsOpen(true);
    setRow(item._id);
    setStatus(status);
  };

  const handleRatings = (id, status, e) => {
    e.preventDefault();
    setOpenRating(true);
    setRow(id);
    setStatus(status);
    setRatingEdit(true);
    setFormType(userType);
    setCollection(5);
    setArrivalTime(5);
    setPayment(5);
    setNegotiation(5);
    setStaff(5);
    setAvailability(5);
    setDescription("");
  };

  const viewRatings = (rating, e) => {
    e.preventDefault();
    console.log(rating);
    setRow(rating.id);
    setRatingEdit(false);
    setFormType(userType === "Seller" ? "Buyer" : "Seller");
    setCollection(rating?.collection);
    setArrivalTime(rating?.arrivalTime);
    setPayment(rating?.payment);
    setNegotiation(rating?.negotiation);
    setStaff(rating?.staff);
    setAvailability(rating?.productAvailability);
    setDescription(rating?.description);
    setOpenRating(true);
  };

  const acceptHandler = () => {
    // e.preventDefault();
    setIsLoadingConfirm(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/confirm`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        id: row,
        status: status,
        harvestDate: harvestDate,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        console.log(response);
        loadBids(values.startDate, values.endDate, selectedChips);
        setIsOpen(false);
        setHarvestDate(null);
        setRow(null);
      })
      .catch((error) => {
        console.log(error);
        setHarvestDate(null);
        setRow(null);
        setErrorBox("");
      })
      .finally(() => setIsLoadingConfirm(false));
  };

  const declineHandler = (state) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/cancel`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ id: state.target.id }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadBids(values.startDate, values.endDate, selectedChips);
      })
      .catch((error) => console.log(error));
  };

  const closeHandler = () => {
    // e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/seller-rating`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: row,
        status: status,
        collection: collection,
        arrivalTime: arrivalTime,
        payment: payment,
        negotiation: negotiation,
        staff: staff,
        description: description,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        console.log(response);
        loadBids(values.startDate, values.endDate, selectedChips);
        setIsOpen(false);
        setOpenRating(false);
        setAlert(false);
        setRow(null);
      })
      .catch((error) => {
        console.log(error);
        setHarvestDate(null);
        setRow(null);
        setErrorBox("");
      });
  };

  const buyerReviewHandler = () => {
    // e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/buyer-rating`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: row,
        availability: availability,
        negotiation: negotiation,
        description: description,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        console.log(response);
        loadBids(values.startDate, values.endDate, selectedChips);
        setIsOpen(false);
        setOpenRating(false);
        setAlert(false);
        setRow(null);
      })
      .catch((error) => {
        console.log(error);
        setHarvestDate(null);
        setRow(null);
        setErrorBox("");
      });
  };

  const ratingForm = (type) =>
    type === "Seller" ? (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>{strings.COLLECTION}</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setCollection(e)}
            edit={ratingEdit}
            value={collection}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>{strings.ARRIVAL_ON_TIME}</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setArrivalTime(e)}
            edit={ratingEdit}
            value={arrivalTime}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>{strings.PAYMENT}</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setPayment}
            edit={ratingEdit}
            value={payment}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>{strings.NEGOTIATION}</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setNegotiation}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>{strings.STAFF}</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setStaff}
            edit={ratingEdit}
            value={staff}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>{strings.PRODUCT_AVAILABILITY_RATING}</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setAvailability(e)}
            edit={ratingEdit}
            value={availability}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>
          <span>{strings.NEGOTIATION_RATING}</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setNegotiation(e)}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    );

  const loadBids = (startDate, endDate,selected) => {
    setIsLoading(true);
    const filteredStatuses = selected?.map((e) => e.key);
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/bids/${
        userType == "Buyer" ? "bids-buyer" : "bids-seller"
      }`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({
          startDate: startDate,
          endDate: addDays(endDate, 1),
          statuses: filteredStatuses,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBidsKilo(filterBids(response, "killo",filteredStatuses));
        setBidsUnits(filterBids(response, "unit",filteredStatuses));
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const filterBids = (items, unitType,filteredStatuses) => {
    return items?.filter((item) => {
      if (item.unitType !== unitType) {
        return false;
      }
      const hasRequirement = item?.requirement;
      const isExpired =
        hasRequirement &&
        item.status === "pending" &&
        daysBetween(
          new Date().toDateString(),
          new Date(item.requirement.endDate),
          1
        ) === 0;

      if (userType === "Seller" && item?.requirement?.startDate) {
        if (isExpired && subDays(item.requirement.startDate, 7) < new Date()) {
          item.status = "expired";
          return true;
        } else {
          return subDays(item.requirement.startDate, 7) < new Date();
        }
      } else {
        if (isExpired) {
          item.status = "expired";
        }
        return true;
      }
    }).filter((bid)=>filteredStatuses?.includes(bid.status));
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="h-screen flex">
      <PreLoader isLoading={isLoading || isLoadingConfirm} />
      <UserSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="bids"
      />
      <div className="flex-1 flex overflow-hidden pt-16 ">
        <div class="flex-1 overflow-y-scroll">
          <div className="flex flex-col md:flex-row md:justify-end mt-3 px-4">
            <div className="flex flex-col w-full md:w-96 mb-4 md:mr-10">
              <MultiSelect
                options={[
                  { key: "pending", value: "Pending" },
                  { key: "confirmed", value: "Confirmed" },
                  { key: "closed", value: "Closed" },
                  { key: "canceled", value: "Canceled" },
                  { key: "expired", value: "Expired" },
                ]}
                onSelect={(e) => setSelectedChips(e)}
                onRemove={(e) => setSelectedChips(e)}
                placeholder="Status"
                selectedValues={selectedChips}
              />
            </div>
            <div className="flex flex-col w-full md:w-96 mb-4 md:mr-20 border rounded-lg p-4">
              <DateFilter startDate={values?.startDate}  endDate={values?.endDate}  handleChange={handleChange} />
            </div>
          </div>
          <h3 className="text-xl font-semibold w-full text-center ">My Bids</h3>
          <div className="inline-block min-w-full sm:px-6 lg:px-8 mt-4">
            <div className="overflow-hidden flex justify-center">
              <ul
                className=" nav nav-tabs nav-justified flex flex-row flex-wrap list-none border-b-0 pl-0 w-4/5 lg:w-2/4 bg-white z-9"
                id="tabs-tabJustify"
                role="tablist"
              >
                <li
                  className="nav-item flex-grow text-center"
                  role="presentation"
                >
                  <a
                    href="#tabs-profileJustify"
                    className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                    id="tabs-profile-tabJustify"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-profileJustify"
                    role="tab"
                    aria-controls="tabs-profileJustify"
                    aria-selected="true"
                  >
                    {strings.PER_UNIT}
                  </a>
                </li>
                <li
                  className="nav-item flex-grow text-center"
                  role="presentation"
                >
                  <a
                    href="#tabs-homeJustify"
                    className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                    id="tabs-home-tabJustify"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-homeJustify"
                    role="tab"
                    aria-controls="tabs-homeJustify"
                    aria-selected="false"
                  >
                    {strings.PER_KILO}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="overflow-x-auto p-4 mt-0">
            <div class="inline-block w-full sm:px-6 lg:px-4">
              <div class="overflow-hidden">
                <div class="tab-content mt-0" id="tabs-tabContentJustify">
                  <div
                    class="tab-pane fade show active"
                    id="tabs-profileJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-profile-tabJustify"
                  >
                    <div className="items-start">
                      <DataTable
                        columns={columns}
                        data={bidsUnit}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="tabs-homeJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-home-tabJustify"
                  >
                    <div className="items-start">
                      <DataTable
                        columns={columns}
                        data={bidsKilo}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={openRating}
            handleCancel={() => setOpenRating(false)}
            handleOk={() => {
              if (ratingEdit) {
                setOpenRating(false);
                setAlert(true);
              } else {
                setOpenRating(false);
              }
            }}
            title={ratingEdit ? "Review your transaction" : "Buyer Rating"}
          >
            <>{ratingForm(formType)}</>
          </Modal>

          <Modal
            open={isOpen}
            handleCancel={() => setIsOpen(false)}
            handleOk={acceptHandler}
            isDisabled={!harvestDate || isLoading || isLoadingConfirm}
            title={
              status == "pending" ? "Edit Harvest Date" : `${strings.BID_CONFIRMATION}`
            }
          >
            <>
              {status == "confirmed" && (
                <p>
                  {strings.BID_CONFIRMATION_MSG_1}{" "}
                  <b>{selectedRow?.buyerName}</b> {strings.BID_CONFIRMATION_MSG_2}{" "}
                  <b>
                    {strings.RS} {selectedRow?.amount?.toFixed(0)}{" "}
                    <span className="text-red-500">
                      {selectedRow?.unitType == "unit"
                        ? `${strings.PER_UNIT_TXT}`
                        : `${strings.PER_KILO_TEXT}`}
                    </span>
                  </b>{" "}
                  {strings.BID_CONFIRMATION_MSG_3}
                </p>
              )}
              <input
                className={`mt-4 block py-2.5 ${
                  status == "pending" ? "mb-4" : ""
                } px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                type="date"
                name="registered_business"
                id="inlineRadio1"
                value={harvestDate}
                onChange={(e) => setHarvestDate(e.target.value)}
                min={addDays(new Date(), 1).toISOString().split("T")[0]}
                max={addDays(new Date(), 7).toISOString().split("T")[0]}
                // value={values.inlineRadioOptions2}
              />
              {status == "confirmed" && (
                <p className="text-center">{strings.SELECT_HARVEST_DATE}</p>
              )}
              {errorBox != "" ? (
                <h1 className="text-red-600">{errorBox}</h1>
              ) : (
                ""
              )}
            </>
          </Modal>

          <Modal
            open={alert}
            handleCancel={() => setAlert(false)}
            handleOk={() =>
              userType === "Seller" ? closeHandler() : buyerReviewHandler()
            }
            title={"Are you sure?"}
          >
            <>
              <p className="text-center">This step can not be undone!</p>
            </>
          </Modal>

          {/*         
            <center>
              <div class="flex justify-center items-center mt-32">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </center> */}
        </div>
      </div>
    </div>
  );
});

export default MyBids;
