import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useParams } from "react-router-dom";
import Auth, { getToken } from "../Authentication/Auth";
import { getFormattedDate, maskedUsername } from "../common/utils";
import PreLoader from "../components/PreLoader";
import strings from "../config/constants/strings";
import { Card, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";

const styles = {
  detailName: "px-4 py-2",
  detailValue: "px-4 py-2",
};

function SellerDetails() {
  const [sellerDetails, setSellerDetails] = useState({});
  const [bidDetails, setBidDetails] = useState({});
  const { id } = useParams();
  const [errorMessage, setErrors] = useState({});
  const [successMessage, setSuccess] = useState(null);
  const [bidAmount, setBidAmount] = useState("");
  const [expireDays, setExpireDays] = useState("");
  const [unitType, setUnitType] = useState("unit");
  const [starKeyForce, setStarKeyForce] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [userType, setUserType] = useState();

  useEffect(() => {
    loadSellerDetails();
  }, []);

  useEffect(() => {
    setStarKeyForce((prev) => prev + 1);
  }, [bidDetails, bidAmount]);


  const dateString = (utcDate) => {
    return new Date(utcDate);
  };

  const loadSellerDetails = () => {
    console.log(id);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/sellerDetails`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((response) => {
        setSellerDetails(response);
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(unitType);
    try {
      const requestOptions = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({
          buyerName: Auth.getUserName(),
          buyerId: Auth.getUserId(),
          sellerId: sellerDetails._id,
          sellerName: sellerDetails.name,
          amount: bidAmount,
          unitType,
        }),
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/bids/add`,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          if (res.errors != null || res.errors != undefined) {
            setErrors(res.errors);
          } else {
            alert("Bid Successfully Placed");
            const msg = {
              success: "Bid Successfully Placed",
            };
            setErrors(msg);
            setBidAmount("");
            loadSellerDetails();
          }
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      console.log(e);
    }
  };

  const handleAmount = (amt) => {
    const amount =
      amt?.indexOf(".") >= 0 ? amt?.slice(0, amt?.indexOf(".") + 3) : amt;
    setBidAmount(amount);
  };

  return (
    <>
      <PreLoader isLoading={isLoading} />
      <div className="bg-gradient-to-t from-green-50 to-gray-50 pb-16 ">
        <div className="sm:container px-4 sm:px-0 pt-28">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="order-first lg:order-last">
              <div
                className="flex flex-col justify-start sm:ml-10 md:mx-auto"
                style={{ height: 300 }}
              >
                <div className="flex flex-col w-full border rounded-3xl shadow-lg overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    src="https://images.unsplash.com/photo-1537191072641-5e19cc173c6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    alt="nike shoes"
                  />
                </div>
              </div>

              <Card className="bg-white border rounded-3xl mt-6">
                <CardContent className="p-4">
                  <h2 className="capitalize text-2xl font-bold mb-2">
                    {sellerDetails?.name?.toLowerCase()}
                  </h2>
                  <div className="flex items-center mb-2">
                    <ReactStars
                      key={`avg${starKeyForce}`}
                      count={5}
                      edit={false}
                      value={sellerDetails?.rating?.average ?? 0}
                      size={24}
                      activeColor="#ffd700"
                    />
                    <p className="text-gray-700 ml-2">
                      {sellerDetails?.rating?.average}/5
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="font-semibold">
                      {strings.AVERAGE_YIELD_PER_HARVEST}
                    </div>
                    <div>{sellerDetails?.yieldPerHarvest || 0}</div>

                    {sellerDetails?.areasToCollect &&
                      sellerDetails?.areasToCollect[0] && (
                        <>
                          <div className="font-semibold">{strings.CITY}</div>
                          <div>{sellerDetails?.areasToCollect?.join(", ")}</div>
                        </>
                      )}
                    {sellerDetails?.intervalBetweenHarvest && (
                      <>
                        <div className="font-semibold">
                          {strings.INTERVAL_DURING_HARVEST}
                        </div>
                        <div>{sellerDetails?.intervalBetweenHarvest} Days</div>
                      </>
                    )}
                    <div className="font-semibold">{strings.LAST_HARVEST}</div>
                    <div>
                      {sellerDetails?.harvest?.lastHarvest &&
                      sellerDetails?.harvest?.nextHarvest
                        ? dateString(
                            sellerDetails?.harvest?.lastHarvest
                          ).toLocaleDateString()
                        : "---"}
                    </div>
                    <div className="font-semibold">{strings.EXPIRES_ON}</div>
                    <div>
                      {sellerDetails?.harvest?.nextHarvest
                        ? dateString(
                            sellerDetails?.harvest?.nextHarvest
                          ).toLocaleDateString()
                        : "---"}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div>
              <Card className="bg-white border rounded-3xl">
                <CardContent className="p-4">
                  <h2 className="text-xl font-bold mb-2">Seller Ratings</h2>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="font-semibold">
                      {strings.PRODUCT_AVAILABILITY_RATING}
                    </div>
                    <div>
                      <ReactStars
                        key={`available${starKeyForce}`}
                        count={5}
                        edit={false}
                        value={
                          sellerDetails?.rating?.productAvailability
                            ? sellerDetails.rating.productAvailability
                            : 0
                        }
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                    <div className="font-semibold">
                      {strings.NEGOTIATION_RATING}
                    </div>
                    <div>
                      <ReactStars
                        key={`negotiation${starKeyForce}`}
                        count={5}
                        edit={false}
                        value={
                          sellerDetails?.rating?.negotiation
                            ? sellerDetails?.rating?.negotiation
                            : 0
                        }
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="mt-6 bg-white border rounded-3xl">
                <CardContent className="p-4">
                  <h2 className="text-xl font-bold mb-2">Bidding</h2>
                  {errorMessage.amount != null ? (
                    <strong className="text-red-500 text-sm">
                      {errorMessage.amount}
                    </strong>
                  ) : null}
                  {errorMessage.bid != null ? (
                    <strong className="text-red-500 text-sm">
                      {errorMessage.bid}
                    </strong>
                  ) : null}
                  {errorMessage.success != null ? (
                    <strong className="text-green-500 text-sm">
                      {errorMessage.success}
                    </strong>
                  ) : null}
                  {sellerDetails?.scaleMismatch ? (
                    <div className="mt-4 p-4 bg-red-100 rounded-xl shadow-sm">
                      <p className="text-red-500 text-lg font-bold">
                        You are not eligible to place bids
                      </p>
                      <p>
                        The scale of your business does not align with the scale
                        of the seller's business.
                      </p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-2">
                      <div className="font-semibold">
                        {strings.HIGHEST_BID_PER_UNIT}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.maxBidUnit
                          ? `${
                              strings.RS
                            } ${sellerDetails?.maxBidUnit?.amount.toFixed(2)}`
                          : "---"}
                      </div>
                      <div className="font-semibold">
                        {strings.HIGHEST_BID_BY_PER_UNIT}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.maxBidUnit
                          ? maskedUsername(sellerDetails?.maxBidUnit?.buyerName)
                          : "---"}
                      </div>
                      <div className="font-semibold">
                        {strings.HIGHEST_BID_PER_KILO}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.maxBidKilo
                          ? `${
                              strings.RS
                            } ${sellerDetails?.maxBidKilo?.amount.toFixed(2)}`
                          : "---"}
                        {sellerDetails?.maxBidKilo?.createdAt
                          ? ` [${getFormattedDate(
                              sellerDetails?.maxBidKilo?.createdAt
                            )}]`
                          : null}
                      </div>
                      <div className="font-semibold">
                        {strings.HIGHEST_BID_BY_PER_KILO}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.maxBidKilo
                          ? maskedUsername(sellerDetails?.maxBidKilo?.buyerName)
                          : "---"}
                      </div>
                      <div className="font-semibold">
                        {strings.YOUR_HIGHEST_BID_PER_UNIT}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.myMaxBidUnit
                          ? `${
                              strings.RS
                            } ${sellerDetails?.myMaxBidUnit?.amount.toFixed(2)}`
                          : "---"}
                        {sellerDetails?.maxBidKilo?.createdAt
                          ? ` [${getFormattedDate(
                              sellerDetails?.maxBidKilo?.createdAt
                            )}]`
                          : null}
                      </div>
                      <div className="font-semibold">
                        {strings.YOUR_HIGHEST_BID_PER_KILO}
                      </div>
                      <div className="font-bold">
                        {sellerDetails?.myMaxBidKilo
                          ? `${
                              strings.RS
                            } ${sellerDetails?.myMaxBidKilo?.amount.toFixed(2)}`
                          : "---"}
                      </div>
                      {!Auth.isSeller() && (
                        <>
                          <div className="font-semibold">
                            {strings.CONTACT_NUMBER}
                          </div>
                          <div className="font-bold">
                            {sellerDetails.mobile1 ? sellerDetails.mobile1 : 0}
                          </div>
                        </>
                      )}
                      {Auth.getUserLevel() === "Buyer" && (
                        <>
                          <div className="font-semibold mt-5">
                            {strings.PLACE_YOUR_BID}
                          </div>
                          <div>
                            <div className="grid sm:grid-cols-2 gap-2">
                              <input
                                type="number"
                                className="mt-2 form-control block sm:w-full h-10 px-3 py-1.5 text-base font-normal text-gray-700 bg-yellow-50 bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                value={bidAmount}
                                min={0}
                                onChange={(e) => handleAmount(e.target.value)}
                                id="exampleNumber0"
                                placeholder="0,00"
                              />
                              <select
                                type="number"
                                className="mt-2 form-control block w-full h-10 px-3 py-1.5 text-base font-normal text-gray-700 bg-yellow-50 bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                value={unitType}
                                onChange={(e) => setUnitType(e.target.value)}
                                id="exampleNumber0"
                                placeholder="0,00"
                                name="unitType"
                              >
                                <option value={"unit"}>
                                  {strings.PER_UNIT_TXT}
                                </option>
                                <option value={"killo"}>
                                  {strings.PER_KILO_TEXT}
                                </option>
                              </select>
                            </div>
                            <div className=" mt-5">
                              {strings.EXPIRE_DATE}
                            </div>
                            <div>
                              <div className="grid grid-cols-2 gap-2">
                                <input
                                  type="number"
                                  className="mt-2 form-control block sm:w-full h-10 px-3 py-1.5 text-base font-normal text-gray-700 bg-lime-50 bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  value={expireDays}
                                  min={0}
                                  onChange={(e) => setExpireDays(e.target.value)}
                                  id="exampleNumber0"
                                  placeholder="7"
                                />
                                <div className="mt-4 ml-4">Days</div>
                              </div>
                            </div>
                            {bidAmount > 10000 && (
                              <div className="col-span-3 sm:col-span-5 text-center font-medium text-red-500">
                                Bidding amount should be less than 10,000
                              </div>
                            )}
                            <Button variant="default" size="xl">
                              Bid
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerDetails;
