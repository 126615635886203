import React, { useEffect, useState } from "react";
import AdvertistmentCarousel from "../components/AdvertistmentCarousel";
import ListItemBuyer from "../components/ListItemBuyer";

import SelectBox from "../components/SelectBox";

import { districts } from "../common/districts";
import { getToken, getUserLocation } from "../Authentication/Auth";
import SelectBoxSearch from "../components/SelectBoxSearch";
import { allCities } from "../common/cities";
import strings from "../config/constants/strings";
import Modal from "../components/Modal";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { SearchBar } from "../components/SearchBar";
import { useDebounce } from "../hooks/debounce";

function SearchBuyers() {
  const scale = [
    { id: "Small", name: strings.SMALL, unavailable: false },
    { id: "Medium", name: strings.MEDIUM, unavailable: false },
    { id: "Large", name: strings.LARGE, unavailable: false },
    { id: "Any", name: strings.ANY, unavailable: false },
  ];

  const sortings = [
    {
      id: "Select Criteria",
      name: strings.SELECT_CRITERIA,
      unavailable: false,
    },
    { id: "Highest Price", name: strings.HIGHEST_PRICE, unavailable: false },
    {
      id: "Overall Highest Rating",
      name: strings.OVERALL_HIGHEST_RATING,
      unavailable: false,
    },
    {
      id: "Highest Price and Rating",
      name: strings.HIGHEST_PRICE_AND_RATING,
      unavailable: false,
    },
    {
      id: "Punctually Rating Highest",
      name: strings.PUNCTUALITY_RATING_HIGHEST,
      unavailable: false,
    },
  ];
  const [selectedDistrict, setSelectedDistrict] = useState(districts[0]);
  const [selectedScale, setSelectedScale] = useState(scale[3]);
  const [selectedSorting, setSelectedSorting] = useState(sortings[0]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState({
    label: "Any",
    value: "Any",
  });

  const [page, setPage] = useState(1);
  const [buyers, setBuyers] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [radius, setRadius] = useState(20);
  const [searchText, setSearchText] = useState("");

  const { debouncedValue } = useDebounce(searchText, 300);

  const [circleOptions, setCircleOptions] = useState({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: radius * 1000,
    zIndex: 1,
  });
  const [enableLocation, setEnableLocation] = useState(false);

  const [currentLocation, setCurrentLocation] = useState(
    getUserLocation()
      ? getUserLocation()
      : {
          lat: 6.9271,
          lng: 79.8612,
        }
  );

  const loadBuyers = (pageProp) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/buyers`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        page: pageProp,
        scale: `${selectedScale.id} Scale`,
        district: selectedDistrict.name,
        city: selectedCities.value,
        sort:
          selectedSorting.id === "Select Criteria" ? "Any" : selectedSorting.id,
        lat: enableLocation ? currentLocation.lat : undefined,
        lng: enableLocation ? currentLocation.lng : undefined,
        radius,
        searchText,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setBuyers(response);
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadBuyers(page);
  }, [page]);

  useEffect(() => {
    setPage(1);
    loadBuyers(1);
  }, [
    selectedScale,
    selectedDistrict,
    selectedCities,
    selectedSorting,
    enableLocation,
    debouncedValue,
  ]);

  useEffect(() => {
    getCities("Colombo");
  }, []);

  useEffect(() => {
    if (selectedDistrict !== "Any") {
      getCities(selectedDistrict.name);
    }
  }, [selectedDistrict]);

  const getCities = (data) => {
    const selectedDistrict = districts?.filter((dist) => dist.name === data);

    const selectedCities = allCities?.filter((city) => {
      if (city?.id === selectedDistrict[0]?.id) {
        return city;
      }
    });

    const mappedCities = selectedCities?.map((city) => ({
      label: city[strings.getLanguage()],
      value: city.name,
    }));
    setCities([
      {
        label: strings.ANY,
        value: "Any",
      },
      ...mappedCities,
    ]);
  };

  useEffect(() => {
    setCircleOptions({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: radius * 1000,
      zIndex: 1,
    });
  }, [radius]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });

  return (
    <>
      <div>
        <div className="sm:container mx-auto pt-1">
          {/* <h1 class="text-3xl font-normal leading-normal mx-auto text-center lg:mx-72 mt-28 lg:text-left">
          Search Sellers
        </h1> */}
          <div className="mt-24">
            <SearchBar
              placeholder={"Search Coconut Buyers"}
              value={searchText}
              setSearchText={setSearchText}
            />
          </div>
          <div className="flex flex-wrap mt-5 mx-10 justify-center ">
            {/* <div className="mt-2">
            <SelectBox
              list={districts}
              selected={selectedDistrict}
              setSelected={setSelectedDistrict}
              title={strings.DISTRICT}

            />
          </div>

          <div className="mt-2">
            <SelectBoxSearch
              items={cities}
              selected={selectedCities}
              onSelect={setSelectedCities}
              title={strings.CITY}
            />
          </div> */}

            <div className="flex mt-2">
              <SelectBox
                list={scale}
                selected={selectedScale}
                setSelected={setSelectedScale}
                title={strings.SCALE}
              />
            </div>
            <div className="flex  mt-2">
              <SelectBox
                list={sortings}
                selected={selectedSorting}
                setSelected={setSelectedSorting}
                title={strings.SORT_BY}
              />
            </div>
          </div>
          <div class="flex-wrap mt-8 content-start items-center	mx-auto">
            <center>
              <label for="four">
                <input
                  id="four"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setIsOpen(true);
                    } else {
                      setEnableLocation(false);
                    }
                  }}
                  checked={enableLocation}
                />
                <span className="font-semibold ">
                  {strings.ENABLE_LOCATION_BASED_SEARCHING}
                </span>
              </label>
            </center>
          </div>
          <div className="sm:container py-4 mt-4">
            {buyers?.docs ? (
              buyers?.docs?.map((e, i) => {
                return (
                  <ListItemBuyer
                    key={e._id}
                    name={e.name}
                    district={e.district}
                    scale={e.scaleOfBusiness}
                    rating={e?.rating?.average ?? null}
                    id={e._id}
                    city={e?.nearestCity?.[0]}
                  />
                );
              })
            ) : (
              <center>
                <div class="flex justify-center items-center">
                  <div
                    class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </center>
            )}

            {buyers?.length === 0 && (
              <center>
                <div class="flex justify-center items-center">
                  <p class="text-center mt-24 text-gray-600">
                    Sorry, no buyers to show currently!
                  </p>
                </div>
              </center>
            )}
          </div>
        </div>
        {buyers?.totalPages && (
          <center className="mb-8 mt-8">
            <nav aria-label="Page navigation example">
              <ul class="inline-flex -space-x-px text-base h-10">
                <li className="cursor-pointer">
                  <a
                    class="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => page !== 1 && setPage(page - 1)}
                  >
                    Previous
                  </a>
                </li>
                {Array.from(
                  {
                    length: buyers?.totalPages < 6 ? buyers?.totalPages : 5,
                  },
                  (_, i) =>
                    buyers?.page === i + 1 ? (
                      <li key={i} className="cursor-pointer">
                        <a
                          href="#"
                          aria-current="page"
                          class="flex items-center justify-center px-4 h-10 text-green-600 border border-green-300 bg-green-50 hover:bg-green-100 hover:text-green-700"
                        >
                          {i + 1}
                        </a>
                      </li>
                    ) : (
                      <li key={i} className="cursor-pointer">
                        <a
                          className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                          onClick={() => setPage(i + 1)}
                        >
                          {i + 1}
                        </a>
                      </li>
                    )
                )}
                {page > 5 && (
                  <li className="cursor-pointer">
                    <a class="flex items-center justify-center px-4 h-10 text-green-600 border border-green-300 bg-green-50 hover:bg-green-100 hover:text-green-700">
                      {page}
                    </a>
                  </li>
                )}

                <li className="cursor-pointer">
                  <a
                    class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                    onClick={() =>
                      page < buyers?.totalPages && setPage(page + 1)
                    }
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </center>
        )}
      </div>

      <Modal
        open={isOpen}
        handleCancel={() => {
          setIsOpen(false);
          setEnableLocation(false);
        }}
        handleOk={() => {
          setIsOpen(false);
          setEnableLocation(true);
          if (enableLocation) {
            loadSellers();
          }
        }}
        title={"Select Your Location"}
        full={true}
      >
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={{
            width: "100%",
            height: "300px",
          }}
          center={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          zoom={10}
        >
          {/* Child components, such as markers, info windows, etc. */}

          <>
            <Marker
              position={currentLocation}
              draggable={true}
              onDragEnd={(e) => {
                setCurrentLocation({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                });
              }}
            />
            <Circle
              center={{
                lat: currentLocation.lat,
                lng: currentLocation.lng,
              }}
              options={circleOptions}
            />
          </>
        </GoogleMap>

        <div>
          <label for="customRange1" class=" inline-block text-neutral-700 mt-4">
            Search Radius : {radius} KM
          </label>
          <input
            type="range"
            class="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600"
            id="customRange1"
            max={20}
            min={1}
            onChange={(e) => {
              setRadius(e.target.value);
            }}
            value={radius}
          />
        </div>
      </Modal>
    </>
  );
}

export default SearchBuyers;
