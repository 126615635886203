import React from "react";

export const TermsAndCondition = ({value, onChange}) => {
  return (
    <div className="flex">
      <input
        className="h-4 w-4 before:h-0 border transition duration-200 mt-1 mr-2 cursor-pointer"
        type="checkbox"
        name="read_terms"
        id="inlineRadio3"
        value={value}
        onChange={(e) => onChange(e.target.checked)}
      />
      <p>
        I have read, understood and accept this{" "}
        <a target="_blank" href="/termsAndConditions" className="text-blue-800">
          Privacy Notice
        </a>{" "}
        &{" "}
        <a target="_blank" href="/termsAndConditions" className="text-blue-800">
          Terms and Conditions
        </a>
      </p>
    </div>
  );
};
