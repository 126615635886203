import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import { getToken } from "../Authentication/Auth";
import AdminSidebar from "../components/AdminSidebar";

function Inflation() {
  const [isOpened, setIsOpened] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [perKilo, setPerKilo] = useState(0);
  const [perItem, setPerItem] = useState(0);
  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const [bids, setBids] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadInflationRates();
    loadInflatedBids();
  }, []);

  const loadInflatedBids = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/inflated`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setBids(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const loadInflationRates = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/inflationRates`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setPerItem(response?.perItem);
        setPerKilo(response?.perKilo);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inflationChange = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/updateInflation`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ perKilo: perKilo, perItem: perItem }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsEdit(false);
        alert("Successfully Updated");
      })
      .catch((error) => {
        console.log(error);
        alert("Error occurred, Try again later");
      });
  };

  const bidStatusChange = (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/inflationUpdate`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadInflatedBids();
        alert("Successfully Updated");
      })
      .catch((error) => {
        console.log(error);
        alert("Error occurred, Try again later");
      });
  };

  if (!error)
    return (
      <div className="h-screen flex">
        <AdminSidebar
          handleSidebar={handleSidebar}
          isOpened={isOpened}
          selected="inflation"
        />
        <div className="flex-1 flex overflow-hidden pt-20 ">
          <div className="flex-1 overflow-y-scroll">
            <ArrowRightIcon
              className={`w-10 ml-4 ${isOpened ? "hidden" : " md:hidden"}`}
              onClick={handleSidebar}
            />

            <h3 className="ml-8 text-xl font-semibold mb-4">
              Manage Artificial Inflation
            </h3>
            <div className="hidden flex-row justify-between  mx-20 md:flex ">
              <div className="relative z-0 mb-4 w-72 group"></div>

              <div className="flex  w-100 z-0 mb-4  group">
                <div className="w-50 ">
                  <p> Per Item</p>
                  <input
                    type="number"
                    name="floating_first_name"
                    id="floating_first_name"
                    className=" border p-2  rounded-sm text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Price per item"
                    value={perItem}
                    onChange={(val) => setPerItem(val.target.value)}
                    disabled={!isEdit}
                  />
                </div>
                <div className="w-50 ml-3">
                  <p> Per kilo</p>
                  <input
                    type="number"
                    name="floating_first_name"
                    id="floating_first_name"
                    className=" border p-2  text-sm rounded-sm text-gray-900 bg-transparent  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Price per kilo"
                    value={perKilo}
                    onChange={(val) => setPerKilo(val.target.value)}
                    disabled={!isEdit}
                  />
                </div>

                <button
                  className={` ml-5 ${
                    !isEdit
                      ? "bg-blue-300 active:bg-blue-400"
                      : "bg-green-300 active:bg-green-400"
                  }  p-2 w-20 h-10 flex self-end justify-center text-white rounded-md`}
                  onClick={() =>
                    !isEdit ? setIsEdit(!isEdit) : inflationChange()
                  }
                >
                  {!isEdit ? "Edit" : "Submit"}
                </button>
              </div>
            </div>
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  {isLoading ? (
                    <center>
                      <div className="flex justify-center items-center mt-32">
                        <div
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </center>
                  ) : (
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Buyer
                          </th>

                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      {bids && bids?.length != 0 ? (
                        <tbody>
                          {bids?.map((e, i) => {
                            return (
                              <tr key={e?.id} className="border-b">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {i + 1}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {e?.buyerName}
                                </td>

                                <td className="text-sm  px-6 py-4 whitespace-nowrap font-bold text-blue-600">
                                  {e.status == "inflated"
                                    ? "Inflated"
                                    : "Pending"}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {e?.amount}{" "}
                                  {e?.unitType === "unit"
                                    ? "Per Unit"
                                    : "Per Kilo"}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <button
                                    className="bg-red-500 p-2 text-white rounded-xl"
                                    onClick={() => bidStatusChange(e?._id)}
                                  >
                                    Change Status
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <center>
                                <div className="flex justify-center items-center mt-20">
                                  <span className="">No Records...</span>
                                </div>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <div className="h-screen flex">
        <AdminSidebar
          handleSidebar={handleSidebar}
          isOpened={isOpened}
          selected="sellers"
        />
        <div className="flex-1 flex overflow-hidden pt-20 ">
          <div className="flex-1 overflow-y-scroll">
            <ArrowRightIcon
              className={`w-10 ml-4 ${isOpened ? "hidden" : " md:hidden"}`}
              onClick={handleSidebar}
            />
          </div>
        </div>
        <h3 className="ml-8 text-xl font-semibold mb-4">
          Manage Artificial Inflation
        </h3>
        <h3 className="ml-8 text-xl font-semibold mt-48">{error}</h3>
      </div>
    );
  }
}

export default Inflation;
