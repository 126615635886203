import { ArrowRightIcon, PlusIcon} from "@heroicons/react/solid";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { getToken } from "../Authentication/Auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProgressBar from "@ramonak/react-progress-bar";
import UserSidebar from "../components/UserSidebar";
import Modal from "../components/Modal";
import { Link, useParams } from "react-router-dom";
import {addDays, getFirstDateOfMonth, subDays } from "../common/utils";
import PreLoader from "../components/PreLoader";
import strings from "../config/constants/strings";
import { DateFilter } from "../components/DateFilter/DateFilter";

const defaultValues ={
  startDate: "",
  endDate: "",
  price: 0,
  quantity: 0,
  unitType: "unit",
}

const defaultFilterValues ={
  startDate: "",
  endDate: "",
}

const Requirements = forwardRef((props, ref) => {
  const { fromSellers } = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({id:"",isOpen:false});
  const [excludeDates, setExcludeDates] = useState([]);
  const [errors, setErrors] = useState({});
  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const [requirements, setRequirements] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState(defaultValues);
  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  useEffect(() => {
    if (fromSellers) {
      setOpenModal(true);
    }
    setFilterValues({
      ...filterValues,
      startDate: getFirstDateOfMonth(),
      endDate: new Date(),
    });
  }, []);

  useEffect(() => {
    if (filterValues?.startDate) {
      loadRequirements(filterValues.startDate, filterValues.endDate);
    }
  }, [filterValues.startDate, filterValues.endDate]);

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const loadRequirements = (startDateFilter, endDateFilter) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getRequirement`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        startDate: startDateFilter?.toDateString(),
        endDate: addDays(endDateFilter, 1),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setRequirements(response);
        let dates = [];
        response?.forEach((element) => {
          dates = [
            ...dates,
            {
              start: subDays(element?.startDate, 1),
              end: new Date(element?.endDate),
            },
          ];
        });
        setExcludeDates(dates);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const addRequirement = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/requirement`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        startDate: values.startDate?.toDateString(),
        endDate: values.endDate?.toDateString(),
        quantity: values.quantity,
        price: values.price,
        unitType: values.unitType,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadRequirements(filterValues.startDate, filterValues.endDate);
        setOpenModal(false);
        setValues(defaultValues);
      })
      .catch((error) => console.log(error));
  };

  const deleteRequirement = async (id) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/requirement-cancel`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setDeleteModal({ id: "", isOpen: false });
        loadRequirements(filterValues.startDate, filterValues.endDate);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleFilterChange = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  const validate = async () => {
    const errors = {};
    let phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!values.startDate) {
      errors.startDate = "Starting date is mandatory";
    }
    if (!values.endDate) {
      errors.endDate = "Ending date is mandatory";
    }

    if (!values.quantity || values.quantity <= 0) {
      errors.quantity = "Quantity should be grater than 0";
    }

    if (!values.price || values.price <= 0) {
      errors.price = "Price should be grater than 0";
    }

    setErrors(errors);
    return errors;
  };

  const ratingForm = () => (
    <div className="grid grid-cols-5 gap-2 mx-2" autoFocus={false}>
      <div className={"text-l col-span-3"}>
        <span>Required date range *</span>
      </div>
      <div className="text-xl w-full col-span-5 ">
        <div className="flex justify-between">
          <div className="w-1/2 pr-2">
            {/* To remove auto focus issue inside modal */}
            <input type="text" className="h-0 w-0" />
            <DatePicker
              name="startDate"
              placeholderText="From"
              selected={values?.startDate}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "startDate",
                    value: e,
                  },
                })
              }
              selectsStart
              minDate={new Date()}
              excludeDateIntervals={excludeDates}
              startDate={values?.startDate}
              endDate={values?.endDate}
              className={`py-2 w-full pr-10 text-base text-gray-900 bg-transparent border-0 border-b-2 mr-2 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600  ${
                errors?.startDate ? "border-red-500" : "border-gray-300"
              }`}
            />
          </div>
          <div className="w-1/2 pl-2">
            <DatePicker
              name="endDate"
              placeholderText="To"
              selected={values?.endDate}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "endDate",
                    value: e,
                  },
                })
              }
              selectsEnd
              startDate={values?.startDate}
              endDate={values?.endDate}
              minDate={values?.startDate || new Date()}
              excludeDateIntervals={excludeDates}
              className={` py-2 pr-10 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 appearance-none ml-2 focus:outline-none focus:ring-0 focus:border-blue-600  ${
                errors?.endDate ? "border-red-500" : "border-gray-300"
              }`}
            />
          </div>
        </div>
        {errors?.startDate ? (
          <span className="text-red-600 text-sm">{errors?.startDate}</span>
        ) : (
          errors?.endDate && (
            <span className="text-red-600 text-sm">{errors?.endDate}</span>
          )
        )}
      </div>

      <div className={"text-l col-span-3 mt-4"}>
        <span>Required Quantity *</span>
      </div>
      <div className="text-xl w-full col-span-5">
        <input
          type="number"
          name="quantity"
          min={0}
          autoFocus={false}
          onChange={handleChange}
          value={values.quantity}
          className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
            errors?.quantity ? "border-red-500" : "border-gray-300"
          }`}
          placeholder=" "
        />
        {errors?.quantity && (
          <span className="text-red-600 text-sm">{errors?.quantity}</span>
        )}
      </div>
      <div className={"text-l col-span-3 mt-4"}>
        <span>Bidding Price *</span>
      </div>
      <div className="text-xl w-full col-span-5 flex justify-between">
        <input
          type="number"
          name="price"
          autoFocus={false}
          onChange={handleChange}
          min={0}
          value={values.price}
          className={`block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2  appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
            errors?.price ? "border-red-500" : "border-gray-300"
          }`}
          placeholder=" "
        />
        <p className="text-2xl mx-4 flex items-end">Rs. </p>
        <div className="w-auto">
          <div className="flex w-24">
            <input
              type="radio"
              name="unitType"
              onClick={handleChange}
              value={"unit"}
              defaultChecked
            />
            <p className="text-base ml-2">Per Unit</p>
          </div>
          <div className="flex">
            <input
              type="radio"
              name="unitType"
              onClick={handleChange}
              value={"killo"}
            />
            <p className="text-base ml-2">Per Kilo</p>
          </div>
        </div>
      </div>
      <div className={"col-span-3"}>
        {errors?.price && (
          <span className="text-red-600 text-sm w-full">{errors?.price}</span>
        )}
      </div>
    </div>
  );

  return (
    <div className="h-screen flex" autoFocus={false}>
      <PreLoader isLoading={isLoading} />
      <Modal
        open={openModal}
        handleCancel={() => setOpenModal(false)}
        submitButtonTitle={"Submit"}
        handleOk={async () => {
          const error = await validate();
          if (Object.keys(error).length === 0) {
            await addRequirement();
          }
        }}
        title={"Add a Requirement"}
      >
        <>{ratingForm()}</>
      </Modal>
      <Modal
        open={deleteModal?.isOpen}
        handleCancel={() => setDeleteModal({ id: "", isOpen: false })}
        submitButtonTitle={"Sure"}
        handleOk={async () => await deleteRequirement(deleteModal?.id)}
        title={"Are You Sure?"}
      >
        <p>
          All pending bids will be cancelled, while all confirmed bids will be
          retained.
        </p>
      </Modal>
      <UserSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="requirements"
      />
      <div className="flex-1 flex overflow-hidden pt-20 ">
        <div className="flex-1 overflow-y-scroll">
          <h3 className="ml-8 text-xl font-semibold my-4">
            Multiple Bids & Requirements
          </h3>
          <div className="flex flex-col md:flex-row  justify-between mr-8 lg:mr-20">
            <div className="hidden md:flex justify-end md:justify-start w-100 z-0 mb-4">
              <button
                className={`ml-5 bg-blue-900 active:bg-blue-400 p-2 pr-8 h-10 flex self-end text-white rounded-md`}
                onClick={() => setOpenModal(true)}
              >
                <PlusIcon className="w-6 mx-2" />
                Add
              </button>
            </div>
            <div className="flex flex-col w-full md:w-96 mx-4 mb-4 md:mr-20 border rounded-lg p-4">
              <DateFilter
                startDateName="startDate"
                endDateName="endDate"
                startDate={filterValues?.startDate}
                endDate={filterValues.endDate}
                handleChange={handleFilterChange}
              />
            </div>
            <div className="flex md:hidden justify-end md:justify-start w-100 z-0 mb-4">
              <button
                className={`ml-5 bg-blue-900 active:bg-blue-400 p-2 pr-8 h-10 flex self-end text-white rounded-md`}
                onClick={() => setOpenModal(true)}
              >
                <PlusIcon className="w-6 mx-2" />
                Add
              </button>
            </div>
          </div>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                {isLoading ? (
                  <center>
                    <div className="flex justify-center items-center mt-32">
                      <div
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </center>
                ) : (
                  <table className="min-w-full">
                    <thead className="border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {strings.FROM}
                        </th>

                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {strings.TO}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-center"
                        >
                          {strings.APPROVED_TILL_NOW}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-pre-wrap "
                        >
                          {strings.NUMBER_OF_SELLERS}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {strings.PRICE}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {strings.ACTIONS_TXT}
                        </th>
                      </tr>
                    </thead>
                    {requirements && requirements?.length != 0 ? (
                      <tbody>
                        {requirements?.map((e, i) => {
                          return (
                            <tr key={e?._id} className="border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {i + 1}
                              </td>
                              <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                                {new Date(e.startDate).toLocaleDateString()}
                              </td>

                              <td className="text-sm  px-6 py-4 text-gray-900 whitespace-nowrap">
                                {new Date(e.endDate).toLocaleDateString()}
                              </td>
                              <td className="text-sm px-6 py-4 whitespace-nowrap">
                                <ProgressBar
                                  height="13px"
                                  animateOnRender
                                  maxCompleted={100}
                                  completed={
                                    (e?.acceptedQuantity / e?.amount) * 100
                                  }
                                  customLabel={
                                    e?.acceptedQuantity + "/" + e?.amount
                                  }
                                  labelAlignment="outside"
                                  labelColor="black"
                                  labelSize="13px"
                                />
                              </td>
                              <td className="text-sm px-6 py-4 font-semibold text-center">
                                {e.approved + "/" + e.sellers}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {e?.price}{" "}
                                {e?.unitType === "unit"
                                  ? "Per Unit"
                                  : "Per Kilo"}
                              </td>
                              <td className="text-sm px-6 py-4 flex align-middle">
                                {subDays(e.startDate, 7) < new Date() &&
                                  addDays(e.endDate, 1) > new Date() && (
                                    <Link to={`/sellers${e?._id}`}>
                                      <button
                                        className={`${
                                          e.sellers === 0
                                            ? "bg-green-700 mr-1.5"
                                            : "bg-emerald-800 mr-1.5"
                                        } p-2 text-white rounded-xl`}
                                      >
                                        Add Sellers
                                      </button>
                                    </Link>
                                  )}
                                <button
                                  className={`bg-red-600 text-white ml-2 p-1.5 rounded-xl`}
                                  onClick={async () =>
                                    setDeleteModal({ id: e?._id, isOpen: true })
                                  }
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <center>
                              <div className="flex justify-center items-center mt-20">
                                <span className="">No Records...</span>
                              </div>
                            </center>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Requirements;
