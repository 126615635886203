import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    //BuyerDetails page
    COLLECTION: "Collection(Pickiness)",
    ARRIVAL_TIME: "Arrival Time",
    PAYMENT: "Payment",
    NEGOTIATION: "Negotiation",
    RATINGS: "Ratings",
    ASKING_PRICE: "Asking Price",
    ESTIMATED_HARVEST: "Estimated Harvest",
    EXPIRE_DATE: "Expire in ",

    //SellerDetails page
    AVERAGE_YIELD_PER_HARVEST: "Average yield per harvest",
    CITY: "City",
    INTERVAL_DURING_HARVEST: "Interval during harvest",
    LAST_HARVEST: "Last harvest",
    EXPIRES_ON: "Expires on",
    PRODUCT_AVAILABILITY_RATING: "Product availability rating",
    NEGOTIATION_RATING: "Negotiation rating",
    HIGHEST_BID_PER_UNIT: "Highest bid (Per Unit)",
    HIGHEST_BID_BY_PER_UNIT: "Highest bid by (Per Unit)",
    HIGHEST_BID_PER_KILO: "Highest bid (Per Kilo)",
    HIGHEST_BID_BY_PER_KILO: "Highest bid by (Per Kilo)",
    YOUR_HIGHEST_BID_PER_UNIT: "Your highest bid(Per Unit)",
    YOUR_HIGHEST_BID_PER_KILO: "Your highest bid(Per Kilo)",
    CONTACT_NUMBER: "Contact number",
    PLACE_YOUR_BID: "Place your bid",

    //home
    NUMBER_OF_BUYERS: "Number of Buyers",
    NUMBER_OF_SELLERS: "Number of Sellers",
    MAXIMUM_ASKING_PRICE: "Maximum Asking Price",
    NO_HARVEST_DATE_MSG:
      "Please provide us with your next harvest date to make the advertisement appear automatically!",

    //nav bar
    HOME: "Home",
    SELLERS: "Sellers",
    BUYERS: "Buyers",
    MY_OFFERS: "My Offers",
    HARVEST: "Harvest",

    //harvest date page
    NEXT_HARVEST_DATE: "Next Harvest Date",
    NEXT_DATE: "Next Date",
    APPEARS_ON: "Advertisement appears on",
    EDIT: "EDIT",

    //search buyers
    DISTRICT: "District",
    CITY: "City",
    LOCATION: "Location",
    SCALE: "Scale",
    SORT_BY: "Sort By",
    SMALL: "Small",
    MEDIUM: "Medium",
    LARGE: "Large",
    ANY: "Any",
    SELECT_CRITERIA: "Select Criteria",
    HIGHEST_PRICE: "Highest Price",
    HIGHEST_PRICE_BY_UNIT: "Highest Price per Unit",
    OVERALL_HIGHEST_RATING: "Overall Highest Rating",
    HIGHEST_PRICE_AND_RATING: "Highest Price and Rating",
    PUNCTUALITY_RATING_HIGHEST: "Punctuality Rating Highest",
    ENABLE_LOCATION_BASED_SEARCHING: "Enable Location Based Searching",

    //search Sellers
    BID_MULTIPLE_SET_REQUIRED_AMOUNT: "Bid Multiple & Set Required Amount",

    //userSidebar
    BIDS: "Bids",
    OFFERS: "Offers",
    BIDS: "Bids",
    OFFERS: "Offers",
    REQUIREMENTS: "Requirements",
    TRANSACTIONS: "Transactions",
    SEARCH_HISTORY: "Search History",

    //MyBids
    PRICE: "Price",
    RS: "Rs.",
    MY_BID: "My Bid",
    MY_BIDS: "My Bids",
    CURRENT_HIGHEST_BID: "Current Highest Bid",
    BUYER: "Buyer",
    SELLER: "Seller",
    STATUS: "Status",
    HARVEST_DATE: "Harvest Date",
    APPROXIMATE_HARVEST: "Approximate \nHarvest",
    SEE_MORE: "See more",
    ACTIONS: "Actions",
    PER_UNIT: "Per unit",
    PER_KILO: "Per kilo",
    ACQUIRED_TILL_NOW: "Acquired Till \nNow",
    REQUIREMENT_ENDS_IN: "Requirement \nEnds In",
    CONFIRM: "Confirm",
    EDIT_HARVEST_DATE: "Edit harvest date",
    CLOSE: "Close",
    BID_CONFIRMATION: "Bid Confirmation",
    SELECT_HARVEST_DATE: "Select the harvest date",
    BID_CONFIRMATION_MSG_1:
      "Do you wish to sell your harvest of this period to buyer",
    BID_CONFIRMATION_MSG_2: "at",
    BID_CONFIRMATION_MSG_3: "on the following date?",
    COLLECTION_PICKINESS: "Collection (Pickiness)",
    ARRIVAL_ON_TIME: "Arrival on time",
    PAYMENT: "Payment",
    NEGOTIATION: "Negotiation",
    STAFF: "Staff",
    PER_UNIT_TXT: "Per Unit",
    PER_KILO_TEXT: "Per kilo",

    //transactions
    QUANTITY: "Quantity",
    BUYER_NAME: "Buyer Name",
    TRANSACTION_PRICE: "Transaction Price",
    MY_RATING: "My Rating",
    TIME_PERIOD: "Time period",
    REQUIRED_QUANTITY: "Required quantity",
    PURCHASED_QUANTITY: "Purchased quantity",
    PURCHASED_PRICE: "Purchased price",

    //Requirements
    FROM: "From",
    TO: "To",
    APPROVED_TILL_NOW: "Approved \ntill now",
    ACTIONS_TXT: "Actions",
  },
  si: {
    //BuyerDetails page
    COLLECTION: "තේරීම",
    ARRIVAL_TIME: "පැමිණීමේ වේලාව",
    PAYMENT: "ගෙවීම",
    NEGOTIATION: "මිල සාකච්ඡා කිරීම",
    RATINGS: "ශ්රේණිගත කිරීම",
    ASKING_PRICE: "ඉල්ලූ මිල",
    ESTIMATED_HARVEST: "අපේක්ශිත අස්වැන්න",

    //SellerDetails page
    AVERAGE_YIELD_PER_HARVEST: "අපේක්ශිත අස්වැන්න",
    CITY: "ගම/නගරය",
    INTERVAL_DURING_HARVEST: "පොල්මුර අතර කාල පරාසය",
    LAST_HARVEST: "අවසාන අස්වනු දිනය",
    EXPIRES_ON: "දැන්වීම අවසාන වීමට දින",
    PRODUCT_AVAILABILITY_RATING:
      "ගැනුම් කරුවා පැමිනෙන විට පලදාව එක්‍ රැස් කර තිබුනාද?",
    NEGOTIATION_RATING: "පොරොන්දු වූ මිල වෙනස් කිරීමට තැත් කලාද?",
    HIGHEST_BID_PER_UNIT: "උපරිම ලන්සුව ( ගෙඩියකට )",
    HIGHEST_BID_BY_PER_UNIT: "උපරිම ලන්සුව දුන් ගැනුම්කරු ( ගෙඩියකට )",
    HIGHEST_BID_PER_KILO: "උපරිම ලන්සුව ( කිලෝවකට )",
    HIGHEST_BID_BY_PER_KILO: "උපරිම ලන්සුව දුන් ගැනුම්කරු ( කිලෝවකට )",
    YOUR_HIGHEST_BID_PER_UNIT: "මගේ උපරිම ලන්සුව ( ගෙඩියකට )",
    YOUR_HIGHEST_BID_PER_KILO: "මගේ උපරිම ලන්සුව ( කිලෝවකට )",
    CONTACT_NUMBER: "ජංගම දුරකතන අංකය",
    PLACE_YOUR_BID: "Place your bid",

    //home
    NUMBER_OF_BUYERS: "මුලු ගැනුම් කරුවන් ගණන",
    NUMBER_OF_SELLERS: "මුලු විකුනුම් කරුවන් ගණන",
    MAXIMUM_ASKING_PRICE: "උපරිම ලන්සුව",
    NO_HARVEST_DATE_MSG:
      "ඔබගේ දැන්වීම අප වෙබ්පිටුවේ පලවීමට ඔබගේ මීලඟ අස්වනු දිනය අප හට ලබා දෙන්න!",

    //nav bar
    HOME: "ප්‍රධාන පිටුව",
    SELLERS: "විකුනුම් කරුවන්",
    BUYERS: "ගැනුම් කරුවන්",
    MY_OFFERS: "ලැබී ඇති ලන්සු",
    HARVEST: "අස්වනු දිනය",

    //harvest date page
    NEXT_HARVEST_DATE: "ඊලඟ අස්වනු දිනය",
    NEXT_DATE: "ඊලඟ දිනය",
    APPEARS_ON: "දැන්වීම නිකුත් වන දිනය",
    EDIT: "වෙනස් කරන්න",

    //search buyers
    DISTRICT: "දිස්ත්‍රික්කය",
    CITY: "ගම/නගරය",
    SCALE: "ප්‍රමානය",
    SORT_BY: "හදුනා ගැනීමේ පිලිවෙල",
    SMALL: "කුඩා",
    MEDIUM: "මධ්යම",
    LARGE: "විශාල",
    ANY: "ඕනෑම",
    SELECT_CRITERIA: "අවශ්‍යය ආකාරය තෝරාගන්න",
    HIGHEST_PRICE: "වැඩිම මුදලින්",
    OVERALL_HIGHEST_RATING: "හොදම ඇගයීම්",
    HIGHEST_PRICE_AND_RATING: "වැඩිම මුදල හා හොඳම ඇගයීම්",
    PUNCTUALITY_RATING_HIGHEST: "නිසි කලට වේලාවට පැමිනීම හොදම",
    ENABLE_LOCATION_BASED_SEARCHING: "ස්ථාන පිහිටීම අනුව සෙවීම",

    //search Sellers
    BID_MULTIPLE_SET_REQUIRED_AMOUNT:
      "කාල සීමාවක් හා අවශ්‍ය ප්‍රමානයක් සමඟ එකවර ලන්සු තැබීමට",

    //userSidebar
    BIDS: "ලැබුනු ලන්සු",
    OFFERS: "අප යෝජනා කල ලන්සු",
    BIDS: "ලැබුනු ලන්සු",
    OFFERS: "අප යෝජනා කල ලන්සු",
    REQUIREMENTS: "අවශ්‍යතා",
    TRANSACTIONS: "සිදු වූ ගනුදෙනු",
    SEARCH_HISTORY: "Search History",

    //MyBids
    PRICE: "මිල",
    RS: "රු.",
    MY_BID: "ලන්සු",
    MY_BIDS: "මගේ ලන්සු",
    CURRENT_HIGHEST_BID: "දැනට උපරිම ලන්සුව",
    BUYER: "ගැනුම් කරු",
    SELLER: "විකුනුම්කරු",
    STATUS: "ලන්සුවේ තත්වය",
    HARVEST_DATE: "අස්වනු දිනය",
    APPROXIMATE_HARVEST: "අපේක්ශිත \nඅස්වැන්න",
    SEE_MORE: "වැඩි තොරතුරු",
    ACTIONS: "සම්මත කිරීම්",
    PER_UNIT: "පොල් ගෙඩි සඳහා ලැබුනු ලන්සු",
    PER_KILO: "පොල් කිලෝ බර සඳහා ලැබුනු ලන්සු",
    ACQUIRED_TILL_NOW: "දැනට එකතු වී \nඇති ප්‍රමානය",
    REQUIREMENT_ENDS_IN: "අවශ්‍යතාවය අවසාන \nවීමට ඇති දින",
    CONFIRM: "සනාථ කරන්න",
    EDIT_HARVEST_DATE: "දිනය වෙනස් කරන්න",
    CLOSE: "විකිනීම සනාථ කරන්න",
    BID_CONFIRMATION: "ලන්සුව තහවුරු කිරීම",
    SELECT_HARVEST_DATE: "අස්වනු දිනය ලබා දෙන්න",
    BID_CONFIRMATION_MSG_1: "ඔබගේ අස්වැන්න",
    BID_CONFIRMATION_MSG_2: "හට",
    BID_CONFIRMATION_MSG_3: "ලබාදීමට එකඟද?",
    COLLECTION_PICKINESS: "තේරීම",
    ARRIVAL_ON_TIME: "වේලාවට පැමිනීම",
    PAYMENT: "ගෙවීම",
    NEGOTIATION: "කේවල් කිරීම",
    STAFF: "සේවකයන්",
    PER_UNIT_TXT: "කට ගෙඩියක්",
    PER_KILO_TEXT: "කට කිලෝවක්",

    //transactions
    QUANTITY: "අස්වනු ප්‍රමානය",
    BUYER_NAME: "ගැනුම් කරුගේ නම",
    TRANSACTION_PRICE: "ගෙඩියක හෝ කිලෝවක මිල",
    MY_RATING: "මා ලබා දුන් ඇගයුම",
    TIME_PERIOD: "කාල පරාසය",
    REQUIRED_QUANTITY: "අවශ්‍යවූ ප්‍රමානය",
    PURCHASED_QUANTITY: "මිලදීගත් ප්‍රමානය\n(දල වශයෙන්)",
    PURCHASED_PRICE: "මිලදී ගත් මිල",

    //Requirements
    FROM: "ඇරඹුම් දිනය",
    TO: "අවසන් වන \nදිනය",
    APPROVED_TILL_NOW: "දැනට එකතුවී \nඇති ප්‍රමානය",
    ACTIONS_TXT: "ක්‍රියාමාර්ග",
  },
});

export default strings;
