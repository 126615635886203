import { getUserLevel } from "../Authentication/Auth";
import { Navigate } from "react-router-dom";
import { USER_TYPES } from "../config/constants/general";

export function RequireOfficer({ children, redirectTo }) {
  let isAuth = getUserLevel();
  console.log(isAuth);
  return isAuth == USER_TYPES.OFFICER ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}
