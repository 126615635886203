import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useState } from "react";
import { districts } from "../common/districts";
import { MultiSelect } from "react-multi-select-component";
import { allCities } from "../common/cities";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import SelectBoxRegister from "../components/SelectBoxRegister";
import PreLoader from "../components/PreLoader";
import { TermsAndCondition } from "../components/TermsAndCondition/TermsAndCondition";
import Modal from "../components/Modal";

function RegisterBuyer(params) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isBusiness, setisBusiness] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [cities, setCities] = useState("");
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 6.9271,
    lng: 79.8612,
  });
  const [selected, setSelected] = useState();
  const [loadingCities, setLoadingCities] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setCurrentLocation({ lat: latitude, lng: longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const getCities = () => {
    setLoadingCities(true);
    console.log("fetching", currentLocation);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/home/cities`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        lat: currentLocation.lat,
        lng: currentLocation.lng,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        const mappedCities = response?.map((city) => ({
          label: city,
          value: city,
        }));
        console.log(mappedCities);
        setLoadingCities(false);

        setCities(mappedCities);
      })
      .catch((error) => {
        setLoadingCities(false);
        console.log(error);
      });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });

  const addToRequirement = (id) => {
    let local = addRequirements;
    if (!local?.includes(id)) {
      local = [...addRequirements, id];
    } else {
      local = local?.filter((item) => item !== id);
    }
    setAddRequirements(local);
  };

  const showBusiness = () => {
    setisBusiness(true);
  };
  const hideBusiness = () => {
    setisBusiness(false);
  };

  // useEffect(() => {
  //   getCities(city);
  // }, [city]);

  useEffect(() => {
    if (state.code) {
      getLocation(state.code);
    }
  }, [state.code]);

  const getLocation = async (code) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          code: code,
        }),
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/referral/location`,
        requestOptions
      )
        .then((res) => res.json())
        .then((item) => {
          setCity(item?.district);
          setSelectedCities(
            item?.cities?.map((city) => ({
              label: city,
              value: city,
            }))
          );
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="lg:flex ">
       <Modal
        open={alert}
        isSingleButton={true}
        handleCancel={() => setAlert(false)}
        handleOk={() => navigate("/login")}
        title={"Registration Successful!"}
      >
        <>
          <p className="text-center">
            You have have registered successfully. Please login with your email
            address and your password
          </p>
        </>
      </Modal>
      <PreLoader isLoading={isLoading} />
      <div className="lg:w-4/5 xl:max-w-screen-sm ">
        <div className="h-10"></div>
        <div class="flex-1 mt-4 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-12 xl:max-w-2xl overflow-y-auto">
          <h2
            className="text-center text-4xl text-green-900  font-semibold lg:text-left xl:text-3xl
          xl:text-bold mb-1"
          >
            Registration
          </h2>
          <h2
            className="text-center text-xl text-green-600  font-semibold lg:text-left 
          xl:text-bold mb-4"
          >
            Buyer
          </h2>
          <Formik
            initialValues={{
              scale: "Medium Scale",
              registered_business: "false",
              provide_tree_climber: "false",
              receive_calls: "false",
              district: "Colombo",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={(values) => {
              const errors = {};
              // if (!values.inheritor_name) {
              //   errors.inheritorName = "Inheritor Name is Required*";
              // }
              // if (!values.inheritor_phone) {
              //   errors.inheritorMobile = "Inheritor Mobile is Required*";
              // }
              // if (!values.lname) {
              //   errors.lname = "Last Name is Required*";
              // }
              // if (!values.utype || values.utype == "Select") {
              //   errors.utype = "Please Select Buyer or Seller*";
              // }
              // if (!values.province || values.province == "Select") {
              //   errors.province = "Please Select Province*";
              // }
              // if (!values.mobile) {
              //   errors.mobile = "Mobile Number is Required*";
              // }

              // if (!values.landLine) {
              //   errors.landLine = "Land Line is Required*";
              // }

              // //email
              // if (!values.email) {
              //   errors.email = "Email is Required";
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = "Invalid email address";
              // }
              if (!selected) {
                errors.nearestCity = "Select at least one nearest city";
              }

              // if (!values.passwordConfirm) {
              //   errors.passwordConfirm = "Confirm Password Is Required*";
              // } else if (values.password != values.passwordConfirm) {
              //   errors.passwordConfirm = "Passwords Not Matching*";
              // }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setIsLoading(true);
                const request = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    name: state.fname + " " + state.lname,
                    type: state.utype,
                    email: state.email,
                    password: state.password,
                    mobile1: state.mobile,
                    mobile2: state.altMobile,
                    landLine: state.landLine,
                  }),
                };
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/api/users/initial-validate`,
                  request
                )
                  .then((res) => res.json())
                  .then(async (item) => {
                    if ("errors" in item && "email" in item.errors) {
                      setEmailError(item.errors.email);
                    } else if ("success" in item) {
                      const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          name: state.fname + " " + state.lname,
                          type: state.utype,
                          email: state.email,
                          password: state.password,
                          mobile1: state.mobile,
                          mobile2: state.altMobile,
                          landLine: state.landLine,
                          code: state?.code || "",
                          //Buyer
                          scaleOfBusiness: values.scale,
                          canProvideTreeClimbers: values.provide_tree_climber,
                          isRegisteredBusiness: values.registered_business,
                          businessName: values.business_name,
                          alternateMobile: values.alternative_phone,
                          alternateMobile2: values.alternative_phone1,
                          recieveCalls: values.receive_calls,
                          inheritorName: values.inheritor_name,
                          inheritorMobile: values.inheritor_phone,
                          inheritorAltMobile: values.inheritor_phone_alt,
                          aditionalInfo: values.additional_info,
                          nearestCity: selected.value,
                          //seller
                          sizeOfLand: "",
                          yieldPerHarvest: "",
                          totalHarvest: "",
                          intervalBetweenHarvest: "",
                          recieveEmails: "",
                          dirstrict: "",
                          noOfTrees: "",
                          areasToCollect: "",
                          orgType: "",
                          orgName: "",
                          location: {
                            lat: currentLocation.lat,
                            lng: currentLocation.lng,
                          },
                        }),
                      };
                      const result = await fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/api/users/signup`,
                        requestOptions
                      );
                      if (result) {
                        setAlert(true);
                      }
                    }
                  })
                  .finally(() => setIsLoading(false));
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap mx-auto mb-8 ">
                  <a
                    className="
                    inline-flex
                    items-center
                    justify-center
                    w-1/2
                    py-3
                    font-medium
                    leading-none
                    tracking-wider
                    border-b-2 border-gray-200
                    sm:px-6 sm:w-auto sm:justify-start
                    hover:text-gray-900
                  "
                    href="/register"
                  >
                    STEP 1
                  </a>
                  <a
                    className="
                    inline-flex
                    items-center
                    justify-center
                    w-1/2
                    py-3
                    font-medium
                    leading-none
                    tracking-wider
                    text-indigo-500
                    bg-gray-100
                    border-b-2 border-indigo-500
                    rounded-t
                    sm:px-6 sm:w-auto sm:justify-start
                  "
                  >
                    STEP 2
                  </a>
                </div>
                <div className="hidden xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-4 w-full group">
                    <select
                      name="scale"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                      id="grid-state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.scale}
                    >
                      <option value="Small Scale">Small Scale</option>
                      <option value="Medium Scale">Medium Scale</option>
                      <option value="Large Scale">Large Scale</option>
                    </select>
                    <label
                      for="floating_first_name"
                      className="absolute text-xl  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 mt-1"
                    >
                      Scale Of Plantation*
                    </label>
                  </div>
                </div>

                <div className="flex justify-between">
                  <p className="text-left leading-4 mt-1">
                    Can Your provide tree climber for buyers?
                  </p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="provide_tree_climber"
                        id="inlineRadio1"
                        value="true"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.inlineRadioOptions}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio10"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="provide_tree_climber"
                        id="inlineRadio2"
                        value="false"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultChecked
                        // value={values.inlineRadioOptions}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio20"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between mt-2 mb">
                  <p className="text-left leading-4 mt-1">
                    Are you a registered Business?
                  </p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="registered_business"
                        id="inlineRadio1"
                        value="true"
                        onClick={showBusiness}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.inlineRadioOptions2}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio10"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="registered_business"
                        id="inlineRadio2"
                        // value="option2"
                        onClick={hideBusiness}
                        defaultChecked
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value="true"
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio20"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {isBusiness ? (
                  <div className="relative z-0 mb-6 mt-2 w-full group">
                    <input
                      type="text"
                      name="business_name"
                      id="floating_repeat_password"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.business_name}
                      required
                    />
                    <label
                      for="floating_repeat_password"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Business Name
                    </label>
                  </div>
                ) : null}

                <div className="grid xl:grid-cols-2 xl:gap-6 mt-8">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="alternative_phone"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.alternative_phone}
                    />
                    <label
                      for="floating_first_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Alternative Contact Number
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="alternative_phone1"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.alternative_phone1}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Alternative Contact Number 2
                    </label>
                  </div>
                </div>

                <p className="text-md  text-gray-500 dark:text-gray-400 mt-1 mb-2">
                  Select your location*
                </p>
                <div
                  style={{ height: "300px", width: "100%" }}
                  className="mb-4"
                >
                  {isLoaded ? (
                    <GoogleMap
                      options={{
                        mapTypeControl: false,
                        streetViewControl: false,
                      }}
                      mapContainerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      center={{
                        lat: currentLocation.lat,
                        lng: currentLocation.lng,
                      }}
                      zoom={10}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <Marker
                        position={currentLocation}
                        draggable={true}
                        onDragEnd={(e) => {
                          setCurrentLocation({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          });
                        }}
                      />
                    </GoogleMap>
                  ) : (
                    <div>Loading</div>
                  )}
                  <h2 className="relative z-50 bottom-10 text-center text-white font-semibold">
                    Drag the drop pin to your location.
                  </h2>
                </div>

                <div className="relative mb-6 group">
                  {/* <input
                    type="text"
                    name="collecting_form_area"
                    id="floating_repeat_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.collecting_form_area}
                    required
                  /> */}
                  <div className="relative w-full mt-4 bg-white mb-2 overflow-x-clip">
                    <label
                      for="floating_repeat_password"
                      className=" text-sm text-gray-500 dark:text-gray-400 "
                    >
                      {errors.nearestCity ? (
                        <span className="text-red-600">
                          {errors.nearestCity}
                        </span>
                      ) : (
                        "Nearest City*"
                      )}
                    </label>
                    <SelectBoxRegister
                      items={cities}
                      value={selected}
                      onSelect={setSelected}
                      labelledBy="Select"
                      className="mt-4"
                      onClickBtn={getCities}
                      loading={loadingCities}
                    />
                  </div>
                </div>

                <div className="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="inheritor_name"
                    id="floating_repeat_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.inheritor_name}
                  />
                  <label
                    for="floating_repeat_password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    {errors.inheritorName ? (
                      <span className="text-red-600">
                        {errors?.inheritorName}
                      </span>
                    ) : (
                      "Inheritor Name"
                    )}
                  </label>
                </div>
                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="inheritor_phone"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inheritor_phone}
                    />
                    <label
                      for="floating_first_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.inheritorMobile ? (
                        <span className="text-red-600">
                          {errors?.inheritorMobile}
                        </span>
                      ) : (
                        "Inheritor Mobile"
                      )}
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="inheritor_phone_alt"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inheritor_phone_alt}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Inheritor Alt Mobile
                    </label>
                  </div>
                </div>

                <div className="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="additional_info"
                    id="floating_repeat_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.additional_info}
                  />
                  <label
                    for="floating_repeat_password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Additional Info
                  </label>
                </div>
                <div className="flex justify-between">
                  <p className="text-left leading-4 mt-1">
                    Would you like to receive calls from potential Sellers?
                  </p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="receive_calls"
                        id="inlineRadio1"
                        value="true"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.receive_calls}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio10"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="receive_calls"
                        id="inlineRadio2"
                        value="false"
                        defaultChecked
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio20"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <TermsAndCondition onChange={setIsAcceptedTerms}  />
                </div>
                {emailError ? (
                  <p className="text-red-500">{emailError} !</p>
                ) : null}
                <button
                  disabled={isLoading || !isAcceptedTerms}
                  type="submit"
                  className="text-white bg-green-700 hover:bg-green-800 disabled:bg-slate-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center my-4"
                >
                  Finish and Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-green-100 flex-1 h-auto">
        <img
          className="h-full object-cover "
          src="https://images.unsplash.com/photo-1553787434-dd9eb4ea4d0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="nike shoes"
        />
      </div>
    </div>
  );
}

export default RegisterBuyer;
