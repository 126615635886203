import React from "react";
import { Outlet } from "react-router";
import Nav from "../Nav";
import Footer from "../Footer/Footer";
// import NavBarNewUI from "../Navbar";

export default ({ page, handleSidebar, isSidebar }) => {
  return (
    <>
      <Nav page={page} handleSidebar={handleSidebar} isSidebar={isSidebar} />
      {/* <NavBarNewUI /> */}
      <div className="h-5 sm:hidden" />
      <Outlet  />
      <div className="h-32 mt-12 sm:hidden" />

      <Footer />
    </>
  );
};
