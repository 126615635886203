import { ClipboardCopyIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { MyAccountExtrasContainer } from "../components/Cards/MyAccountExtrasContainer";
import { getToken, isBuyer, isOfficer, isSeller } from "../Authentication/Auth";
import { InputOne } from "../components/Inputs/InputOne";
import PreLoader from "../components/PreLoader";

function MyAccount() {
  const isSellerVar = isSeller();
  const isBuyerVar = isBuyer();
  const isOfficerVar = isOfficer();
  const [loading, setLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [extras, setExtras] = useState();
  const [user, setUser] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    !isEdit && loadUserData();
  }, [isEdit]);

  useEffect(() => {
    validate();
  }, [user]);

  useEffect(() => {
    (isSellerVar || isBuyerVar) && loadExtras();
  }, []);

  const loadUserData = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/user`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setUser(response);
        console.log(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const loadExtras = () => {
    setIsSkeleton(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/myAccount/extras`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setExtras(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsSkeleton(false));
  };

  const updatedUser = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/myAccount`, {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        mobile: user?.mobile,
        approximateHarvest: user?.approximateHarvest,
        approximateTrees: user?.approximateTrees,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadUserData();
        setIsEdit(false);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const validate = async () => {
    const errors = {};

    let phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!user.mobile) {
      errors.mobile = "Mobile is mandatory*.";
    } else if (!user.mobile.toString().match(phoneNo)) {
      errors.mobile = "Mobile Number format is incorrect";
    }
    if (isSellerVar) {
      if (!user.approximateHarvest || user.approximateHarvest <= 0) {
        errors.approximateHarvest = "Yield Per Harvest Is Required*";
      }
      if (!user.approximateTrees || user.approximateTrees <= 0) {
        errors.approximateTrees = "Number of Trees Is Required*";
      }
    }
    setErrors(errors);
    return errors;
  };

  return (
    <div className=" py-1 pt-16 bg-blueGray-50">
      <PreLoader isLoading={loading} />
      <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-4">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold mt-2">
                My Account
              </h6>

              {isOfficerVar && user?.referral ? (
                <button
                  class="bg-green-600 pl-6 pr-3 py-3 flex font-semibold text-white rounded rounded-tr-lg rounded-bl-3xl"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `${window.location.host}/register/${user?.referral}`
                      )
                      .then(() =>
                        alert("Successfully copied to the clipboard!")
                      );
                  }}
                >
                  My Referral Code :
                  <span className="underline ml-1">{user?.referral}</span>{" "}
                  <ClipboardCopyIcon className="w-5 ml-2 text-blue-" />
                </button>
              ) : (
                <button class="bg-amber-700 px-6 py-3 font-semibold text-white rounded rounded-tr-lg rounded-bl-3xl">
                  You are on trial period
                </button>
              )}
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user?.name?.split(" ")[0]}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user?.name?.split(" ")[1]}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Email address
                  </label>
                  <input
                    type="email"
                    disabled={true}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user?.email}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <InputOne
                  label="Mobile Number"
                  name="mobile"
                  isDisable={!isEdit}
                  value={user?.mobile}
                  onChange={handleChange}
                  error={errors?.mobile}
                />
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <InputOne
                  label="Nearest City"
                  isDisable={true}
                  value={user?.nearestCity?.join(", ")}
                  onChange={handleChange}
                />
              </div>
            </div>

            {isSellerVar && (
              <>
                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Harvest Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <InputOne
                      type="number"
                      label="Approximate harvest"
                      name="approximateHarvest"
                      isDisable={!isEdit}
                      value={user?.approximateHarvest}
                      onChange={handleChange}
                      error={errors?.approximateHarvest}
                    />
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <InputOne
                      type="number"
                      label="Approximate trees"
                      name="approximateTrees"
                      isDisable={!isEdit}
                      value={user?.approximateTrees}
                      onChange={handleChange}
                      error={errors?.approximateTrees}
                    />
                  </div>
                </div>
              </>
            )}

            <hr className="my-6 border-b-1 border-blueGray-300 " />

            <div className="flex items-center justify-center">
              <div className="container mx-auto">
                <MyAccountExtrasContainer
                  isLoading={isSkeleton}
                  data={extras}
                />
              </div>
            </div>
            <div className="text-center mt-6 flex justify-end">
              {isEdit ? (
                <>
                  <button
                    className="bg-green-400 mr-3 text-white active:bg-green-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-green-600 text-white disabled:bg-slate-300 active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={Object.keys(errors).length}
                    onClick={() => updatedUser()}
                  >
                    Update
                  </button>
                </>
              ) : (
                <button
                  className="bg-orange-400 disabled:bg-slate-500 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
