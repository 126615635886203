import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import AdvertistmentCarousel from "../components/AdvertistmentCarousel";
import ListItemAd from "../components/ListItemAd";
import ListItemAdSellers from "../components/ListItemAdSeller";
import ListItemOffer from "../components/ListItemOffer";
import ListItemSeller from "../components/ListItemSeller";

import SelectBox from "../components/SelectBox";
import DataTable from "react-data-table-component";
import ReactStars from "react-rating-stars-component";
import UserSidebar from "../components/UserSidebar";
import Auth, { getToken } from "../Authentication/Auth";
import { Dialog } from "@headlessui/react";
import Modal from "../components/Modal";
import strings from "../config/constants/strings";
const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#effce0",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      backgroundColor: "#effce0",
    },
  },
};

const MyOffers = forwardRef((props,ref) => {
  const [formType, setFormType] = useState(Auth.getUserLevel());
  const [userType, setUserType] = useState(Auth.getUserLevel());
  const [isOpened, setIsOpened] = useState(false);
  const [offers, setOffers] = useState([]);
  const [errorBox, setErrorBox] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState(null);
  const [harvestDate, setHarvestDate] = useState(null);
  const [status, setStatus] = useState("");
  const [openRating, setOpenRating] = useState(false);
  const [alert, setAlert] = useState(false);
  const [ratingEdit, setRatingEdit] = useState(true);
  const [collection, setCollection] = useState(5);
  const [arrivalTime, setArrivalTime] = useState(5);
  const [payment, setPayment] = useState(5);
  const [negotiation, setNegotiation] = useState(5);
  const [staff, setStaff] = useState(5);
  const [availability, setAvailability] = useState(5);
  const [description, setDescription] = useState("");

  const handleAccept = (state) => {
    console.log("clicked accepted");
    console.log(state.target.id);
    setIsOpen(true);
    setRow(state);
  };

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const acceptHandler = () => {
    console.log("clicked accepted");
    console.log(row.target.id);

    if (harvestDate == null || harvestDate == "") {
      setErrorBox("Invalid Date");
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/confirm`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ id: row.target.id, date: harvestDate }),
    })
      .then((res) => {
        console.log(res);
      })
      .then((response) => {
        console.log(response);
        setIsOpen(false);
        setHarvestDate(null);
        setRow(null);
        loadSellerOffers();
        setErrorBox("");
      })
      .catch((error) => {
        console.log(error);
        setHarvestDate(null);
        setErrorBox("");
      });
  };

  const declineHandler = (state) => {
    console.log("clicked decline");
    console.log(state.target.id);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/cancel`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ id: state.target.id }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadSellerOffers();
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const closeHandler = () => {
    // e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/seller-rating`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: row,
        status: status,
        collection: collection,
        arrivalTime: arrivalTime,
        payment: payment,
        negotiation: negotiation,
        staff: staff,
        description: description,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadSellerOffers();
        setIsOpen(false);
        setOpenRating(false);
        setAlert(false);
        setRow(null);
      })
      .catch((error) => {
        console.log(error);
        setHarvestDate(null);
        setRow(null);
        setErrorBox("");
      });
  };

  const ratingForm = (type) =>
    type === "Seller" ? (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Collection (Pickiness)</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setCollection(e)}
            edit={ratingEdit}
            value={collection}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Arrival on time</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setArrivalTime(e)}
            edit={ratingEdit}
            value={arrivalTime}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Payment</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setPayment}
            edit={ratingEdit}
            value={payment}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Negotiation</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setNegotiation}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>Staff</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setStaff}
            edit={ratingEdit}
            value={staff}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Product availability</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setAvailability(e)}
            edit={ratingEdit}
            value={availability}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>
          <span>Negotiation after arrival</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setNegotiation(e)}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    );

  const handleRatings = (id, status, e) => {
    e.preventDefault();
    setOpenRating(true);
    setRow(id);
    setStatus(status);
    setRatingEdit(true);
    setFormType(userType);
    setCollection(5);
    setArrivalTime(5);
    setPayment(5);
    setNegotiation(5);
    setStaff(5);
    setAvailability(5);
    setDescription("");
  };

  const viewRatings = (rating, e) => {
    e.preventDefault();
    console.log(rating);
    setRow(rating.id);
    setRatingEdit(false);
    setFormType(userType === "Seller" ? "Buyer" : "Seller");
    setCollection(rating?.collection);
    setArrivalTime(rating?.arrivalTime);
    setPayment(rating?.payment);
    setNegotiation(rating?.negotiation);
    setStaff(rating?.staff);
    setAvailability(rating?.productAvailability);
    setDescription(rating?.description);
    setOpenRating(true);
  };

  const columns = [
    {
      name: `${strings.PRICE}`,
      selector: (row) => row.amount + " Rs / Per " + row?.unitType,
      sortable: true,
    },
    {
      name: `${strings.BUYER}`,
      cell: (row) => (
        <a
          className=" text-black cursor-pointer "
          onClick={acceptHandler}
          href={`viewBuyer${row.buyerId}`}
        >
          {row.buyerName}
        </a>
      ),
    },
    {
      name: `${strings.STATUS}`,
      selector: (row) => (
        <h4
          className={
            row.status == "pending"
              ? "text-yellow-600 capitalize font-semibold"
              : row.status == "canceled"
              ? "text-red-600 capitalize font-semibold"
              : row.status == "confirmed"
              ? "text-green-600 capitalize font-semibold"
              : row.status == "expired"
              ? "text-gray-600 capitalize font-semibold"
              : row.status == "accepted"
              ? "text-blue-600 capitalize font-semibold"
              : "capitalize"
          }
        >
          {row.status == "accepted" ? (
            <span>
              Pending <br />
              Confirmation
            </span>
          ) : (
            row.status
          )}
        </h4>
      ),
    },
    // {
    //   name: "Scale",
    //   selector: (row) => row.scale,
    //   sortable: true,
    // },
    // {
    //   name: "District",
    //   selector: (row) => row.district,
    //   sortable: true,
    // },
    {
      name: `${strings.ACTIONS}`,
      cell: (row) =>
        row.status == "accepted" ? (
          <button
            className="bg-green-600 p-2 rounded-2xl text-white "
            onClick={handleAccept}
            id={row._id}
          >
            Confirm
          </button>
        ) : row.status === "confirmed" ? (
          <div className="flex flex-col">
            <button
              className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
              onClick={(e) => handleRatings(row._id, "closed", e)}
              id={row._id}
            >
              Review
            </button>
          </div>
        ) : row.status === "closed" && row?.buyerRating ? (
          // <h1> N/A</h1>
          <button
            className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
            onClick={(e) => viewRatings(row?.buyerRating, e)}
            id={row._id}
          >
            View Buyer Rating
          </button>
        ) : row.status == "pending" ? (
          <button
            className="bg-red-500 p-2 rounded-2xl text-white "
            onClick={declineHandler}
            id={row._id}
          >
            Cancel
          </button>
        ) : (
          <h1> N/A</h1>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) =>
        row.status == "accepted" ? (
          <button
            className="bg-red-500 p-2 rounded-2xl text-white "
            onClick={declineHandler}
            id={row._id}
          >
            Cancel
          </button>
        ) : (
          <h1> </h1>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const loadSellerOffers = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/offers-seller`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ id: Auth.getUserId() }),
    })
      .then((res) => res.json())
      .then((response) => {
        setOffers(response);
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    loadSellerOffers();
  }, []);

  return (
    <div className="h-screen flex">
      <UserSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="offers"
      />
      <div className="flex-1 flex overflow-hidden pt-16 ">
        <div class="flex-1 overflow-y-scroll">
          <br />
          <div>
            <h3 className=" text-2xl font-semibold mb-4 mt-4 w-full justify-center  text-center">
              My Offers
            </h3>
            <div className="w-4/5 mx-auto ">
              <DataTable
                columns={columns}
                data={offers}
                customStyles={customStyles}
              />
            </div>
          </div>
          <Modal
            open={openRating}
            handleCancel={() => setOpenRating(false)}
            handleOk={() => {
              if (ratingEdit) {
                setOpenRating(false);
                setAlert(true);
              } else {
                setOpenRating(false);
              }
            }}
            title={"Review your Transaction"}
          >
            <>{ratingForm(formType)}</>
          </Modal>

          <Modal
            open={alert}
            handleCancel={() => setAlert(false)}
            handleOk={closeHandler}
            title={"Are you sure?"}
          >
            <>
              <p className="text-center">This step can not be undone!</p>
            </>
          </Modal>

          {/*         
            <center>
              <div class="flex justify-center items-center mt-32">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </center> */}
        </div>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-white rounded max-w-sm mx-auto p-10">
            <Dialog.Title className="font-semibold">
              Select Harvest Date
            </Dialog.Title>
            <Dialog.Description>
              <input
                className="my-4 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                type="date"
                name="registered_business"
                id="inlineRadio1"
                value={harvestDate}
                onChange={(e) => setHarvestDate(e.target.value)}

                // value={values.inlineRadioOptions2}
              />
              {errorBox != "" ? (
                <h1 className="text-red-600">{errorBox}</h1>
              ) : (
                ""
              )}
              <div>
                <button
                  className="bg-green-300 m-3 px-4 py-2"
                  onClick={acceptHandler}
                >
                  OK
                </button>
                <button
                  className="bg-red-300 m-3 mt-4 px-4 py-2"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Description>
          </div>
        </div>
      </Dialog>
    </div>
  );
});

export default MyOffers;
