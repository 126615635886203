import { getToken } from "../Authentication/Auth";

export const getNotifications = async () => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications`, {
    method: "GET",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    }),
  })
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => console.log(error));
};

export const submitClick = async (id) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications`, {
    method: "PUT",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    }),
    body: JSON.stringify({
      id: id,
    }),
  })
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => console.log(error));
};



