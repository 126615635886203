export const districts = [
  {
    id: 0,
    name: "All",
    unavailable: false,
    si: "සෑම",
    en: "All",
  },
  {
    id: 1,
    name: "Ampara",
    unavailable: false,
    si: "අම්පාර",
    en: "Ampara",
  },
  {
    id: 2,
    name: "Anuradhapura",
    unavailable: false,
    si: "අනුරාධපුර",
    en: "Anuradhapura",
  },
  {
    id: 3,
    name: "Badulla",
    unavailable: false,
    si: "බදුල්ල",
    en: "Badulla",
  },
  {
    id: 4,
    name: "Batticaloa",
    unavailable: false,
    si: "මඩකලපුව",
    en: "Batticaloa",
  },
  {
    id: 5,
    name: "Colombo",
    unavailable: false,
    si: "කොළඹ",
    en: "Colombo",
  },
  {
    id: 6,
    name: "Galle",
    unavailable: false,
    si: "ගාල්ල",
    en: "Galle",
  },
  {
    id: 7,
    name: "Gampaha",
    unavailable: false,
    si: "ගම්පහ",
    en: "Gampaha",
  },
  {
    id: 8,
    name: "Hambantota",
    unavailable: false,
    si: "හම්බන්තොට",
    en: "Hambantota",
  },
  {
    id: 9,
    name: "Jaffna",
    unavailable: false,
    si: "යාපනය",
    en: "Jaffna",
  },
  {
    id: 10,
    name: "Kalutara",
    unavailable: false,
    si: "කළුතර",
    en: "Kalutara",
  },
  {
    id: 11,
    name: "Kandy",
    unavailable: false,
    si: "මහනුවර",
    en: "Kandy",
  },
  {
    id: 12,
    name: "Kegalle",
    unavailable: false,
    si: "කෑගල්ල",
    en: "Kegalle",
  },
  {
    id: 13,
    name: "Kilinochchi",
    unavailable: false,
    si: "කිලිනොච්චි",
    en: "Kilinochchi",
  },
  {
    id: 14,
    name: "Kurunegala",
    unavailable: false,
    si: "කුරුණෑගල",
    en: "Kurunegala",
  },
  {
    id: 15,
    name: "Mannar",
    unavailable: false,
    si: "මන්නාරම",
    en: "Mannar",
  },
  {
    id: 16,
    name: "Matale",
    unavailable: false,
    si: "මාතලේ",
    en: "Matale",
  },
  {
    id: 17,
    name: "Matara",
    unavailable: false,
    si: "මාතර",
    en: "Matara",
  },
  {
    id: 18,
    name: "Moneragala",
    unavailable: false,
    si: "මොණරාගල",
    en: "Moneragala",
  },
  {
    id: 19,
    name: "Mullaitivu",
    unavailable: false,
    si: "මුලතිව්",
    en: "Mullaitivu",
  },
  {
    id: 20,
    name: "Nuwara Eliya",
    unavailable: false,
    si: "නුවරඑලිය",
    en: "Nuwara Eliya",
  },
  {
    id: 21,
    name: "Polonnaruwa",
    unavailable: false,
    si: "පොළොන්නරුව",
    en: "Polonnaruwa",
  },
  {
    id: 22,
    name: "Puttalam",
    unavailable: false,
    si: "පුත්තලම",
    en: "Puttalam",
  },
  {
    id: 23,
    name: "Ratnapura",
    unavailable: false,
    si: "රත්නපුරය",
    en: "Ratnapura",
  },
  {
    id: 24,
    name: "Trincomalee",
    unavailable: false,
    si: "ත්රිකුණාමලය",
    en: "Trincomalee",
  },
  {
    id: 25,
    name: "Vavuniya",
    unavailable: false,
    si: "වවුනියාව",
    en: "Vavuniya",
  },
];
