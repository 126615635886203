import { React } from "react";

const TermsAndConditions = () => {
  return (
    <div className="">
      <iframe
        className="fixed w-full h-full pt-10 px-4"
        src="terms.html"
      ></iframe>
    </div>
  );
};

export default TermsAndConditions;
