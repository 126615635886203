import Multiselect from 'multiselect-react-dropdown';
import React from 'react'
import "./multiselect.css";

const MultiSelect = ({options,onSelect,onRemove,selectedValues,placeholder}) => {
  return (
    <Multiselect
      displayValue="value"
      id="css_custom"
    //   onKeyPressFn={function noRefCheck() {}}
      onRemove={onRemove}
    //   onSearch={function noRefCheck() {}}
      onSelect={onSelect}
      options={options}
      selectedValues={selectedValues}
      placeholder={placeholder}
      showArrow={true}
      avoidHighlightFirstOption={true}
      keepSearchTerm={false}
      style={{
        chips: {
          // background: "#BFD4FD",
          // color:'black',
          fontSize:"14px",
        },
        multiselectContainer: {
          // color: "red",
        },
        searchBox: {
          padding: "16px",
          // border: "none",
          // "border-bottom": "1px solid blue",
          "border-radius": "8px",
        },
      }}
    />
  );
};

export default MultiSelect