import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import React from "react";
import ReactStars from "react-rating-stars-component";
import { daysBetween } from "../common/utils";
import image2 from "../assets/tb2.png";
import strings from "../config/constants/strings";

function ListItemSeller({
  name,
  district,
  scale,
  price,
  rating,
  id,
  harvest,
  isRequirementEnabled,
  isSelected,
  clickBtn,
  isDisabled,
  isNew,
  isOld,
  city,
  yieldPerHarvest,
  highestBid,
}) {
  return (
    <a href={!isRequirementEnabled ? "/viewSeller" + id : null}>
      <div className="flex justify-center mb-4  hover:cursor-pointer ">
        <div
          className={`flex flex-col p-4 sm:p-0 sm:flex-row w-screen sm:w-7/12 rounded-lg shadow-lg ${
            isSelected ? "border-2 border-blue-400" : ""
          } lg:h-40 md:h-40 hover:shadow-xl`}
        >
          <img
            class="h-56 lg:h-auto md:h-auto object-cover  sm:w-32 rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={image2}
            alt=""
          />
          <div class="px-4 py-2 flex flex-col justify-start w-4/5 ">
            <div class="flex justify-between ">
              <div>
                <h5 class="text-gray-900 text-lg font-semibold text-transform: capitalize">
                  {name.toLowerCase()}
                </h5>
                <h5 class="text-gray-900 text-base font-medium ">{price}</h5>
                <div className="flex flex-col md:flex-col sm:flex-col lg:flex-row">
                  {city && (
                    <h5 class="text-gray-900 text-base  font-medium flex lg:mr-4">
                      <span className="font-semibold mr-1">
                        {strings.CITY}:
                      </span>{" "}
                      {city}
                    </h5>
                  )}
                  {/* <h5 class="text-gray-900 text-base  font-medium flex ">
                    <span className="font-semibold mr-1">Scale: </span> {scale}
                  </h5> */}
                </div>

                {yieldPerHarvest && (
                  <h5 class="text-gray-900 text-base  font-medium flex lg:mr-4">
                    <span className="font-semibold mr-1">
                      {strings.ESTIMATED_HARVEST}:
                    </span>{" "}
                    {yieldPerHarvest} {" units"}
                  </h5>
                )}
                {harvest?.nextHarvest && (
                  <h5 class="text-gray-900 text-base  font-medium flex">
                    <span className="mr-1 font-semibold">Next Harvest in </span>{" "}
                    {daysBetween(new Date(), new Date(harvest.nextHarvest), 1) +
                      " days"}
                  </h5>
                )}
                {highestBid && highestBid > 0 ? (
                  <h5 class="text-gray-900 text-base  font-medium flex">
                    <span className="mr-1 font-semibold">Highest Bid </span>{" "}
                    {highestBid} per unit
                  </h5>
                ) : null}

                <div className="mt-0">
                  {rating ? (
                    <ReactStars
                      count={5}
                      // onChange={ratingChanged}
                      value={parseInt(rating)}
                      edit={false}
                      size={24}
                      activeColor="#ffd700"
                    />
                  ) : (
                    <div>
                      <p className="text-gray-500 text-base  font-medium flex ">
                        No Ratings Available
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end content-end">
                {isRequirementEnabled && isDisabled && !isOld ? (
                  <button onClick={clickBtn}>
                    {isSelected ? (
                      <MinusCircleIcon className="w-8 mx-2" />
                    ) : (
                      <PlusCircleIcon className="w-8 mx-2" />
                    )}
                  </button>
                ) : null}
                {isRequirementEnabled && !isDisabled && (
                  <button onClick={clickBtn}>
                    {isSelected ? (
                      <MinusCircleIcon className="w-8 mx-2" />
                    ) : (
                      <PlusCircleIcon className="w-8 mx-2" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default ListItemSeller;
