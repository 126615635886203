import React from "react";

export const MyAccountExtrasCard = ({ title, value }) => {
  return (
    <div class="bg-white rounded-lg border p-2 flex items-center justify-center">
      <div class="px-1 py-2">
        <div class="font-semibold text-lg text-center">{title}</div>
        <p class="font-bold text-xs text-center">-</p>
        <p class="text-gray-700 text-base text-center">{value}</p>
      </div>
    </div>
  );
};
