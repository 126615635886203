import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Auth, { getToken, getUserLevel } from "../Authentication/Auth";
import "react-datepicker/dist/react-datepicker.css";
import ReactStars from "react-rating-stars-component";
import UserSidebar from "../components/UserSidebar";
import Modal from "../components/Modal";
import { Link, useParams } from "react-router-dom";
import PreLoader from "../components/PreLoader";
import strings from "../config/constants/strings";
import { DateFilter } from "../components/DateFilter/DateFilter";
import { getFirstDateOfMonth } from "../common/utils";

const defaultValues = {
  startDate: "",
  endDate: "",
};

const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};

const Transactions = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [bids, setBids] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState(Auth.getUserLevel());

  const [openRating, setOpenRating] = useState(false);
  const [ratingEdit, setRatingEdit] = useState(true);
  const [collection, setCollection] = useState(5);
  const [arrivalTime, setArrivalTime] = useState(5);
  const [payment, setPayment] = useState(5);
  const [negotiation, setNegotiation] = useState(5);
  const [staff, setStaff] = useState(5);
  const [availability, setAvailability] = useState(5);
  const [description, setDescription] = useState("");
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setValues({
      ...values,
      startDate: getFirstDateOfMonth(),
      endDate: new Date(),
    });
  }, []);

  useEffect(() => {
    if (values?.startDate) {
      loadTransaction(values.startDate, values.endDate);
    }
  }, [values.startDate, values.endDate]);

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const loadTransaction = (startDate, endDate) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/transactions`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        startDate: startDate?.toDateString(),
        endDate: endDate?.toDateString(),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setBids(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const ratingForm = (type) =>
    type === "Seller" ? (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Collection (Pickiness)</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setCollection(e)}
            edit={ratingEdit}
            value={collection}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Arrival on time</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setArrivalTime(e)}
            edit={ratingEdit}
            value={arrivalTime}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Payment</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setPayment}
            edit={ratingEdit}
            value={payment}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Negotiation</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setNegotiation}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>Staff</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setStaff}
            edit={ratingEdit}
            value={staff}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Product availability</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setAvailability(e)}
            edit={ratingEdit}
            value={availability}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>
          <span>Negotiation after arrival</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setNegotiation(e)}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    );

  const viewRatings = (rating, e) => {
    e.preventDefault();
    setRatingEdit(false);
    setFormType("Seller");
    setCollection(rating?.collection);
    setArrivalTime(rating?.arrivalTime);
    setPayment(rating?.payment);
    setNegotiation(rating?.negotiation);
    setStaff(rating?.staff);
    setAvailability(rating?.productAvailability);
    setDescription(rating?.description);
    setOpenRating(true);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <PreLoader isLoading={isLoading} />
      <div className="h-screen flex" autoFocus={false}>
        <Modal
          open={openRating}
          handleCancel={() => setOpenRating(false)}
          handleOk={() => setOpenRating(false)}
          title={"Your rating"}
        >
          <>{ratingForm(formType)}</>
        </Modal>
        <UserSidebar
          handleSidebar={handleSidebar}
          isOpened={isOpened}
          selected="transactions"
        />
        <div className="flex-1 flex overflow-hidden mt-4 pt-20 ">
          <div className="flex-1 overflow-y-scroll">
            <div className="flex flex-col md:flex-row md:justify-between ">
              <h3 className="ml-8 text-xl font-semibold mb-4">
                {strings.TRANSACTIONS}
              </h3>
              <div className="flex flex-col w-full md:w-96 mx-4 mb-4 md:mr-20 border rounded-lg p-4">
                <DateFilter
                  startDate={values?.startDate}
                  endDate={values.endDate}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  {getUserLevel() == "Buyer" ? (
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.TIME_PERIOD}
                          </th>

                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.REQUIRED_QUANTITY}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.PURCHASED_QUANTITY}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-pre-wrap "
                          >
                            {strings.PURCHASED_PRICE}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.ACTIONS_TXT}
                          </th>
                        </tr>
                      </thead>
                      {bids && bids?.length != 0 ? (
                        <tbody>
                          {bids?.map((e, i) => {
                            return (
                              <tr key={e?._id} className="border-b">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {i + 1}
                                </td>
                                <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                                  {e?.requirement
                                    ? `${new Date(
                                        e?.requirement.startDate
                                      ).toLocaleDateString()} - ${new Date(
                                        e?.requirement.endDate
                                      ).toLocaleDateString()}`
                                    : e?.createdAt
                                    ? new Date(
                                        e?.createdAt
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>

                                <td className="text-sm  px-6 py-4 text-gray-900 whitespace-nowrap">
                                  {e?.requirement?.quantity || "-"}
                                </td>
                                <td className="text-sm px-6 py-4 whitespace-nowrap">
                                  {e?.requirement?.acceptedQuantity || "-"}
                                </td>
                                <td className="text-sm px-6 py-4 font-semibold text-center">
                                  {e?.amount}{" "}
                                  {e?.unitType === "unit"
                                    ? "Per Unit"
                                    : "Per Kilo"}
                                </td>
                                <td className="text-sm px-6 py-4 flex align-middle">
                                  <Link to={`/transactions${e?._id}`}>
                                    <button
                                      className={`${
                                        e.sellers === 0
                                          ? "bg-green-700 mr-1.5"
                                          : "bg-emerald-800 mr-1.5"
                                      } p-2 text-white rounded-xl`}
                                    >
                                      See seller ratings
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <center>
                                <div className="flex justify-center items-center mt-20">
                                  <span className="">No Records...</span>
                                </div>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  ) : (
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.HARVEST_DATE}
                          </th>

                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.QUANTITY}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.BUYER_NAME}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-pre-wrap "
                          >
                            {strings.TRANSACTION_PRICE}
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            {strings.MY_RATING}
                          </th>
                        </tr>
                      </thead>
                      {bids && bids?.length != 0 ? (
                        <tbody>
                          {bids?.map((e, i) => {
                            return (
                              <tr key={e?._id} className="border-b">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {i + 1}
                                </td>
                                <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">
                                  {e?.harvestDate
                                    ? new Date(
                                        e?.harvestDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>

                                <td className="text-sm  px-6 py-4 text-gray-900 whitespace-nowrap">
                                  {e?.quantity || "-"}
                                </td>
                                <td className="text-sm px-6 py-4 whitespace-nowrap">
                                  {e?.buyerName || "-"}
                                </td>
                                <td className="text-sm px-6 py-4 font-semibold text-center">
                                  {e?.amount}{" "}
                                  {e?.unitType === "unit"
                                    ? "Per Unit"
                                    : "Per Kilo"}
                                </td>
                                <td className="text-sm px-6 py-4 flex align-middle">
                                  {e?.sellerRating && (
                                    <button
                                      onClick={(item) =>
                                        viewRatings(e.sellerRating, item)
                                      }
                                      className={`${
                                        e.sellers === 0
                                          ? "bg-green-700 mr-1.5"
                                          : "bg-emerald-800 mr-1.5"
                                      } p-2 text-white rounded-xl`}
                                    >
                                      See ratings
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <center>
                                <div className="flex justify-center items-center mt-20">
                                  <span className="">No Records...</span>
                                </div>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Transactions;
