import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import AdvertistmentCarousel from "../components/AdvertistmentCarousel";

import DataTable from "react-data-table-component";
import { ArrowRightIcon, ArrowSmRightIcon } from "@heroicons/react/solid";
import UserSidebar from "../components/UserSidebar";
import Auth, { getToken } from "../Authentication/Auth";
import ReactStars from "react-rating-stars-component";
import { Dialog } from "@headlessui/react";
import Modal from "../components/Modal";
import { addDays, getFirstDateOfMonth, slashDate } from "../common/utils";
import DatePicker from "react-datepicker";
import MultiSelect from "../components/MultiSelect/MultiSelect";
import PreLoader from "../components/PreLoader";
const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#effce0",
      minWidth: "20px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      backgroundColor: "#effce0",
    },
  },
};

const data = [
  {
    id: 1,
    price: 200,
    name: "Jayantha Perera",
    scale: "Large",
    district: "Colombo",
  },
  {
    id: 3,
    price: 220,
    name: "Nimal Perera",
    scale: "Large",
    district: "Colombo",
  },
];


const defaultValues ={
  startDate: "",
  endDate: "",
}

const MyOffersBuyer = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState([]);
  const [userType, setUserType] = useState(Auth.getUserLevel());
  const [formType, setFormType] = useState(Auth.getUserLevel());
  const [row, setRow] = useState(null);
  const [openRating, setOpenRating] = useState(false);
  const [alert, setAlert] = useState(false);
  const [ratingEdit, setRatingEdit] = useState(true);
  const [collection, setCollection] = useState(5);
  const [arrivalTime, setArrivalTime] = useState(5);
  const [payment, setPayment] = useState(5);
  const [negotiation, setNegotiation] = useState(5);
  const [staff, setStaff] = useState(5);
  const [availability, setAvailability] = useState(5);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [values, setValues] = useState(defaultValues);
  const [selectedChips, setSelectedChips] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const acceptHandler = (row) => {
    console.log("clicked accepted");
    console.log(row.target.id);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/offers/accept-offer-buyer`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ id: row.target.id }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        loadBuyersOffers(values.startDate, values.endDate, selectedChips);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const declineHandler = (state) => {
    console.log("clicked decline");
    console.log(state.target.id);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/cancel`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ id: state.target.id }),
    })
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        loadBuyersOffers(values.startDate, values.endDate, selectedChips);
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  const loadBuyersOffers = (startDate, endDate, selected) => {
    setIsLoading(true);
    const filteredStatuses = selected?.map((e) => e.key);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/offers-buyer`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        startDate: startDate,
        endDate: addDays(endDate, 1),
        statuses: filteredStatuses,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setOffers(response);
      })
      .catch((error) => console.log(error)).finally(()=>setIsLoading(false));
  };

  const buyerReviewHandler = () => {
    // e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/offers/buyer-rating`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: row,
        availability: availability,
        negotiation: negotiation,
        description: description,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setError(response);
        loadBuyersOffers(values.startDate, values.endDate, selectedChips);
        setOpenRating(false);
        setAlert(false);
        setRow(null);
      })
      .catch((error) => {
        console.log(error);
        setRow(null);
      });
  };

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));


  useEffect(() => {
    setValues({
      ...values,
      startDate: getFirstDateOfMonth(),
      endDate: new Date(),
    });
    setSelectedChips([
      { key: "pending", value: "Pending" },
      { key: "accepted", value: "Accepted" },
      { key: "confirmed", value: "Confirmed" },
      { key: "closed", value: "Closed" },
      { key: "canceled", value: "Canceled" },
    ])
  }, []);


  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (values?.startDate && selectedChips) {
      loadBuyersOffers(values.startDate, values.endDate, selectedChips);
    }
  }, [values.startDate, values.endDate, selectedChips]);


  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const columns = [
    {
      name: "Price",
      selector: (row) => row.amount,
      cell: (row) => (
        <p className="text-base whitespace-pre-wrap">
          {row.amount + " Rs / Per " + row?.unitType}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => (
        <a
          className="text-black whitespace-pre-wrap cursor-pointer "
          onClick={acceptHandler}
          href={`viewBuyer${row.buyerId}`}
        >
          {row.buyerName}
        </a>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <h4
          className={
            row.status == "pending"
              ? "text-yellow-600 capitalize font-semibold"
              : row.status == "canceled"
              ? "text-red-600 capitalize font-semibold"
              : row.status == "confirmed"
              ? "text-green-600 capitalize font-semibold"
              : row.status == "expired"
              ? "text-gray-600 capitalize font-semibold"
              : row.status == "accepted"
              ? "text-blue-600 capitalize font-semibold"
              : "capitalize"
          }
        >
          {row.status == "accepted" ? (
            <span>
              Pending <br />
              Confirmation
            </span>
          ) : (
            row.status
          )}
        </h4>
      ),
      minWidth: true,
    },
    {
      name: <p className="text-base whitespace-pre-wrap">Harvest Date</p>,
      selector: (row) => row.date,
      cell: (row) => (
        <p className="text-base whitespace-pre min-w-full">
          {slashDate(row.date)}
        </p>
      ),
      sortable: true,
      minWidth: true,
    },
    // {
    //   name: "Scale",
    //   selector: (row) => row.scale,
    //   sortable: true,
    // },
    // {
    //   name: "District",
    //   selector: (row) => row.district,
    //   sortable: true,
    // },
    {
      name: "Actions",
      cell: (row) =>
        row.status == "pending" ? (
          <button
            className="bg-green-600 p-2 rounded-2xl text-white "
            onClick={acceptHandler}
            id={row._id}
          >
            Accept
          </button>
        ) : row.status === "closed" && row?.buyerRating && row?.sellerRating ? (
          // <h1> N/A</h1>
          <button
            className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
            onClick={(e) => viewRatings(row?.sellerRating, e)}
            id={row._id}
          >
            View Buyer Rating
          </button>
        ) : row.status === "closed" ? (
          <div className="flex flex-col">
            <button
              className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
              onClick={(e) => handleRatings(row._id, "closed", e)}
              id={row._id}
            >
              Review
            </button>
          </div>
        ) : (
          <h1> N/A</h1>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) =>
        row.status == "pending" ? (
          <button
            className="bg-red-600 p-2 rounded-2xl text-white "
            onClick={declineHandler}
            id={row._id}
          >
            Decline
          </button>
        ) : (
          <h1> </h1>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const ratingForm = (type) =>
    type === "Seller" ? (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Collection (Pickiness)</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setCollection(e)}
            edit={ratingEdit}
            value={collection}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Arrival on time</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setArrivalTime(e)}
            edit={ratingEdit}
            value={arrivalTime}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Payment</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setPayment}
            edit={ratingEdit}
            value={payment}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Negotiation</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setNegotiation}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>Staff</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setStaff}
            edit={ratingEdit}
            value={staff}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Product availability</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setAvailability(e)}
            edit={ratingEdit}
            value={availability}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>
          <span>Negotiation after arrival</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setNegotiation(e)}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    );

  const handleRatings = (id, status, e) => {
    e.preventDefault();
    setOpenRating(true);
    setRow(id);
    setRatingEdit(true);
    setFormType(userType);
    setCollection(5);
    setArrivalTime(5);
    setPayment(5);
    setNegotiation(5);
    setStaff(5);
    setAvailability(5);
    setDescription("");
  };

  const viewRatings = (rating, e) => {
    e.preventDefault();
    setRow(rating.id);
    setRatingEdit(false);
    setFormType(userType === "Seller" ? "Buyer" : "Seller");
    setCollection(rating?.collection);
    setArrivalTime(rating?.arrivalTime);
    setPayment(rating?.payment);
    setNegotiation(rating?.negotiation);
    setStaff(rating?.staff);
    setAvailability(rating?.productAvailability);
    setDescription(rating?.description);
    setOpenRating(true);
  };

  return (
    <div className="h-screen flex">
      <PreLoader isLoading={isLoading} />
      <UserSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="offers"
      />
      <div className="flex-1 flex overflow-hidden pt-16 ">
        <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col md:flex-row md:justify-end mt-3 px-4">
            <div className="flex flex-col w-full md:w-96 mb-4 md:mr-10">
              <MultiSelect
                options={[
                  { key: "pending", value: "Pending" },
                  { key: "accepted", value: "Accepted" },
                  { key: "confirmed", value: "Confirmed" },
                  { key: "closed", value: "Closed" },
                  { key: "canceled", value: "Canceled" },
                ]}
                onSelect={(e) => setSelectedChips(e)}
                onRemove={(e) => setSelectedChips(e)}
                placeholder="Status"
                selectedValues={selectedChips}
              />
            </div>
            <div className="flex flex-col w-full md:w-96 mb-4 md:mr-20 border rounded-lg p-4">
              <div>
                <p className="pl-2"> Date Range</p>
              </div>
              <div className="flex ">
                <div className="w-2/5">
                  <DatePicker
                    showYearDropdown
                    showIcon
                    name="startDate"
                    placeholderText="From Date"
                    dateFormat={"dd/MM/yyyy"}
                    selected={values?.startDate}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "startDate",
                          value: e,
                        },
                      })
                    }
                    selectsStart
                    // minDate={new Date()}
                    maxDate={new Date()}
                    startDate={getFirstDateOfMonth()}
                    endDate={values?.endDate}
                    yearDropdownItemNumber={3}
                    dropdownMode="scroll"
                    className="p-2 rounded-sm text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                </div>
                <div className="w-1/5">
                  <ArrowSmRightIcon className="w-8 mx-2" />
                </div>
                <div className="w-2/5">
                  <DatePicker
                    dateFormat={"dd/MM/yyyy"}
                    showIcon
                    name="endDate"
                    placeholderText="To Date"
                    selected={values?.endDate}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "endDate",
                          value: e,
                        },
                      })
                    }
                    selectsEnd
                    startDate={values?.startDate}
                    endDate={values?.endDate}
                    minDate={values?.startDate || new Date()}
                    maxDate={new Date()}
                    className="p-2 rounded-sm text-sm text-gray-900 bg-transparent border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4 mt-0 w-full justify-center  text-center">
              My Offers
            </h3>
            <div className="w-full px-4 ">
              <DataTable
                columns={columns}
                data={offers}
                customStyles={customStyles}
              />
            </div>
          </div>
          <Modal
            open={openRating}
            handleCancel={() => setOpenRating(false)}
            handleOk={() => {
              if (ratingEdit) {
                setOpenRating(false);
                setAlert(true);
              } else {
                setOpenRating(false);
              }
            }}
            title={"Review your Transaction"}
          >
            <>{ratingForm(formType)}</>
          </Modal>
          <Modal
            open={alert}
            handleCancel={() => setAlert(false)}
            handleOk={buyerReviewHandler}
            title={"Are you sure?"}
          >
            <>
              <p className="text-center">This step can not be undone!</p>
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
});

export default MyOffersBuyer;
