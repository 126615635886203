import React from "react";
import { HARVEST_DATE_TYPES } from "../../config/constants/general";

function ActionButtons({ status, onApprove, onDiscard }) {
  return (
    <>
      {status === HARVEST_DATE_TYPES.PENDING ? (
        <>
          <button
            className="bg-green-500 p-2 text-white rounded-xl"
            onClick={onApprove}
          >
            Approve
          </button>
          <button
            className="bg-red-500 p-2 text-white rounded-xl ml-4"
            onClick={onDiscard}
          >
            Discard
          </button>
        </>
      ) : null}
      {status === HARVEST_DATE_TYPES.APPROVED ? (
        <>
          <button
            className="bg-red-500 p-2 text-white rounded-xl ml-4"
            onClick={onDiscard}
          >
            Discard
          </button>
        </>
      ) : null}
      {status === HARVEST_DATE_TYPES.DECLINE ? (
        <>
          <button
            className="bg-green-500 p-2 text-white rounded-xl"
            onClick={onApprove}
          >
            Approve
          </button>
        </>
      ) : null}
    </>
  );
}

export default ActionButtons;
