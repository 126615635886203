import React, { useState, Fragment, useEffect, useImperativeHandle, forwardRef } from "react";

import DataTable from "react-data-table-component";
import { ChevronDoubleRightIcon } from "@heroicons/react/solid";
import UserSidebar from "../../components/UserSidebar";
import Auth, { getToken } from "../../Authentication/Auth";
import ReactStars from "react-rating-stars-component";
import Modal from "../../components/Modal";
import strings from "../../config/constants/strings";
import { addDays, daysBetween, slashDate, subDays } from "../../common/utils";
import ProgressBar from "@ramonak/react-progress-bar";
const styles = {
  detailName: "text-l  col-span-3",
  detailValue: "text-xl col-span-2",
};
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#effce0",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
      backgroundColor: "#effce0",
    },
  },
};

const MyBidsDemo=forwardRef((props, ref)=>{
  const [harvestDate, setHarvestDate] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [userType, setUserType] = useState(Auth.getUserLevel());
  const [formType, setFormType] = useState(Auth.getUserLevel());
  const [bidsKilo, setBidsKilo] = useState([]);
  const [bidsUnit, setBidsUnits] = useState([]);
  const [error, setError] = useState([]);
  const [errorBox, setErrorBox] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [alert, setAlert] = useState(false);
  const [ratingEdit, setRatingEdit] = useState(true);
  const [collection, setCollection] = useState(5);
  const [arrivalTime, setArrivalTime] = useState(5);
  const [payment, setPayment] = useState(5);
  const [negotiation, setNegotiation] = useState(5);
  const [staff, setStaff] = useState(5);
  const [availability, setAvailability] = useState(5);
  const [description, setDescription] = useState("");
  const [row, setRow] = useState(null);
  const [status, setStatus] = useState("");
  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const columns = [
    {
      name: `${strings.PRICE}`,
      selector: (row) => row.amount + " Rs / Per " + row?.unitType,
      sortable: true,
    },
    {
      name: `${userType == "Buyer" ? "Seller" : `${strings.BUYER}`}`,
      selector: (row) => {
        if (userType == "Buyer") {
          return row.sellerName;
        } else {
          return row.buyerName;
        }
      },
    },
    {
      name: (
        <p className="whitespace-pre-wrap text-sm text-center">{`Total Requirement \nRemaining`}</p>
      ),
      cell: (row) =>
        row?.requirement ? (
          <div className="w-full">
            <ProgressBar
              height="13px"
              animateOnRender
              maxCompleted={100}
              completed={
                ((row.requirement.quantity - row.requirement.acceptedQuantity) /
                  row.requirement.quantity) *
                100
              }
              customLabel={
                row.requirement.quantity -
                row.requirement.acceptedQuantity +
                "/" +
                row.requirement.quantity
              }
              labelAlignment="outside"
              labelColor="black"
              labelSize="13px"
              width="50%"
            />
          </div>
        ) : (
          <p>-</p>
        ),
    },
    {
      name: (
        <p className="whitespace-pre-wrap text-sm text-center">{`Requirement \n Ends In`}</p>
      ),
      selector: (row) =>
        row?.requirement ? (
          <p className="whitespace-pre-wrap text-center text-sm font-semibold">{`${daysBetween(
            new Date().toDateString(),
            new Date(row?.requirement?.endDate),
            1
          )} Days \n ${new Date(
            row?.requirement?.endDate
          ).toLocaleDateString()}`}</p>
        ) : (
          <p>-</p>
        ),
    },
    {
      name: `${strings.STATUS}`,
      selector: (row) =>
        row?.requirement &&
        daysBetween(
          new Date().toDateString(),
          new Date(row?.requirement?.endDate),
          1
        ) === 0 ? (
          <h4 className="text-gray-600 capitalize font-semibold">Expired</h4>
        ) : (
          <h4
            className={
              row.status == "pending"
                ? "text-yellow-600 capitalize font-semibold"
                : row.status == "canceled" || row.status == "inflated"
                ? "text-red-600 capitalize font-semibold"
                : row.status == "confirmed" || row.status == "closed"
                ? "text-green-600 capitalize font-semibold"
                : row.status == "expired"
                ? "text-gray-600 capitalize font-semibold"
                : row.status == "accepted"
                ? "text-blue-600 capitalize font-semibold"
                : "capitalize"
            }
          >
            {row.status}
          </h4>
        ),
      sortable: true,
    },
    {
      name: `${strings.HARVEST_DATE}`,
      selector: (row) =>
        row.harvestDate !== "" && row.harvestDate != null
          ? slashDate(row.harvestDate)
          : "-",
      sortable: true,
    },
    {
      name: `${strings.SEE_MORE}`,
      selector: (row) => (
        <a
          href={
            userType === "Seller"
              ? `viewBuyer${row.buyerId}`
              : `viewSeller${row.sellerId}`
          }
        >
          <b className="flex ">
            View
            <ChevronDoubleRightIcon
              className={`w-5 ml-1`}
              onClick={handleSidebar}
            />
          </b>
        </a>
      ),
      sortable: true,
    },
    {
      name: `${strings.ACTIONS}`,
      cell: (row) => {
        if (userType === "Seller") {
          return row.status === "pending" ? (
            <button
              className="bg-green-400 p-2 rounded-2xl text-white "
              onClick={(e) => handleAccept(row._id, "confirmed", e)}
              id={row._id}
            >
              Confirm
            </button>
          ) : row.status === "confirmed" ? (
            <div className="flex flex-col">
              <button
                className="bg-orange-300 p-2 rounded-2xl text-white my-2"
                onClick={(e) => handleAccept(row._id, "pending", e)}
                id={row._id}
              >
                Pending
              </button>
              <button
                className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
                onClick={(e) => handleRatings(row._id, "closed", e)}
                id={row}
              >
                Close
              </button>
            </div>
          ) : row.status === "closed" && row?.buyerRating ? (
            // <h1> N/A</h1>
            <button
              className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
              onClick={(e) => viewRatings(row?.buyerRating, e)}
              id={row._id}
            >
              View Buyer Rating
            </button>
          ) : (
            <h1> N/A</h1>
          );
        } else {
          return row.status === "pending" ? (
            <button
              className="bg-red-500 p-2 rounded-2xl text-white "
              onClick={declineHandler}
              id={row._id}
            >
              Cancel
            </button>
          ) : row.status === "confirmed" ? (
            <h1> N/A</h1>
          ) : row.status === "closed" &&
            row?.sellerRating &&
            row?.buyerRating ? (
            <button
              className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
              onClick={(e) => viewRatings(row.sellerRating, e)}
              id={row._id}
            >
              View Seller Rating
            </button>
          ) : row.status === "closed" ? (
            <button
              className="bg-teal-600 p-2 rounded-2xl text-white mb-2"
              onClick={(e) => handleRatings(row._id, "closed", e)}
              id={row._id}
            >
              Review
            </button>
          ) : (
            <h1> N/A</h1>
          );
        }
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    loadBids();
  }, []);

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const handleAccept = (id, status, e) => {
    e.preventDefault();
    setIsOpen(true);
    setRow(id);
    setStatus(status);
  };

  const handleRatings = (id, status, e) => {
    e.preventDefault();
    setOpenRating(true);
    setRow(id);
    setStatus(status);
    setRatingEdit(true);
    setFormType(userType);
    setCollection(5);
    setArrivalTime(5);
    setPayment(5);
    setNegotiation(5);
    setStaff(5);
    setAvailability(5);
    setDescription("");
  };

  const viewRatings = (rating, e) => {
    e.preventDefault();
    console.log(rating);
    setRow(rating.id);
    setRatingEdit(false);
    setFormType(userType === "Seller" ? "Buyer" : "Seller");
    setCollection(rating?.collection);
    setArrivalTime(rating?.arrivalTime);
    setPayment(rating?.payment);
    setNegotiation(rating?.negotiation);
    setStaff(rating?.staff);
    setAvailability(rating?.productAvailability);
    setDescription(rating?.description);
    setOpenRating(true);
  };

  const acceptHandler = () => {
    const bKilo = bidsKilo.map((item) => {
      if (item._id == row) {
        item.status = status;
        item.harvestDate = harvestDate;
      }
      return item;
    });
    const bUnit = bidsUnit.map((item) => {
      if (item._id == row) {
        item.status = status;
        item.harvestDate = harvestDate;
      }

      return item;
    });
    setBidsKilo(bKilo);
    setBidsUnits(bUnit);
    setIsOpen(false);
    setHarvestDate(null);
    setRow(null);
  };

  const declineHandler = (state) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bids/cancel`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ id: state.target.id }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadBids();
      })
      .catch((error) => console.log(error));
  };

  const closeHandler = () => {
    const bKilo=bidsKilo.map((item)=>{
      if(item._id==row){
        item.status=status;
        item.harvestDate=harvestDate;
      }
      
      return item
    })
    const bUnit=bidsUnit.map((item)=>{
      if(item._id==row){
        item.status=status;
        item.harvestDate=harvestDate;
      }
      
      return item
    })
    setBidsKilo(bKilo);
    setBidsUnits(bUnit)
    setIsOpen(false);
    setOpenRating(false);
    setAlert(false);
    setRow(null);
  };

  const buyerReviewHandler = () => {
    setIsOpen(false);
    setOpenRating(false);
    setAlert(false);
    setRow(null);
  };

  const ratingForm = (type) =>
    type === "Seller" ? (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Collection (Pickiness)</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setCollection(e)}
            edit={ratingEdit}
            value={collection}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Arrival on time</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setArrivalTime(e)}
            edit={ratingEdit}
            value={arrivalTime}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Payment</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setPayment}
            edit={ratingEdit}
            value={payment}
            size={24}
            activeColor="#ffd700"
          />
        </div>

        <div className={styles.detailName}>Negotiation</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setNegotiation}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>Staff</div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={setStaff}
            edit={ratingEdit}
            value={staff}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-5 gap-2 ml-2">
        <div className={styles.detailName}>
          <span>Product availability</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setAvailability(e)}
            edit={ratingEdit}
            value={availability}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className={styles.detailName}>
          <span>Negotiation after arrival</span>
        </div>
        <div className={styles.detailValue}>
          {" "}
          <ReactStars
            count={5}
            onChange={(e) => setNegotiation(e)}
            edit={ratingEdit}
            value={negotiation}
            size={24}
            activeColor="#ffd700"
          />
        </div>
        <div className="text-xl w-full col-span-5">
          <textarea
            className="text-base w-full  border-2 py-3 px-2"
            placeholder="How was your experience,tell us more!"
            onChange={(e) => setDescription(e.target.value)}
            disabled={!ratingEdit}
            value={description}
          ></textarea>
        </div>
      </div>
    );

  const loadBids = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/settings/demoBids`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBidsKilo(
          response?.filter((item) => {
            if (item.unitType === "killo") {
              if (
                userType === "Seller" &&
                item?.requirement?.startDate
              ) {
                return subDays(item?.requirement?.startDate, 7) < new Date() && item;
              } else {
                return item;
              }
            }
          })
        );
        setBidsUnits(response?.filter((item) => {
          if (item.unitType === "unit") {
            if (
              userType === "Seller" &&
              item?.requirement?.startDate
            ) {
              return subDays(item?.requirement?.startDate, 7) < new Date() && item;
            } else {
              return item;
            }
          }
        }));
      })
      .catch((error) => console.log(error)); 
  };

  return (
    <div className="h-screen flex">
      <UserSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="bids"
      />
      <div className="flex-1 flex overflow-hidden pt-16 ">
        <div class="flex-1 overflow-y-scroll">
          <br />
          <div className="inline-block min-w-full sm:px-6 lg:px-8 mt-4">
            <div className="overflow-hidden flex justify-center">
              <ul
                className=" nav nav-tabs nav-justified flex flex-row flex-wrap list-none border-b-0 pl-0 w-4/5 lg:w-2/4 bg-white z-9"
                id="tabs-tabJustify"
                role="tablist"
              >
                <li
                  className="nav-item flex-grow text-center"
                  role="presentation"
                >
                  <a
                    href="#tabs-profileJustify"
                    className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                    id="tabs-profile-tabJustify"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-profileJustify"
                    role="tab"
                    aria-controls="tabs-profileJustify"
                    aria-selected="true"
                  >
                    Per Unit
                  </a>
                </li>
                <li
                  className="nav-item flex-grow text-center"
                  role="presentation"
                >
                  <a
                    href="#tabs-homeJustify"
                    className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent "
                    id="tabs-home-tabJustify"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-homeJustify"
                    role="tab"
                    aria-controls="tabs-homeJustify"
                    aria-selected="false"
                  >
                    Per Kilo
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="overflow-x-auto p-4 mt-0 ">
            <div class="inline-block min-w-full sm:px-6 lg:px-8 ">
              <div class="overflow-hidden">
                <div class="tab-content mt-0" id="tabs-tabContentJustify">
                  <div
                    class="tab-pane fade show active"
                    id="tabs-profileJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-profile-tabJustify"
                  >
                    <div className="items-start">
                      <DataTable
                        columns={columns}
                        data={bidsUnit}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                  <div
                    class="tab-pane fade "
                    id="tabs-homeJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-home-tabJustify"
                  >
                    <div className="items-start">
                      <DataTable
                        columns={columns}
                        data={bidsKilo}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={openRating}
            handleCancel={() => setOpenRating(false)}
            handleOk={() => {
              if (ratingEdit) {
                setOpenRating(false);
                setAlert(true);
              } else {
                setOpenRating(false);
              }
            }}
            title={ratingEdit ? "Review your transaction" : "Buyer Rating"}
          >
            <>{ratingForm(formType)}</>
          </Modal>

          <Modal
            open={isOpen}
            isDisabled={!harvestDate}
            handleCancel={() => setIsOpen(false)}
            handleOk={acceptHandler}
            title={"Select Harvest Date"}
          >
            <>
              <input
                className="my-4 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                type="date"
                name="registered_business"
                id="inlineRadio1"
                value={harvestDate}
                onChange={(e) => setHarvestDate(e.target.value)}
                min={addDays(new Date(), 1).toISOString().split("T")[0]}
                max={addDays(new Date(), 7).toISOString().split("T")[0]}
                // value={values.inlineRadioOptions2}
              />
              {errorBox != "" ? (
                <h1 className="text-red-600">{errorBox}</h1>
              ) : (
                ""
              )}
            </>
          </Modal>

          <Modal
            open={alert}
            handleCancel={() => setAlert(false)}
            handleOk={() =>
              userType === "Seller" ? closeHandler() : buyerReviewHandler()
            }
            title={"Are you sure?"}
          >
            <>
              <p className="text-center">This step can not be undone!</p>
            </>
          </Modal>

          {/*         
            <center>
              <div class="flex justify-center items-center mt-32">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </center> */}
        </div>
      </div>
    </div>
  );
})

export default MyBidsDemo;
