import "./App.css";
import Search from "./pages/SearchSellers";
import SearchBuyers from "./pages/SearchBuyers";
import SellerDetails from "./pages/SellerDetails";
import Admin from "./pages/Admin";
// requires a loader
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { clarity } from 'react-microsoft-clarity';
import Home from "./pages/Home";
import BuyerDetails from "./pages/BuyerDetails";
import AdminAds from "./pages/AdminAds";
import Login from "./pages/Login";
import WithoutNav from "./components/Support/WithoutNav";
import WithNav from "./components/Support/WithNav";
import Register from "./pages/Register";
import RegisterBuyer from "./pages/RegisterBuyer";
import RegisterSeller from "./pages/RegisterSeller";
import AdminSellers from "./pages/AdminSellers";
import BuyerAccount from "./pages/BuyerAcount";
import SellerAccount from "./pages/SellerAccount";
import PostAdBuyer from "./pages/PostAdBuyer";
import PostAdSeller from "./pages/PostAdSeller";
import MyOffers from "./pages/MyOffers";
import OfferDetails from "./pages/OfferDetails";
import { RequireAuth } from "./components/RequiredAuth";
import { RequireAdmin } from "./components/RequiredAdmin";
import MyBids from "./pages/MyBids";
import MyOffersBuyer from "./pages/MyOffersBuyer";
import ForgotPassword from "./pages/ForgotPassword";
import OtpScreen from "./pages/OtpScreen";
import NewPassword from "./pages/NewPassword";
import { useEffect, useRef } from "react";
import Harvest from "./pages/Harvest";
import HarvestDates from "./pages/HarvestDates";
import RegisterOfficer from "./pages/RegisterOfficer";
import AdminReferral from "./pages/AdminReferral";
import { RequireOfficer } from "./components/RequiredOfficer";
import OfficerReferral from "./pages/OfficerReferral";
import OfficerNotifications from "./pages/OfficerNotifications";
import strings from "./config/constants/strings";
import Inflation from "./pages/Inflation";
import Requirements from "./pages/Requirements";
import Transactions from "./pages/Transactions";
import SellerTransactions from "./pages/SellerTransactions";
import TermsAndConditions from "./pages/TermsAndConditions";
import MyAccount from "./pages/MyAccount";
import { getUserId } from "./Authentication/Auth";

function App() {
  const childRef = useRef();
  
  strings.setLanguage(localStorage.getItem("lang") ?? "en");

  const clickSidebar = () => childRef?.current?.openSidebar();

  useEffect(() => {
    clarity.init("l4thr5lazy");
    if(clarity.hasStarted()){
      clarity.identify(getUserId());
    }
  }, []);
  
  return (
    <Router>
    <Routes>
      <Route element={<WithNav page="home" />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route>
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
      </Route>
      <Route element={<WithNav page="sellers" />}>
        <Route
          path="/sellers"
          element={
            <RequireAuth redirectTo="/login">
              <Search />
            </RequireAuth>
          }
        />
      </Route>
      <Route element={<WithNav page="sellers" />}>
        <Route
          path="/sellers:isReqEnabled"
          element={
            <RequireAuth redirectTo="/login">
              <Search />
            </RequireAuth>
          }
        />
      </Route>
      <Route element={<WithNav page="buyers" />}>
        <Route
          path="/buyers"
          element={
            <RequireAuth redirectTo="/login">
              <SearchBuyers />
            </RequireAuth>
          }
        />
      </Route>
      <Route element={<WithNav />} page="sellers">
        <Route path="/viewSeller:id" element={<SellerDetails />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/viewBuyer:id" element={<BuyerDetails />} page="buyers"/>
      </Route>
      <Route element={<WithNav page='admin'/>}>
        <Route
          path="/admin"
          element={
            <RequireAdmin redirectTo="/">
              <Admin />
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav />}>
        <Route
          path="/inflation"
          element={
            <RequireAdmin redirectTo="/">
              <Inflation />
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav />}>
        <Route
          path="/adminSellers"
          element={
            <RequireAdmin redirectTo="/">
              <AdminSellers />
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav />}>
        <Route
          path="/adminAds"
          element={
            <RequireAdmin redirectTo="/">
              <AdminAds />
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav />}>
        <Route
          path="/adminHarvest"
          element={
            <RequireAdmin redirectTo="/">
              <HarvestDates />
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route
          path="/adminReferral"
          element={
            <RequireAdmin redirectTo="/">
              <AdminReferral  ref={childRef}/>
            </RequireAdmin>
          }
        />
      </Route>
      <Route element={<WithNav page='admin' handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route
          path="/officerReferral"
          element={
            <RequireOfficer redirectTo="/">
              <OfficerReferral ref={childRef} />
            </RequireOfficer>
          }
        />
      </Route>
      <Route element={<WithNav page='admin' handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route
          path="/officerNotifications"
          element={
            <RequireOfficer redirectTo="/">
              <OfficerNotifications ref={childRef} />
            </RequireOfficer>
          }
        />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/login/:type" element={<Login />} />
      </Route>
      <Route element={<WithoutNav />}>
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route element={<WithoutNav />}>
        <Route path="/new-password" element={<NewPassword />} />
      </Route>
      <Route element={<WithoutNav />}>
        <Route path="/otp-screen" element={<OtpScreen />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/register/:id" element={<Register />} />
      </Route>
      <Route element={<WithNav />}>
        <Route
          path="/referral/enroll-officers"
          element={<RegisterOfficer />}
        />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/registerBuyer" element={<RegisterBuyer />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/registerSeller" element={<RegisterSeller />} />
      </Route>
      <Route element={<WithNav />}>
        <Route path="/buyerAccount:id" element={<BuyerAccount />} />
      </Route>

      <Route element={<WithNav />}>
        <Route path="/sellerAccount:id" element={<SellerAccount />} />
      </Route>

      <Route element={<WithNav handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/transactions" element={<Transactions ref={childRef}/>} />
      </Route>

      <Route element={<WithNav handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/transactions:id" element={<SellerTransactions ref={childRef}/>} />
      </Route>

      <Route element={<WithNav />}>
        <Route path="/postAdBuyer" element={<PostAdBuyer />} />
      </Route>

      <Route element={<WithNav />}>
        <Route path="/postAdSeller" element={<PostAdSeller />} />
      </Route>

      <Route element={<WithNav page="offers" handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/offers" element={<MyOffers ref={childRef}/>} />
      </Route>

      <Route element={<WithNav page="offers" handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/offersBuyer" element={<MyOffersBuyer ref={childRef}/>} />
      </Route>

      <Route element={<WithNav page="requirements" handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/requirementsBuyer" element={<Requirements ref={childRef}/>} />
      </Route>
      <Route element={<WithNav page="requirements" handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/requirementsBuyer:fromSellers" element={<Requirements ref={childRef}/>} />
      </Route>

      <Route element={<WithNav page="harvest"/>}>
        <Route path="/harvest" element={<Harvest />} />
      </Route>

      <Route element={<WithNav />}>
        <Route path="/viewOffer" element={<OfferDetails />} />
      </Route>

      <Route element={<WithNav />}>
        <Route path="/myAccount" element={<MyAccount />} />
      </Route>

      <Route element={<WithNav handleSidebar={clickSidebar} isSidebar={true}/>}>
        <Route path="/bids" element={<MyBids ref={childRef} />} />
      </Route>

      {/* <Route exact path="/login" element={<Login />} />
    <Route exact path="/recovery-password" element={<RecoveryPassword />} /> */}
      <Route element={<WithNav />}>
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  </Router>
  );
}

export default App;
