import React from "react";
import ReactStars from "react-rating-stars-component";
import { HARVEST_DATE_TYPES } from "../config/constants/general";

function ListItemHarvest({
  name,
  state,
  scale,
  price,
  landline,
  mobile1,
  mobile2,
  status,
  rating,
  date,
  onApprove,
  onDiscard,
  lastUpdated,
  requestCount,
  nearestCities,
  district,
}) {
  return (
    <div className="flex justify-start mb-4">
      <div class="flex flex-col p-4 sm:p-0 sm:flex-row w-screen sm:w-3/4 rounded-lg  shadow-lg lg:h-auto md:h-40 bg-lime-100">
        <img
          class="h-56 lg:h-36 md:h-auto object-cover  sm:w-36 rounded-t-lg md:rounded-lg m-4"
          src="https://images.unsplash.com/photo-1553787434-dd9eb4ea4d0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt=""
        />
        <div class="p-4 flex flex-col justify-start w-3/5 ">
          <h5 class="text-gray-900 text-lg font-semibold ">
            {name}{" "}
            {status !== HARVEST_DATE_TYPES.PENDING && (
              <button
                disabled
                className={`${
                  status === HARVEST_DATE_TYPES.APPROVED
                    ? "bg-green-500"
                    : "bg-red-500"
                }  px-3 text-white rounded-xl`}
              >
                {status === HARVEST_DATE_TYPES.APPROVED
                  ? "Approved"
                  : "Declined"}
              </button>
            )}
          </h5>
          {/* <h5 class="text-gray-900 text-base font-medium ">100</h5> */}
          {date && (
            <h5 class="text-gray-900 text-base font-medium flex">
              <span className="font-semibold">Requested date:</span> &nbsp;
              <b>{date}</b>
            </h5>
          )}

          <h5 class="text-gray-900 text-base font-medium flex">
            <span className="font-semibold">Contact info:</span> &nbsp;
            {`${landline ? landline : ""}, ${mobile1 ? mobile1 : ""}, ${
              mobile2 ? mobile2 : ""
            }`}
          </h5>

          {district && (
            <h5 class="text-gray-900 text-base font-medium flex">
              <span className="font-semibold">Districts:</span> &nbsp;{district}
            </h5>
          )}

        {nearestCities && (
            <h5 class="text-gray-900 text-base font-medium flex">
              <span className="font-semibold">Nearest Cities:</span> &nbsp;{nearestCities?.join(", ")}
            </h5>
          )}
          {scale && (
            <h5 class="text-gray-900 text-base  font-medium flex ">
              <span className="font-semibold">Scale : </span> &nbsp; {scale}
            </h5>
          )}
          <h5 class="text-gray-900 text-base font-medium flex ">
            <span className="font-semibold">
              {requestCount &&
                `Requested Count : 
            ${requestCount}`}
            </span>
          </h5>
          <h5 class="text-gray-900 text-base font-medium flex ">
            <span className="font-semibold">
              {lastUpdated &&
                `Updated at : 
            ${lastUpdated}`}
            </span>
          </h5>

          <div className="-mt-2">
            {/* <ReactStars
                count={5}
                // onChange={ratingChanged}
                value={rating}
                edit={false}
                size={24}
                activeColor="#ffd700"
              /> */}
          </div>
          <div className="mt-4  mb-4">
            {/* <a
                className="bg-blue-500 p-2 text-white rounded-xl "
                href="/buyerDetails"
              >
                View Details
              </a> */}
            {status === HARVEST_DATE_TYPES.PENDING ? (
              <>
                <button
                  className="bg-green-500 p-2 text-white rounded-xl"
                  onClick={onApprove}
                >
                  Approve
                </button>
                <button
                  className="bg-red-500 p-2 text-white rounded-xl ml-4"
                  onClick={onDiscard}
                >
                  Discard
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListItemHarvest;
