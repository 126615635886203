import { React, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import eye from "../assets/eye.svg";
import eyeOff from "../assets/eye-off.svg";

function NewPassword() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const { otp, email } = state; 
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [values1, setValues1] = useState({
    password: "",
    showPassword: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.password === "" || values.password !== values1.password) {
      setErrors({password:"Password does not match"});
      return;
    }
    if (values.password.length <= 5) {
      setErrors({password:"Password does meet the requirements"});
      return;
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: values.password,
          otp:otp,
        }),
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/resetPass`,
        requestOptions
      )
        .then((res) => res.json())
        .then((item) => {
          if ("error" in item) {
            setErrors({password:item?.error});
          } else {
            navigate("/");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword1 = () => {
    setValues1({ ...values1, showPassword: !values1.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handlePasswordChange1 = (prop) => (event) => {
    setValues1({ ...values1, [prop]: event.target.value });
  };

  return (
    <div className="lg:flex ">
      <div className="lg:w-1/2 xl:max-w-screen-sm">
        <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <div className="text-2xl text-green-800 tracking-wide ml-2 font-semibold">
              පොල් මුරේ
            </div>
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-0 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-green-900 font-display font-semibold lg:text-left xl:text-5xl
          xl:text-bold"
          >
            Reset Password
          </h2>
          <div className="mt-12">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="text-sm font-bold text-gray-700 tracking-wide text-center mb-5">
                Set a new password for your account
                </div>
                <div className="relative">
                  <input
                    name="password"
                    onChange={handlePasswordChange1("password")}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-green-500"
                    type={values1.showPassword ? "text" : "password"}
                    placeholder="Enter your new password"
                  />
                  <button
                    type="button"
                    className="absolute right-4 h-full "
                    onClick={handleClickShowPassword1}
                    style={{ padding: "0"}}
                  >
                    <img
                      src={values1.showPassword ? eyeOff : eye}
                      style={{ width: "20px", color: "#677294;" }}
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="mt-5">
                <div className="relative">
                  <input
                    name="password"
                    onChange={handlePasswordChange("password")}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-green-500"
                    type={values.showPassword ? "text" : "password"}
                    placeholder="Confirm your password"
                  />
                  <button
                    type="button"
                    className="absolute right-4 h-full "
                    onClick={handleClickShowPassword}
                    style={{ padding: "0"}}
                  >
                    <img
                      src={values.showPassword ? eyeOff : eye}
                      style={{ width: "20px", color: "#677294;" }}
                      alt=""
                    />
                  </button>
                </div>

                {errors.password ? (
                  <p className="text-red-500 mt-2">{errors.password} !</p>
                ) : null}
              </div>
              <div className="mt-10">
                <button
                  className="bg-green-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                      font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-600
                      shadow-lg"
                >
                  Continue
                </button>
              </div>
            </form>
            <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
              Don't have an account ?{" "}
              <a href="/register" className="cursor-pointer text-green-600 hover:text-green-800">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-green-100 flex-1 h-screen">
        <img
          className="h-screen object-cover "
          src="https://images.unsplash.com/photo-1621442745928-8f8d98021f35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2669&q=80"
          alt="nike shoes"
        />
      </div>
    </div>
  );
}

export default NewPassword;
