import React, { forwardRef, useEffect, useState } from "react";
import { getToken, getUserId } from "../../Authentication/Auth";
import MyBidsDemo from "./MyBidsDemo";
import MyBids from "./MyBids";

const index = forwardRef((props,ref) => {
  const [isDemoUser, setIsDemoUser] = useState(false);
  useEffect(() => {
    loadBids();
  }, []);

  const loadBids = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/demoSeller`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsDemoUser(response?.sellerId === getUserId());
      })
      .catch((error) => console.log(error));
  };
  return isDemoUser ? <MyBidsDemo ref={ref}/> : <MyBids ref={ref}/>;
});

export default index;
