import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getToken } from "../Authentication/Auth";
import OfficerSidebar from "../components/OfficerSidebar";
import PreLoader from "../components/PreLoader";

const OfficerReferral = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadReferrals();
  }, []);

  useImperativeHandle(ref, () => ({
    openSidebar() {
      handleSidebar();
    },
  }));

  const loadReferrals = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/myReferrals`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setReferrals(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className="flex-1 flex overflow-hidden ">
      <PreLoader isLoading={isLoading} />
      <div class="flex-1 overflow-y-scroll">
        <div className="h-screen flex">
          <OfficerSidebar
            handleSidebar={handleSidebar}
            isOpened={isOpened}
            selected="referral"
          />
          <div className="flex-1 flex overflow-hidden pt-24 ">
            <div class="flex-1 overflow-y-scroll">
              <h3 className="ml-8 text-xl font-semibold mb-4">My Referrals</h3>
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
                  <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                      <table class="min-w-full">
                        <thead class="border-b">
                          <tr>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Scale
                            </th>

                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Account Status
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Payment Status
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Payment Date
                            </th>
                            <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Reference Number
                            </th>

                            {/* <th
                              scope="col"
                              class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Actions
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {referrals?.map((e, i) => {
                            return (
                              <tr key={e?.id} class="border-b">
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {i + 1}
                                </td>
                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {e.name}
                                </td>
                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {e.scaleOfBusiness}
                                </td>
                                <td class="text-sm  px-6 py-4 whitespace-nowrap font-bold text-gray-600">
                                  {"Trial"}
                                </td>
                                <td class="text-sm  px-6 py-4 whitespace-nowrap font-bold text-gray-600">
                                  {"Not Paid"}
                                </td>
                                <td class="text-sm  px-6 py-4 whitespace-nowrap font-bold text-gray-600">
                                  {"-"}
                                </td>
                                <td class="text-sm  px-6 py-4 whitespace-nowrap font-bold text-gray-600">
                                  {"-"}
                                </td>

                                {/* <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <a
                                    className="bg-red-500 p-2 text-white rounded-xl"
                                    href={"/sellerAccount" + e._id}
                                  >
                                    Change Status
                                  </a>
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OfficerReferral;
