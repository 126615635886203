import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import SelectBoxRegister from "../components/SelectBoxRegister";
import { TermsAndCondition } from "../components/TermsAndCondition/TermsAndCondition";
import Modal from "../components/Modal";

function RegisterSeller() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [isOrganization, setIsOrganization] = useState("None");
  const [selected, setSelected] = useState();
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [cities, setCities] = useState("");
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 6.9271,
    lng: 79.8612,
  });
  const [loadingCities, setLoadingCities] = useState(false);

  // useEffect(() => {
  //   getCities();
  // }, [currentLocation]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setCurrentLocation({ lat: latitude, lng: longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const getCities = () => {
    setLoadingCities(true);
    console.log("fetching", currentLocation);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/home/cities`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        lat: currentLocation.lat,
        lng: currentLocation.lng,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        const mappedCities = response?.map((city) => ({
          label: city,
          value: city,
        }));
        console.log(mappedCities);

        setCities(mappedCities);
        setLoadingCities(false);
      })
      .catch((error) => {
        setLoadingCities(false);
        console.log(error);
      });
  };

  // const getCities = (data) => {
  //   const selectedDistrict = districts?.filter((dist) => dist.name === data);

  //   const selectedCities = allCities?.filter((city) => {
  //     if (city.id === selectedDistrict[0].id) {
  //       return city;
  //     }
  //   });

  //   const mappedCities = selectedCities?.map((city) => ({
  //     label: city.name,
  //     value: city.name,
  //   }));
  //   setCities(mappedCities);
  // };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });

  return (
    <div className="lg:flex ">
      <Modal
        open={alert}
        isSingleButton={true}
        handleCancel={() => setAlert(false)}
        handleOk={() => navigate("/login")}
        title={"Registration Successful!"}
      >
        <>
          <p className="text-center">
            You have have registered successfully. Please login with your email
            address and your password
          </p>
        </>
      </Modal>
      <div className="lg:w-4/5 xl:max-w-screen-sm">
        <div className="h-10"></div>
        <div class="flex-1 mt-4 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-12 xl:max-w-2xl ">
          <h2
            className="text-center text-4xl text-green-900  font-semibold lg:text-left xl:text-3xl
          xl:text-bold mb-1"
          >
            Registration
          </h2>
          <h2
            className="text-center text-xl text-green-600  font-semibold lg:text-left xl:text-xl
          xl:text-bold mb-8"
          >
            Seller
          </h2>
          <Formik
            initialValues={{
              scale: "Medium Scale",
              organization_type: "None",
              receive_emails: "false",
              district: "Colombo",
              receive_calls: "false",
              organization_name: "",
              interval_between_harvests: 30,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={(values) => {
              const errors = {};
              let phoneNo =
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
              if (
                values.inheritor_mobile &&
                !values.inheritor_mobile.match(phoneNo)
              ) {
                errors.inheritor_mobile = "Incorrect Mobile number format";
              }
              if (
                values.inheritor_mobile_alt &&
                !values.inheritor_mobile_alt.match(phoneNo)
              ) {
                errors.inheritor_mobile_alt = "Incorrect Mobile number format";
              }
              if (isOrganization !== "None" && !values.organization_name) {
                errors.organization_name = "Required field*";
              }
              if (!selected) {
                errors.collectingFrom =
                  "Enter at least one Area Prefer to Collecting From/ Nearest City";
              }
              // if (!values.interval_between_harvests) {
              //   errors.harvestInterval =
              //     "Interval Between Harvest Is Required*";
              // } else if (
              //   values.interval_between_harvests < 45 ||
              //   values.interval_between_harvests > 60
              // ) {
              //   errors.harvestInterval =
              //     "Harvesting interval should lie between 45 & 60 days";
              // }
              if (!values.total_land || values.total_land <= 0) {
                errors.total_land = "Total Land (Acres) Is Required*";
              }
              if (!values.yield_per_harvest || values.yield_per_harvest <= 0) {
                errors.yield_per_harvest = "Yield Per Harvest Is Required*";
              }
              if (!values.total_trees || values.total_trees <= 0) {
                errors.total_trees = "Number of Trees Is Required*";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values);
              try {
                setIsLoading(true);
                const request = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    name: state.fname + " " + state.lname,
                    type: state.utype,
                    email: state.email,
                    password: state.password,
                    mobile1: state.mobile,
                    mobile2: state.altMobile,
                    landLine: state.landLine,
                  }),
                };
                await fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/api/users/initial-validate`,
                  request
                )
                  .then((res) => res.json())
                  .then(async (item) => {
                    if ("errors" in item && "email" in item.errors) {
                      setEmailError(item.errors.email);
                    } else if ("success" in item) {
                      const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          name: state.fname + " " + state.lname,
                          type: state.utype,
                          email: state.email,
                          password: state.password,
                          mobile1: state.mobile,
                          mobile2: state.altMobile,
                          landLine: state.landLine,
                          code: state?.code || "",
                          //Buyer
                          scaleOfBusiness: values.scale,
                          // canProvideTreeClimbers: "",
                          // isRegisteredBusiness: "",
                          businessName: "",
                          alternateMobile: "",
                          alternateMobile2: "",
                          recieveCalls: values.receive_calls,
                          inheritorName: values.registered_business,
                          inheritorMobile: values.inheritor_phone,
                          inheritorAltMobile: values.inheritor_phone_alt,
                          aditionalInfo: values.additional_info,
                          //seller
                          sizeOfLand: values.total_land,
                          yieldPerHarvest: values.yield_per_harvest,
                          totalHarvest: values.yield_per_harvest,
                          intervalBetweenHarvest:
                            values.interval_between_harvests,
                          recieveEmails: values.receive_emails,
                          dirstrict: values.district,
                          noOfTrees: values.total_trees,
                          areasToCollect: selected.value,
                          orgType: values.organization_type,
                          orgName: values.organization_name,
                          location: {
                            lat: currentLocation.lat,
                            lng: currentLocation.lng,
                          },
                        }),
                      };
                      const result = await fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/api/users/signup`,
                        requestOptions
                      );
                      if (result) {
                        setAlert(true);
                      }
                    }
                  })
                  .finally(() => setIsLoading(false));
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap mx-auto mb-6 ">
                  <a
                    className="
                    inline-flex
                    items-center
                    justify-center
                    w-1/2
                    py-3
                    font-medium
                    leading-none
                    tracking-wider
                    border-b-2 border-gray-200
                    sm:px-6 sm:w-auto sm:justify-start
                    hover:text-gray-900
                  "
                    href="/register"
                  >
                    STEP 1
                  </a>
                  <a
                    className="
                    inline-flex
                    items-center
                    justify-center
                    w-1/2
                    py-3
                    font-medium
                    leading-none
                    tracking-wider
                    text-green-500
                    bg-gray-100
                    border-b-2 border-green-500
                    rounded-t
                    sm:px-6 sm:w-auto sm:justify-start
                  "
                  >
                    STEP 2
                  </a>
                </div>
                <div className="hidden xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-4 w-full group">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                      id="grid-state"
                      name="scale"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.scale}
                    >
                      <option value="Small Scale">Small Scale</option>
                      <option value="Medium Scale">Medium Scale</option>
                      <option value="Large Scale">Large Scale</option>
                    </select>
                    <label
                      for="floating_first_name"
                      className="absolute text-xl  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 mt-1"
                    >
                      Scale Of Plantation*
                    </label>
                  </div>
                  {/* <div className="relative z-0 mb-4 w-full group">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                      id="grid-state"
                      name="district"
                      onChange={(e) => {
                        getCities(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.district}
                    >
                      {districts.map((e) => {
                        return <option>{e.name}</option>;
                      })}
                    </select>
                    <label
                      for="floating_phone"
                      className="absolute text-xl  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 mt-1"
                    >
                      District*
                    </label>
                  </div> */}
                </div>
                <p className="text-md  text-gray-500 dark:text-gray-400 mt-1 mb-2">
                  Select your location*
                </p>
                <div
                  style={{ height: "300px", width: "100%" }}
                  className="mb-4"
                >
                  {isLoaded ? (
                    <GoogleMap
                      options={{
                        mapTypeControl: false,
                        streetViewControl: false,
                      }}
                      mapContainerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      center={{
                        lat: currentLocation.lat,
                        lng: currentLocation.lng,
                      }}
                      zoom={10}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <Marker
                        position={currentLocation}
                        draggable={true}
                        onDragEnd={(e) => {
                          setCurrentLocation({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          });
                        }}
                      />
                    </GoogleMap>
                  ) : (
                    <div>Loading</div>
                  )}
                  <h2 className="relative z-50 bottom-10 text-center text-white font-semibold">
                    Drag the drop pin to your location.
                  </h2>
                </div>

                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <select
                      name="interval_between_harvests"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.interval_between_harvests}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                      id="floating_last_name"
                    >
                      <option defaultChecked value={30}>
                        30 days
                      </option>
                      <option value={35}>35 days</option>
                      <option value={40}>40 days</option>
                      <option value={45}>45 days</option>
                      <option value={50}>50 days</option>
                      <option value={55}>55 days</option>
                      <option value={60}>60 days</option>
                      <option value={65}>65 days</option>
                    </select>
                    <label
                      for="floating_last_name"
                      className="absolute text-x text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 mt-1"
                    >
                      Interval Between Harvest(In Days)*
                    </label>
                  </div>
                  {/* <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="number"
                      name="interval_between_harvests"
                      id="floating_last_name"
                      class="block mt-2 py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.interval_between_harvests}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.harvestInterval ? (
                        <span className="text-red-600">
                          {errors.harvestInterval}
                        </span>
                      ) : (
                        "Interval Between Harvest(In Days)*"
                      )}
                    </label>
                  </div> */}
                  <div className="relative z-0 mb-6 w-full group ">
                    <input
                      type="number"
                      name="total_land"
                      min={0}
                      id="floating_last_name"
                      className="block mt-2 py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_land}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.total_land ? (
                        <span className="text-red-600">
                          {errors.total_land}
                        </span>
                      ) : (
                        "Total Land (Acres)*"
                      )}
                    </label>
                  </div>
                </div>

                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="number"
                      name="yield_per_harvest"
                      min={0}
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.yield_per_harvest}
                    />
                    <label
                      for="floating_first_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.yield_per_harvest ? (
                        <span className="text-red-600">
                          {errors.yield_per_harvest}
                        </span>
                      ) : (
                        "Yield Per Harvest (Average)*"
                      )}
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="number"
                      name="total_trees"
                      min={0}
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_trees}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.total_trees ? (
                        <span className="text-red-600">
                          {errors.total_trees}
                        </span>
                      ) : (
                        "Number of Trees (Approximately)*"
                      )}
                    </label>
                  </div>
                </div>

                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="inheritor_name"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inheritor_name}
                    />
                    <label
                      for="floating_first_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Inheritor Name
                    </label>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="tel"
                      name="inheritor_mobile"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inheritor_mobile}
                    />
                    <label
                      for="floating_first_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.inheritor_mobile ? (
                        <span className="text-red-600">
                          {errors.inheritor_mobile}
                        </span>
                      ) : (
                        "Inheritor Mobile"
                      )}
                    </label>
                  </div>
                </div>

                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="tel"
                      name="inheritor_mobile_alt"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.inheritor_mobile_alt}
                    />
                    <label
                      for="floating_first_name"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {errors.inheritor_mobile_alt ? (
                        <span className="text-red-600">
                          {errors.inheritor_mobile_alt}
                        </span>
                      ) : (
                        "Inheritor Alt Mobile"
                      )}
                    </label>
                  </div>
                  {/* <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="nearest_city"
                      id="floating_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nearest_city}
                    />
                    <label
                      for="floating_last_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Nearest City
                    </label>
                  </div> */}
                </div>

                <div className="relative mb-6 group">
                  <label
                    for="nearest_city"
                    className=" text-sm text-gray-500 dark:text-gray-400 "
                  >
                    {errors.collectingFrom ? (
                      <span className="text-red-600">
                        {errors.collectingFrom}
                      </span>
                    ) : (
                      "Enter Area Prefer to Collecting From/ Nearest City*"
                    )}
                  </label>
                  {/* <input
                    type="text"
                    name="collecting_form_area"
                    id="floating_repeat_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.collecting_form_area}
                    required
                  /> */}
                  <div className="relative w-full mt-4 bg-white mb-2 overflow-x-clip">
                    <SelectBoxRegister
                      id="nearest_city"
                      items={cities}
                      value={selected}
                      onSelect={setSelected}
                      labelledBy="Select"
                      className="mt-4"
                      onClickBtn={getCities}
                      loading={loadingCities}
                    />
                  </div>
                </div>
                <div className="grid xl:grid-cols-2 xl:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <select
                      name="organization_type"
                      onChange={(e) => {
                        handleChange(e);
                        setIsOrganization(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.organization_type}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
                      id="organization_type"
                    >
                      <option defaultChecked>None</option>
                      <option>Private</option>
                      <option>Public</option>
                      <option>Church</option>
                      <option>Other</option>
                    </select>
                    <label
                      for="organization_type"
                      className="absolute text-xl  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 mt-1"
                    >
                      Organization Type
                    </label>
                  </div>

                  {isOrganization !== "None" && (
                    <div className="relative z-0 mb-6 w-full group mt-4">
                      <input
                        type="text"
                        name="organization_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization_name}
                        id="floating_last_name"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        for="floating_first_name"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        {errors.organization_name ? (
                          <span className="text-red-600">
                            {errors.organization_name}
                          </span>
                        ) : (
                          "Organization Name*"
                        )}
                      </label>
                    </div>
                  )}
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="additional_info"
                    id="floating_repeat_password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.additional_info}
                  />
                  <label
                    for="floating_repeat_password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Additional Info
                  </label>
                </div>
                <div className="flex justify-between">
                  <p className="text-left leading-4 mt-1">
                    Would you like to receive calls from potential buyers?
                  </p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="recieve_calls"
                        id="inlineRadio1"
                        value="true"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio10"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="recieve_calls"
                        id="inlineRadio2"
                        value="false"
                        defaultChecked
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio20"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="text-left leading-4 mt-1">
                    Would you like to receive Emails from potential buyers?
                  </p>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="receive_emails"
                        id="inlineRadio1"
                        value="false"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio10"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="receive_emails"
                        id="inlineRadio2"
                        value="false"
                        defaultChecked
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.scale}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        for="inlineRadio20"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <TermsAndCondition onChange={setIsAcceptedTerms} />
                </div>
                {emailError ? (
                  <p className="text-red-500">{emailError} !</p>
                ) : null}
                <button
                  type="submit"
                  disabled={isLoading || !isAcceptedTerms}
                  className="text-white bg-green-700 hover:bg-green-800 disabled:bg-slate-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center my-4"
                >
                  Finish and Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-green-100 flex-1 h-auto">
        <img
          className="h-full object-cover "
          src="https://images.unsplash.com/photo-1564490292125-2e3c78a0ef44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="nike shoes"
        />
      </div>
    </div>
  );
}

export default RegisterSeller;
