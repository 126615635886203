import { ScaleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";

import { useParams } from "react-router";
import { getUserLevel } from "../Authentication/Auth";
import Auth, { getToken } from "../Authentication/Auth";
import strings from "../config/constants/strings";
import { UNIT_TYPES } from "../config/constants/general";
import PreLoader from "../components/PreLoader";

const styles = {
  detailName: "text-xl  col-span-3",
  detailValue: "text-xl col-span-2",
};

function BuyerDetails() {
  const { id } = useParams();

  const [buyerDetails, setBuyerDetails] = useState(false);
  const [offerAmount, setOfferAmount] = useState("");
  const [unitType, setUnitType] = useState(UNIT_TYPES.UNIT);
  const [errorMessage, setErrors] = useState({});
  const [bidDetails, setBidDetails] = useState({});
  const [starKeyForce, setStarKeyForce] = useState(0);
  const [forbidden, setFrobidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadBuyerDetails = () => {
    console.log(id);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/buyerDetails`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ id: id }),
    })
      .then((res) => {
        console.log(res.status);

        if (res.status === 403) {
          setFrobidden(true);
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setBuyerDetails(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setStarKeyForce((prev) => prev + 1);
  }, [bidDetails]);

  useEffect(() => {
    loadBuyerDetails();
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          buyerName: buyerDetails.buyerName,
          buyerId: id,
          sellerId: Auth.getUserId(),
          sellerName: Auth.getUserName(),
          amount: offerAmount,
          unitType: unitType,
        }),
      };
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/offers/add`,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then((res) => {
          console.log(res);
          if (res.errors != null || res.errors != undefined) {
            setErrors(res.errors);
          } else {
            const msg = {
              success: "Offer Successfully Send",
            };
            setErrors(msg);
            setOfferAmount("");
            loadBuyerDetails();
          }
        }).finally(()=>setIsLoading(false));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container px-0 lg:px-40 sm:px-10 md:px-20 ">
      <PreLoader isLoading={isLoading} />
      <main class="flex items-center p-10 sm:px-0 w-full h-full bg-white">
        <div class="border-t border-b pt-16 grid lg:grid-cols-5 md:grid-cols-1 gap-8">
          <div class="flex flex-col col-span-3">
            {buyerDetails?.buyerName ? (
              <div class="flex flex-col gap-2">
                <h1 class="capitalize text-3xl font-bold ">
                  {buyerDetails.buyerName}
                </h1>
                {/* <h1 class="capitalize text-xl font-semibold mt-4 ml-2 ">
                  Asking Price :{" "}
                  {buyerDetails.ratings && buyerDetails.ratings.askingPrice
                    ? buyerDetails.ratings.askingPrice
                    : "Not Mentioned"}
                </h1> */}
                <h1 class="capitalize text-xl font-semibold mb-5 ml-2 ">
                  {strings.ASKING_PRICE} &nbsp;&nbsp;&nbsp;:{" "}
                  {buyerDetails.price
                    ? buyerDetails.price + " RS"
                    : "Not Mentioned"}
                </h1>
                <h1 class="capitalize text-xl font-semibold ml-2 ">
                  {strings.RATINGS}
                </h1>
                <div className="grid grid-cols-5 gap-2 ml-2">
                  <div className={styles.detailName}>
                    <span>{strings.COLLECTION}</span>
                  </div>
                  <div className={styles.detailValue}>
                    {" "}
                    <ReactStars
                      key={`collection${starKeyForce}`}
                      count={5}
                      // onChange={ratingChanged}
                      edit={false}
                      value={
                        buyerDetails?.ratings?.collection
                          ? buyerDetails?.ratings?.collection
                          : 0
                      }
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>

                  <div className={styles.detailName}>
                    {strings.ARRIVAL_TIME}
                  </div>
                  <div className={styles.detailValue}>
                    {" "}
                    <ReactStars
                      key={`arrivalTime${starKeyForce}`}
                      count={5}
                      // onChange={ratingChanged}
                      edit={false}
                      value={
                        buyerDetails?.ratings?.arrivalTime
                          ? buyerDetails?.ratings?.arrivalTime
                          : 0
                      }
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>

                  <div className={styles.detailName}>{strings.PAYMENT}</div>
                  <div className={styles.detailValue}>
                    {" "}
                    <ReactStars
                      key={`payment${starKeyForce}`}
                      count={5}
                      // onChange={ratingChanged}
                      edit={false}
                      value={
                        buyerDetails?.ratings?.payment
                          ? buyerDetails?.ratings?.payment
                          : 0
                      }
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>

                  <div className={styles.detailName}>{strings.NEGOTIATION}</div>
                  <div className={styles.detailValue}>
                    {" "}
                    <ReactStars
                      key={`negotiation${starKeyForce}`}
                      count={5}
                      // onChange={ratingChanged}
                      edit={false}
                      value={
                        buyerDetails?.ratings?.negotiation
                          ? buyerDetails?.ratings?.negotiation
                          : 0
                      }
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>

                  <div className={styles.detailName}>{strings.STAFF}</div>
                  <div className={styles.detailValue}>
                    {" "}
                    <ReactStars
                      key={`staff${starKeyForce}`}
                      count={5}
                      // onChange={ratingChanged}
                      edit={false}
                      value={
                        buyerDetails?.ratings?.staff
                          ? buyerDetails?.ratings?.staff
                          : 0
                      }
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
                {getUserLevel() == "Seller" &&
                buyerDetails?.scaleMismatch === undefined ? (
                  <div className="grid grid-cols-0 sm:grid-cols-5 gap-2 p-4 bg-green-100 mt-5 rounded-xl shadow-sm">
                    <div className={styles.detailName + " font-semibold"}>
                      Contact Number
                    </div>
                    <div className={styles.detailValue + " font-bold"}>
                      {buyerDetails.phone ?? "N/A"}
                    </div>
                    {buyerDetails?.offerData && (
                      <>
                        <div className={styles.detailName + " font-semibold"}>
                          Offer Sent
                        </div>
                        <div className={styles.detailValue + " font-bold"}>
                          {buyerDetails?.offerData?.amount +
                            " per " +
                            buyerDetails?.offerData?.unitType || "unit"}
                        </div>
                      </>
                    )}
                    {Auth.getUserLevel() === "Seller" && (
                      <>
                        <div
                          className={
                            styles.detailName + " mt-5" + " font-semibold"
                          }
                        >
                          {buyerDetails.offerData
                            ? "Send Another Offer"
                            : "Send Your Offer"}
                        </div>

                        <input
                          type="number"
                          className="-ml-2 mt-2 form-control block h-10 px-3 py-1.5 text-base font-normal text-gray-700 bg-yellow-50 bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          value={offerAmount}
                          min={0}
                          onChange={(e) => setOfferAmount(e.target.value)}
                          id="exampleNumber0"
                          placeholder="0,00"
                        />
                        <select
                          type="number"
                          className="-ml-2 mt-2 form-control block  h-10 px-3 py-1.5 text-base font-normal text-gray-700 bg-yellow-50 bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          value={unitType}
                          onChange={(e) => setUnitType(e.target.value)}
                          id="exampleNumber0"
                          placeholder="0,00"
                          name="unitType"
                        >
                          <option value={UNIT_TYPES.UNIT}>Per Unit</option>
                          <option value={UNIT_TYPES.KILLO}>Per Killo</option>
                        </select>
                        <div className="sm:block hidden" />
                        <div className="sm:block hidden" />
                        <div />
                        <button
                          onClick={(e) => onSubmit(e)}
                          disabled={!Number(offerAmount) > 0}
                          type="button"
                          className="col-span-3 sm:col-auto inline-block h-10 px-2 py-1.0 mt-2 sm:w-36 w-full bg-green-400 disabled:bg-slate-300 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg focus:bg-green-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-600 active:shadow-lg transition duration-150 ease-in-out"
                        >
                          Confirm
                        </button>
                      </>
                    )}
                  </div>
                ) : buyerDetails?.scaleMismatch ? (
                  <div className="mt-4 p-4 bg-red-100 rounded-xl shadow-sm">
                    <p className="text-red-500 text-lg font-bold">
                      You are not eligible to place offers
                    </p>
                    <p>
                      The scale of your business does not align with the scale
                      of the buyer's business.
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-5 gap-2 p-4 bg-green-100 mt-5 rounded-xl shadow-sm">
                    <div className={styles.detailName + " font-semibold"}>
                      Contact Number
                    </div>
                    <div className={styles.detailValue + " font-bold"}>
                      {buyerDetails.phone}
                    </div>
                  </div>
                )}
                <div className="ml-2 mb-2">
                  {errorMessage.amount != null ? (
                    <strong className="text-red-500 text-sm">
                      {errorMessage.amount}
                    </strong>
                  ) : null}
                  {errorMessage.offer != null ? (
                    <strong className="text-red-500 text-sm">
                      {errorMessage.offer}
                    </strong>
                  ) : null}
                  {errorMessage.success != null ? (
                    <strong className="text-green-500 text-sm">
                      {errorMessage.success}
                    </strong>
                  ) : null}
                </div>
              </div>
            ) : forbidden ? (
              <center>
                <div class="flex justify-center items-center mt-32 flex-col">
                  <h1 className="text-2xl mt-10">
                    You have already confirmed an offer!!
                  </h1>
                  <h1 className="text-xl mt-4">
                    Please finish your order to see other buyers
                  </h1>
                </div>
              </center>
            ) : (
              <center>
                <div class="flex justify-center items-center mt-32">
                  <div
                    class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <h1 className="text-2xl mt-10">Please Wait....</h1>
              </center>
            )}
          </div>

          <div class="flex flex-col col-span-2 sm:col-span-2 justify-start order-first lg:order-last md:order-first mt-10 ml-10 md:mx-auto">
            <div
              class="flex flex-col  object-cover w-full justify-items-start border rounded-3xl shadow-lg overflow-hidden"
              style={{ minHeight: 320 }}
            >
              <img
                className="w-full h-full object-cover "
                src="https://images.unsplash.com/photo-1537191072641-5e19cc173c6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt="nike shoes"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default BuyerDetails;
