import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function SelectBoxRegister({
  items,
  title,
  onSelect,
  value,
  placeholder,
  onClickBtn,
  loading,
}) {
  // const [selected, setSelected] = useState(value ?? items?.[0]);
  const [query, setQuery] = useState(value?.label ?? "");

  const filteredPeople =
    query === ""
      ? items
      : items?.filter?.((person) =>
          person?.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toLowerCase()?.replace(/\s+/g, ""))
        );

  return (
    <div className="w-100">
      <h3 className="ml-2 font-semibold">{title}</h3>
      <Combobox value={value} onChange={onSelect}>
        <div className="relative mt-1">
          <div className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 mt-5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ">
            <Combobox.Input
              className="w-full bg-gray-200 border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none"
              displayValue={(person) => person.label}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
            />
            <Combobox.Button
              className="absolute inset-y-0 right-0 flex items-center pr-2"
              onClick={onClickBtn}
            >
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            {loading ? (
              <div className="flex flex-row gap-4 px-4 py-6">
                <span class="relative flex h-4 w-4 mt-1">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-4 w-4 bg-lime-500"></span>
                </span>
                <p>Loading Data...</p>
              </div>
            ) : (
              <Combobox.Options className="flex-col gap-0 px-0 py-0 z-50 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredPeople?.map?.((person) => (
                    <Combobox.Option
                      key={person.id}
                      className={({ active }) =>
                        `${
                          active
                            ? "text-amber-900 bg-amber-100"
                            : "text-gray-900"
                        }
                      cursor-default select-none relative py-2 pl-10 pr-4`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? "font-medium" : "font-normal"
                            } block truncate`}
                          >
                            {person?.label}
                          </span>
                          {selected ? (
                            <span
                              className={`${
                                active ? "text-amber-600" : "text-amber-600"
                              }
                            absolute inset-y-0 left-0 flex items-center pl-3`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            )}
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
