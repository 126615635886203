import React from "react";

const LanguageDropDown = () => {
  return (
    <div>
      <select
        onChange={(e) => {
          localStorage.setItem("lang", e.target.value);
          location.reload();
        }}
        value={localStorage.getItem("lang")}
      >
        <option value="en">En</option>
        <option value="si">Si</option>
      </select>
    </div>
  );
};

export default LanguageDropDown;
