export const HARVEST_DATE_TYPES = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINE: "decline",
};

export const REFERRAL_TYPES = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINE: "decline",
};

export const USER_TYPES = {
  SELLER: "Seller",
  BUYER: "Buyer",
  ADMIN: "Admin",
  OFFICER: "OFFICER",
};

export const UNIT_TYPES = {
  UNIT: "unit",
  KILLO: "killo",
};
