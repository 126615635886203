import React, { Fragment, useState } from "react";
import {
  CurrencyDollarIcon,
  HandIcon,
  ShoppingBagIcon,
  TemplateIcon,
  SearchCircleIcon,
} from "@heroicons/react/solid";
import { getUserLevel } from "../Authentication/Auth";
import strings from "../config/constants/strings";
import { Combobox, Transition } from "@headlessui/react";
import useCheckMobileScreen from "../common/hooks/isMobile";

function UserSidebar({ handleSidebar, isOpened, selected }) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease duration-500 transform "
      enterFrom="opacity-0 -translate-x-12"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-12"
      show={isOpened || !useCheckMobileScreen()}
    >
      <div
        className={` sm:flex sm:opacity-100   w-60 h-screen shadow-md bg-lime-100    pt-20 transition-all absolute sm:relative ${
          isOpened ? "z-40" : ""
        }`}
      >
        <ul class="relative">
          <li
            class={`relative w-60 mt-4 ${
              selected == "bids" ? "bg-lime-200 " : null
            }`}
          >
            <a
              class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
              href="/bids"
              data-mdb-ripple="true"
              data-mdb-ripple-color="dark"
            >
              <ShoppingBagIcon className="w-8 mx-2" />
              {strings.BIDS}
            </a>
          </li>
          <li
            class={`relative w-60 mt-4 ${
              selected == "offers" ? "bg-lime-200 " : null
            }`}
          >
            <a
              class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
              href={getUserLevel() == "Seller" ? "offers" : "offersBuyer"}
              data-mdb-ripple="true"
              data-mdb-ripple-color="dark"
            >
              <HandIcon className="w-8 mx-2" />
              {strings.OFFERS}
            </a>
          </li>
          {getUserLevel() == "Buyer" && (
            <li
              className={`relative w-60 mt-4 ${
                selected == "requirements" ? "bg-lime-200 " : null
              }`}
            >
              <a
                className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
                href={"requirementsBuyer"}
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <TemplateIcon className="w-8 mx-2" />
                {strings.REQUIREMENTS}
              </a>
            </li>
          )}
          {(getUserLevel() == "Buyer" || getUserLevel() == "Seller") && (
            <li
              className={`relative w-60 mt-4 ${
                selected == "transactions" ? "bg-lime-200 " : null
              }`}
            >
              <a
                className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-lime-300 transition duration-300 ease-in-out"
                href={"transactions"}
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                <CurrencyDollarIcon className="w-8 mx-2" />
                {strings.TRANSACTIONS}
              </a>
            </li>
          )}
        </ul>
      </div>
    </Transition>
  );
}

export default UserSidebar;
