import React from "react";
import ReactStars from "react-rating-stars-component";

function ListItemAd({
  name,
  district,
  scale,
  price,
  rating,
  noOfDays,
  id,
  onApprove,
  onDiscard,
  pricPerKillo,
}) {
  return (
    <div className="flex justify-center mb-4 hover:cursor-pointer ">
      <div class="flex flex-col p-4 sm:p-0 sm:flex-row w-screen sm:w-3/4 rounded-lg  shadow-lg lg:h-auto md:h-40 bg-lime-100">
        <img
          class="h-56 lg:h-36 md:h-auto object-cover  sm:w-36 rounded-t-lg md:rounded-lg m-4"
          src="https://images.unsplash.com/photo-1553787434-dd9eb4ea4d0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt=""
        />
        <div class="p-4 flex flex-col justify-start flex-grow">
          <h5 class="text-gray-900 text-lg font-semibold ">{name}</h5>

          <h5 class="text-gray-900 text-base  font-medium flex">
            <span className="font-semibold">District:</span> &nbsp;{district}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">Scale : </span> &nbsp; {scale}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">No of Days : </span> &nbsp;{" "}
            {noOfDays}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">Price per Unit : </span> &nbsp;{" "}
            {price}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">Price per Killo : </span> &nbsp;{" "}
            {pricPerKillo ?? "N/A"}
          </h5>

          {/* <div className="-mt-2">
              <ReactStars
                count={5}
                // onChange={ratingChanged}
                value={rating}
                edit={false}
                size={24}
                activeColor="#ffd700"
              />
            </div> */}
        </div>
        <div className="mt-2  mb-8 self-end	mr-8">
          <a
            className="bg-blue-500 p-2 text-white rounded-xl "
            href={"/viewBuyer" + id}
          >
            View Details
          </a>
          <button
            className="bg-green-500 p-2 text-white rounded-xl ml-4"
            href="/sellerAccount"
            onClick={onApprove}
          >
            Approve
          </button>
          <button
            className="bg-red-500 p-2 text-white rounded-xl ml-4"
            href="/sellerAccount"
            onClick={onDiscard}
          >
            Discard
          </button>
        </div>
      </div>
    </div>
  );
}

export default ListItemAd;
