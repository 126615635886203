import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import Logo from "../assets/logo_small.jpg";
import {
  getUserName,
  isAuthenticated,
  getUserLevel,
  logout,
  getUserLogins,
  getTokenName,
  getEstate,
} from "../Authentication/Auth";
import { useNavigate } from "react-router-dom";
import { USER_TYPES } from "../config/constants/general";
import { getNotifications, submitClick } from "./action";
import LangDropDown from "./LanguageDropDown";
import strings from "../config/constants/strings";
import {
  AdjustmentsIcon,
  BriefcaseIcon,
  ChartSquareBarIcon,
  HomeIcon,
  OfficeBuildingIcon,
  TruckIcon,
  UserGroupIcon,
  MenuAlt1Icon,
  MenuIcon,
  UserAddIcon
} from "@heroicons/react/solid";

function Nav({ page, handleSidebar, isSidebar = false }) {
  let navigate = useNavigate();
  const userType = getUserLevel();
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    loadNotifications();
  }, []);
  const loadNotifications = async () => {
    const response = await getNotifications();
    console.log(response);
    if (response.length) {
      setNotifications(response);
    }
  };

  const handleClick = async (id) => {
    const response = await submitClick(id);
    if (response) {
      init();
    }
  };

  const changeUser = async (name) => {
    localStorage.setItem("tokenName", name);
    location.reload();
  };

  return (
    <>
      <nav
        className="fixed
    z-50
    w-full
    flex flex-wrap
    items-center
    justify-between
    bg-gray-100
    text-gray-500
    hover:text-gray-700
    focus:text-gray-700
    shadow-lg
    navbar navbar-expand-lg navbar-light
    pt-2
    sm:py-2"
      >
        <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
          <img
            className="sm:hidden mb-2"
            src={Logo}
            style={{ height: 30 }}
            alt=""
            loading="lazy"
          />
          <div
            class="collapse navbar-collapse flex-grow items-center"
            id="navbarSupportedContent"
          >
            <a
              class=" flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mt-2 lg:mt-0 mr-1"
              href="home"
            >
              <img src={Logo} style={{ height: 35 }} alt="" loading="lazy" />
            </a>
            {isAuthenticated() ? (
              <ul class="navbar-nav flex flex-col pl-0 list-style-none mr-auto">
                {userType === "Admin" && (
                  <li class="nav-item p-2">
                    <a
                      class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                      href="admin"
                    >
                      Dashboard
                    </a>
                  </li>
                )}
                {userType === USER_TYPES.OFFICER && (
                  <li class="nav-item p-2">
                    <a
                      class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                      href="officerReferral"
                    >
                      Dashboard
                    </a>
                  </li>
                )}
                {userType !== USER_TYPES.OFFICER && (
                  <>
                    <li class="nav-item p-2">
                      <a
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href="sellers"
                      >
                        {strings.SELLERS}
                      </a>
                    </li>
                    <li class="nav-item p-2">
                      <a
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href="buyers"
                      >
                        {strings.BUYERS}
                      </a>
                    </li>

                    <li class="nav-item p-2">
                      <a
                        class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                        href={"bids"}
                      >
                        {strings.MY_BIDS}
                      </a>
                    </li>
                  </>
                )}

                {getUserLevel() === "Seller" ? (
                  <li class="nav-item p-2">
                    <a
                      class="nav-link text-gray-500 hover:text-gray-700 focus:text-gray-700 p-0"
                      href={"harvest"}
                    >
                      {strings.HARVEST}
                    </a>
                  </li>
                ) : null}
              </ul>
            ) : null}
          </div>

          {isAuthenticated() ? (
            <div class="flex items-center relative">
              <LangDropDown />

              <div class="dropdown relative">
                <a
                  class=" text-gray-500 hover:text-gray-700 focus:text-gray-700 mr-4 ml-4 dropdown-toggle hidden-arrow flex items-center"
                  href="#"
                  id="dropdownMenuButton1"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="bell"
                    class="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                    ></path>
                  </svg>
                  <span class="text-white bg-red-700 absolute rounded-full text-xs -mt-2.5 ml-2 py-0 px-1.5">
                    {notifications?.filter((n) => !n?.isClicked).length || 0}
                  </span>
                </a>
                <ul
                  class=" dropdown-menu w-72 max-w-sm absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none left-auto -right-10 md:right-0 "
                  aria-labelledby="dropdownMenuButton1"
                >
                  {notifications?.map((notification) => (
                    <li
                      className="flex flex-row hover:bg-gray-100 py-1 w-full"
                      key={notification?._id}
                      onClick={() =>
                        !notification?.isClicked &&
                        handleClick(notification?._id)
                      }
                    >
                      <div className="flex justify-center items-center py-0.5">
                        <div
                          className={` p-0.5 m-2 mx-2 h-full ${
                            !notification?.isClicked
                              ? "bg-green-600"
                              : "bg-gray-300 "
                          }`}
                        ></div>
                      </div>
                      <a
                        class={`dropdown-item text-sm py-1 pr-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 `}
                        href={notification?.link}
                      >
                        <div>
                          <h2>
                            <b>{notification?.title}</b>
                          </h2>
                          <p>{notification?.body}</p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div class="dropdown relative">
                <a
                  class="dropdown-toggle flex items-center hidden-arrow"
                  href="#"
                  id="dropdownMenuButton2"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="https://png.pngtree.com/png-clipart/20210915/ourlarge/pngtree-avatar-placeholder-abstract-white-blue-green-png-image_3918476.jpg"
                    class="rounded-full"
                    style={{ height: 25, width: 25 }}
                    alt=""
                    loading="lazy"
                  />
                  <div>
                    <h1 className="ml-2">{getUserName()}</h1>
                    {getEstate() && (
                      <p className="ml-2 text-xs text-red-900">
                        {`(${getEstate()})`}
                      </p>
                    )}
                  </div>
                </a>
                <ul
                  class=" dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none left-auto right-0"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {getUserLogins().map(
                    (item, index) =>
                      ((getTokenName()?.length &&
                        getTokenName() != item?.tokenName) ||
                        (!getTokenName()?.length &&
                          getUserLogins()[0].tokenName != item?.tokenName)) && (
                        <li>
                          <a
                            class="dropdown-toggle flex items-center hidden-arrow mx-4 my-2"
                            href="#"
                            id="dropdownMenuButton2"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => changeUser(item?.tokenName)}
                          >
                            <img
                              src="https://png.pngtree.com/png-clipart/20210915/ourlarge/pngtree-avatar-placeholder-abstract-white-blue-green-png-image_3918476.jpg"
                              class="rounded-full"
                              style={{ height: 25, width: 25 }}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h1 className="ml-2">{item.name}</h1>
                              {item?.estate && (
                                <p className="ml-2 text-xs text-red-900">
                                  ({item?.estate})
                                </p>
                              )}
                            </div>
                          </a>
                        </li>
                      )
                  )}
                  {getUserLevel() === "Seller" && (
                    <li>
                      <a
                        class=" dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                        href="/login/multiple"
                      >
                        <div className="flex flex-row items-center">
                          <UserAddIcon width={24} />
                          <p className={"ml-2"}>Add another account</p>
                        </div>
                      </a>
                    </li>
                  )}
                  {getUserLevel() === "Buyer" && (
                    <li>
                      <a
                        class=" dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                        href="/postAdSeller"
                      >
                        Publish Ad
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      class=" dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                      href="/myAccount"
                    >
                      My Account
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                      onClick={() =>
                        logout((res) => {
                          if (res) {
                            navigate("/");
                          }
                        })
                      }
                    >
                      {getUserLogins()?.length>1
                        ? "Logout from all accounts"
                        : "Logout"}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <a href="/login">Login</a>
          )}
        </div>

        {isAuthenticated() && (
          <>
            {isSidebar && (
              <div
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                  handleSidebar();
                }}
              >
                {isSidebarOpen ? (
                  <MenuAlt1Icon className="pl-1 sm:hidden" width={30} />
                ) : (
                  <MenuIcon className="pl-1 sm:hidden" width={30} />
                )}
              </div>
            )}

            <div
              className={`flex bg-green-100 h-10 flex-row sm:hidden overflow-x-auto space-x-8 no-scrollbar px-4 ${
                isSidebar ? "w-11/12" : "w-full"
              }`}
            >
              <a
                href="/"
                className={`flex flex-1 justify-center items-center px-2 leading-4 ${
                  page == "home" ? "bg-green-300" : ""
                }`}
              >
                <div className="flex flex-row items-center gap-2">
                  <HomeIcon width={24} color="green" />
                  <p
                    className={`whitespace-nowrap ${
                      page == "home"
                        ? "text-green-900 font-semibold"
                        : "text-gray-700 text-md"
                    }`}
                  >
                    {strings.HOME}
                  </p>
                </div>
              </a>
              {userType === USER_TYPES.ADMIN && (
                <a
                  href="/admin"
                  className={`flex flex-1 justify-center items-center px-2 ${
                    page == "admin" ? "bg-green-300" : ""
                  }`}
                >
                  <div className="flex flex-row items-center gap-2">
                    <ChartSquareBarIcon width={24} color="green" />
                    <p
                      className={`${
                        page == "admin"
                          ? "text-green-900 font-semibold"
                          : "text-gray-700 text-md"
                      }`}
                    >
                      Dashboard
                    </p>
                  </div>
                </a>
              )}
              {userType !== USER_TYPES.OFFICER && (
                <>
                  <a
                    href="/buyers"
                    className={`flex flex-1 justify-center items-center  px-2 leading-4 ${
                      page == "buyers" ? "bg-green-300" : ""
                    }`}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <UserGroupIcon width={24} color="green" />
                      <p
                        className={`${
                          page == "buyers"
                            ? "text-green-900 font-semibold"
                            : "text-gray-700 text-md"
                        } whitespace-nowrap`}
                      >
                        {strings.BUYERS}
                      </p>
                    </div>
                  </a>
                  <a
                    href="/sellers"
                    className={`flex  flex-1  justify-center items-center  px-2 leading-4 ${
                      page == "sellers" ? "bg-green-300" : ""
                    } whitespace-nowrap`}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <OfficeBuildingIcon width={24} color="green" />
                      <p
                        className={`${
                          page == "sellers"
                            ? "text-green-900 font-semibold"
                            : "text-gray-700 text-md"
                        }`}
                      >
                        {strings.SELLERS}
                      </p>
                    </div>
                  </a>
                  <a
                    href="/bids"
                    className={`flex  flex-1  justify-center items-center px-2 leading-4 w-38 ${
                      page == "offers" ? "bg-green-300" : ""
                    }`}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <BriefcaseIcon width={24} color="green" />
                      <p
                        className={`${
                          page == "bids" || page == "offers"
                            ? "text-green-900 font-semibold"
                            : "text-gray-700 text-md"
                        } whitespace-nowrap`}
                      >
                        {strings.MY_BIDS}
                      </p>
                    </div>
                  </a>
                  {userType === USER_TYPES.SELLER && (
                    <a
                      href="/harvest"
                      className={`flex flex-1 justify-center items-center  px-2 leading-4 ${
                        page == "harvest" ? "bg-green-300" : ""
                      } whitespace-nowrap`}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <TruckIcon width={24} color="green" />
                        <p
                          className={`${
                            page == "offers"
                              ? "text-green-900 font-semibold"
                              : "text-gray-700 text-md"
                          }`}
                        >
                          {strings.HARVEST}
                        </p>
                      </div>
                    </a>
                  )}
                </>
              )}
              {userType === USER_TYPES.OFFICER && (
                <a
                  href="/officerReferral"
                  className={`flex flex-1 justify-center items-center  px-2 ${
                    page == "admin" ? "bg-green-300" : ""
                  }`}
                >
                  <div className="flex flex-row items-center gap-2">
                    <ChartSquareBarIcon width={24} color="green" />
                    <p
                      className={`${
                        page == "admin"
                          ? "text-green-900 font-semibold"
                          : "text-gray-700 text-md"
                      }`}
                    >
                      Dashboard
                    </p>
                  </div>
                </a>
              )}
            </div>
          </>
        )}
      </nav>
    </>
  );
}

export default Nav;
