import React from "react";
import { Dialog } from "@headlessui/react";

export default function Modal({
  handleOk,
  handleCancel,
  open,
  title,
  children,
  full,
  isDisabled=false,
  isSingleButton=false,
  submitButtonTitle="OK"
}) {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      className="fixed z-10 inset-0 overflow-y-auto p-4"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div
          className={`relative bg-white rounded w-full ${
            full ? "md:w-8/12" : "md:w-4/12"
          } mx-auto p-10 pt-7`}
        >
          <Dialog.Title className="font-semibold text-center mb-5 text-xl ">
            {title}
          </Dialog.Title>
          <Dialog.Description>
            <div>{children}</div>
            <div>
              <div className="flex justify-center mt-3 min-w-md">
                <button
                  className="bg-green-300 m-3 px-6 py-2 w-1/2 disabled:bg-gray-200 "
                  disabled={isDisabled}
                  onClick={handleOk}
                >
                  {submitButtonTitle}
                </button>
                {!isSingleButton && (
                  <button
                    className="bg-red-300 m-3  px-3 py-2 w-1/2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </Dialog.Description>
        </div>
      </div>
    </Dialog>
  );
}
