export const timeString = (utcDate) => {
  return new Date(utcDate).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const slashDate = (date) => {
  return date?.split("-").reverse().join("/");
};

export const dateString = (utcDate) => {
  return utcDate?.split("T")[0];
};

export const getFormattedDate = (date) => {
  return new Date(date).toLocaleDateString().replace(/\//g, "-");
};

export const addDays = (date, number) => {
  const newDate = new Date(date);
  return new Date(newDate.setDate(newDate.getDate() + number));
};

export const subDays = (date, number) => {
  const newDate = new Date(date);
  return new Date(newDate.setDate(newDate.getDate() - number));
};

export const daysBetween = (startDate, endDate, number = 0) => {
  const newDate = addDays(endDate, number);
  const diff = Math.floor(
    (Date.parse(newDate) - Date.parse(startDate)) / 86400000
  );
  return diff > 0 ? diff : 0;
};

export const maskedUsername = (string) => {
  let fileExt = string.substr(0, string?.lastIndexOf(".") - 1);
  if (string.length > 10) {
    return string.substr(0, 10) + "..." + fileExt;
  }
  return string;
};

export const getFirstDateOfMonth = () => {
  const newDate = new Date();
  return new Date(newDate.getFullYear(), newDate.getMonth() - 1, 1);
};
