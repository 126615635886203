import React from "react";
import DatePicker from "react-datepicker";
import { ArrowSmRightIcon } from "@heroicons/react/solid";
import { getFirstDateOfMonth } from "../../common/utils";

export const DateFilter = ({startDate,endDate,handleChange,startDateName='startDate',endDateName='endDate'}) => {
  return (
    <div className="">
      <div>
        <p className="pl-2"> Date Range</p>
      </div>
      <div className="flex ">
        <div className="w-2/5">
          <DatePicker
            showYearDropdown
            showIcon
            name={startDateName}
            placeholderText="From Date"
            dateFormat={"dd/MM/yyyy"}
            selected={startDate}
            onChange={(e) =>
              handleChange({
                target: {
                  name: startDateName,
                  value: e,
                },
              })
            }
            selectsStart
            // minDate={new Date()}
            maxDate={new Date()}
            startDate={getFirstDateOfMonth()}
            endDate={endDate}
            yearDropdownItemNumber={3}
            dropdownMode="scroll"
            className="p-2 rounded-sm text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
        </div>
        <div className="w-1/5">
          <ArrowSmRightIcon className="w-8 mx-2" />
        </div>
        <div className="w-2/5">
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            showIcon
            name={endDateName}
            placeholderText="To Date"
            selected={endDate}
            onChange={(e) =>
              handleChange({
                target: {
                  name: endDateName,
                  value: e,
                },
              })
            }
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate || new Date()}
            maxDate={new Date()}
            className="p-2 rounded-sm text-sm text-gray-900 bg-transparent border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
        </div>
      </div>
    </div>
  );
};
