import { DownloadIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.jpg";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  return (
    <div class={`fixed top-20 right-0 w-80 z-20 ${supportsPWA && isVisible  ? "block" : "hidden"}`}>
      <div class="bg-slate-900 flex  h-32 text-slate-100 mx-2 py-4 rounded-lg shadow hover:shadow-xl transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear">
        <div class="w-full absolute">
          <button
            onClick={() => setIsVisible(false)}
            class="absolute float-right right-5 bg-slate-800 hover:bg-slate-700/70 text-slate-400 hover:text-slate-200 h-7 w-7 rounded-full flex justify-center items-center"
          >
            <svg class="h-4 w-4 fill-current items-center" viewBox="0 0 20 20">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div className="mt-2 ml-4 flex flex-row">
          <img
            alt="Polmure logo"
            className="h-20 w-20 object-cover mx-auto rounded-2xl shadow-lg"
            src={Logo}
          ></img>
          <div className="ml-4">
            <h3 className="font-semibold">Need to install the <br/>App?</h3>
            <button
              onClick={onClick}
              className=" text-gray-900 border-2 mt-2 py-0 px-3 rounded-full
        font-semibold bg-white
        shadow-sm shadow-white"
            >
              <DownloadIcon className="w-5 mx-0" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallPWA;
