import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import AdminSidebar from "../components/AdminSidebar";
import { getToken } from "../Authentication/Auth";
import ListItemHarvest from "../components/ListItemHarvest";
import { HARVEST_DATE_TYPES } from "../config/constants/general";

function HarvestDates() {
  const [isOpened, setIsOpened] = useState(false);
  const [harvestReqsCnt, setHarvestReqsCnt] = useState();
  const [harvestHisCnt, setHarvestHisCnt] = useState();

  useEffect(() => {
    loadHarvestData();
  }, []);

  const dateString = (utcDate) => {
    return new Date(utcDate);
  };

  const loadHarvestData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/harvest/changeRequests`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        const reqCnt = response.filter(
          (item) => item?.harvest?.status === HARVEST_DATE_TYPES.PENDING && item
        );
        const hisCnt = response.filter(
          (item) => item?.harvest?.status !== HARVEST_DATE_TYPES.PENDING && item
        );
        setHarvestReqsCnt(reqCnt);
        setHarvestHisCnt(hisCnt);
      })
      .catch((error) => console.log(error));
  };

  const harvestDataEdit = (type, id) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/harvest/harvestDateConfirm`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ type: type, id: id }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        loadHarvestData();
      })
      .catch((error) => console.log(error));
  };

  const handleSidebar = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className="h-screen flex">
      <AdminSidebar
        handleSidebar={handleSidebar}
        isOpened={isOpened}
        selected="harvest"
      />
      <div className="flex-1 flex overflow-hidden pt-10 ">
        <div class="flex-1 overflow-y-scroll">
          <ArrowRightIcon
            className={`w-10 ml-4 ${isOpened ? "opacity:0" : " md:hidden"}`}
            onClick={handleSidebar}
          />
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-hidden">
                <ul
                  class=" nav nav-tabs nav-justified flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0  fixed w-3/4 bg-white z-20"
                  id="tabs-tabJustify"
                  role="tablist"
                >
                  <li
                    class="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-homeJustify"
                      class=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                      id="tabs-home-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-homeJustify"
                      role="tab"
                      aria-controls="tabs-homeJustify"
                      aria-selected="true"
                    >
                      Change Requests
                    </a>
                  </li>
                  <li
                    class="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-profileJustify"
                      class=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent                           "
                      id="tabs-profile-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-profileJustify"
                      role="tab"
                      aria-controls="tabs-profileJustify"
                      aria-selected="false"
                    >
                      History
                    </a>
                  </li>
                </ul>
                <div class="tab-content mt-20" id="tabs-tabContentJustify">
                  <div
                    class="tab-pane fade show active"
                    id="tabs-homeJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-home-tabJustify"
                  >
                    <div className="items-start">
                      {harvestReqsCnt?.map(
                        (item, i) => (
                          <ListItemHarvest
                            name={item?.name}
                            district={"Colombo"}
                            landline={item?.landLine}
                            mobile1={item?.mobile1}
                            mobile2={item?.mobile2}
                            scale={item?.scale}
                            rating={5}
                            state={item?.state}
                            date={item?.harvest?.harvestDate}
                            status={item?.harvest?.status}
                            lastUpdated={null}
                            requestCount={item?.requestCount}
                            onApprove={() =>
                              harvestDataEdit(
                                HARVEST_DATE_TYPES.APPROVED,
                                item?.harvest?._id
                              )
                            }
                            onDiscard={() =>
                              harvestDataEdit(
                                HARVEST_DATE_TYPES.DECLINE,
                                item?.harvest?._id
                              )
                            }
                          />
                        )
                        // if (i === 0) {
                        //   return <h1 className="text-center mt-28 text-lg font-bold w-5/5" >No Data...</h1>;
                        // }
                      )}
                      {harvestReqsCnt?.length === 0 && (
                        <h1 className="text-center mt-28 text-lg font-bold w-5/5">
                          No Data...
                        </h1>
                      )}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="tabs-profileJustify"
                    role="tabpanel"
                    aria-labelledby="tabs-profile-tabJustify"
                  >
                    <div className="items-start">
                      {harvestHisCnt?.map((item, i) => (
                        <ListItemHarvest
                          name={item?.name}
                          district={"Colombo"}
                          landline={item?.landLine}
                          mobile1={item?.mobile1}
                          mobile2={item?.mobile2}
                          scale={item?.scale}
                          rating={5}
                          state={item?.state}
                          date={item?.harvest?.harvestDate}
                          status={item?.harvest?.status}
                          requestCount={null}
                          lastUpdated={dateString(
                            item?.harvest?.updatedAt
                          ).toLocaleString()}
                          onApprove={() =>
                            harvestDataEdit(
                              HARVEST_DATE_TYPES.APPROVED,
                              item?.harvest?._id
                            )
                          }
                          onDiscard={() =>
                            harvestDataEdit(
                              HARVEST_DATE_TYPES.DECLINE,
                              item?.harvest?._id
                            )
                          }
                        />
                      ))}
                      {harvestHisCnt?.length === 0 && (
                        <h1 className="text-center mt-28 text-lg font-bold w-5/5">
                          No Data...
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HarvestDates;
